import PropTypes from 'prop-types';

const StarSvg = ({ color, width, height }) => {
  return (
    <svg
      width={`${width || 24}`}
      height={`${height || 24}`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M5.74035 16C5.85035 15.51 5.65035 14.81 5.30035 14.46L2.87035 12.03C2.11035 11.27 1.81035 10.46 2.03035 9.76C2.26035 9.06 2.97035 8.58 4.03035 8.4L7.15035 7.88C7.60035 7.8 8.15035 7.4 8.36035 6.99L10.0803 3.54C10.5803 2.55 11.2603 2 12.0003 2C12.7403 2 13.4203 2.55 13.9203 3.54L15.6403 6.99C15.7703 7.25 16.0403 7.5 16.3303 7.67L5.56035 18.44C5.42035 18.58 5.18035 18.45 5.22035 18.25L5.74035 16Z"
        fill={color}
      />
      <path
        d="M18.6998 14.4599C18.3398 14.8199 18.1398 15.5099 18.2598 15.9999L18.9498 19.0099C19.2398 20.2599 19.0598 21.1999 18.4398 21.6499C18.1898 21.8299 17.8898 21.9199 17.5398 21.9199C17.0298 21.9199 16.4298 21.7299 15.7698 21.3399L12.8398 19.5999C12.3798 19.3299 11.6198 19.3299 11.1598 19.5999L8.2298 21.3399C7.1198 21.9899 6.1698 22.0999 5.5598 21.6499C5.3298 21.4799 5.1598 21.2499 5.0498 20.9499L17.2098 8.7899C17.6698 8.3299 18.3198 8.1199 18.9498 8.2299L19.9598 8.3999C21.0198 8.5799 21.7298 9.0599 21.9598 9.7599C22.1798 10.4599 21.8798 11.2699 21.1198 12.0299L18.6998 14.4599Z"
        fill={color}
      />
    </svg>
  );
};

StarSvg.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default StarSvg;
