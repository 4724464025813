import PropTypes from 'prop-types';

const GoldMedalSvg = ({ width, height, palette = [] }) => {
  return (
    <svg
      width={`${width || 70}`}
      height={`${height || 70}`}
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35 0C20.3 0 8.33984 12.0969 8.33984 26.7969C8.33984 36.2101 13.2568 44.4809 20.6445 49.226L25.1468 57.5658H46.2901L49.3555 49.226C56.7432 44.4808 61.6602 36.2101 61.6602 26.7969C61.6602 12.0969 49.6999 0 35 0Z"
        fill={`url(#paint0_linear_125_10674${width + height})`}
      />
      <path
        d="M20.6445 49.226V67.9492C20.6445 68.7062 21.0611 69.4012 21.726 69.7577C22.3949 70.1122 23.1999 70.0761 23.8328 69.6556L35 62.2114L46.1672 69.6555C46.8058 70.0788 47.6112 70.1109 48.274 69.7576C48.9389 69.4012 49.3555 68.7062 49.3555 67.9491V49.226C45.2063 51.8912 40.2866 53.457 35 53.457C29.7134 53.457 24.7937 51.8912 20.6445 49.226ZM35 45.2539C45.1778 45.2539 53.457 36.9746 53.457 26.7969C53.457 16.6191 45.1778 8.33984 35 8.33984C24.8222 8.33984 16.543 16.6191 16.543 26.7969C16.543 36.9746 24.8222 45.2539 35 45.2539ZM25.2468 23.6286C25.4871 22.8855 26.132 22.3448 26.9011 22.2327L31.227 21.6038L33.1615 17.6865C33.8505 16.2846 36.1495 16.2846 36.8386 17.6865L38.7732 21.6038L43.0991 22.2327C43.8681 22.3448 44.513 22.8855 44.7534 23.6286C44.9937 24.3696 44.7934 25.1847 44.2326 25.7294L41.1044 28.7796L41.8454 33.0874C41.9776 33.8565 41.6611 34.6336 41.0282 35.0921C40.4186 35.5373 39.5825 35.6238 38.8693 35.2484L35 33.2155L31.1307 35.2483C30.4418 35.6088 29.6007 35.5547 28.9718 35.092C28.3389 34.6335 28.0226 33.8563 28.1546 33.0873L28.8956 28.7794L25.7674 25.7292C25.2067 25.1847 25.0064 24.3696 25.2468 23.6286Z"
        fill={`url(#paint1_linear_125_10674${width + height})`}
      />
      <defs>
        <linearGradient
          id={`paint0_linear_125_10674${width + height}`}
          x1="35"
          y1="57.5658"
          x2="35"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[0]} />
          <stop offset="1" stopColor={palette[1]} />
        </linearGradient>
        <linearGradient
          id={`paint1_linear_125_10674${width + height}`}
          x1="35"
          y1="69.9999"
          x2="35"
          y2="8.33984"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[2]} />
          <stop offset="1" stopColor={palette[3]} />
        </linearGradient>
      </defs>
    </svg>
  );
};

GoldMedalSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  palette: PropTypes.arrayOf(PropTypes.string),
};

export default GoldMedalSvg;
