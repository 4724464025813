import PropTypes from 'prop-types';

const PhoneSvg = ({ color, width, height }) => {
  return (
    <svg
      width={`${width || 16}`}
      height={`${height || 16}`}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M2.03749 3.25907L2.0375 3.25907L2.03853 3.25644C2.16996 2.92241 2.37986 2.60975 2.68303 2.32374L2.68311 2.32382L2.69068 2.31637C3.04187 1.97066 3.38783 1.83337 3.72659 1.83337C3.84324 1.83337 3.95535 1.8586 4.05022 1.90414L4.0502 1.90419L4.05706 1.90735C4.15611 1.95307 4.23713 2.01738 4.30216 2.11131L4.30213 2.11133L4.30546 2.11603L5.85213 4.29603L5.85212 4.29603L5.85415 4.29886C5.95604 4.44037 6.02156 4.5594 6.06381 4.66269L6.06375 4.66272L6.06701 4.67033C6.10752 4.76486 6.11992 4.83721 6.11992 4.88004C6.11992 4.9394 6.10335 5.01039 6.05282 5.0934L6.04862 5.10031L6.04464 5.10735C5.9859 5.21127 5.89038 5.33581 5.75303 5.47315L5.75297 5.47309L5.74626 5.48006L5.24231 6.0039C5.07032 6.17804 4.99325 6.39389 4.99325 6.62004C4.99325 6.72074 5.00664 6.80848 5.02818 6.89464L5.03509 6.92227L5.04509 6.94894C5.05815 6.98378 5.07127 7.01472 5.07958 7.03431C5.08042 7.03628 5.0812 7.03813 5.08194 7.03986C5.0906 7.06034 5.0923 7.06502 5.09231 7.06501L5.10632 7.10705L5.12764 7.14613C5.27127 7.40945 5.50271 7.72589 5.80489 8.08301L5.80487 8.08303L5.8085 8.08723C6.11359 8.43977 6.44074 8.8011 6.79635 9.16354L6.79625 9.16364L6.80471 9.17187L6.80615 9.17327C6.9146 9.27871 7.02451 9.38557 7.13546 9.49072L5.66467 10.9615C5.553 10.8562 5.4429 10.7492 5.33439 10.6407C4.66547 9.96524 4.0625 9.25839 3.52499 8.52025C2.9965 7.78537 2.57666 7.05816 2.27378 6.34532C1.97503 5.63558 1.83325 4.97517 1.83325 4.36004C1.83325 3.96596 1.90268 3.5961 2.03749 3.25907Z"
        fill={color}
        stroke={color}
      />
      <path
        d="M14.6466 12.22C14.6466 12.4067 14.6132 12.6 14.5466 12.7867C14.5266 12.84 14.5066 12.8933 14.4799 12.9467C14.3666 13.1867 14.2199 13.4133 14.0266 13.6267C13.6999 13.9867 13.3399 14.2467 12.9332 14.4133C12.9266 14.4133 12.9199 14.42 12.9132 14.42C12.5199 14.58 12.0932 14.6667 11.6332 14.6667C10.9532 14.6667 10.2266 14.5067 9.4599 14.18C8.69324 13.8533 7.92657 13.4133 7.16657 12.86C6.90657 12.6667 6.64657 12.4733 6.3999 12.2667L8.5799 10.0867C8.76657 10.2267 8.93324 10.3333 9.07324 10.4067C9.10657 10.42 9.14657 10.44 9.19324 10.46C9.24657 10.48 9.2999 10.4867 9.3599 10.4867C9.47324 10.4867 9.5599 10.4467 9.63324 10.3733L10.1399 9.87332C10.3066 9.70665 10.4666 9.57999 10.6199 9.49999C10.7732 9.40665 10.9266 9.35999 11.0932 9.35999C11.2199 9.35999 11.3532 9.38665 11.4999 9.44665C11.6466 9.50665 11.7999 9.59332 11.9666 9.70665L14.1732 11.2733C14.3466 11.3933 14.4666 11.5333 14.5399 11.7C14.6066 11.8667 14.6466 12.0333 14.6466 12.22Z"
        fill={color}
      />
    </svg>
  );
};

PhoneSvg.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default PhoneSvg;
