import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { TipsService } from '_services';
import { processError } from '_helpers/api';

export const subscribe = createAsyncThunk(
  'tips/subscribe',
  async function (body, { rejectWithValue }) {
    try {
      await TipsService.subscribe({ body });
    } catch (e) {
      return rejectWithValue(processError(e));
    }
  }
);

const tipsSlice = createSlice({
  name: 'tips',
  initialState: {
    result: false,
    error: null,
    loading: false,
  },
  reducers: {
    resetResult(state) {
      state.result = false;
    },
    resetError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(subscribe.pending, (state) => {
        state.result = false;
        state.error = null;
        state.loading = true;
      })
      .addCase(subscribe.fulfilled, (state) => {
        state.loading = false;
        state.result = true;
      })
      .addCase(subscribe.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export const { resetResult, resetError } = tipsSlice.actions;

export default tipsSlice.reducer;
