import PropTypes from 'prop-types';

const LockSvg = ({ color, width, height }) => {
  return (
    <svg
      width={`${width || 50}`}
      height={`${height || 50}`}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M25.0003 45.8333C36.5063 45.8333 45.8337 36.5059 45.8337 25C45.8337 13.494 36.5063 4.16663 25.0003 4.16663C13.4944 4.16663 4.16699 13.494 4.16699 25C4.16699 36.5059 13.4944 45.8333 25.0003 45.8333Z"
        fill={color}
      />
      <path
        d="M32.8128 22.3542V20.8334C32.8128 18.8959 32.8128 13.0209 25.0003 13.0209C17.1878 13.0209 17.1878 18.8959 17.1878 20.8334V22.3542C14.6462 22.9167 13.792 24.5625 13.792 28.125V30.2084C13.792 34.7917 15.2087 36.2084 19.792 36.2084H30.2087C34.792 36.2084 36.2087 34.7917 36.2087 30.2084V28.125C36.2087 24.5625 35.3545 22.9167 32.8128 22.3542ZM25.0003 31.4584C23.7295 31.4584 22.7087 30.4375 22.7087 29.1667C22.7087 27.8959 23.7295 26.875 25.0003 26.875C26.2712 26.875 27.292 27.8959 27.292 29.1667C27.292 30.4375 26.2712 31.4584 25.0003 31.4584ZM29.6878 22.125H20.3128V20.8334C20.3128 17.7917 21.0628 16.1459 25.0003 16.1459C28.9378 16.1459 29.6878 17.7917 29.6878 20.8334V22.125Z"
        fill={color}
      />
    </svg>
  );
};

LockSvg.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default LockSvg;
