import PropTypes from 'prop-types';

const EditSvg = ({ color, width, height }) => {
  return (
    <svg
      width={`${width || 24}`}
      height={`${height || 25}`}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M15.48 3.5H7.52C4.07 3.5 2 5.56 2 9.02V16.97C2 20.44 4.07 22.5 7.52 22.5H15.47C18.93 22.5 20.99 20.44 20.99 16.98V9.02C21 5.56 18.93 3.5 15.48 3.5Z"
        fill={color}
      />
      <path
        d="M21.0205 3.48003C19.2305 1.68003 17.4805 1.64003 15.6405 3.48003L14.5105 4.60003C14.4105 4.70003 14.3805 4.84003 14.4205 4.97003C15.1205 7.42003 17.0805 9.38003 19.5305 10.08C19.5605 10.09 19.6105 10.09 19.6405 10.09C19.7405 10.09 19.8405 10.05 19.9105 9.98003L21.0205 8.86003C21.9305 7.95003 22.3805 7.08003 22.3805 6.19003C22.3805 5.29003 21.9305 4.40003 21.0205 3.48003Z"
        fill={color}
      />
      <path
        d="M17.8601 10.9201C17.5901 10.7901 17.3301 10.6601 17.0901 10.5101C16.8901 10.3901 16.6901 10.2601 16.5001 10.1201C16.3401 10.0201 16.1601 9.87009 15.9801 9.72009C15.9601 9.71009 15.9001 9.66009 15.8201 9.58009C15.5101 9.33009 15.1801 8.99009 14.8701 8.62009C14.8501 8.60009 14.7901 8.54009 14.7401 8.45009C14.6401 8.34009 14.4901 8.15009 14.3601 7.94009C14.2501 7.80009 14.1201 7.60009 14.0001 7.39009C13.8501 7.14009 13.7201 6.89009 13.6001 6.63009C13.4701 6.35009 13.3701 6.09009 13.2801 5.84009L7.9001 11.2201C7.5501 11.5701 7.2101 12.2301 7.1401 12.7201L6.7101 15.7001C6.6201 16.3301 6.7901 16.9201 7.1801 17.3101C7.5101 17.6401 7.9601 17.8101 8.4601 17.8101C8.5701 17.8101 8.6801 17.8001 8.7901 17.7901L11.7601 17.3701C12.2501 17.3001 12.9101 16.9701 13.2601 16.6101L18.6401 11.2301C18.3901 11.1501 18.1401 11.0401 17.8601 10.9201Z"
        fill={color}
      />
    </svg>
  );
};

EditSvg.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default EditSvg;
