import PropTypes from 'prop-types';

const DevelopmentSvg = ({ width, height, palette = [] }) => {
  return (
    <svg
      width={`${width || 80}`}
      height={`${height || 80}`}
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath={`url(#clip0_504_12980${width + height})`}>
        <path
          d="M22.9059 19.5929C21.9904 18.6774 20.5072 18.6774 19.5917 19.5929L14.9042 24.2804C13.9887 25.1958 13.9887 26.6791 14.9042 27.5946C15.8197 28.51 17.3029 28.51 18.2184 27.5946L18.6968 27.1161C16.6961 55.615 0.683092 75.979 0.51153 76.1936C-0.0492513 76.8963 -0.159095 77.8622 0.232311 78.6724C0.621374 79.4825 1.44294 79.9999 2.34247 79.9999H11.8623L20.5237 75.9466L20.6156 71.0718C21.8901 63.9046 23.3514 50.4766 23.5567 26.8721L24.2792 27.5946C25.1947 28.51 26.6779 28.51 27.5934 27.5946C28.5089 26.6791 28.5089 25.1958 27.5934 24.2804L22.9059 19.5929ZM79.4861 76.1913C79.3143 75.9789 63.2961 55.6714 61.2993 27.1147L61.7792 27.5946C62.6947 28.51 64.1779 28.51 65.0934 27.5946C66.0089 26.6791 66.0089 25.1958 65.0934 24.2804L60.4059 19.5929C59.4904 18.6774 58.0072 18.6774 57.0917 19.5929L52.4042 24.2804C51.4887 25.1958 51.4887 26.6791 52.4042 27.5946C53.3197 28.51 54.8029 28.51 55.7184 27.5946L56.4409 26.8721C56.6431 50.1363 58.0648 63.4935 59.3262 70.7319L58.2815 75.3124L66.697 79.9999H77.655C78.5567 79.9999 79.3762 79.4825 79.7676 78.6724C80.1567 77.8599 80.0492 76.8963 79.4861 76.1913Z"
          fill={`url(#paint0_linear_504_12980${width + height})`}
        />
        <path
          d="M59.3252 70.7321C48.5424 54.4622 45.0245 33.9461 44.7432 14.2188H49.3729C50.3204 14.2188 51.1765 13.6489 51.538 12.7722C51.902 11.8957 51.7006 10.8885 51.0299 10.2179L41.6549 0.686602C40.7395 -0.228867 39.2562 -0.228867 38.3407 0.686602L28.9657 10.2179C28.2951 10.8885 28.0937 11.8955 28.4576 12.7722C28.8191 13.6488 29.6752 14.2188 30.6227 14.2188H35.2579C35.0138 34.7555 32.4109 55.7566 20.6146 71.0719C18.1215 74.3089 15.243 77.3138 11.8613 80H66.696C63.9149 77.1064 61.4871 73.9943 59.3252 70.7321Z"
          fill={`url(#paint1_linear_504_12980${width + height})`}
        />
      </g>
      <defs>
        <linearGradient
          id={`paint0_linear_504_12980${width + height}`}
          x1="39.9994"
          y1="79.9999"
          x2="39.9994"
          y2="18.9063"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[2]} />
          <stop offset="1" stopColor={palette[3]} />
        </linearGradient>
        <linearGradient
          id={`paint1_linear_504_12980${width + height}`}
          x1="12.3503"
          y1="79.2782"
          x2="76.463"
          y2="35.3331"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[0]} />
          <stop offset="1" stopColor={palette[1]} />
        </linearGradient>
        <clipPath id={`clip0_504_12980${width + height}`}>
          <rect width="80" height="80" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

DevelopmentSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  palette: PropTypes.arrayOf(PropTypes.string),
};

export default DevelopmentSvg;
