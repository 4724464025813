import PropTypes from 'prop-types';

const FavoriteSvg = ({ width, height, mixBlendMode, palette = [] }) => {
  return (
    <svg
      width={`${width || 51}`}
      height={`${height || 50}`}
      viewBox="0 0 51 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        style={{ mixBlendMode: mixBlendMode || 'normal' }}
        clipPath={`url(#clip0_665_6900${width + height})`}
      >
        <path
          d="M6.12305 0V38.7695C6.12305 39.3016 6.41143 39.7917 6.87646 40.05L24.4546 49.8156C24.897 50.0614 25.435 50.0614 25.8773 49.8156L43.4555 40.05C43.9206 39.7917 44.209 39.3016 44.209 38.7695V0H6.12305Z"
          fill={`url(#paint0_linear_665_6900${width + height})`}
        />
        <path
          d="M50.166 1.46484V5.85938C50.166 6.66836 49.5102 7.32422 48.7012 7.32422H44.209V0H48.7012C49.5102 0 50.166 0.655859 50.166 1.46484ZM6.12305 0H1.63086C0.821875 0 0.166016 0.655859 0.166016 1.46484V5.85938C0.166016 6.66836 0.821875 7.32422 1.63086 7.32422H6.12305V0ZM36.1189 19.1854L29.4583 18.2177L26.4796 12.1821C25.9423 11.0934 24.3897 11.0934 23.8524 12.1821L20.8737 18.2177L14.2132 19.1854C13.0117 19.3601 12.5319 20.8365 13.4014 21.684L18.221 26.3819L17.0832 33.0156C16.8779 34.2123 18.134 35.1248 19.2086 34.5599L25.166 31.4279L31.1234 34.56C32.198 35.1249 33.4541 34.2124 33.2488 33.0157L32.111 26.382L36.9307 21.6841C37.8001 20.8366 37.3203 19.3601 36.1189 19.1854Z"
          fill={`url(#paint1_linear_665_6900${width + height})`}
        />
      </g>
      <defs>
        <linearGradient
          id={`paint0_linear_665_6900${width + height}`}
          x1="25.166"
          y1="50"
          x2="25.166"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[2]} />
          <stop offset="1" stopColor={palette[3]} />
        </linearGradient>
        <linearGradient
          id={`paint1_linear_665_6900${width + height}`}
          x1="25.166"
          y1="34.7314"
          x2="25.166"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[0]} />
          <stop offset="1" stopColor={palette[1]} />
        </linearGradient>
        <clipPath id={`clip0_665_6900${width + height}`}>
          <rect
            width="50"
            height="50"
            fill="white"
            transform="translate(0.166016)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

FavoriteSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  mixBlendMode: PropTypes.string,
  palette: PropTypes.arrayOf(PropTypes.string),
};

export default FavoriteSvg;
