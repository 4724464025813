import PropTypes from 'prop-types';

const FilingCheckSvg = ({ uniqueKey, width, height, palette = [] }) => {
  return (
    <svg
      width={`${width || 50}`}
      height={`${height || 50}`}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="50"
        height="50"
        rx="8"
        fill={`url(#paint0_linear_83_11163${width + height}${uniqueKey})`}
      />
      <path
        d="M15 25.7576L23 34L35 17"
        stroke={`url(#paint1_linear_83_11163${width + height}${uniqueKey})`}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id={`paint0_linear_83_11163${width + height}${uniqueKey}`}
          x1="25"
          y1="50"
          x2="25"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[0]} />
          <stop offset="1" stopColor={palette[1]} />
        </linearGradient>
        <linearGradient
          id={`paint1_linear_83_11163${width + height}${uniqueKey}`}
          x1="25"
          y1="34"
          x2="25"
          y2="17"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[2]} />
          <stop offset="1" stopColor={palette[3]} />
        </linearGradient>
      </defs>
    </svg>
  );
};

FilingCheckSvg.propTypes = {
  uniqueKey: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  palette: PropTypes.arrayOf(PropTypes.string),
};

export default FilingCheckSvg;
