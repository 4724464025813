export const PRODUCT_CODES = {
  incLLC: 'INC_LLCIncorporation',
  incRegisteredAgent: 'INC_RegisteredAgent',
  incRushProcessing: 'INC_Rush_Processing',
  incSameDayProcessing: 'INC_Same_Day_Processing',
  incBusinessLicense: 'INC_BusinessLicenses',
  incAnnualCompliance: 'INC_AnnualComplianceReport',
  incAnnualReport: 'INC_AnnualReport',
  incSCorp: 'INC_S_Corp',
  incCertificateOfGoodStanding: 'INC_CertificateOfGoodStanding',
  incEIN: 'INC_EIN',
  incLLCKit: 'INC_LLCKit',
  incOperatingAgreement: 'INC_OperatingAgreement',
  incBankingResolution: 'INC_BankingResolution',
  incRegisterTrademark: 'INC_RegisterTrademark',
  incTrademarkMonitoring: 'INC_Trademark_Monitoring',
  incTrademarkBasicSearch: 'INC_Trademark_Search_Basic',
  incSalesTaxPermit: 'INC_SalesTaxPermit',
  incCertifiedCopy: 'INC_CertifiedCopy',
  incBOI: 'INC_BOI',
  incMonthlyBookkeeping: 'INC_Monthly_bookkeeping',
  incPersonalTaxFiling: 'INC_Personal_Tax_filing',
  incLLCTaxFiling: 'INC_LLC_Tax_filing',
};

export const LINKED_PRODUCT_CODES = {
  incStateIncorporationFee: 'INC_StateIncorporationFee',
  incSalesTaxPermitStateFee: 'INC_SalesTaxPermitStateFee',
  incCertifiedCopyStateFee: 'INC_CertifiedCopyStateFee',
};

export const SYNTHETIC_PRODUCT_CODES = {
  incOAAndEIN: 'INC_OperatingAgreementAndEIN',
  incOAAndEINAndBR: 'INC_OperatingAgreementAndEINAndBankingResolution',
};

export const PRODUCTS_SORT_ORDER_ARRAY = [
  PRODUCT_CODES.incLLC,
  PRODUCT_CODES.incRegisteredAgent,
  PRODUCT_CODES.incEIN,
  PRODUCT_CODES.incOperatingAgreement,
  PRODUCT_CODES.incBankingResolution,
  PRODUCT_CODES.incBusinessLicense,
  PRODUCT_CODES.incAnnualCompliance,
  PRODUCT_CODES.incSCorp,
  PRODUCT_CODES.incCertificateOfGoodStanding,
  PRODUCT_CODES.incLLCKit,
  PRODUCT_CODES.incRushProcessing,
  PRODUCT_CODES.incSameDayProcessing,
  LINKED_PRODUCT_CODES.incStateIncorporationFee,
  LINKED_PRODUCT_CODES.incSalesTaxPermitStateFee,
];

export const RECOMMENDED_PRODUCT_CODES_ARRAY = [
  PRODUCT_CODES.incSalesTaxPermit,
  PRODUCT_CODES.incCertifiedCopy,
  PRODUCT_CODES.incBOI,
  PRODUCT_CODES.incMonthlyBookkeeping,
];

export const TAX_PRODUCT_CODES_ARRAY = [PRODUCT_CODES.incSCorp];

export const UPSELL_PRODUCT_CODES_ARRAY = [
  ...RECOMMENDED_PRODUCT_CODES_ARRAY,
  ...TAX_PRODUCT_CODES_ARRAY,
];
