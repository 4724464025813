import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import styled, { css } from 'styled-components';

import { luminance, hexToRGBA } from '_helpers/theme';
import { theme } from '_constants/theme';

const { colors, devices, fontSizes, spaces } = theme;

const StyledButton = styled.button`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  position: relative;

  padding-top: ${spaces.small}px;
  padding-bottom: ${spaces.small}px;
  padding-left: ${spaces.regular}px;
  padding-right: ${spaces.regular}px;

  border-radius: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ borderColor }) => borderColor};

  background: ${({ color }) => color};

  font-family: 'Aeonik';
  font-style: normal;
  font-size: ${fontSizes.regular}px;
  font-weight: 500;
  color: ${({ textColor }) => textColor || colors.white};

  .prefix,
  .suffix {
    display: inline-flex;
    align-items: center;
  }

  .prefix {
    margin-right: ${spaces.small - 2}px;
  }

  .suffix {
    margin-left: ${spaces.small - 2}px;
  }

  @media ${devices.tablet} {
    padding-top: ${spaces.smallX}px;
    padding-bottom: ${spaces.smallX}px;
    padding-left: ${spaces.small + 2}px;
    padding-right: ${spaces.small + 2}px;

    ${({ small }) =>
      small &&
      css`
        padding-top: ${spaces.small2X}px;
        padding-bottom: ${spaces.small2X}px;
        padding-left: ${spaces.small - 2}px;
        padding-right: ${spaces.small - 2}px;

        font-size: ${fontSizes.regular}px;

        .prefix {
          margin-right: ${spaces.small - 2}px;
        }

        .suffix {
          margin-left: ${spaces.small - 2}px;
        }
      `}
  }

  ${({ large }) =>
    large &&
    css`
      padding-top: ${spaces.regular}px;
      padding-bottom: ${spaces.regular}px;
      padding-left: ${spaces.medium}px;
      padding-right: ${spaces.medium}px;

      font-size: ${fontSizes.large}px;

      .prefix {
        margin-right: ${spaces.medium - 2}px;
      }

      .suffix {
        margin-left: ${spaces.medium - 8}px;
      }
      @media ${devices.tablet} {
        font-size: ${fontSizes.regular}px;
      }
    `}

  ${({ small }) =>
    small &&
    css`
      padding-top: ${spaces.small2X}px;
      padding-bottom: ${spaces.small2X}px;
      padding-left: ${spaces.small - 2}px;
      padding-right: ${spaces.small - 2}px;

      font-size: ${fontSizes.regular}px;

      .prefix {
        margin-right: ${spaces.small - 2}px;
      }

      .suffix {
        margin-left: ${spaces.small - 2}px;
      }
    `}
  
  ${({ outlined }) =>
    outlined &&
    css`
      border-color: ${({ color }) => hexToRGBA(color, 0.2)};
      background: ${({ color }) => hexToRGBA(color, 0.05)};
      color: ${({ color }) => color};
    `}

  ${({ transparent }) =>
    transparent &&
    css`
      border-color: ${({ color }) => hexToRGBA(color, 0.2)};
      background: ${({ color }) => hexToRGBA(color, 0)};
      color: ${({ color }) => color};
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      border-color: ${colors.gray[400]};
      background: ${colors.gray[400]};
      color: ${colors.gray[200]};

      svg {
        path {
          fill: ${colors.gray[200]};
        }
      }
    `}

  ${({ disabled, outlined }) =>
    disabled &&
    outlined &&
    css`
      border-color: ${colors.gray[300]};
      background: ${colors.gray[200]};
      color: ${colors.gray[500]};

      svg {
        path {
          fill: ${colors.gray[500]};
        }
      }
    `}

  &:hover {
    ${({ outlined, disabled, hoverColor, hoverTextColor }) =>
      !outlined &&
      !disabled &&
      css`
        border-color: ${({ color }) => hoverColor || luminance(color, -0.1)};
        background: ${({ color }) => hoverColor || luminance(color, -0.1)};
        color: ${hoverTextColor || colors.white};

        &::before {
          content: '';
          display: inherit;
          position: absolute;
          top: -6px;
          bottom: -6px;
          left: -6px;
          right: -6px;
          background-color: transparent;
          border: 4px solid ${colors.indigo[200]};
          border-radius: 14px;
        }
      `}

    ${({ outlined, disabled }) =>
      outlined &&
      !disabled &&
      css`
        background: ${({ color }) => hexToRGBA(color, 0.1)};
      `}

    ${({ transparent, disabled }) =>
      transparent &&
      !disabled &&
      css`
        svg {
          path {
            fill: ${colors.white};
          }
        }
      `}
  }
`;

// eslint-disable-next-line react/display-name
const Button = forwardRef(
  (
    {
      children,
      type,
      color,
      borderColor,
      textColor,
      hoverColor,
      hoverTextColor,
      disabled,
      large,
      small,
      transparent,
      outlined,
      prefix,
      suffix,
      secondary,
      ...restProps
    },
    ref
  ) => {
    return (
      <StyledButton
        ref={ref}
        type={type || 'button'}
        color={color}
        borderColor={borderColor ? borderColor : color}
        textColor={textColor}
        hoverColor={hoverColor}
        hoverTextColor={hoverTextColor}
        disabled={disabled}
        transparent={transparent}
        outlined={outlined}
        secondary={secondary}
        large={large}
        small={small}
        {...restProps}
      >
        {prefix && <span className="prefix">{prefix}</span>}
        {children}
        {suffix && <span className="suffix">{suffix}</span>}
      </StyledButton>
    );
  }
);

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  type: PropTypes.string,
  color: PropTypes.string,
  borderColor: PropTypes.string,
  textColor: PropTypes.string,
  hoverColor: PropTypes.string,
  hoverTextColor: PropTypes.string,
  disabled: PropTypes.bool,
  large: PropTypes.bool,
  transparent: PropTypes.bool,
  outlined: PropTypes.bool,
  prefix: PropTypes.node,
  suffix: PropTypes.node,
  secondary: PropTypes.bool,
};

export default Button;
