import PropTypes from 'prop-types';

const LinkSvg = ({ color, width, height }) => {
  return (
    <svg
      width={`${width || 16}`}
      height={`${height || 16}`}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.18065 8C1.65398 7.36667 1.33398 6.55333 1.33398 5.66667C1.33398 3.65333 2.98065 2 5.00065 2H8.33398C10.3473 2 12.0007 3.65333 12.0007 5.66667C12.0007 7.68 10.354 9.33333 8.33398 9.33333H6.66732"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.4"
        d="M13.82 7.99999C14.3467 8.63332 14.6667 9.44666 14.6667 10.3333C14.6667 12.3467 13.02 14 11 14H7.66667C5.65333 14 4 12.3467 4 10.3333C4 8.31999 5.64667 6.66666 7.66667 6.66666H9.33333"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

LinkSvg.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default LinkSvg;
