import PropTypes from 'prop-types';

const PiggyBankSvg = ({ width, height, palette = [] }) => {
  return (
    <svg
      width={`${width || 70}`}
      height={`${height || 70}`}
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_503_7642)">
        <path
          d="M35 16.4062C39.5242 16.4062 43.2031 12.7273 43.2031 8.20312C43.2031 3.67896 39.5242 0 35 0C30.4758 0 26.7969 3.67896 26.7969 8.20312C26.7969 12.7273 30.4758 16.4062 35 16.4062ZM11.0675 41.2891H10.3906C6.99809 41.2891 4.10156 38.5293 4.10156 35.1367C4.10156 34.0032 3.18432 33.0859 2.05078 33.0859C0.917246 33.0859 0 34.0032 0 35.1367C0 40.7905 4.73689 45.3906 10.3906 45.3906H11.0675C12.2011 45.3906 13.1183 44.4734 13.1183 43.3398C13.1183 42.2063 12.2011 41.2891 11.0675 41.2891ZM51.4062 31.0352C49.1431 31.0352 47.3047 32.8757 47.3047 35.1367C47.3047 37.3978 49.1431 39.2383 51.4062 39.2383C53.6694 39.2383 55.5078 37.3978 55.5078 35.1367C55.5078 32.8757 53.6694 31.0352 51.4062 31.0352ZM35 36.2362C31.8907 34.4393 27.8137 34.8354 25.0986 37.54C21.9022 40.7384 21.9022 45.9413 25.0986 49.1397L32.1001 56.1412C32.8731 56.9163 33.9065 57.3428 35 57.3428C36.0935 57.3428 37.1269 56.9163 37.8999 56.1412L44.9014 49.1397C48.0978 45.9413 48.0978 40.7384 44.9014 37.54C42.1764 34.8149 38.0909 34.4556 35 36.2362Z"
          fill="url(#paint0_linear_503_7642)"
        />
        <path
          d="M67.9487 33.0859H62.3006C61.0769 30.9731 59.4868 29.0784 57.5581 27.4343V18.7305C57.5581 17.5969 56.6408 16.6797 55.5073 16.6797C51.3657 16.6797 47.5345 18.426 44.8048 21.5163C42.3454 21.0276 39.7439 20.7812 37.0503 20.7812C23.6261 20.7812 13.5603 26.7333 10.1257 36.7028C7.338 44.7938 9.85745 53.5317 16.5425 59.2434V67.9492C16.5425 69.0828 17.4597 70 18.5932 70H30.8979C32.0315 70 32.9487 69.0828 32.9487 67.9492V65.7042C35.6203 65.9605 38.4802 65.9605 41.1518 65.7042V67.9492C41.1518 69.0828 42.0691 70 43.2026 70H55.5073C56.6408 70 57.5581 69.0828 57.5581 67.9492V59.2454C59.4866 57.5992 61.0768 55.7047 62.3006 53.5938H67.9487C69.0822 53.5938 69.9995 52.6765 69.9995 51.543V35.1367C69.9995 34.0032 69.0822 33.0859 67.9487 33.0859ZM43.4509 47.6897L36.4494 54.6912C36.0488 55.0918 35.5241 55.2921 34.9995 55.2921C34.4749 55.2921 33.95 55.0918 33.5496 54.6912L26.5481 47.6897C24.1488 45.2904 24.1488 41.3891 26.5481 38.9899C28.8693 36.6747 32.5883 36.5946 34.9995 38.7535C37.4148 36.5946 41.1377 36.6747 43.4509 38.9899C45.8502 41.3891 45.8502 45.2905 43.4509 47.6897ZM51.4057 37.1875C50.2732 37.1875 49.3549 36.2693 49.3549 35.1367C49.3549 34.0041 50.2732 33.0859 51.4057 33.0859C52.5383 33.0859 53.4565 34.0041 53.4565 35.1367C53.4565 36.2693 52.5383 37.1875 51.4057 37.1875Z"
          fill="url(#paint1_linear_503_7642)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_503_7642"
          x1="27.7539"
          y1="57.3429"
          x2="27.7539"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[2]} />
          <stop offset="1" stopColor={palette[3]} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_503_7642"
          x1="39.5027"
          y1="70"
          x2="39.5027"
          y2="16.6797"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[0]} />
          <stop offset="1" stopColor={palette[1]} />
        </linearGradient>
        <clipPath id="clip0_503_7642">
          <rect width="70" height="70" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

PiggyBankSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  palette: PropTypes.arrayOf(PropTypes.string),
};

export default PiggyBankSvg;
