import PropTypes from 'prop-types';

const MonoCheckSvg = ({ color, width, height }) => {
  return (
    <svg
      width={`${width || 26}`}
      height={`${height || 20}`}
      viewBox="0 0 26 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.08654 8.66981L12.1675 16.9725L9.46844 19.9246L0.387451 11.6219L3.08654 8.66981Z"
        fill={color}
      />
      <path
        d="M6.54162 17.216L22.3391 0.0488281L25.2825 2.7574L9.48503 19.9245L6.54162 17.216Z"
        fill={color}
      />
    </svg>
  );
};

MonoCheckSvg.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default MonoCheckSvg;
