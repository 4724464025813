import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { MainApiService } from '_services';
import { STORAGE_KEYS } from '_constants/storage';
import { storeSessionValue, restoreSessionValue } from '_helpers/storage';
import { processResponse, processError } from '_helpers/api';

export const getProducts = createAsyncThunk(
  'products/getProducts',
  async function (_, { rejectWithValue }) {
    try {
      const response = await MainApiService.getProducts();

      return processResponse(response);
    } catch (e) {
      return rejectWithValue(processError(e));
    }
  }
);

const productsSlice = createSlice({
  name: 'products',
  initialState: {
    activeProductCode: null,
    items: [],
    error: null,
    loading: false,
    restored: false,
  },
  reducers: {
    refreshActiveProductCode(state, action) {
      state.activeProductCode = action.payload;
      storeSessionValue(STORAGE_KEYS.productCode, action.payload);
    },
    restoreActiveProductCode(state) {
      const storedActiveProductCode = restoreSessionValue(
        STORAGE_KEYS.productCode
      );

      if (storedActiveProductCode) {
        state.activeProductCode = storedActiveProductCode;
      }

      state.restored = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProducts.pending, (state) => {
        state.error = null;
        state.loading = true;
      })
      .addCase(getProducts.fulfilled, (state, action) => {
        state.items = action.payload;
        state.loading = false;
      })
      .addCase(getProducts.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export const { refreshActiveProductCode, restoreActiveProductCode } =
  productsSlice.actions;

export default productsSlice.reducer;
