import PropTypes from 'prop-types';

const ShieldSvg = ({ width, height, palette = [] }) => {
  return (
    <svg
      width={`${width || 70}`}
      height={`${height || 70}`}
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath={`url(#clip0_503_7622${width + height})`}>
        <path
          d="M60.2589 8.30834L35.6492 0.105104C35.2287 -0.0350347 34.772 -0.0350347 34.3515 0.105104L9.74178 8.30834C8.90464 8.5867 8.33984 9.36984 8.33984 10.2531V36.3339C8.33984 55.34 20.5165 63.0004 34.0831 69.7837C34.3714 69.9279 34.6859 70 35.0004 70C35.3148 70 35.6293 69.9279 35.9176 69.7837C49.4843 63.0004 61.6609 55.3398 61.6609 36.3339V10.2531C61.6609 9.36984 61.0961 8.5867 60.2589 8.30834Z"
          fill={`url(#paint0_linear_503_7622${width + height})`}
        />
        <path
          d="M52.0556 14.3572L35.6491 8.88977C35.2286 8.74963 34.7719 8.74963 34.3514 8.88977L17.9449 14.3572C17.1078 14.6356 16.543 15.4187 16.543 16.3019V36.1973C16.543 49.4228 22.9758 53.0473 33.8808 60.09C34.2212 60.3123 34.6098 60.4225 35.0003 60.4225C35.3907 60.4225 35.7793 60.3123 36.1197 60.09C46.9792 53.0766 53.4575 49.465 53.4575 36.1973V16.3019C53.4575 15.4187 52.8929 14.6356 52.0556 14.3572ZM44.2349 31.8813L41.1046 34.9315L41.8435 39.2394C42.1306 40.91 40.3705 42.1919 38.8674 41.4004L35.0001 39.3677L31.1328 41.4004C29.6472 42.1927 27.8679 40.9199 28.1567 39.2394L28.8957 34.9315L25.7653 31.8813C24.5515 30.6979 25.2228 28.6283 26.9028 28.3845L31.2268 27.7556L33.1614 23.8383C33.8503 22.4363 36.1494 22.4363 36.8385 23.8383L38.7731 27.7556L43.097 28.3845C44.775 28.6279 45.4505 30.6962 44.2349 31.8813Z"
          fill={`url(#paint1_linear_503_7622${width + height})`}
        />
      </g>
      <defs>
        <linearGradient
          id={`paint0_linear_503_7622${width + height}`}
          x1="8.81529"
          y1="69.3683"
          x2="66.8023"
          y2="25.1979"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[0]} />
          <stop offset="1" stopColor={palette[2]} />
        </linearGradient>
        <linearGradient
          id={`paint1_linear_503_7622${width + height}`}
          x1="25.0304"
          y1="48.5444"
          x2="62.0773"
          y2="22.0606"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[1]} />
          {/* <stop offset="0.5028" stopColor={palette[3]} /> */}
          <stop offset="1" stopColor={palette[3]} />
        </linearGradient>
        <clipPath id={`clip0_503_7622${width + height}`}>
          <rect width="70" height="70" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

ShieldSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  palette: PropTypes.arrayOf(PropTypes.string),
};

export default ShieldSvg;
