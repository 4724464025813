import PropTypes from 'prop-types';

const CancelSvg = ({ width, height, palette = [] }) => {
  return (
    <svg
      width={`${width || 80}`}
      height={`${height || 80}`}
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M54.0625 32.8125C63.1234 32.8125 70.4688 25.4672 70.4688 16.4062C70.4688 7.34533 63.1234 0 54.0625 0C45.0016 0 37.6562 7.34533 37.6562 16.4062C37.6562 25.4672 45.0016 32.8125 54.0625 32.8125Z"
        fill={`url(#paint0_linear_504_12964${width + height})`}
      />
      <path
        d="M64.3259 45.0173L12.7634 23.9236C11.8959 23.5756 10.8912 23.7679 10.2182 24.4362C9.54994 25.1045 9.34853 26.107 9.70556 26.9814L30.7993 78.5439C31.1609 79.4273 32.0215 79.9995 32.9692 79.9995C34.016 79.9995 34.9193 79.3109 35.2145 78.3287L39.5884 63.7489L49.0915 73.252C50.007 74.1675 51.4902 74.1675 52.4057 73.252L59.0342 66.6236C59.9496 65.7081 59.9496 64.2248 59.0342 63.3093L49.531 53.8062L64.1109 49.4323C65.0631 49.1462 65.7313 48.2925 65.7795 47.3014C65.8273 46.3082 65.2437 45.395 64.3259 45.0173ZM49.0915 21.3771C50.007 22.2926 51.4901 22.2926 52.4057 21.3771L54.0628 19.7201L55.7198 21.3771C56.6353 22.2926 58.1184 22.2926 59.034 21.3771C59.9495 20.4617 59.9495 18.9784 59.034 18.0629L57.377 16.4059L59.034 14.7489C59.9495 13.8334 59.9495 12.3501 59.034 11.4346C58.1185 10.5192 56.6353 10.5192 55.7198 11.4346L54.0628 13.0917L52.4057 11.4346C51.4902 10.5192 50.007 10.5192 49.0915 11.4346C48.176 12.3501 48.176 13.8334 49.0915 14.7489L50.7485 16.4059L49.0915 18.0629C48.1759 18.9786 48.1759 20.4617 49.0915 21.3771Z"
        fill={`url(#paint1_linear_504_12964${width + height})`}
      />
      <defs>
        <linearGradient
          id={`paint0_linear_504_12964${width + height}`}
          x1="54.0625"
          y1="32.8125"
          x2="54.0625"
          y2="4.69386e-05"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[2]} />
          <stop offset="1" stopColor={palette[3]} />
        </linearGradient>
        <linearGradient
          id={`paint1_linear_504_12964${width + height}`}
          x1="10.0328"
          y1="79.3746"
          x2="68.2741"
          y2="32.0668"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[0]} />
          <stop offset="1" stopColor={palette[1]} />
        </linearGradient>
      </defs>
    </svg>
  );
};

CancelSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  palette: PropTypes.arrayOf(PropTypes.string),
};

export default CancelSvg;
