import PropTypes from 'prop-types';

const PovertySvg = ({ width, height, palette = [] }) => {
  return (
    <svg
      width={`${width || 70}`}
      height={`${height || 70}`}
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_503_7638)">
        <path
          d="M1.82082 66.4498C0.783672 66.3328 0 65.4556 0 64.412V41.1999C0 40.5266 0.330586 39.896 0.88457 39.5131L1.08486 39.3746C7.40633 35.007 15.7969 34.9419 22.1761 39.1493H36.937C40.5236 39.1493 43.4312 42.0567 43.4312 45.6434C43.4312 47.6104 42.5566 49.3731 41.175 50.5641L59.9346 39.7332C64.4081 37.1503 70 40.3788 70 45.5444C70 47.7306 68.9351 49.7797 67.1459 51.036L44.9137 66.6475C41.7919 68.8397 38.0903 69.9999 34.3113 70C33.6209 70.0001 32.9288 69.9614 32.2362 69.8832L1.82082 66.4498ZM40.4688 0C34.8147 0 30.2148 4.5999 30.2148 10.2539C30.2148 15.9079 34.8147 20.5078 40.4688 20.5078C46.1228 20.5078 50.7227 15.9079 50.7227 10.2539C50.7227 4.5999 46.1228 0 40.4688 0Z"
          fill="url(#paint0_linear_503_7638)"
        />
        <path
          d="M22.1759 39.1493C20.7635 38.2177 19.2523 37.4961 17.6869 36.9837C16.9444 34.6768 16.543 32.2185 16.543 29.668V26.9336C16.543 25.801 17.4612 24.8828 18.5938 24.8828H62.3438C63.4763 24.8828 64.3945 25.801 64.3945 26.9336V29.668C64.3945 32.9249 63.7399 36.0318 62.5561 38.8645C61.6725 38.9625 60.7834 39.2429 59.9345 39.7331L41.1749 50.5639C42.5563 49.373 43.431 47.6104 43.431 45.6433C43.431 42.0567 40.5236 39.1491 36.9369 39.1491H22.1759V39.1493ZM22.2396 56.2391C21.1071 56.2391 20.1888 55.3209 20.1888 54.1883C20.1888 53.0558 21.1071 52.1376 22.2396 52.1376H36.2514C37.6913 52.1376 39.1059 51.7586 40.353 51.0385L47.4532 46.9391C46.8121 52.1726 42.3407 56.2391 36.9369 56.2391H22.2396Z"
          fill="url(#paint1_linear_503_7638)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_503_7638"
          x1="35"
          y1="70"
          x2="35"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[2]} />
          <stop offset="1" stopColor={palette[3]} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_503_7638"
          x1="40.4687"
          y1="56.2391"
          x2="40.4687"
          y2="24.8828"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[0]} />
          <stop offset="1" stopColor={palette[1]} />
        </linearGradient>
        <clipPath id="clip0_503_7638">
          <rect width="70" height="70" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

PovertySvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  palette: PropTypes.arrayOf(PropTypes.string),
};

export default PovertySvg;
