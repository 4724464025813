import { config } from '_config';
import Icon from '_components/atomic/Icon';
import { ICONS_ALIASES } from '_constants/icons';
import { PatternFormat } from 'react-number-format';
import React from 'react';
import styled from 'styled-components';
import { theme } from '_constants/theme';
const { colors, devices } = theme;

const StyledPhoneItem = styled.div`
  a {
    color: ${colors.indigo[600]} !important;
    display: block;
    padding-left: 12px;
    padding-right: 12px;
    font-family: 'Aeonik';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    -webkit-text-decoration: none;
    text-decoration: none;
    white-space: nowrap;
  }
  @media ${devices.tablet} {
    a {
      padding: 0px;
    }
  }
`;

const PhoneItem = () => {
  return (
    <StyledPhoneItem>
      <a href={'tel:' + config.phone}>
        <Icon alias={ICONS_ALIASES.phone} color={colors.indigo[600]} />
        <PatternFormat
          value={config.phone?.substring(2)}
          displayType="text"
          format="(###) ###-####"
        />
      </a>
    </StyledPhoneItem>
  );
};

export default PhoneItem;
