import PropTypes from 'prop-types';

const StampSvg = ({ width, height, palette = [] }) => {
  return (
    <svg
      width={`${width || 60}`}
      height={`${height || 60}`}
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath={`url(#clip0_268_16247${width + height})`}>
        <path
          d="M49.2188 52.9688V37.0312C49.2188 36.5742 49.043 36.1172 48.6913 35.8007L34.9453 22.0194L36.1407 20.7891C36.8438 20.1211 37.9688 20.1211 38.6719 20.7891L41.66 23.7772C42.328 24.4804 43.4531 24.4804 44.121 23.7772L48.6913 19.2422C49.043 18.9257 49.2188 18.4686 49.2188 18.0116V7.03125C49.2188 3.16406 46.0547 0 42.1875 0H5.27344L10.5469 14.0625V52.9688C10.5469 56.8426 13.7419 60 17.5781 60L50.3631 59.0645L49.2188 52.9688Z"
          fill={`url(#paint0_linear_268_16247${width + height})`}
        />
        <path
          d="M24.6094 38.7891C24.6094 42.6669 27.7645 45.8203 31.6406 45.8203C35.5167 45.8203 38.6719 42.6669 38.6719 38.7891C38.6719 34.9112 35.5167 31.7578 31.6406 31.7578C27.7645 31.7578 24.6094 34.9112 24.6094 38.7891ZM19.3359 10.5469H40.4297C41.4013 10.5469 42.1875 9.76066 42.1875 8.78906C42.1875 7.81746 41.4013 7.03125 40.4297 7.03125H19.3359C18.3643 7.03125 17.5781 7.81746 17.5781 8.78906C17.5781 9.76066 18.3643 10.5469 19.3359 10.5469ZM19.3359 17.5781H26.3672C27.3388 17.5781 28.125 16.7919 28.125 15.8203C28.125 14.8487 27.3388 14.0625 26.3672 14.0625H19.3359C18.3643 14.0625 17.5781 14.8487 17.5781 15.8203C17.5781 16.7919 18.3643 17.5781 19.3359 17.5781ZM19.3359 24.6094H26.3672C27.3388 24.6094 28.125 23.8232 28.125 22.8516C28.125 21.88 27.3388 21.0938 26.3672 21.0938H19.3359C18.3643 21.0938 17.5781 21.88 17.5781 22.8516C17.5781 23.8232 18.3643 24.6094 19.3359 24.6094ZM54.4922 52.9688H26.1739C25.3659 52.9688 24.6349 53.5154 24.4804 54.3086C23.8504 57.5407 20.9873 60 17.5781 60H50.9766C53.889 60 56.25 57.639 56.25 54.7266C56.25 53.7558 55.463 52.9688 54.4922 52.9688ZM5.27344 0C4.71105 0 4.14844 0.105586 3.65613 0.281367H3.62121C1.51172 0.949336 0 2.91809 0 5.27344V12.3047C0 13.2755 0.787031 14.0625 1.75781 14.0625H10.5469V5.27344C10.5469 2.35535 8.19152 0 5.27344 0ZM56.4844 10.4297C55.5437 10.4297 54.6236 10.8142 53.9541 11.4837L47.2823 18.1914L42.8912 22.5464L39.9008 19.5578C39.2347 18.8934 38.3524 18.5278 37.4082 18.5278C36.4641 18.5278 35.5748 18.8952 34.9089 19.5578L33.6919 20.7765C33.0062 21.4631 33.0066 22.5754 33.6927 23.2615L47.1659 36.7361C47.8521 37.4224 48.9648 37.4227 49.6514 36.7365L50.87 35.5188C51.5361 34.8563 51.9 33.9704 51.9 33.0246C51.9 32.0788 51.5326 31.1912 50.87 30.527L47.8832 27.5384L59.0146 16.4052C59.6395 15.7444 60 14.8465 60 13.9453C60 12.0073 58.4242 10.4297 56.4844 10.4297Z"
          fill={`url(#paint1_linear_268_16247${width + height})`}
        />
      </g>
      <defs>
        <linearGradient
          id={`paint0_linear_268_16247${width + height}`}
          x1="27.8183"
          y1="60"
          x2="27.8183"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[2]} />
          <stop offset="1" stopColor={palette[3]} />
        </linearGradient>
        <linearGradient
          id={`paint1_linear_268_16247${width + height}`}
          x1="30"
          y1="60"
          x2="30"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[0]} />
          <stop offset="1" stopColor={palette[1]} />
        </linearGradient>
        <clipPath id={`clip0_268_16247${width + height}`}>
          <rect width="60" height="60" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

StampSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  palette: PropTypes.arrayOf(PropTypes.string),
};

export default StampSvg;
