import PropTypes from 'prop-types';

const SafeLockSvg = ({ color, width, height }) => {
  return (
    <svg
      width={`${width || 25}`}
      height={`${height || 24}`}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.25 8V10.1C18.81 10.04 18.31 10.01 17.75 10V8C17.75 4.85 16.86 2.75 12.5 2.75C8.14 2.75 7.25 4.85 7.25 8V10C6.69 10.01 6.19 10.04 5.75 10.1V8C5.75 5.1 6.45 1.25 12.5 1.25C18.55 1.25 19.25 5.1 19.25 8Z"
        fill={color}
      />
      <path
        opacity="0.4"
        d="M22.5 15V17C22.5 21 21.5 22 17.5 22H7.5C3.5 22 2.5 21 2.5 17V15C2.5 11.66 3.2 10.41 5.75 10.1C6.19 10.04 6.69 10.01 7.25 10H17.75C18.31 10.01 18.81 10.04 19.25 10.1C21.8 10.41 22.5 11.66 22.5 15Z"
        fill={color}
      />
      <path
        d="M8.5 17C8.37 17 8.24 16.97 8.12 16.92C7.99 16.87 7.89001 16.8 7.79001 16.71C7.61001 16.52 7.5 16.26 7.5 16C7.5 15.87 7.52999 15.74 7.57999 15.62C7.62999 15.5 7.70001 15.39 7.79001 15.29C7.89001 15.2 7.99 15.13 8.12 15.08C8.49 14.92 8.92999 15.01 9.20999 15.29C9.29999 15.39 9.37001 15.5 9.42001 15.62C9.47001 15.74 9.5 15.87 9.5 16C9.5 16.26 9.38999 16.52 9.20999 16.71C9.01999 16.89 8.76 17 8.5 17Z"
        fill={color}
      />
      <path
        d="M12.5 17C12.23 17 11.98 16.89 11.79 16.71C11.7 16.61 11.63 16.5 11.58 16.38C11.53 16.26 11.5 16.13 11.5 16C11.5 15.73 11.61 15.48 11.79 15.29C12.16 14.92 12.83 14.92 13.21 15.29C13.39 15.48 13.5 15.73 13.5 16C13.5 16.13 13.47 16.26 13.42 16.38C13.37 16.5 13.3 16.61 13.21 16.71C13.02 16.89 12.76 17 12.5 17Z"
        fill={color}
      />
      <path
        d="M16.5 17C16.24 17 15.98 16.89 15.79 16.71C15.61 16.52 15.5 16.27 15.5 16C15.5 15.73 15.61 15.48 15.79 15.29C16.17 14.92 16.84 14.92 17.21 15.29C17.25 15.34 17.29 15.39 17.33 15.45C17.37 15.5 17.4 15.56 17.42 15.62C17.45 15.68 17.47 15.74 17.48 15.8C17.49 15.87 17.5 15.94 17.5 16C17.5 16.26 17.39 16.52 17.21 16.71C17.02 16.89 16.76 17 16.5 17Z"
        fill={color}
      />
    </svg>
  );
};

SafeLockSvg.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default SafeLockSvg;
