import PropTypes from 'prop-types';

const MonoShieldSvg = ({ color, width, height }) => {
  return (
    <svg
      width={`${width || 51}`}
      height={`${height || 51}`}
      viewBox="0 0 51 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.3258 20.0068L22.9508 29.3818L19.8258 26.2568M25.0341 6.46509L8.36746 14.7984C8.36746 25.6176 14.168 40.8605 25.0341 43.9651C35.9002 40.8605 41.7008 25.6176 41.7008 14.7984L25.0341 6.46509Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

MonoShieldSvg.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default MonoShieldSvg;
