import PropTypes from 'prop-types';

const TwitterSvg = ({ color, width, height }) => {
  return (
    <svg
      width={`${width || 24}`}
      height={`${height || 24}`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.5343 6.85813C21.5502 7.07185 21.5502 7.28435 21.5502 7.49684C21.5502 14 16.6004 21.4936 7.55465 21.4936C4.76776 21.4936 2.17871 20.6863 0 19.2843C0.395685 19.3295 0.776715 19.3454 1.18828 19.3454C3.48789 19.3454 5.60431 18.5687 7.29575 17.2436C5.13291 17.1985 3.32058 15.7818 2.6953 13.8327C2.99939 13.8779 3.3047 13.9084 3.62467 13.9084C4.06554 13.9084 4.50885 13.8473 4.92041 13.7411C2.66477 13.2843 0.975779 11.3047 0.975779 8.91349V8.85243C1.63159 9.21759 2.39243 9.44596 3.19845 9.47649C1.8734 8.59475 1.00509 7.0865 1.00509 5.38042C1.00509 4.46692 1.24812 3.62915 1.67433 2.89884C4.09607 5.88235 7.73662 7.83269 11.818 8.04641C11.7423 7.68003 11.6959 7.30022 11.6959 6.91919C11.6959 4.20802 13.8893 2 16.6151 2C18.0318 2 19.3104 2.59353 20.2092 3.55343C21.3206 3.33971 22.3855 2.92815 23.3308 2.36515C22.9656 3.50702 22.1877 4.46692 21.1679 5.07633C22.1584 4.96886 23.1183 4.6953 24 4.31427C23.3308 5.28883 22.4942 6.15713 21.5343 6.85813Z"
        fill={color}
      />
    </svg>
  );
};

TwitterSvg.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default TwitterSvg;
