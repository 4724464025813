import PropTypes from 'prop-types';

const AccuracySvg = ({ width, height, palette = [] }) => {
  return (
    <svg
      width={`${width || 70}`}
      height={`${height || 70}`}
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath={`url(#clip0_624_7401${width + height})`}>
        <path
          d="M59.5989 35.0001C59.5989 48.1092 49.0632 59.5979 35.0002 59.5979C17.2008 59.5979 10.4004 43.9497 10.4004 35.0001C10.4004 21.8937 20.9372 10.4023 35.0002 10.4023C48.2824 10.4023 59.5989 21.1039 59.5989 35.0001Z"
          fill={`url(#paint0_linear_624_7401${width + height})`}
        />
        <path
          d="M41.1523 35C41.1523 36.1335 40.2351 37.0508 39.1016 37.0508H35C33.8665 37.0508 32.9492 36.1335 32.9492 35V26.7969C32.9492 25.6633 33.8665 24.7461 35 24.7461C36.1335 24.7461 37.0508 25.6633 37.0508 26.7969V32.9492H39.1016C40.2351 32.9492 41.1523 33.8665 41.1523 35ZM70 35C70 36.1335 69.0828 37.0508 67.9492 37.0508H65.6917C64.6777 52.3828 52.3828 64.6777 37.0508 65.6917V67.9492C37.0508 69.0828 36.1335 70 35 70C33.8665 70 32.9492 69.0828 32.9492 67.9492V65.6917C17.6172 64.6777 5.32232 52.3828 4.30828 37.0508H2.05078C0.917246 37.0508 0 36.1335 0 35C0 33.8665 0.917246 32.9492 2.05078 32.9492H4.30828C5.32232 17.6172 17.6172 5.32232 32.9492 4.30828V2.05078C32.9492 0.917246 33.8665 0 35 0C36.1335 0 37.0508 0.917246 37.0508 2.05078V4.30828C52.3828 5.32232 64.6777 17.6172 65.6917 32.9492H67.9492C69.0828 32.9492 70 33.8665 70 35ZM49.3555 35C49.3555 33.8665 50.2727 32.9492 51.4062 32.9492H57.4547C56.4748 22.1458 47.8529 13.5252 37.0508 12.5453V18.5938C37.0508 19.7273 36.1335 20.6445 35 20.6445C33.8665 20.6445 32.9492 19.7273 32.9492 18.5938V12.5453C22.1471 13.5252 13.5252 22.1458 12.5453 32.9492H18.5938C19.7273 32.9492 20.6445 33.8665 20.6445 35C20.6445 36.1335 19.7273 37.0508 18.5938 37.0508H12.5453C13.5252 47.8542 22.1471 56.4748 32.9492 57.4547V51.4062C32.9492 50.2727 33.8665 49.3555 35 49.3555C36.1335 49.3555 37.0508 50.2727 37.0508 51.4062V57.4547C47.8529 56.4748 56.4748 47.8542 57.4547 37.0508H51.4062C50.2727 37.0508 49.3555 36.1335 49.3555 35Z"
          fill={`url(#paint1_linear_624_7401${width + height})`}
        />
      </g>
      <defs>
        <linearGradient
          id={`paint0_linear_624_7401${width + height}`}
          x1="21.7122"
          y1="48.2816"
          x2="59.0137"
          y2="10.9777"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[2]} />
          {/*<stop offset="0.5028" stop-color="#C7D4EC" />*/}
          <stop offset="1" stopColor={palette[3]} />
        </linearGradient>
        <linearGradient
          id={`paint1_linear_624_7401${width + height}`}
          x1="0.624168"
          y1="69.3684"
          x2="60.7721"
          y2="9.22035"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[0]} />
          <stop offset="1" stopColor={palette[1]} />
        </linearGradient>
        <clipPath id={`clip0_624_7401${width + height}`}>
          <rect width="70" height="70" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

AccuracySvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  palette: PropTypes.arrayOf(PropTypes.string),
};

export default AccuracySvg;
