import PropTypes from 'prop-types';

const CheckRoundedInvertedSvg = ({ color, width, height }) => {
  return (
    <svg
      width={`${width || 24}`}
      height={`${height || 24}`}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.1637 6.05225C11.1637 8.90731 8.84921 11.2218 5.99414 11.2218C3.13907 11.2218 0.824585 8.90731 0.824585 6.05225C0.824585 3.19718 3.13907 0.88269 5.99414 0.88269C8.84921 0.88269 11.1637 3.19718 11.1637 6.05225Z"
        fill={color}
      />
      <path d="M2.81685 6.31547L5.3074 8.47249" stroke="white" />
      <line
        x1="9.20319"
        y1="3.62177"
        x2="4.62568"
        y2="8.47781"
        stroke="white"
      />
    </svg>
  );
};

CheckRoundedInvertedSvg.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default CheckRoundedInvertedSvg;
