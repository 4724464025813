import PropTypes from 'prop-types';

const ProfileSvg = ({ color, width, height }) => {
  return (
    <svg
      width={`${width || 30}`}
      height={`${height || 30}`}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M15 27.5125C21.9036 27.5125 27.5 21.9161 27.5 15.0125C27.5 8.10895 21.9036 2.51251 15 2.51251C8.09644 2.51251 2.5 8.10895 2.5 15.0125C2.5 21.9161 8.09644 27.5125 15 27.5125Z"
        fill={color}
      />
      <path
        d="M15 8.67499C12.4125 8.67499 10.3125 10.775 10.3125 13.3625C10.3125 15.9 12.3 17.9625 14.9375 18.0375C14.975 18.0375 15.025 18.0375 15.05 18.0375C15.075 18.0375 15.1125 18.0375 15.1375 18.0375C15.15 18.0375 15.1625 18.0375 15.1625 18.0375C17.6875 17.95 19.675 15.9 19.6875 13.3625C19.6875 10.775 17.5875 8.67499 15 8.67499Z"
        fill={color}
      />
      <path
        d="M23.4754 24.2C21.2504 26.25 18.2754 27.5125 15.0004 27.5125C11.7254 27.5125 8.75039 26.25 6.52539 24.2C6.82539 23.0625 7.63789 22.025 8.82539 21.225C12.2379 18.95 17.7879 18.95 21.1754 21.225C22.3754 22.025 23.1754 23.0625 23.4754 24.2Z"
        fill={color}
      />
    </svg>
  );
};

ProfileSvg.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default ProfileSvg;
