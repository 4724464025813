import PropTypes from 'prop-types';

const ArrowRightSquaredSvg = ({ color, width, height }) => {
  return (
    <svg
      width={`${width || 24}`}
      height={`${height || 24}`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2Z"
        fill={color}
      />
      <path
        d="M18.53 11.4704L14.24 7.18039C13.95 6.89039 13.47 6.89039 13.18 7.18039C12.89 7.47039 12.89 7.95039 13.18 8.24039L16.19 11.2504H6C5.59 11.2504 5.25 11.5904 5.25 12.0004C5.25 12.4104 5.59 12.7504 6 12.7504H16.19L13.18 15.7604C12.89 16.0504 12.89 16.5304 13.18 16.8204C13.33 16.9704 13.52 17.0404 13.71 17.0404C13.9 17.0404 14.09 16.9704 14.24 16.8204L18.53 12.5304C18.67 12.3904 18.75 12.2004 18.75 12.0004C18.75 11.8004 18.67 11.6104 18.53 11.4704Z"
        fill={color}
      />
    </svg>
  );
};

ArrowRightSquaredSvg.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default ArrowRightSquaredSvg;
