import PropTypes from 'prop-types';

const WorkSvg = ({ width, height, palette = [] }) => {
  return (
    <svg
      width={`${width || 70}`}
      height={`${height || 70}`}
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_624_11994)">
        <path
          d="M62.5018 49.8847C60.9555 48.2479 58.3086 48.1919 56.6539 49.8467L54.6191 51.8815C52.9277 50.2074 50.2662 50.3654 48.8633 51.8595C47.1886 53.4458 47.1963 56.0845 48.8313 57.7194L51.6592 60.5012C52.3961 61.3242 53.4775 61.7969 54.6191 61.7969C55.7607 61.7969 56.8422 61.3242 57.499 60.5872L62.4657 55.6605C64.1186 54.0077 64.0447 51.3376 62.5018 49.8847ZM22.832 12.5781C23.9656 12.5781 24.8828 11.6609 24.8828 10.5273V6.15234C24.8828 5.02086 25.804 4.10156 26.9336 4.10156H35.1367C36.2663 4.10156 37.1875 5.02086 37.1875 6.15234V10.5273C37.1875 11.6609 38.1047 12.5781 39.2383 12.5781C40.3718 12.5781 41.2891 11.6609 41.2891 10.5273V6.15234C41.2891 2.7598 38.5293 0 35.1367 0H26.9336C23.5411 0 20.7812 2.7598 20.7812 6.15234V10.5273C20.7812 11.6609 21.6985 12.5781 22.832 12.5781ZM55.6445 45.3906C56.8462 45.3906 57.9797 45.5928 59.0051 45.9915C59.634 46.2338 60.3468 46.1538 60.9037 45.7712C61.4645 45.3887 61.7969 44.7558 61.7969 44.0789V22.832C61.7969 21.6985 60.8796 20.7812 59.7461 20.7812C58.6126 20.7812 57.6953 21.6985 57.6953 22.832C57.6953 26.2246 54.9355 28.9844 51.543 28.9844H10.2539C6.86137 28.9844 4.10156 26.2246 4.10156 22.832C4.10156 21.6985 3.18432 20.7812 2.05078 20.7812C0.917246 20.7812 0 21.6985 0 22.832V47.4414C0 50.8339 2.7598 53.5938 6.15234 53.5938H44.0769C44.9541 53.5938 45.7352 53.037 46.0156 52.2078C47.4134 48.1303 51.2827 45.3906 55.6445 45.3906Z"
          fill="url(#paint0_linear_624_11994)"
        />
        <path
          d="M55.6445 41.2891C47.7285 41.2891 41.2891 47.7285 41.2891 55.6445C41.2891 63.5605 47.7285 70 55.6445 70C63.5605 70 70 63.5605 70 55.6445C70 47.7285 63.5605 41.2891 55.6445 41.2891ZM61.0176 54.2088L56.0546 59.1307C55.6855 59.541 55.1523 59.7461 54.6191 59.7461C54.0859 59.7461 53.5527 59.541 53.1837 59.1307L50.2715 56.2596C49.4512 55.4395 49.4512 54.127 50.2715 53.3477C51.0508 52.5273 52.3633 52.5273 53.1837 53.3477L54.6191 54.7831L58.1053 51.2969C58.9256 50.4766 60.2383 50.4766 61.0176 51.2969C61.8379 52.0762 61.8379 53.3887 61.0176 54.2088ZM61.7969 22.832V14.6289C61.7969 11.2364 59.0371 8.47656 55.6445 8.47656H6.15234C2.7598 8.47656 0 11.2364 0 14.6289V22.832C0 28.4858 4.59826 33.0859 10.2539 33.0859H28.9844V35.1367C28.9844 36.2703 29.9016 37.1875 31.0352 37.1875C32.1687 37.1875 33.0859 36.2703 33.0859 35.1367V33.0859H51.543C57.1986 33.0859 61.7969 28.4858 61.7969 22.832Z"
          fill="url(#paint1_linear_624_11994)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_624_11994"
          x1="14.6419"
          y1="47.582"
          x2="61.477"
          y2="-0.682087"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[2]} />
          {/*<stop offset="0.5028" stop-color="#C7D4EC" />*/}
          <stop offset="1" stopColor={palette[3]} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_624_11994"
          x1="0.624168"
          y1="69.4449"
          x2="53.0512"
          y2="9.79445"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[0]} />
          <stop offset="1" stopColor={palette[1]} />
        </linearGradient>
        <clipPath id="clip0_624_11994">
          <rect width="70" height="70" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

WorkSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  palette: PropTypes.arrayOf(PropTypes.string),
};

export default WorkSvg;
