import { useEffect, useState } from 'react';
import { getRandomBlogSubRoutes } from '_helpers/blog';

export const useRandomBlogSubRoutes = (route) => {
  const [randomBlogSubRoutes, setRandomBlogRoutes] = useState([]);

  useEffect(() => {
    if (route) {
      setRandomBlogRoutes(getRandomBlogSubRoutes({ exclude: [route] }));
    }
  }, [route]);

  return randomBlogSubRoutes;
};
