import { menuItems } from '_constants/menuItems';
import MenuItems from './MenuItems';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import { theme } from '_constants/theme';

const { colors, devices } = theme;
const StyledNav = styled.nav`
  .menus {
    display: flex;
    list-style: none;
    padding: 0px;
    margin: 0px;
  }
  .menu-items button {
    display: flex;
    align-items: center;
    color: ${colors.gray[500]};
    font-size: 16px;
    font-family: 'Aeonik';
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    width: 100%;
  }
  .menu-items > a,
  .menu-items button {
    text-align: left;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 12px;
    padding-right: 12px;
  }
  .menu-items svg {
    margin-top: 3px;
    margin-left: 4px;
  }
  .menu-items a:hover,
  .menu-items button:hover {
    background-color: ${colors.gray[100]};
  }
  .dropdown {
    position: absolute;
    left: auto;
    box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
      0 4px 6px -2px rgba(71, 63, 79, 0.16);
    font-size: 0.875rem;
    z-index: 9999;
    min-width: 10rem;
    padding: 0.5rem 0;
    list-style: none;
    background-color: ${colors.white};
    border-radius: 0.5rem;
    display: none;
  }

  .dropdown.show {
    display: block;
  }

  .dropdown .dropdown-submenu {
    position: absolute;
    left: 100%;
    top: -7px;
  }

  @media ${devices.tablet} {
    .menus {
      border-top: 1px solid ${colors.gray[100]};
      padding-bottom: 16px;
      .menu-items {
        border-bottom: 1px solid ${colors.gray[100]};
        padding-top: 16px;
        padding-bottom: 16px;
      }
    }
    .menu-items > a,
    .menu-items button,
    .menu-items button > a {
      font-family: 'Aeonik';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      padding-left: 0px;
      color: ${colors.gray[500]};
      text-decoration: none;
      padding-top: 0px;
      padding-bottom: 0px;
    }
    .menus {
      display: block;
    }
    .dropdown {
      position: static;
      box-shadow: initial;
      font-size: 0.875rem;
      padding: 0;
      padding-left: 50px;
      list-style: none;
      display: none;
      max-height: 300px;
      overflow-y: scroll;
      .menu-items > a {
        font-family: 'Aeonik';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: ${colors.gray[600]};
      }
      .menu-items > a.active,
      .menu-items button > a.active {
        color: ${colors.indigo[600]};
      }
    }
    .menu-items a:hover,
    .menu-items button:hover {
      background-color: initial;
    }
    .menu-items li {
      border-bottom: 1px solid ${colors.gray[100]};
      padding-top: 10px;
      padding-bottom: 10px;
    }
    .menu-items li:last-child {
      border: 0;
      padding-bottom: 0px;
    }
    a.active,
    button > a.active {
      color: ${colors.indigo[600]};
    }
  }
`;

const Navbar = ({ handleOpen }) => {
  const router = useRouter();
  return (
    <StyledNav>
      <ul className="menus">
        {menuItems.map((menu, index) => {
          const depthLevel = 0;
          return (
            <MenuItems
              items={menu}
              key={index}
              depthLevel={depthLevel}
              router={router}
              handleOpen={handleOpen}
            />
          );
        })}
      </ul>
    </StyledNav>
  );
};

export default Navbar;
