import { ROUTES, BLOG_SUB_ROUTES } from './routes';

export const menuItems = [
  {
    title: 'Home',
    url: `${ROUTES.home}`,
  },
  {
    title: 'About',
    url: `${ROUTES.about}`,
  },
  {
    title: 'Services',
    url: `${ROUTES.services}`,
    submenu: [
      {
        title: 'LLC Incorporation',
        url: `${ROUTES.llcIncorporation}`,
      },
      {
        title: 'EIN/Tax ID',
        url: `${ROUTES.einTaxId}`,
      },
      {
        title: 'Registered Agent Service',
        url: `${ROUTES.registeredAgent}`,
      },
      {
        title: 'Trademarking',
        url: `${ROUTES.trademarking}`,
      },
      {
        title: 'Bookeeping',
        url: `${ROUTES.bookkeeping}`,
      },
      {
        title: 'Virtual Address',
        url: `${ROUTES.virtualAddress}`,
      },
      {
        title: 'Payroll',
        url: `${ROUTES.payroll}`,
      },
      {
        title: 'Merchant Processing',
        url: `${ROUTES.merchantProcessing}`,
      },
    ],
  },
  {
    title: 'Resource Center',
    url: `${ROUTES.blogGallery}`,
  },
  {
    title: 'Contact Us',
    url: `${ROUTES.contactUs}`,
  },
];
