import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { MainApiService } from '_services';
import { processResponse, processError } from '_helpers/api';

export const getActivities = createAsyncThunk(
  'variants/getActivities',
  async function (_, { rejectWithValue }) {
    try {
      const response = await MainApiService.getActivities();
      return processResponse(response);
    } catch (e) {
      return rejectWithValue(processError(e));
    }
  }
);

export const getProcessingOptions = createAsyncThunk(
  'variants/getProcessingOptions',
  async function (_, { rejectWithValue }) {
    try {
      const response = await MainApiService.getProcessingOptions();
      return processResponse(response);
    } catch (e) {
      return rejectWithValue(processError(e));
    }
  }
);

export const getStates = createAsyncThunk(
  'variants/getStates',
  async function (_, { rejectWithValue }) {
    try {
      const response = await MainApiService.getStates();
      return processResponse(response);
    } catch (e) {
      return rejectWithValue(processError(e));
    }
  }
);

export const getPrices = createAsyncThunk(
  'variants/getPrices',
  async function (params = {}, { rejectWithValue }) {
    try {
      const response = await MainApiService.getPrices({
        onlyForSale: false,
        ...params,
      });
      return processResponse(response);
    } catch (e) {
      return rejectWithValue(processError(e));
    }
  }
);

export const calculateFees = createAsyncThunk(
  'variants/calculateFees',
  async function ({ state, codes, ...restParams }, { rejectWithValue }) {
    try {
      const response = await MainApiService.calculateFees({
        state,
        codes,
        ...restParams,
      });
      return processResponse(response);
    } catch (e) {
      return rejectWithValue(processError(e));
    }
  }
);

export const getUpsellOffers = createAsyncThunk(
  'variants/getUpsellOffers',
  async function (_, { rejectWithValue }) {
    try {
      const response = await MainApiService.getUpsellOffers({ active: true });
      return processResponse(response);
    } catch (e) {
      return rejectWithValue(processError(e));
    }
  }
);

export const getFileTypes = createAsyncThunk(
  'variants/getFileTypes',
  async function ({ productCode }, { rejectWithValue }) {
    try {
      const response = await MainApiService.getFileTypes({ productCode });
      return processResponse(response);
    } catch (e) {
      return rejectWithValue(processError(e));
    }
  }
);

const variantsSlice = createSlice({
  name: 'variants',
  initialState: {
    activities: {
      data: [],
      error: null,
      loading: false,
    },
    processingOptions: {
      data: [],
      error: null,
      loading: false,
    },
    states: {
      data: [],
      error: null,
      loading: false,
    },
    prices: {
      data: [],
      error: null,
      loading: false,
    },
    fees: {
      data: [],
      error: null,
      loading: false,
    },
    upsellOffers: {
      data: [],
      error: null,
      loading: false,
    },
    fileTypes: {
      data: [],
      error: null,
      loading: false,
    },
  },
  reducers: {
    resetErrors(state) {
      state.activities.error = null;
      state.processingOptions.error = null;
      state.states.error = null;
      state.prices.error = null;
      state.fees.error = null;
      state.upsellOffers.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getActivities.pending, (state) => {
        state.activities.error = null;
        state.activities.loading = true;
      })
      .addCase(getActivities.fulfilled, (state, action) => {
        state.activities.data = action.payload;
        state.activities.loading = false;
      })
      .addCase(getActivities.rejected, (state, action) => {
        state.activities.error = action.payload;
        state.activities.loading = false;
      })
      .addCase(getProcessingOptions.pending, (state) => {
        state.processingOptions.error = null;
        state.processingOptions.loading = true;
      })
      .addCase(getProcessingOptions.fulfilled, (state, action) => {
        state.processingOptions.data = action.payload;
        state.processingOptions.loading = false;
      })
      .addCase(getProcessingOptions.rejected, (state, action) => {
        state.processingOptions.error = action.payload;
        state.processingOptions.loading = false;
      })
      .addCase(getStates.pending, (state) => {
        state.states.error = null;
        state.states.loading = true;
      })
      .addCase(getStates.fulfilled, (state, action) => {
        state.states.data = action.payload;
        state.states.loading = false;
      })
      .addCase(getStates.rejected, (state, action) => {
        state.states.error = action.payload;
        state.states.loading = false;
      })
      .addCase(getPrices.pending, (state) => {
        state.prices.error = null;
        state.prices.loading = true;
      })
      .addCase(getPrices.fulfilled, (state, action) => {
        state.prices.data = action.payload;
        state.prices.loading = false;
      })
      .addCase(getPrices.rejected, (state, action) => {
        state.prices.error = action.payload;
        state.prices.loading = false;
      })
      .addCase(calculateFees.pending, (state) => {
        state.fees.error = null;
        state.fees.loading = true;
      })
      .addCase(calculateFees.fulfilled, (state, action) => {
        state.fees.data = action.payload;
        state.fees.loading = false;
      })
      .addCase(calculateFees.rejected, (state, action) => {
        state.fees.error = action.payload;
        state.fees.loading = false;
      })
      .addCase(getUpsellOffers.pending, (state) => {
        state.upsellOffers.error = null;
        state.upsellOffers.loading = true;
      })
      .addCase(getUpsellOffers.fulfilled, (state, action) => {
        state.upsellOffers.data = action.payload;
        state.upsellOffers.loading = false;
      })
      .addCase(getUpsellOffers.rejected, (state, action) => {
        state.fees.error = action.payload;
        state.upsellOffers.loading = false;
      })
      .addCase(getFileTypes.pending, (state) => {
        state.fileTypes.error = null;
        state.fileTypes.loading = true;
      })
      .addCase(getFileTypes.fulfilled, (state, action) => {
        state.fileTypes.data = action.payload;
        state.fileTypes.loading = false;
      })
      .addCase(getFileTypes.rejected, (state, action) => {
        state.fileTypes.error = action.payload;
        state.fileTypes.loading = false;
      });
  },
});

export const { resetErrors } = variantsSlice.actions;

export default variantsSlice.reducer;
