import PropTypes from 'prop-types';

const FreelanceSvg = ({ width, height, mixBlendMode, palette = [] }) => {
  return (
    <svg
      width={`${width || 51}`}
      height={`${height || 50}`}
      viewBox="0 0 51 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        style={{ mixBlendMode: mixBlendMode || 'normal' }}
        clipPath={`url(#clip0_665_6773${width + height})`}
      >
        <path
          d="M9.28906 8.52529L16.6133 11.7188L29.7969 8.78906V4.39453C29.7969 1.96279 27.8342 0 25.4023 0H7.82422C7.00381 0 6.35937 0.644434 6.35937 1.46484V4.39453C6.35937 6.29893 7.58965 7.93955 9.28906 8.52529ZM38.0002 33.3888C36.5646 29.3164 32.6973 26.5625 28.332 26.5625H21.0078L19.543 29.4922L18.0781 26.5625H10.7539C5.0998 26.5625 0.5 31.1621 0.5 36.8164V42.6758C0.5 43.4962 1.14443 44.1406 1.96484 44.1406H6.35937L7.82422 41.2109L9.28906 44.1406H24.8165C25.4023 44.1406 25.9588 43.7891 26.1641 43.2324L29.3283 35.3516H36.6232C37.0917 35.3516 37.5312 35.1171 37.8241 34.7362C38.0881 34.3554 38.1758 33.8574 38.0002 33.3888Z"
          fill={`url(#paint0_linear_665_6773${width + height})`}
        />
        <path
          d="M29.7969 16.3087V8.78916H10.7539C10.2268 8.78916 9.75752 8.70117 9.28906 8.52539V16.3087C9.28906 21.9584 13.8933 26.5626 19.543 26.5626C25.1971 26.5626 29.7969 21.963 29.7969 16.3087ZM18.0781 33.8868C18.0781 34.7072 18.7226 35.3517 19.543 35.3517C20.3634 35.3517 21.0078 34.7072 21.0078 33.8868V26.5626H19.543H18.0781V33.8868ZM7.82422 35.3517C7.00381 35.3517 6.35938 35.9961 6.35938 36.8165V44.1407H9.28906V36.8165C9.28906 35.9961 8.64463 35.3517 7.82422 35.3517ZM50.2482 33.0657C49.9765 32.6638 49.5216 32.422 49.0352 32.422H28.332C27.7341 32.422 27.1934 32.7867 26.973 33.3433L21.482 47.0704H16.6133C15.8036 47.0704 15.1484 47.7256 15.1484 48.5353C15.1484 49.3449 15.8036 50.0001 16.6133 50.0001H43.1758C43.7737 50.0001 44.3144 49.6354 44.5348 49.0788L50.3941 34.4304C50.5744 33.9798 50.52 33.4677 50.2482 33.0657Z"
          fill={`url(#paint1_linear_665_6773${width + height})`}
        />
      </g>
      <defs>
        <linearGradient
          id={`paint0_linear_665_6773${width + height}`}
          x1="19.2958"
          y1="44.1406"
          x2="19.2958"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[0]} />
          <stop offset="1" stopColor={palette[1]} />
        </linearGradient>
        <linearGradient
          id={`paint1_linear_665_6773${width + height}`}
          x1="28.4292"
          y1="50.0001"
          x2="28.4292"
          y2="8.52539"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[2]} />
          <stop offset="1" stopColor={palette[3]} />
        </linearGradient>
        <clipPath id={`clip0_665_6773${width + height}`}>
          <rect
            width="50"
            height="50"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

FreelanceSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  mixBlendMode: PropTypes.string,
  palette: PropTypes.arrayOf(PropTypes.string),
};

export default FreelanceSvg;
