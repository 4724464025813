const colors = {
  white: '#FFFFFF',
  black: '#000000',
  monexa: '#6941C6',
  gray: {
    25: '#FCFCFD',
    50: '#F9FAFB',
    100: '#F2F4F7',
    200: '#EAECF0',
    300: '#D0D5DD',
    400: '#98A2B3',
    500: '#667085',
    600: '#475467',
    700: '#344054',
    800: '#1D2939',
    900: '#101828',
  },
  green: {
    25: '#F6FEF9',
    50: '#ECFDF3',
    100: '#D1FADF',
    200: '#A6F4C5',
    300: '#6CE9A6',
    400: '#32D583',
    500: '#12B76A',
    600: '#039855',
    700: '#027A48',
    800: '#05603A',
    900: '#054F31',
  },
  orange: {
    25: '#FFFAF5',
    50: '#FFF6ED',
    100: '#FFEAD5',
    200: '#FDDCAB',
    300: '#FEB273',
    400: '#FD853A',
    500: '#FB6514',
    600: '#EC4A0A',
    700: '#C4320A',
    800: '#9C2A10',
    900: '#7E2410',
  },
  blue: {
    25: '#F5FAFF',
    50: '#EFF8FF',
    100: '#D1E9FF',
    200: '#B2DDFF',
    300: '#84CAFF',
    400: '#53B1FD',
    500: '#2E90FA',
    600: '#1570EF',
    700: '#175CD3',
    800: '#1849A9',
    900: '#194185',
  },
  indigo: {
    25: '#F5F8FF',
    50: '#EEF4FF',
    100: '#E0EAFF',
    200: '#C7D7FE',
    300: '#A4BCFD',
    400: '#8098F9',
    500: '#6172F3',
    600: '#444CE7',
    650: '#454cdf',
    700: '#3538CD',
    800: '#2D31A6',
    900: '#2D3282',
  },
};

const displaySizes = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptopS: '1024px',
  laptopM: '1280px',
  laptopL: '1440px',
  desktopS: '1680px',
  desktopM: '1920px',
  desktopL: '2560px',
};

const fontSizes = {
  smallest: 8,
  smallX: 12,
  small: 14,
  regular: 16,
  medium: 18,
  large: 20,
  largeX: 22,
  large2X: 24,
  large3X: 26,
  large4X: 28,
  large5X: 30,
  large6X: 32,
  large7X: 34,
  large8X: 36,
  large9X: 40,
  large10X: 44,
  large11X: 48,
  large12X: 52,
  large13X: 56,
  large14X: 60,
};

const spaces = {
  smallest: 2,
  small2X: 4,
  smallX: 8,
  small: 12,
  regular: 16,
  medium: 20,
  large: 24,
  largeX: 28,
  large2X: 32,
  large3X: 36,
  large4X: 40,
  large5X: 44,
  large6X: 48,
  large7X: 52,
  large8X: 56,
  large9X: 60,
  large10X: 64,
  large11X: 68,
  large12X: 72,
  large13X: 76,
  large14X: 80,
  large15X: 84,
  large16X: 88,
  large17X: 92,
  large18X: 96,
  large19X: 100,
  large20X: 104,
  large21X: 108,
  large22X: 112,
  large23X: 116,
  large24X: 120,
  large25X: 124,
  large26X: 128,
  large27X: 132,
  large28X: 136,
};

export const theme = {
  colors,
  devices: {
    mobileS: `(max-width: ${displaySizes.mobileS})`,
    mobileM: `(max-width: ${displaySizes.mobileM})`,
    mobileL: `(max-width: ${displaySizes.mobileL})`,
    tablet: `(max-width: ${displaySizes.tablet})`,
    laptopS: `(max-width: ${displaySizes.laptopS})`,
    laptopM: `(max-width: ${displaySizes.laptopM})`,
    laptopL: `(max-width: ${displaySizes.laptopL})`,
    desktopS: `(max-width: ${displaySizes.desktopS})`,
    desktopM: `(max-width: ${displaySizes.desktopM})`,
    desktopL: `(max-width: ${displaySizes.desktopL})`,
  },
  displaySizes,
  fontSizes,
  spaces,
};
