import PropTypes from 'prop-types';

const MessageTextSvg = ({ color, width, height }) => {
  return (
    <svg
      width={`${width || 40}`}
      height={`${height || 40}`}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M3.3335 21.6166V11.65C3.3335 7.04998 7.06683 3.33331 11.6668 3.33331H28.3335C32.9335 3.33331 36.6668 7.04998 36.6668 11.65V23.2833C36.6668 27.8666 32.9335 31.5833 28.3335 31.5833H25.8335C25.3168 31.5833 24.8168 31.8333 24.5002 32.25L22.0002 35.5666C20.9002 37.0333 19.1002 37.0333 18.0002 35.5666L15.5002 32.25C15.2335 31.8833 14.6335 31.5833 14.1668 31.5833H11.6668C7.06683 31.5833 3.3335 27.8666 3.3335 23.2833V21.6166Z"
        fill={color}
      />
      <path
        d="M28.3332 14.5833H11.6665C10.9832 14.5833 10.4165 14.0166 10.4165 13.3333C10.4165 12.65 10.9832 12.0833 11.6665 12.0833H28.3332C29.0165 12.0833 29.5832 12.65 29.5832 13.3333C29.5832 14.0166 29.0165 14.5833 28.3332 14.5833Z"
        fill={color}
      />
      <path
        d="M21.6665 22.9167H11.6665C10.9832 22.9167 10.4165 22.35 10.4165 21.6667C10.4165 20.9834 10.9832 20.4167 11.6665 20.4167H21.6665C22.3498 20.4167 22.9165 20.9834 22.9165 21.6667C22.9165 22.35 22.3498 22.9167 21.6665 22.9167Z"
        fill={color}
      />
    </svg>
  );
};

MessageTextSvg.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default MessageTextSvg;
