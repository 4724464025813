import PropTypes from 'prop-types';

const ProtectionSvg = ({ width, height, palette = [] }) => {
  return (
    <svg
      width={`${width || 60}`}
      height={`${height || 60}`}
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30 7.14844L17.6953 1.875H1.75781C0.77332 1.875 0 2.64832 0 3.63281V56.3672C0 57.3514 0.77332 58.125 1.75781 58.125H58.2422C59.2267 58.125 60 57.3514 60 56.3672V10.6641L30 7.14844Z"
        fill={`url(#paint0_linear_237_16264${width + height})`}
      />
      <path
        d="M58.2422 1.875H17.6953L25.9696 10.1491C26.2992 10.4789 26.7463 10.6641 27.2125 10.6641H60V3.63281C60 2.64832 59.2267 1.875 58.2422 1.875ZM44.4844 19.5233L30.4219 16.0077C30.1405 15.9023 29.8595 15.9023 29.5781 16.0077L15.5156 19.5233C14.7421 19.6992 14.1797 20.4022 14.1797 21.2109V31.3359C14.1797 40.6522 20.5078 48.7733 29.5781 51.0233C29.7188 51.0938 29.8595 51.0938 30 51.0938C30.1405 51.0938 30.2812 51.0938 30.4219 51.0233C39.4922 48.7733 45.8203 40.6522 45.8203 31.3359V21.2109C45.8203 20.4022 45.2579 19.6992 44.4844 19.5233ZM36.5038 31.2304L29.4725 38.2616C29.1562 38.6133 28.6992 38.7891 28.2422 38.7891C27.7852 38.7891 27.3281 38.6133 27.0118 38.2616L23.4962 34.746C22.7931 34.078 22.7931 32.953 23.4962 32.285C24.1642 31.582 25.2891 31.582 25.957 32.285L28.2423 34.535L34.0432 28.7694C34.7112 28.0664 35.8361 28.0664 36.504 28.7694C37.207 29.4374 37.207 30.5624 36.5038 31.2304ZM17.6953 10.6641C17.6953 9.69246 16.9091 8.90625 15.9375 8.90625H8.90625C7.93465 8.90625 7.14844 9.69246 7.14844 10.6641C7.14844 11.6357 7.93465 12.4219 8.90625 12.4219H15.9375C16.9091 12.4219 17.6953 11.6357 17.6953 10.6641Z"
        fill={`url(#paint1_linear_237_16264${width + height})`}
      />
      <defs>
        <linearGradient
          id={`paint0_linear_237_16264${width + height}`}
          x1="30"
          y1="58.125"
          x2="30"
          y2="1.875"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[0]} />
          <stop offset="1" stopColor={palette[1]} />
        </linearGradient>
        <linearGradient
          id={`paint1_linear_237_16264${width + height}`}
          x1="33.5742"
          y1="51.0938"
          x2="33.5742"
          y2="1.875"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[2]} />
          <stop offset="1" stopColor={palette[3]} />
        </linearGradient>
      </defs>
    </svg>
  );
};

ProtectionSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  palette: PropTypes.arrayOf(PropTypes.string),
};

export default ProtectionSvg;
