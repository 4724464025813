import PropTypes from 'prop-types';

const FeedbackSvg = ({ width, height, mixBlendMode, palette = [] }) => {
  return (
    <svg
      width={`${width || 70}`}
      height={`${height || 70}`}
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath={`url(#clip0_624_7413${width + height})`}>
        <path
          d="M45.9375 38.0078C45.9375 44.0387 41.0309 48.9453 35 48.9453C28.9691 48.9453 24.0625 44.0387 24.0625 38.0078C24.0625 31.9769 28.9691 27.0703 35 27.0703C41.0309 27.0703 45.9375 31.9769 45.9375 38.0078Z"
          fill={`url(#paint0_linear_624_7413${width + height})`}
        />
        <path
          d="M46.0017 10.4726L41.9886 14.3844L42.936 19.9079C43.2233 21.5832 41.4649 22.8607 39.9604 22.0698L35 19.4617L30.0396 22.0697C28.5351 22.8606 26.7766 21.5831 27.064 19.9077L28.0113 14.3843L23.9982 10.4726C22.781 9.28618 23.4527 7.21913 25.1348 6.97467L30.6808 6.16872L33.161 1.14321C33.9132 -0.381069 36.0868 -0.381069 36.839 1.14321L39.3192 6.16872L44.8652 6.97467C46.5473 7.21913 47.2189 9.28618 46.0017 10.4726ZM68.2419 15.4512L62.6959 14.6453L60.2157 9.61977C59.4635 8.09549 57.2899 8.09549 56.5377 9.61977L54.0575 14.6453L48.5115 15.4512C46.8294 15.6957 46.1579 17.7627 47.3749 18.9492L51.3881 22.861L50.4407 28.3844C50.1533 30.0598 51.9118 31.3373 53.4163 30.5464L58.3767 27.9384L63.3371 30.5464C64.8416 31.3373 66.6001 30.0598 66.3127 28.3844L65.3654 22.861L69.3785 18.9492C70.5955 17.7627 69.924 15.6957 68.2419 15.4512ZM15.9426 14.6451L13.4623 9.61977C12.71 8.09549 10.5365 8.09549 9.78426 9.61977L7.30404 14.6453L1.75805 15.4512C0.0759971 15.6957 -0.595565 17.7627 0.621505 18.9492L4.63461 22.861L3.68729 28.3844C3.3999 30.0598 5.15838 31.3373 6.66283 30.5464L11.6233 27.9384L16.5837 30.5464C18.0881 31.3373 19.8466 30.0598 19.5592 28.3844L18.6119 22.861L22.625 18.9492C23.8422 17.7627 23.1705 15.6957 21.4885 15.4512L15.9426 14.6451ZM41.0408 47.1198C39.3087 48.2719 37.2318 48.9453 35 48.9453C32.7682 48.9453 30.6913 48.2719 28.9592 47.1198C20.5904 49.7045 14.4922 57.5125 14.4922 66.7187V67.9492C14.4922 69.0818 15.4104 70 16.5429 70H53.457C54.5896 70 55.5078 69.0818 55.5078 67.9492V66.7187C55.5078 57.5125 49.4096 49.7045 41.0408 47.1198Z"
          fill={`url(#paint1_linear_624_7413${width + height})`}
        />
      </g>
      <defs>
        <linearGradient
          id={`paint0_linear_624_7413${width + height}`}
          x1="35"
          y1="48.9453"
          x2="35"
          y2="27.0703"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[2]} />
          <stop offset="1" stopColor={palette[3]} />
        </linearGradient>
        <linearGradient
          id={`paint1_linear_624_7413${width + height}`}
          x1="35"
          y1="70"
          x2="35"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[0]} />
          <stop offset="1" stopColor={palette[1]} />
        </linearGradient>
        <clipPath id={`clip0_624_7413${width + height}`}>
          <rect width="70" height="70" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

FeedbackSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  mixBlendMode: PropTypes.string,
  palette: PropTypes.arrayOf(PropTypes.string),
};

export default FeedbackSvg;
