import PropTypes from 'prop-types';

const HeadphonesSvg = ({ width, height, palette = [] }) => {
  return (
    <svg
      width={`${width || 90}`}
      height={`${height || 90}`}
      viewBox="0 0 90 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M71.3672 68.9062C69.911 68.9062 68.7305 70.0868 68.7305 71.543C68.7305 75.9046 65.182 79.4531 60.8203 79.4531H50.2734C48.8173 79.4531 47.6367 80.6337 47.6367 82.0898C47.6367 83.546 48.8173 84.7266 50.2734 84.7266H60.8203C68.0897 84.7266 74.0039 78.8124 74.0039 71.543C74.0039 70.0868 72.8234 68.9062 71.3672 68.9062ZM45 0C23.1917 0 5.44922 17.8212 5.44922 39.7266V60.9961C5.44922 62.4523 6.62977 63.6328 8.08594 63.6328C9.54211 63.6328 10.7227 62.4523 10.7227 60.9961V39.7266C10.7227 20.729 26.0995 5.27344 45 5.27344C63.9005 5.27344 79.2773 20.729 79.2773 39.7266V60.9961C79.2773 62.4523 80.4579 63.6328 81.9141 63.6328C83.3702 63.6328 84.5508 62.4523 84.5508 60.9961V39.7266C84.5508 17.8212 66.8083 0 45 0ZM54.3442 60.2771C55.6857 60.8544 57.2347 60.2306 57.8085 58.8971C58.384 57.5596 57.7661 56.0085 56.4284 55.433C52.8089 53.8755 48.9637 53.0859 45 53.0859C41.0363 53.0859 37.1911 53.8755 33.5716 55.433C32.2339 56.0085 31.616 57.5596 32.1915 58.8971C32.767 60.2348 34.3179 60.8525 35.6558 60.2771C38.6133 59.0045 41.757 58.3594 45 58.3594C48.243 58.3594 51.3867 59.0045 54.3442 60.2771ZM53.6541 68.5905C54.2294 67.2529 53.6115 65.7019 52.2738 65.1264C49.9697 64.1354 47.5225 63.6328 45 63.6328C42.4775 63.6328 40.0303 64.1354 37.7262 65.1266C36.3885 65.7021 35.7706 67.253 36.3459 68.5907C36.9214 69.9284 38.4722 70.5463 39.8101 69.971C41.452 69.2645 43.1982 68.9062 45 68.9062C46.8018 68.9062 48.548 69.2645 50.1899 69.9708C51.5319 70.548 53.0805 69.924 53.6541 68.5905Z"
        fill={`url(#paint0_linear_488_11137${width + height})`}
      />
      <path
        d="M45 74.1797C40.6383 74.1797 37.0898 77.7282 37.0898 82.0898C37.0898 86.4515 40.6383 90 45 90C49.3617 90 52.9102 86.4515 52.9102 82.0898C52.9102 77.7282 49.3617 74.1797 45 74.1797ZM18.6328 42.5391C11.3634 42.5391 5.44922 48.4532 5.44922 55.7227V60.9961C5.44922 68.2655 11.3634 74.1797 18.6328 74.1797C20.089 74.1797 21.2695 72.9991 21.2695 71.543V45.1758C21.2695 43.7196 20.089 42.5391 18.6328 42.5391ZM71.3672 42.5391C69.911 42.5391 68.7305 43.7196 68.7305 45.1758V71.543C68.7305 72.9991 69.911 74.1797 71.3672 74.1797C78.6366 74.1797 84.5508 68.2655 84.5508 60.9961V55.7227C84.5508 48.4532 78.6366 42.5391 71.3672 42.5391Z"
        fill={`url(#paint1_linear_488_11137${width + height})`}
      />
      <defs>
        <linearGradient
          id={`paint0_linear_488_11137${width + height}`}
          x1="45"
          y1="84.7266"
          x2="45"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[2]} />
          <stop offset="1" stopColor={palette[3]} />
        </linearGradient>
        <linearGradient
          id={`paint1_linear_488_11137${width + height}`}
          x1="6.15454"
          y1="89.5718"
          x2="42.1379"
          y2="29.5995"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[0]} />
          <stop offset="1" stopColor={palette[1]} />
        </linearGradient>
      </defs>
    </svg>
  );
};

HeadphonesSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  palette: PropTypes.arrayOf(PropTypes.string),
};

export default HeadphonesSvg;
