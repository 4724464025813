import PropTypes from 'prop-types';

const ArrowUpSvg = ({ color, width, height }) => {
  return (
    <svg
      width={`${width || 40}`}
      height={`${height || 40}`}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M20.0002 36.6666C29.2049 36.6666 36.6668 29.2047 36.6668 20C36.6668 10.7952 29.2049 3.33331 20.0002 3.33331C10.7954 3.33331 3.3335 10.7952 3.3335 20C3.3335 29.2047 10.7954 36.6666 20.0002 36.6666Z"
        fill={color}
      />
      <path
        d="M25.8836 18.2833L20.8836 13.2833C20.4002 12.8 19.6002 12.8 19.1169 13.2833L14.1169 18.2833C13.6336 18.7667 13.6336 19.5667 14.1169 20.05C14.6002 20.5333 15.4002 20.5333 15.8836 20.05L18.7502 17.1833V25.8333C18.7502 26.5167 19.3169 27.0833 20.0002 27.0833C20.6836 27.0833 21.2502 26.5167 21.2502 25.8333V17.1833L24.1169 20.05C24.3669 20.3 24.6836 20.4167 25.0002 20.4167C25.3169 20.4167 25.6336 20.3 25.8836 20.05C26.3669 19.5667 26.3669 18.7667 25.8836 18.2833Z"
        fill={color}
      />
    </svg>
  );
};

ArrowUpSvg.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default ArrowUpSvg;
