import { useState, useEffect, useRef } from 'react';
import Link from 'next/link';
import Dropdown from '../Dropdown';
import Icon from '_components/atomic/Icon';
import { ICONS_ALIASES } from '_constants/icons';
import { theme } from '_constants/theme';

const MenuItems = ({ items, depthLevel, router, handleOpen }) => {
  const [dropdown, setDropdown] = useState(false);

  let ref = useRef();

  useEffect(() => {
    const handler = (event) => {
      if (dropdown && ref.current && !ref.current.contains(event.target)) {
        setDropdown(false);
      }
    };
    document.addEventListener('mousedown', handler);
    document.addEventListener('touchstart', handler);
    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', handler);
      document.removeEventListener('touchstart', handler);
    };
  }, [dropdown]);

  const onMouseEnter = () => {
    setDropdown(true);
  };

  const onMouseLeave = () => {
    setDropdown(false);
  };

  const closeDropdown = () => {
    setDropdown(!dropdown);
  };

  return (
    <li
      className="menu-items"
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={closeDropdown}
    >
      {items.url && items.submenu ? (
        <>
          <button
            type="button"
            aria-haspopup="menu"
            aria-expanded={dropdown ? 'true' : 'false'}
            onClick={() => setDropdown((prev) => !prev)}
          >
            <Link
              href={items.url}
              className={router.pathname.endsWith(items.url) ? 'active' : ''}
              onClick={handleOpen}
            >
              {items.title}
            </Link>
            {depthLevel > 0 ? null : depthLevel > 0 ? (
              <span>&raquo;</span>
            ) : (
              <Icon
                alias={
                  dropdown ? ICONS_ALIASES.chevronUp : ICONS_ALIASES.chevronDown
                }
                color={theme.colors.gray[500]}
              />
            )}
          </button>
          <Dropdown
            depthLevel={depthLevel}
            submenus={items.submenu}
            dropdown={dropdown}
            router={router}
            handleOpen={handleOpen}
          />
        </>
      ) : !items.url && items.submenu ? (
        <>
          <button
            type="button"
            aria-haspopup="menu"
            aria-expanded={dropdown ? 'true' : 'false'}
            onClick={() => setDropdown((prev) => !prev)}
          >
            {items.title}{' '}
            {depthLevel > 0 ? (
              <span>&raquo;</span>
            ) : (
              <Icon
                alias={
                  dropdown ? ICONS_ALIASES.chevronUp : ICONS_ALIASES.chevronDown
                }
                color={theme.colors.gray[500]}
              />
            )}
          </button>
          <Dropdown
            depthLevel={depthLevel}
            submenus={items.submenu}
            dropdown={dropdown}
            router={router}
            handleOpen={handleOpen}
          />
        </>
      ) : (
        <Link
          href={items.url}
          className={router.pathname.endsWith(items.url) ? 'active' : ''}
          onClick={handleOpen}
        >
          {items.title}
        </Link>
      )}
    </li>
  );
};

export default MenuItems;
