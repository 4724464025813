export const GTM_EVENTS = {
  lead: 'gtm.lead',
  sale: 'gtm.sale',
};

export const GA_EVENTS = {
  viewFilingOrganizedState: 'view_filing_organized_state',
  viewFilingBusinessInfo: 'view_filing_business_info',
  viewFilingBusinessActivity: 'view_filing_business_activity',
  viewFilingAddress: 'view_filing_address',
  viewFilingManagerInfo: 'view_filing_manager_info',
  viewFilingGeneralQuestions: 'view_filing_general_questions',
  viewFilingPackage: 'view_filing_package',
  viewFilingRegisteredAgent: 'view_filing_registered_agent',
  viewFilingTaxId: 'view_filing_tax_id',
  viewFilingBusinessLicenses: 'view_filing_business_licenses',
  viewFilingTrademark: 'view_filing_trademark',
  viewFilingAnnualCompliance: 'view_filing_annual_compliance',
  viewFilingBusinessBankingBundlePackages:
    'view_filing_business_banking_bundle_packages',
  viewFilingBOIReport: 'view_filing_boi_report',
  viewFilingRushProcessing: 'view_filing_rush_processing',
  viewFilingConfirm: 'view_filing_confirm',
  viewFilingSalesTaxPermit: 'view_filing_sales_tax_permit',
  viewFilingSCorp: 'view_filing_s_corp',
  viewFilingCertifiedCopy: 'view_filing_certified_copy',
  viewFilingBookkeeping: 'view_filing_bookkeeping',
  addToCart: 'add_to_cart',
  removeFromCart: 'remove_from_cart',
  viewCart: 'view_cart',
  beginCheckout: 'begin_checkout',
  addPaymentInfo: 'add_payment_info',
};

export const GA_ECOMMERCE_FIELDS = {
  cardType: 'cardType',
  transactionId: 'transaction_id',
  value: 'value',
  currency: 'currency',
  coupon: 'coupon',
  id: 'item_id',
  name: 'item_name',
  category: 'item_category',
  price: 'price',
  quantity: 'quantity',
};

export const GTM_ENHANCED_CONVERSION_FIELDS = {
  customerPhone: 'customerPhone',
  customerEmail: 'customerEmail',
  customerCity: 'customerCity',
  customerCountry: 'customerCountry',
  customerStreet: 'customerStreet',
  customerState: 'customerState',
  customerFirstName: 'customerFirstName',
  customerLastName: 'customerLastName',
  customerZip: 'customerZip',
};

export const HOTJAR_EVENTS = {
  leadFormSubmitted: 'lead_form_submitted',
  paymentReached: 'payment_reached',
  paymentFormSubmitted: 'payment_form_submitted',
  paymentDeclined: 'payment_declined',
  paymentSuccessful: 'payment_successful',
};
