import PropTypes from 'prop-types';

const InfoFiledSvg = ({ color, width, height }) => {
  return (
    <svg
      width={`${width || 18}`}
      height={`${height || 17}`}
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8.79048" cy="8.6416" r="8.31213" fill={color} />
      <path
        d="M8.99639 11.4725H8.19839L7.84839 4.29048H9.34639L8.99639 11.4725ZM7.72239 13.5305C7.72239 13.1852 7.80639 12.9425 7.97439 12.8025C8.14239 12.6625 8.34772 12.5925 8.59039 12.5925C8.82372 12.5925 9.02439 12.6625 9.19239 12.8025C9.36972 12.9425 9.45839 13.1852 9.45839 13.5305C9.45839 13.8665 9.36972 14.1092 9.19239 14.2585C9.02439 14.4078 8.82372 14.4825 8.59039 14.4825C8.34772 14.4825 8.14239 14.4078 7.97439 14.2585C7.80639 14.1092 7.72239 13.8665 7.72239 13.5305Z"
        fill="white"
      />
    </svg>
  );
};

InfoFiledSvg.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default InfoFiledSvg;
