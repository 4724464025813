import PropTypes from 'prop-types';

const SecuritySvg = ({ width, height, palette = [] }) => {
  return (
    <svg
      width={`${width || 70}`}
      height={`${height || 70}`}
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_713_11327)">
        <path
          d="M35 70C29.346 70 24.7461 65.4001 24.7461 59.7461C24.7461 54.0921 29.346 49.4922 35 49.4922C40.654 49.4922 45.2539 54.0921 45.2539 59.7461C45.2539 65.4001 40.654 70 35 70ZM10.2539 70C4.5999 70 0 65.4001 0 59.7461C0 54.0921 4.5999 49.4922 10.2539 49.4922C15.9079 49.4922 20.5078 54.0921 20.5078 59.7461C20.5078 65.4001 15.9079 70 10.2539 70ZM59.7461 70C54.0921 70 49.4922 65.4001 49.4922 59.7461C49.4922 54.0921 54.0921 49.4922 59.7461 49.4922C65.4001 49.4922 70 54.0921 70 59.7461C70 65.4001 65.4001 70 59.7461 70ZM35 45.1172C34.7318 45.1172 34.4637 45.0646 34.2111 44.9594C28.9617 42.7722 24.5194 39.115 21.3649 34.3831C18.2104 29.6513 16.543 24.1441 16.543 18.457V7.51953C16.543 6.97553 16.759 6.45395 17.1437 6.06949L22.6125 0.600742C22.9969 0.216016 23.5185 0 24.0625 0H45.9375C46.4815 0 47.0031 0.216016 47.3875 0.600742L52.8563 6.06949C53.241 6.45395 53.457 6.97553 53.457 7.51953V18.457C53.457 24.1441 51.7896 29.6512 48.635 34.383C45.4803 39.1148 41.0383 42.7721 35.7887 44.9593C35.5364 45.0646 35.2682 45.1172 35 45.1172Z"
          fill="url(#paint0_linear_713_11327)"
        />
        <path
          d="M35 61.7969C33.8692 61.7969 32.9492 60.8769 32.9492 59.7461C32.9492 58.6153 33.8692 57.6953 35 57.6953C36.1308 57.6953 37.0508 58.6153 37.0508 59.7461C37.0508 60.8769 36.1308 61.7969 35 61.7969ZM10.2539 61.7969C9.12311 61.7969 8.20312 60.8769 8.20312 59.7461C8.20312 58.6153 9.12311 57.6953 10.2539 57.6953C11.3847 57.6953 12.3047 58.6153 12.3047 59.7461C12.3047 60.8769 11.3847 61.7969 10.2539 61.7969ZM49.6985 57.6953H45.0475C45.1825 58.3581 45.2538 59.0439 45.2538 59.7461C45.2538 60.4483 45.1825 61.1341 45.0475 61.7969H49.6985C49.5634 61.1341 49.4922 60.4483 49.4922 59.7461C49.4922 59.0439 49.5634 58.3581 49.6985 57.6953ZM24.9524 57.6953H20.3014C20.4364 58.3581 20.5077 59.0439 20.5077 59.7461C20.5077 60.4483 20.4364 61.1341 20.3014 61.7969H24.9524C24.8173 61.1341 24.7461 60.4483 24.7461 59.7461C24.7461 59.0439 24.8173 58.3581 24.9524 57.6953ZM59.7461 61.7969C58.6153 61.7969 57.6953 60.8769 57.6953 59.7461C57.6953 58.6153 58.6153 57.6953 59.7461 57.6953C60.8769 57.6953 61.7969 58.6153 61.7969 59.7461C61.7969 60.8769 60.8769 61.7969 59.7461 61.7969ZM45.0881 4.10156H24.9119L20.6445 8.36897V18.457C20.6445 28.1358 26.2505 36.8244 35 40.8287C43.7495 36.8244 49.3555 28.1358 49.3555 18.457V8.36897L45.0881 4.10156ZM43.2173 16.4406L33.55 26.1081C32.7491 26.909 31.4506 26.909 30.6497 26.1081L26.7828 22.2411C25.9819 21.4402 25.9819 20.1418 26.7828 19.3409C27.5837 18.54 28.8821 18.54 29.683 19.3409L32.0999 21.7578L40.3173 13.5405C41.1182 12.7396 42.4166 12.7396 43.2175 13.5405C44.0181 14.3413 44.0181 15.6397 43.2173 16.4406Z"
          fill="url(#paint1_linear_713_11327)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_713_11327"
          x1="35"
          y1="70"
          x2="35"
          y2="0.000100136"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[2]} />
          <stop offset="1" stopColor={palette[3]} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_713_11327"
          x1="8.681"
          y1="61.2763"
          x2="58.1216"
          y2="15.3504"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[0]} />
          <stop offset="1" stopColor={palette[1]} />
        </linearGradient>
        <clipPath id="clip0_713_11327">
          <rect width="70" height="70" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

SecuritySvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  palette: PropTypes.arrayOf(PropTypes.string),
};

export default SecuritySvg;
