import PropTypes from 'prop-types';

const QuickSvg = ({ width, height, palette = [] }) => {
  return (
    <svg
      width={`${width || 90}`}
      height={`${height || 90}`}
      viewBox="0 0 90 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath={`url(#clip0_488_11125${width + height})`}>
        <path
          d="M55.7227 23.9062C41.1847 23.9062 29.3555 35.7354 29.3555 50.2734C29.3555 64.8114 41.1847 76.6406 55.7227 76.6406C70.2606 76.6406 82.0898 64.8114 82.0898 50.2734C82.0898 35.7354 70.2606 23.9062 55.7227 23.9062ZM50.4492 10.7227H53.0859V18.6328C53.0859 20.0902 54.2652 21.2695 55.7227 21.2695C57.1801 21.2695 58.3594 20.0902 58.3594 18.6328V10.7227H60.9961C62.4535 10.7227 63.6328 9.54334 63.6328 8.08594C63.6328 6.62854 62.4535 5.44922 60.9961 5.44922H50.4492C48.9918 5.44922 47.8125 6.62854 47.8125 8.08594C47.8125 9.54334 48.9918 10.7227 50.4492 10.7227ZM35.216 29.7668C36.2459 28.7369 36.2459 27.0682 35.216 26.0383L31.4824 22.3047C30.4525 21.2748 28.7838 21.2748 27.7539 22.3047C26.724 23.3346 26.724 25.0033 27.7539 26.0332L31.4875 29.7668C32.5174 30.7967 34.1859 30.7967 35.216 29.7668ZM83.6914 22.3047C82.6615 21.2748 80.9928 21.2748 79.9629 22.3047L76.2293 26.0383C75.1994 27.0682 75.1994 28.7369 76.2293 29.7668C77.2592 30.7967 78.9277 30.7967 79.9578 29.7668L83.6914 26.0332C84.7215 25.0031 84.7215 23.3346 83.6914 22.3047ZM15.3376 37.0898H2.63672C1.17932 37.0898 0 38.2692 0 39.7266C0 41.184 1.17932 42.3633 2.63672 42.3633H15.3376C16.795 42.3633 17.9743 41.184 17.9743 39.7266C17.9743 38.2692 16.795 37.0898 15.3376 37.0898ZM2.63672 52.9102H13.5352C14.9926 52.9102 16.1719 51.7308 16.1719 50.2734C16.1719 48.816 14.9926 47.6367 13.5352 47.6367H2.63672C1.17932 47.6367 0 48.816 0 50.2734C0 51.7308 1.17932 52.9102 2.63672 52.9102ZM15.3376 58.1836H2.63672C1.17932 58.1836 0 59.3629 0 60.8203C0 62.2777 1.17932 63.457 2.63672 63.457H15.3376C16.795 63.457 17.9743 62.2777 17.9743 60.8203C17.9743 59.3629 16.795 58.1836 15.3376 58.1836Z"
          fill={`url(#paint0_linear_488_11125${width + height})`}
        />
        <path
          d="M55.7227 15.9961C36.8438 15.9961 21.4453 31.3945 21.4453 50.2734C21.4453 69.152 36.8438 84.5508 55.7227 84.5508C74.6016 84.5508 90 69.152 90 50.2734C90 31.3945 74.6016 15.9961 55.7227 15.9961ZM55.7227 26.543C57.1994 26.543 58.3594 27.7029 58.3594 29.1797C58.3594 30.6561 57.1994 31.8164 55.7227 31.8164C54.2459 31.8164 53.0859 30.6561 53.0859 29.1797C53.0859 27.7029 54.2459 26.543 55.7227 26.543ZM34.6289 52.9102C33.1522 52.9102 31.9922 51.7498 31.9922 50.2734C31.9922 48.7967 33.1522 47.6367 34.6289 47.6367C36.1056 47.6367 37.2656 48.7967 37.2656 50.2734C37.2656 51.7498 36.1056 52.9102 34.6289 52.9102ZM55.7227 74.0039C54.2459 74.0039 53.0859 72.8436 53.0859 71.3672C53.0859 69.8904 54.2459 68.7305 55.7227 68.7305C57.1994 68.7305 58.3594 69.8904 58.3594 71.3672C58.3594 72.8436 57.1994 74.0039 55.7227 74.0039ZM68.1151 41.5721L54.9315 54.7557C54.457 55.2832 53.7715 55.5469 53.0859 55.5469C52.4004 55.5469 51.7148 55.2832 51.2404 54.7557L45.967 49.4822C44.9123 48.4803 44.9123 46.7928 45.967 45.7908C46.9689 44.7363 48.6562 44.7363 49.6582 45.7908L53.0861 49.1658L64.4242 37.8807C65.4261 36.8262 67.1135 36.8262 68.1154 37.8807C69.1699 38.8826 69.1699 40.5701 68.1151 41.5721ZM76.8164 52.9102C75.3397 52.9102 74.1797 51.7498 74.1797 50.2734C74.1797 48.7967 75.3397 47.6367 76.8164 47.6367C78.2931 47.6367 79.4531 48.7967 79.4531 50.2734C79.4531 51.7498 78.2931 52.9102 76.8164 52.9102Z"
          fill={`url(#paint1_linear_488_11125${width + height})`}
        />
      </g>
      <defs>
        <linearGradient
          id={`paint0_linear_488_11125${width + height}`}
          x1="42.232"
          y1="76.6406"
          x2="42.232"
          y2="5.44922"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[2]} />
          <stop offset="0.9973" stopColor={palette[3]} />
        </linearGradient>
        <linearGradient
          id={`paint1_linear_488_11125${width + height}`}
          x1="22.0566"
          y1="83.9322"
          x2="80.9627"
          y2="25.0261"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[0]} />
          <stop offset="1" stopColor={palette[1]} />
        </linearGradient>
        <clipPath id={`clip0_488_11125${width + height}`}>
          <rect width="90" height="90" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

QuickSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  palette: PropTypes.arrayOf(PropTypes.string),
};

export default QuickSvg;
