import PropTypes from 'prop-types';

const KindnessSvg = ({ width, height, mixBlendMode, palette = [] }) => {
  return (
    <svg
      width={`${width || 50}`}
      height={`${height || 50}`}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        style={{ mixBlendMode: mixBlendMode || 'normal' }}
        clipPath={`url(#clip0_665_6793${width + height})`}
      >
        <path
          d="M49.8576 27.4674C49.1252 25.2113 46.166 24.7133 44.7303 26.5884C44.7303 26.5884 43.5082 28.2162 42.0565 30.1499C39.9328 32.9788 37.3176 36.4622 37.3176 36.4622C36.4093 37.5463 35.1788 38.1323 33.8896 38.1323H22.6042C21.8071 38.1323 21.1292 37.5097 21.1061 36.713C21.0817 35.8709 21.7349 35.2024 22.5704 35.2024H33.5372C35.0733 35.2024 36.3935 34.0433 36.5169 32.5121C36.6556 30.7938 35.2873 29.3426 33.5968 29.3426H25.6177L25.1781 28.9324C22.8049 26.5592 19.2305 25.768 16.0368 26.9987C16.0368 26.9987 9.35346 29.1595 8.8878 29.3427L7.42285 43.9922H35.3546C37.64 43.9922 39.8082 42.9081 41.2144 41.0623L49.4182 30.1336C50.1533 29.1723 50.0562 28.1636 49.8576 27.4674Z"
          fill={`url(#paint0_linear_665_6793${width + height})`}
        />
        <path
          d="M0 30.8077V45.4573C0 46.2663 0.655908 46.9222 1.46495 46.9222H7.42243C8.23147 46.9222 8.88738 46.2663 8.88738 45.4573V29.3428H1.46495C0.655908 29.3428 0 29.9987 0 30.8077ZM36.062 3.07812C33.1521 3.07812 31.3066 4.73909 30.2021 6.34419C29.0977 4.73899 27.2522 3.07812 24.3423 3.07812C20.1663 3.07812 17.0176 6.24555 17.0176 10.4472C17.0176 14.1225 19.6041 16.2885 22.3423 18.5832C24.5369 20.4215 27.0233 22.5045 28.936 25.7892C29.1979 26.2398 29.6799 26.5174 30.2021 26.5174C30.7244 26.5174 31.2064 26.2398 31.4683 25.7892C33.381 22.5045 35.8674 20.4215 38.062 18.5832C40.8002 16.2885 43.3867 14.1225 43.3867 10.4472C43.3867 6.24545 40.2379 3.07812 36.062 3.07812Z"
          fill={`url(#paint1_linear_665_6793${width + height})`}
        />
      </g>
      <defs>
        <linearGradient
          id={`paint0_linear_665_6793${width + height}`}
          x1="28.7116"
          y1="43.9922"
          x2="28.7116"
          y2="25.436"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[2]} />
          <stop offset="1" stopColor={palette[3]} />
        </linearGradient>
        <linearGradient
          id={`paint1_linear_665_6793${width + height}`}
          x1="21.6934"
          y1="46.9222"
          x2="21.6934"
          y2="3.07812"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[0]} />
          <stop offset="1" stopColor={palette[1]} />
        </linearGradient>
        <clipPath id={`clip0_665_6793${width + height}`}>
          <rect width="50" height="50" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

KindnessSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  mixBlendMode: PropTypes.string,
  palette: PropTypes.arrayOf(PropTypes.string),
};

export default KindnessSvg;
