import PropTypes from 'prop-types';

const LocationSvg = ({ color, width, height }) => {
  return (
    <svg
      width={`${width || 30}`}
      height={`${height || 30}`}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M25.7754 10.5625C24.4629 4.7875 19.4254 2.1875 15.0004 2.1875C15.0004 2.1875 15.0004 2.1875 14.9879 2.1875C10.5754 2.1875 5.52536 4.775 4.21286 10.55C2.75036 17 6.70036 22.4625 10.2754 25.9C11.6004 27.175 13.3004 27.8125 15.0004 27.8125C16.7004 27.8125 18.4004 27.175 19.7129 25.9C23.2879 22.4625 27.2379 17.0125 25.7754 10.5625Z"
        fill={color}
      />
      <path
        d="M15 16.825C17.1746 16.825 18.9375 15.0621 18.9375 12.8875C18.9375 10.7128 17.1746 8.94995 15 8.94995C12.8254 8.94995 11.0625 10.7128 11.0625 12.8875C11.0625 15.0621 12.8254 16.825 15 16.825Z"
        fill={color}
      />
    </svg>
  );
};

LocationSvg.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default LocationSvg;
