export const config = {
  apiUrl: process.env.NEXT_PUBLIC_API_URL,
  apiUrl2: process.env.NEXT_PUBLIC_API_URL_2,
  ipApiUrl: process.env.NEXT_PUBLIC_IP_API_URL,
  ipApiKey: process.env.NEXT_PUBLIC_IP_API_KEY,
  membershipUrl: process.env.NEXT_PUBLIC_MEMBERSHIP_URL,
  trademarkUrl: process.env.NEXT_PUBLIC_TRADEMARK_URL,
  monexaUrl: process.env.NEXT_PUBLIC_MONEXA_URL,
  merchantUrl: process.env.NEXT_PUBLIC_MERCHANT_URL,
  payrollUrl: process.env.NEXT_PUBLIC_PAYROLL_URL,
  basePath: process.env.NEXT_PUBLIC_BASE_PATH,
  email: process.env.NEXT_PUBLIC_APP_EMAIL,
  phone: process.env.NEXT_PUBLIC_APP_PHONE,
  gtmId: process.env.NEXT_PUBLIC_APP_GTM,
  hjId: process.env.NEXT_PUBLIC_REACT_APP_HJID,
  hjSv: process.env.NEXT_PUBLIC_REACT_APP_HJSV,
  devtools: process.env.NEXT_PUBLIC_DEVTOOLS,
};
