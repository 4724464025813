import PropTypes from 'prop-types';

const PointerSvg = ({ width, height, palette = [] }) => {
  return (
    <svg
      width={`${width || 70}`}
      height={`${height || 70}`}
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M55.6452 6.78809H47.4419C46.3084 6.78809 45.3911 7.70535 45.3911 8.8389V15.524C45.3911 16.1148 45.6474 16.6777 46.0921 17.0681L54.2953 24.246C54.9032 24.7772 55.764 24.9013 56.4944 24.5684C57.2273 24.2359 57.696 23.5069 57.696 22.7019V8.8389C57.696 7.70535 56.7787 6.78809 55.6452 6.78809ZM35.0003 19.093C25.9518 19.093 18.5938 26.4531 18.5938 35.4995C18.5938 41.6821 21.5417 45.0506 24.4017 48.3211C26.8089 51.0749 29.2964 53.9208 31.1108 59.3583C31.6717 61.0347 33.2337 62.1602 35.0002 62.1602C36.7666 62.1602 38.3288 61.0347 38.8936 59.3563C40.708 53.9068 43.1995 51.0629 45.6108 48.3091C48.4627 45.0506 51.4068 41.6819 51.4068 35.4995C51.4068 26.4531 44.0488 19.093 35.0003 19.093Z"
        fill="url(#paint0_linear_504_6903)"
      />
      <path
        d="M69.2995 31.9046L36.3538 3.19523C35.5766 2.51832 34.4192 2.51627 33.6501 3.19318L0.700301 31.9046C0.0553873 32.4674 -0.168835 33.3707 0.131541 34.1697C0.431917 34.9707 1.19701 35.4996 2.05015 35.4996H8.2026V66.2618C8.2026 67.3954 9.11986 68.3126 10.2534 68.3126H59.7465C60.88 68.3126 61.7973 67.3954 61.7973 66.2618V35.4996H67.9497C68.8029 35.4996 69.568 34.9688 69.8683 34.1697C70.1687 33.3686 69.9444 32.4654 69.2995 31.9046ZM44.0643 46.9634C41.6369 49.7332 38.8891 52.8735 36.9464 58.7074C36.666 59.5446 35.881 60.1094 34.9998 60.1094C34.1186 60.1094 33.3335 59.5446 33.0532 58.7074C31.1105 52.8734 28.3587 49.7311 25.9353 46.9593C23.2155 43.851 20.6441 40.9129 20.6441 35.4996C20.6441 27.5846 27.0849 21.1438 34.9998 21.1438C42.9147 21.1438 49.3555 27.5846 49.3555 35.4996C49.3555 40.9151 46.7841 43.8531 44.0643 46.9634ZM34.9998 29.3471C31.6072 29.3471 28.8474 32.107 28.8474 35.4996C28.8474 38.8922 31.6072 41.652 34.9998 41.652C38.3924 41.652 41.1523 38.8922 41.1523 35.4996C41.1523 32.107 38.3925 29.3471 34.9998 29.3471Z"
        fill="url(#paint1_linear_504_6903)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_504_6903"
          x1="38.1449"
          y1="62.1602"
          x2="38.1449"
          y2="6.78809"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[2]} />
          <stop offset="1" stopColor={palette[3]} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_504_6903"
          x1="34.9999"
          y1="68.3126"
          x2="34.9999"
          y2="2.68652"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[0]} />
          <stop offset="1" stopColor={palette[1]} />
        </linearGradient>
      </defs>
    </svg>
  );
};

PointerSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  palette: PropTypes.arrayOf(PropTypes.string),
};

export default PointerSvg;
