import PropTypes from 'prop-types';

const FacebookSvg = ({ color, width, height }) => {
  return (
    <svg
      width={`${width || 24}`}
      height={`${height || 24}`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5922 7.86353L17.156 12H13.8496V24H8.88303V12H6.4082V7.86353H8.88303V5.37318C8.88303 2.00894 10.2821 0 14.259 0H17.564V4.13647H15.4957C13.9527 4.13647 13.8496 4.71953 13.8496 5.79671V7.86353H17.5922V7.86353Z"
        fill={color}
      />
    </svg>
  );
};

FacebookSvg.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default FacebookSvg;
