import PropTypes from 'prop-types';

const MoreSvg = ({ color, width, height }) => {
  return (
    <svg
      width={`${width || 30}`}
      height={`${height || 30}`}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M20.2375 2.5H9.7625C5.2125 2.5 2.5 5.2125 2.5 9.7625V20.225C2.5 24.7875 5.2125 27.5 9.7625 27.5H20.225C24.775 27.5 27.4875 24.7875 27.4875 20.2375V9.7625C27.5 5.2125 24.7875 2.5 20.2375 2.5Z"
        fill={color}
      />
      <path
        d="M15 16.25C14.3 16.25 13.75 15.6875 13.75 15C13.75 14.3125 14.3125 13.75 15 13.75C15.6875 13.75 16.25 14.3125 16.25 15C16.25 15.6875 15.7 16.25 15 16.25Z"
        fill={color}
      />
      <path
        d="M20 16.25C19.3 16.25 18.75 15.6875 18.75 15C18.75 14.3125 19.3125 13.75 20 13.75C20.6875 13.75 21.25 14.3125 21.25 15C21.25 15.6875 20.7 16.25 20 16.25Z"
        fill={color}
      />
      <path
        d="M10 16.25C9.3 16.25 8.75 15.6875 8.75 15C8.75 14.3125 9.3125 13.75 10 13.75C10.6875 13.75 11.25 14.3125 11.25 15C11.25 15.6875 10.7 16.25 10 16.25Z"
        fill={color}
      />
    </svg>
  );
};

MoreSvg.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default MoreSvg;
