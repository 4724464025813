import PropTypes from 'prop-types';

const FilingUpdateGearSvg = ({ uniqueKey, width, height, palette = [] }) => {
  return (
    <svg
      width={`${width || 50}`}
      height={`${height || 50}`}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath={`url(#clip0_83_10748${width + height}${uniqueKey})`}>
        <path
          d="M11.3872 3.35891C10.8151 2.78674 9.88801 2.78674 9.31584 3.35891L6.38616 6.2886C5.81399 6.86077 5.81399 7.78782 6.38616 8.35999C6.95833 8.93215 7.88538 8.93215 8.45754 8.35999L8.88665 7.93079V24.7068C8.88665 25.5164 9.54182 26.1716 10.3515 26.1716C11.1612 26.1716 11.8163 25.5164 11.8163 24.7068V7.93079L12.2455 8.35999C12.8177 8.93215 13.7447 8.93215 14.3169 8.35999C14.8891 7.78782 14.8891 6.86077 14.3169 6.2886L11.3872 3.35891ZM43.6138 6.2886L40.6841 3.35891C40.1119 2.78674 39.1849 2.78674 38.6127 3.35891L35.683 6.2886C35.1109 6.86077 35.1109 7.78782 35.683 8.35999C36.2552 8.93215 37.1822 8.93215 37.7544 8.35999L38.1835 7.93079V24.7068C38.1835 25.5164 38.8387 26.1716 39.6484 26.1716C40.458 26.1716 41.1132 25.5164 41.1132 24.7068V7.93079L41.5424 8.35999C42.1146 8.93215 43.0415 8.93215 43.6138 8.35999C44.186 7.78772 44.186 6.86077 43.6138 6.2886ZM27.9296 24.036C27.9296 24.0355 27.9297 24.035 27.9297 24.0344V7.32424H29.5204C30.1126 7.32424 30.6477 6.96809 30.8737 6.42014C31.0996 5.87219 30.9738 5.2428 30.5561 4.82366L26.0357 0.429126C25.4635 -0.143042 24.5364 -0.143042 23.9643 0.429126L19.4439 4.82366C19.0262 5.2428 18.9003 5.87219 19.1263 6.42014C19.3523 6.96809 19.8873 7.32424 20.4795 7.32424H22.0702V24.0344C22.0702 24.0349 22.0703 24.0354 22.0703 24.036C18.6568 25.2426 16.2109 28.4975 16.2109 32.3242C16.2109 37.1783 20.1458 41.1133 24.9999 41.1133C29.854 41.1133 33.789 37.1783 33.789 32.3242C33.789 28.4975 31.3431 25.2426 27.9296 24.036Z"
          fill={`url(#paint0_linear_83_10748${width + height}${uniqueKey})`}
        />
        <path
          d="M41.8883 36.4184L39.3919 34.8602C39.5374 34.0329 39.6483 33.1926 39.6483 32.3243C39.6483 31.503 39.5364 30.7107 39.4058 29.9258L41.8882 28.3766C42.4948 27.9975 42.7408 27.2351 42.4662 26.5727L41.173 23.4513C40.8983 22.7904 40.1774 22.4185 39.4907 22.5844L36.4414 23.2902C35.7358 22.3986 34.9361 21.5986 34.0453 20.8919L34.7381 17.8961C34.8984 17.1981 34.5322 16.4857 33.8712 16.2124L30.7527 14.9206C30.0889 14.6446 29.3307 14.8877 28.9502 15.4971L27.4348 17.922C26.6387 17.7876 25.8336 17.6758 24.9998 17.6758C24.1652 17.6758 23.3592 17.7874 22.5623 17.9222L21.0493 15.4986C20.6688 14.8892 19.9078 14.6474 19.2468 14.9206L16.1283 16.2124C15.4673 16.4857 15.1011 17.1981 15.2614 17.8961L15.9539 20.8923C15.0632 21.5988 14.2636 22.3989 13.5581 23.2902L10.5089 22.5844C9.81938 22.4185 9.10131 22.7904 8.82661 23.4513L7.53344 26.5727C7.25874 27.235 7.50483 27.9975 8.11137 28.3766L10.5939 29.9253C10.4632 30.7103 10.3514 31.5028 10.3514 32.3243C10.3514 33.1928 10.4621 34.033 10.6079 34.8604L8.11147 36.4184C7.50493 36.7975 7.25893 37.5599 7.53354 38.2223L8.8267 41.3437C9.11049 42.0251 9.84165 42.3687 10.509 42.2106L13.6599 41.4814C14.3448 42.3276 15.1124 43.1882 15.9663 43.8639L15.2616 46.9145C15.1013 47.6112 15.4675 48.3236 16.1285 48.5968L19.247 49.8886C19.9072 50.1619 20.6695 49.9198 21.0495 49.3121L22.6018 46.8281C23.3865 46.9584 24.1786 47.0704 24.9998 47.0704C25.8218 47.0704 26.6147 46.9584 27.4002 46.8277L28.9504 49.3105C29.3308 49.9208 30.0938 50.1613 30.7529 49.8884L33.8714 48.5966C34.5324 48.3234 34.8986 47.611 34.7383 46.9129L34.0334 43.864C34.8874 43.1882 35.6551 42.3276 36.3402 41.4813L39.4909 42.2105C40.1581 42.3686 40.8894 42.025 41.1732 41.3436L42.4664 38.2222C42.7409 37.5614 42.4949 36.7975 41.8883 36.4184ZM24.9998 38.1836C21.7638 38.1836 19.1405 35.5603 19.1405 32.3243C19.1405 29.0881 21.7638 26.4649 24.9998 26.4649C28.2359 26.4649 30.8592 29.0881 30.8592 32.3243C30.8592 35.5603 28.2359 38.1836 24.9998 38.1836Z"
          fill={`url(#paint1_linear_83_10748${width + height}${uniqueKey})`}
        />
      </g>
      <defs>
        <linearGradient
          id={`paint0_linear_83_10748${width + height}${uniqueKey}`}
          x1="25"
          y1="41.1133"
          x2="25"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[2]} />
          <stop offset="1" stopColor={palette[3]} />
        </linearGradient>
        <linearGradient
          id={`paint1_linear_83_10748${width + height}${uniqueKey}`}
          x1="24.9998"
          y1="50.0002"
          x2="24.9998"
          y2="14.8075"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[0]} />
          <stop offset="1" stopColor={palette[1]} />
        </linearGradient>
        <clipPath id={`clip0_83_10748${width + height}${uniqueKey}`}>
          <rect width="50" height="50" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

FilingUpdateGearSvg.propTypes = {
  uniqueKey: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  palette: PropTypes.arrayOf(PropTypes.string),
};

export default FilingUpdateGearSvg;
