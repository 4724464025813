import PropTypes from 'prop-types';

const ShieldWithBandSvg = ({ color, width, height }) => {
  return (
    <svg
      width={`${width || 50}`}
      height={`${height || 50}`}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M42.5202 14.5V20.4167L15.416 40.2917L9.93685 36.1875C8.58268 35.1667 7.47852 32.9792 7.47852 31.2917V14.5C7.47852 12.1667 9.27018 9.58333 11.4577 8.77083L22.8535 4.5C24.041 4.0625 25.9577 4.0625 27.1452 4.5L38.541 8.77083C40.7285 9.58333 42.5202 12.1667 42.5202 14.5Z"
        fill={color}
      />
      <path
        d="M42.5215 23.2705V31.2913C42.5215 32.9788 41.4173 35.1663 40.0632 36.1872L28.6673 44.708C27.6673 45.458 26.334 45.833 25.0007 45.833C23.6673 45.833 22.334 45.458 21.334 44.708L17.334 41.7288L42.5215 23.2705Z"
        fill={color}
      />
    </svg>
  );
};

ShieldWithBandSvg.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default ShieldWithBandSvg;
