import { createSelector } from '@reduxjs/toolkit';

export const selectFormValues = (state) => state.order.form;
export const selectPreOrderFlag = (state) => state.order.preOrderFlag;
export const selectData = (state) => state.order.data;
export const selectError = (state) => state.order.error;
export const selectLoading = (state) => state.order.loading;
export const selectRestored = (state) => state.order.restored;

export const selectActiveProcessingCode = (state) =>
  state.order.form.processingOption;

export const selectOrganizedState = (state) => state.order.form.organizedState;

export const selectProductCodesFromPackage = (state) =>
  state.order.package.products;

export const selectBankingBundleProductCodesFromPackage = (state) =>
  state.order.package.bankingBundle;

export const selectLinkedProductCodesFromPackage = (state) =>
  state.order.package.linkedProducts;

export const selectAllProductCodesFromPackage = createSelector(
  [selectProductCodesFromPackage, selectLinkedProductCodesFromPackage],
  (productCodesFromPackage, linkedProductCodesFromPackage) => [
    ...productCodesFromPackage,
    ...linkedProductCodesFromPackage,
  ]
);

export const selectProductCodes = createSelector([selectData], (data) =>
  data?.products?.map(({ code }) => code)
);

export const selectDiscountCouponType = createSelector(
  [selectData],
  (data) => data?.discountCouponType
);

export const selectDeclined = createSelector(
  [selectData],
  (data) => data?.declined
);

export const selectFileData = (state) => state.order.file.data;
export const selectFileLoading = (state) => state.order.file.loading;
export const selectFileError = (state) => state.order.file.error;
