import {
  PRODUCT_CODES,
  RECOMMENDED_PRODUCT_CODES_ARRAY,
  TAX_PRODUCT_CODES_ARRAY,
} from '_constants/products';

export const getForbiddenStatesKeys = (productCode) => {
  const MAP = {
    [PRODUCT_CODES.incSalesTaxPermit]: ['AK', 'DE', 'MT', 'NH', 'OR', 'NY'],
    [PRODUCT_CODES.incSCorp]: ['LA', 'NV', 'NH', 'TN', 'WA', 'WY'],
    [PRODUCT_CODES.incCertifiedCopy]: ['AS', 'DC', 'GU', 'MP', 'PR', 'VI'],
    [PRODUCT_CODES.incBOI]: [],
    [PRODUCT_CODES.incMonthlyBookkeeping]: [],
  };

  return MAP[productCode] || [];
};

export const getUpsellSteps = ({ allowedUpsellProductCodes = [] }) => {
  const steps = [{ title: 'Business Information' }];

  if (
    allowedUpsellProductCodes.some((code) =>
      RECOMMENDED_PRODUCT_CODES_ARRAY.includes(code)
    )
  ) {
    steps.push({ title: 'Recommended Items' });
  }

  if (
    allowedUpsellProductCodes.some((code) =>
      TAX_PRODUCT_CODES_ARRAY.includes(code)
    )
  ) {
    steps.push({ title: 'Tax Items' });
  }

  steps.push({ title: 'Membership Login' });

  return steps;
};
