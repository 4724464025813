import PropTypes from 'prop-types';

const VerifiedSvg = ({ width, height, palette = [] }) => {
  return (
    <svg
      width={`${width || 70}`}
      height={`${height || 70}`}
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M55.5078 6.15234H14.4922C12.1931 6.15234 10.3906 7.95484 10.3906 10.2539V33.8249C10.3906 48.3325 26.2201 58.6165 33.0013 62.3496C33.5821 62.6801 34.2911 62.8623 35 62.8623C35.7089 62.8623 36.4179 62.6801 36.9746 62.3637C43.7799 58.6165 59.6094 48.3327 59.6094 33.8249V10.2539C59.6094 7.95484 57.8069 6.15234 55.5078 6.15234Z"
        fill="url(#paint0_linear_624_12002)"
      />
      <path
        d="M63.7109 4.10156C62.5624 4.10156 61.6602 3.19908 61.6602 2.05078C61.6602 0.902207 60.7579 0 59.6094 0H10.3906C9.24205 0 8.33984 0.902207 8.33984 2.05078C8.33984 3.19908 7.43764 4.10156 6.28906 4.10156C5.14049 4.10156 4.23828 5.00377 4.23828 6.15234V33.8242C4.23828 56.7518 32.9492 69.3026 34.1797 69.8358C34.4668 69.959 34.7129 70 35 70C35.2871 70 35.5332 69.959 35.8203 69.8358C37.0508 69.3026 65.7617 56.7518 65.7617 33.8242V6.15234C65.7617 5.00377 64.8595 4.10156 63.7109 4.10156ZM57.5586 33.8242C57.5586 47.2363 42.4648 56.9978 35.9844 60.5664C35.6973 60.7303 35.3283 60.8125 35 60.8125C34.6717 60.8125 34.3029 60.7303 34.0156 60.5664C27.5352 56.9978 12.4414 47.2363 12.4414 33.8242V10.2539C12.4414 9.10533 13.3436 8.20312 14.4922 8.20312H55.5078C56.6564 8.20312 57.5586 9.10533 57.5586 10.2539V33.8242ZM36.4499 36.5866C36.0493 36.9872 35.5246 37.1875 35 37.1875C34.4754 37.1875 33.9505 36.9872 33.5501 36.5866L29.4485 32.4851C28.6475 31.684 28.6475 30.3862 29.4485 29.5851C30.2496 28.7841 31.5474 28.7841 32.3485 29.5851L35 32.2368L45.6889 21.5477C43.0574 18.586 39.2638 16.6797 35 16.6797C27.0852 16.6797 20.6445 23.1204 20.6445 31.0352C20.6445 38.9499 27.0852 45.3906 35 45.3906C42.9148 45.3906 49.3555 38.9499 49.3555 31.0352C49.3555 28.8877 48.8482 26.8685 47.9994 25.0373L36.4499 36.5866Z"
        fill="url(#paint1_linear_624_12002)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_624_12002"
          x1="35"
          y1="62.8624"
          x2="35"
          y2="6.15234"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[2]} />
          <stop offset="1" stopColor={palette[3]} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_624_12002"
          x1="35"
          y1="70"
          x2="35"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[0]} />
          <stop offset="1" stopColor={palette[1]} />
        </linearGradient>
      </defs>
    </svg>
  );
};

VerifiedSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  palette: PropTypes.arrayOf(PropTypes.string),
};

export default VerifiedSvg;
