export function getFileExtension(fileName, includeDot = false) {
  if (!fileName) {
    return '';
  }

  const regexp = /(?:\.([^.]+))?$/;
  const extension = regexp.exec(fileName)[0];

  if (extension && !includeDot) {
    return extension.slice(1);
  }

  return extension;
}

export function getFileExtensionsString(arr) {
  if (Array.isArray(arr) && !arr.includes('*')) {
    return arr.map((ext) => '.' + ext).join(', ');
  }

  return '';
}

export function checkIfFileAllowed(ext, allowedExt) {
  if (
    !Array.isArray(allowedExt) ||
    allowedExt === undefined ||
    allowedExt.includes('*') ||
    allowedExt.length === 0
  ) {
    return true;
  }

  return allowedExt.includes(ext);
}

export function sanitizeFileName(fileName) {
  const extension = getFileExtension(fileName, true);
  const name = fileName.slice(0, fileName.length - extension.length);
  const result = name.replace(/[^a-zA-Z0-9._-]/g, '') + extension;

  return result;
}
