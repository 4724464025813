import PropTypes from 'prop-types';

const DiamondMedalSvg = ({ width, height, palette = [] }) => {
  return (
    <svg
      width={`${width || 70}`}
      height={`${height || 70}`}
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath={`url(#clip0_125_10690${width + height})`}>
        <path
          d="M50.171 45.2539H19.8288C19.0848 44.4053 18.4856 43.4313 18.062 42.366C13.7889 40.6602 11.2047 36.189 11.8592 31.6351C10.4644 29.8736 9.70708 27.7081 9.70708 25.4297C9.70708 23.1513 10.4644 20.9855 11.8592 19.2239C11.2044 14.6695 13.7898 10.1977 18.0635 8.49229C19.7689 4.21832 24.2406 1.6327 28.7956 2.28881C30.5606 0.892363 32.7241 0.136719 35 0.136719C37.2785 0.136719 39.4442 0.894004 41.2058 2.28881C45.7604 1.63461 50.232 4.21941 51.9374 8.4931C56.2114 10.1987 58.7961 14.6699 58.1411 19.2244C59.5361 20.987 60.293 23.1521 60.293 25.4297C60.293 27.7081 59.5357 29.8739 58.1409 31.6355C58.7957 36.1897 56.2106 40.661 51.9373 42.3667C51.5138 43.4319 50.9148 44.4056 50.171 45.2539ZM11.0743 58.2422V54.8242H2.05397C0.459965 54.8242 -0.524683 56.5631 0.295493 57.9301L2.9436 62.3438L0.295356 66.7573C-0.524683 68.1242 0.459965 69.8633 2.05397 69.8633H18.0501C19.0276 69.8633 19.8692 69.1733 20.0611 68.2147L21.6454 60.293H13.125C11.9925 60.293 11.0743 59.3748 11.0743 58.2422ZM67.0565 62.3438L69.7047 57.9301C70.5249 56.5631 69.5403 54.8242 67.9463 54.8242H58.9258V58.2422C58.9258 59.3748 58.0076 60.293 56.875 60.293H48.3547L49.939 68.2147C50.1307 69.1733 50.9723 69.8633 51.95 69.8633H67.9461C69.5401 69.8633 70.5248 68.1242 69.7046 66.7573L67.0565 62.3438Z"
          fill={`url(#paint0_linear_125_10690${width + height})`}
        />
        <path
          d="M48.7361 22.7686L43.3558 28.013L44.626 35.4182C44.9133 37.0936 43.1549 38.3711 41.6504 37.5801L35 34.084L28.3496 37.5803C26.8451 38.3712 25.0867 37.0937 25.374 35.4183L26.6442 28.0131L21.2639 22.7687C20.0467 21.5823 20.7184 19.5151 22.4004 19.2708L29.8357 18.1903L33.1609 11.4528C33.9131 9.9285 36.0866 9.9285 36.8389 11.4528L40.164 18.1903L47.5993 19.2708C49.2816 19.5151 49.9533 21.5821 48.7361 22.7686ZM52.7734 45.254H17.2266C13.8342 45.254 11.0742 48.014 11.0742 51.4064V58.2423C11.0742 59.3749 11.9924 60.2931 13.125 60.2931H56.875C58.0076 60.2931 58.9258 59.3749 58.9258 58.2423V51.4064C58.9258 48.014 56.1658 45.254 52.7734 45.254Z"
          fill={`url(#paint1_linear_125_10690${width + height})`}
        />
      </g>
      <defs>
        <linearGradient
          id={`paint0_linear_125_10690${width + height}`}
          x1="35.0001"
          y1="69.8633"
          x2="35.0001"
          y2="0.136719"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[2]} />
          <stop offset="1" stopColor={palette[3]} />
        </linearGradient>
        <linearGradient
          id={`paint1_linear_125_10690${width + height}`}
          x1="35"
          y1="60.2931"
          x2="35"
          y2="10.3096"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[0]} />
          <stop offset="1" stopColor={palette[1]} />
        </linearGradient>
        <clipPath id={`clip0_125_10690${width + height}`}>
          <rect width="70" height="70" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

DiamondMedalSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  palette: PropTypes.arrayOf(PropTypes.string),
};

export default DiamondMedalSvg;
