import PropTypes from 'prop-types';

const SaveEnergySvg = ({ width, height, palette = [] }) => {
  return (
    <svg
      width={`${width || 90}`}
      height={`${height || 90}`}
      viewBox="0 0 90 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath={`url(#clip0_488_11112${width + height})`}>
        <path
          d="M88.5452 16.0988C87.6544 15.6584 86.5858 15.7486 85.7849 16.3459L81.974 19.1938C74.7564 8.82196 63.6355 2.02169 51.0518 0.391666C49.7078 0.213951 48.4847 1.06122 48.1576 2.35374L45.5208 12.692C45.1199 14.2689 46.2224 15.8237 47.8408 15.9698C56.593 16.7474 64.4644 21.4543 69.3079 28.7028L64.6886 32.1687C63.8749 32.779 63.4836 33.8012 63.6843 34.8003C63.8852 35.7968 64.6421 36.5899 65.6309 36.8345L86.7247 42.1079C87.473 42.2995 88.3192 42.1489 88.9854 41.6289C89.6266 41.1295 90 40.3622 90 39.5512V18.4574C90 17.4583 89.4361 16.5468 88.5452 16.0988V16.0988ZM42.1592 74.0307C33.4071 73.253 25.5356 68.5461 20.6921 61.2976L25.3114 57.8317C26.1251 57.2214 26.5164 56.1992 26.3157 55.2001C26.1148 54.2036 25.3579 53.4105 24.3691 53.1659L3.27533 47.8925C2.49521 47.6993 1.65832 47.8744 1.01461 48.3715C0.373359 48.8713 0 49.6385 0 50.4496V71.5433C0 72.5425 0.563906 73.4539 1.45477 73.902C2.35336 74.3449 3.41684 74.2572 4.21506 73.6548L8.026 70.807C15.2436 81.1788 26.3646 87.9791 38.9482 89.6091C40.2652 89.7847 41.5118 88.9411 41.8424 87.647L44.4792 77.3088C44.8801 75.7317 43.7776 74.1769 42.1592 74.0307V74.0307Z"
          fill={`url(#paint0_linear_488_11112${width + height})`}
        />
        <path
          d="M87.6465 48.1576L77.3083 45.5208C75.7927 45.1143 74.1801 46.1835 74.0305 47.8408C73.2528 56.593 68.5459 64.4644 61.2974 69.3079L57.8315 64.6886C57.2212 63.8749 56.2092 63.4887 55.1999 63.6843C54.2034 63.8852 53.4103 64.6421 53.1658 65.6309L47.8923 86.7247C47.694 87.5125 47.8718 88.347 48.3713 88.9854C48.8709 89.6266 49.6382 90 50.4492 90H71.543C72.5421 90 73.4536 89.4361 73.9016 88.5452C74.3471 87.6517 74.2518 86.5832 73.6545 85.7849L70.8066 81.974C81.1784 74.7564 87.9787 63.6355 89.6087 51.0518C89.7812 49.7282 88.9392 48.4872 87.6465 48.1576V48.1576ZM34.8001 26.3157C35.7966 26.1148 36.5898 25.3579 36.8343 24.3691L42.1077 3.27533C42.306 2.48748 42.1283 1.65305 41.6287 1.01461C41.1291 0.373359 40.3619 0 39.5508 0H18.4571C17.4579 0 16.5465 0.563906 16.0984 1.45477C15.653 2.34826 15.7483 3.41684 16.3456 4.21506L19.1934 8.026C8.82178 15.2436 2.02133 26.3646 0.391484 38.9482C0.219043 40.2717 1.06104 41.5129 2.35355 41.8424L12.6918 44.4792C14.2213 44.8845 15.8209 43.8071 15.9696 42.1592C16.7472 33.4071 21.4541 25.5356 28.7026 20.6921L32.1685 25.3114C32.7762 26.1251 33.7959 26.5011 34.8001 26.3157V26.3157ZM57.7897 38.3412C57.3082 37.5636 56.461 37.0898 55.5469 37.0898H49.6967L47.5569 28.5411C47.2892 27.4699 46.3854 26.6794 45.2885 26.5584C44.1735 26.4503 43.1359 27.0142 42.6414 28.0004L32.0945 49.0941C31.6851 49.9104 31.7289 50.8811 32.2104 51.6588C32.6918 52.4364 33.5391 52.9102 34.4531 52.9102H40.3033L42.4431 61.4589C42.7108 62.5301 43.6147 63.3206 44.7116 63.4416C45.8399 63.5604 46.8749 62.9643 47.3587 61.9996L57.9055 40.9059C58.3149 40.0896 58.2712 39.1189 57.7897 38.3412V38.3412Z"
          fill={`url(#paint1_linear_488_11112${width + height})`}
        />
      </g>
      <defs>
        <linearGradient
          id={`paint0_linear_488_11112${width + height}`}
          x1="45"
          y1="89.6321"
          x2="45"
          y2="0.368292"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[2]} />
          <stop offset="1" stopColor={palette[3]} />
        </linearGradient>
        <linearGradient
          id={`paint1_linear_488_11112${width + height}`}
          x1="1.16506"
          y1="89.1879"
          x2="78.4955"
          y2="12.4915"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[0]} />
          <stop offset="1" stopColor={palette[1]} />
        </linearGradient>
        <clipPath id={`clip0_488_11112${width + height}`}>
          <rect width="90" height="90" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

SaveEnergySvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  palette: PropTypes.arrayOf(PropTypes.string),
};

export default SaveEnergySvg;
