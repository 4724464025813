import PropTypes from 'prop-types';

const CopyrightSvg = ({ width, height, palette = [] }) => {
  return (
    <svg
      width={`${width || 70}`}
      height={`${height || 70}`}
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_504_12920)">
        <path
          d="M35 0C20.2996 0 8.33984 11.9597 8.33984 26.6602C8.33984 36.9426 14.1985 46.2179 23.3789 50.6606H46.6211C55.8015 46.2179 61.6602 36.9426 61.6602 26.6602C61.6602 11.9597 49.7004 0 35 0ZM26.7969 67.9492C26.7969 69.0818 27.7151 70 28.8477 70H41.1523C42.2849 70 43.2031 69.0818 43.2031 67.9492V61.7969H26.7969V67.9492Z"
          fill="url(#paint0_linear_504_12920)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M46.6211 50.6608V59.7463C46.6211 60.8789 45.7029 61.7971 44.5703 61.7971H25.4297C24.2971 61.7971 23.3789 60.8789 23.3789 59.7463V50.6608H46.6211ZM62.1411 11.3918C62.7693 12.3342 64.0426 12.5888 64.985 11.9605L69.0866 9.22617C70.029 8.59794 70.2835 7.32468 69.6553 6.38228C69.0271 5.43988 67.7538 5.18531 66.8114 5.81353L62.7099 8.5479C61.7675 9.17613 61.5128 10.4494 62.1411 11.3918ZM69.0866 41.3603L64.985 38.6259C64.0426 37.9977 62.7693 38.2522 62.1411 39.1946C61.5129 40.137 61.7675 41.4103 62.7099 42.0385L66.8114 44.7729C67.7538 45.4011 69.0271 45.1466 69.6553 44.2042C70.2835 43.2619 70.029 41.9886 69.0866 41.3603ZM7.29009 8.54777L3.18853 5.81339C2.24613 5.18517 0.972865 5.43974 0.344642 6.38214C-0.283581 7.32454 -0.0290104 8.59781 0.913392 9.22603L5.01496 11.9604C5.95736 12.5886 7.23062 12.3341 7.85884 11.3917C8.4872 10.4494 8.23249 9.17613 7.29009 8.54777ZM7.85884 39.1946C7.23062 38.2522 5.95736 37.9977 5.01496 38.6259L0.913392 41.3603C-0.0290104 41.9885 -0.283581 43.2618 0.344642 44.2042C0.972865 45.1466 2.24613 45.4011 3.18853 44.7729L7.29009 42.0385C8.23249 41.4103 8.4872 40.137 7.85884 39.1946ZM35 9.57056C25.5766 9.57056 17.9101 17.2371 17.9101 26.6604C17.9101 36.0837 25.5766 43.7502 35 43.7502C44.4233 43.7502 52.0898 36.0837 52.0898 26.6604C52.0898 17.2371 44.4233 9.57056 35 9.57056ZM23 24.0271V22H32.0519V24.0271H28.7514V33H26.3005V24.0271H23ZM33.3861 33V22H36.6049L39.7748 30.0771L42.8465 22H46V33H43.6635V25.63L40.6898 33H38.6964L35.7226 25.63V33H33.3861Z"
          fill="url(#paint1_linear_504_12920)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_504_12920"
          x1="35"
          y1="70.0001"
          x2="35"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[2]} />
          <stop offset="1" stopColor={palette[3]} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_504_12920"
          x1="35"
          y1="61.7971"
          x2="35"
          y2="5.46875"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[0]} />
          <stop offset="1" stopColor={palette[1]} />
        </linearGradient>
        <clipPath id="clip0_504_12920">
          <rect width="70" height="70" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

CopyrightSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  palette: PropTypes.arrayOf(PropTypes.string),
};

export default CopyrightSvg;
