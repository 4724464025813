import PropTypes from 'prop-types';

const DevicesSvg = ({ color, width, height }) => {
  return (
    <svg
      width={`${width || 50}`}
      height={`${height || 50}`}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M33.3327 4.16699H12.4993C5.83268 4.16699 4.16602 5.83366 4.16602 12.5003V24.792C4.22852 31.1045 5.97852 32.7087 12.4993 32.7087H17.1868V40.1045H10.3119C9.45771 40.1045 8.74937 40.8128 8.74937 41.667C8.74937 42.5212 9.45771 43.2295 10.3119 43.2295H25.5202C25.2077 42.417 25.0618 41.3962 25.041 40.1045H20.3118V32.7087H25.041V24.792V22.0629C25.041 17.6045 26.5202 16.1253 30.9785 16.1253H39.8952C40.541 16.1253 41.1452 16.1669 41.666 16.2294V12.5003C41.666 5.83366 39.9993 4.16699 33.3327 4.16699Z"
        fill={color}
      />
      <path
        d="M41.666 16.2291C41.1452 16.1666 40.541 16.125 39.8952 16.125H30.9785C26.5202 16.125 25.041 17.6042 25.041 22.0626V40.1042C25.0618 41.3959 25.2077 42.4167 25.5202 43.2292C26.2494 45.125 27.8952 45.8334 30.9785 45.8334H39.8952C44.3535 45.8334 45.8327 44.3542 45.8327 39.8959V22.0626C45.8327 18.2501 44.7494 16.625 41.666 16.2291ZM35.4369 21.0209C37.2494 21.0209 38.7077 22.4791 38.7077 24.2916C38.7077 26.1041 37.2494 27.5625 35.4369 27.5625C33.6244 27.5625 32.166 26.1041 32.166 24.2916C32.166 22.4791 33.6244 21.0209 35.4369 21.0209ZM35.4369 39.8959C32.9786 39.8959 30.9785 37.8959 30.9785 35.4375C30.9785 34.4167 31.3327 33.4584 31.9161 32.7084C32.7286 31.6667 33.9994 30.9792 35.4369 30.9792C36.5619 30.9792 37.5827 31.3959 38.3535 32.0626C39.291 32.8959 39.8952 34.1042 39.8952 35.4375C39.8952 37.8959 37.8952 39.8959 35.4369 39.8959Z"
        fill={color}
      />
      <path
        opacity="0.4"
        d="M39.8952 35.4379C39.8952 37.8962 37.8952 39.8962 35.4369 39.8962C32.9785 39.8962 30.9785 37.8962 30.9785 35.4379C30.9785 34.417 31.3327 33.4587 31.916 32.7087C32.7285 31.667 33.9994 30.9795 35.4369 30.9795C36.5619 30.9795 37.5827 31.3962 38.3535 32.0629C39.291 32.8962 39.8952 34.1045 39.8952 35.4379Z"
        fill={color}
      />
      <path
        opacity="0.4"
        d="M35.4369 27.5621C37.2433 27.5621 38.7076 26.0977 38.7076 24.2912C38.7076 22.4848 37.2433 21.0205 35.4369 21.0205C33.6304 21.0205 32.166 22.4848 32.166 24.2912C32.166 26.0977 33.6304 27.5621 35.4369 27.5621Z"
        fill={color}
      />
    </svg>
  );
};

DevicesSvg.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default DevicesSvg;
