import PropTypes from 'prop-types';

const CloseSvg = ({ color, width, height }) => {
  return (
    <svg
      width={`${width || 18}`}
      height={`${height || 19}`}
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M15.9925 6.35613V6.3575V12.6425C15.9925 13.9081 15.6172 14.8582 14.9839 15.4914C14.3507 16.1247 13.4006 16.5 12.135 16.5H5.8575C4.59195 16.5 3.64196 16.1247 3.00877 15.4907C2.37552 14.8567 2 13.9047 2 12.635V6.3575C2 5.09194 2.3753 4.1418 3.00855 3.50855C3.6418 2.8753 4.59194 2.5 5.8575 2.5H12.1425C13.4082 2.5 14.3581 2.87535 14.9903 3.50833C15.6224 4.14121 15.996 5.09088 15.9925 6.35613Z"
        fill={color}
        stroke={color}
      />
      <path
        d="M9.79502 9.5L11.52 7.775C11.7375 7.5575 11.7375 7.1975 11.52 6.98C11.3025 6.7625 10.9425 6.7625 10.725 6.98L9.00002 8.705L7.27502 6.98C7.05752 6.7625 6.69752 6.7625 6.48002 6.98C6.26252 7.1975 6.26252 7.5575 6.48002 7.775L8.20502 9.5L6.48002 11.225C6.26252 11.4425 6.26252 11.8025 6.48002 12.02C6.59252 12.1325 6.73502 12.185 6.87752 12.185C7.02002 12.185 7.16252 12.1325 7.27502 12.02L9.00002 10.295L10.725 12.02C10.8375 12.1325 10.98 12.185 11.1225 12.185C11.265 12.185 11.4075 12.1325 11.52 12.02C11.7375 11.8025 11.7375 11.4425 11.52 11.225L9.79502 9.5Z"
        fill={color}
      />
    </svg>
  );
};

CloseSvg.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default CloseSvg;
