export const MODALS_KEYS = {
  paymentFailed: 'paymentFailed',
  packageEdit: 'packageEdit',
  bundleEdit: 'bundleEdit',
  packageUpgrade: 'packageUpgrade',
  productRemovalConfirm: 'productRemovalConfirm',
  registeredAgentRemovalConfirmPrimary: 'registeredAgentRemovalConfirmPrimary',
  registeredAgentRemovalConfirmSecondary:
    'registeredAgentRemovalConfirmSecondary',
};
