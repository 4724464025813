import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Router from 'next/router';
import GTM from 'react-gtm-module';
import { hotjar } from 'react-hotjar';

import { MainApiService } from '_services';
import { PAYMENT_STATUS } from '_constants/payment';
import { STORAGE_KEYS } from '_constants/storage';
import { FIELDS_KEYS } from '_constants/forms';
import { MODALS_KEYS } from '_constants/modals';
import { GA_EVENTS, HOTJAR_EVENTS } from '_constants/analytics';
import { processResponse, processError } from '_helpers/api';
import { createPriceMapByProcessingCode } from '_helpers/variants';

import {
  createSaleEventBody,
  createEcommerceEventProps,
} from '_helpers/analytics';

import {
  createProductNameMap,
  createProductCategoryMap,
} from '_helpers/products';

import {
  createPaymentBody,
  fillPaymentBodyWithSourceData,
  checkIfNumberOfPaymentAttemptsExceeded,
  checkIfCardDataNotChanged,
  increaseBillingCounter,
  createBillingHash,
} from '_helpers/payment';

import {
  storeSessionValue,
  restoreSessionValue,
  restoreLocalValue,
  clearSessionValue,
} from '_helpers/storage';

import {
  resetResult as resetOrderBeforePayment,
  resetForm as resetBusinessInfoForm,
  resetPackage,
  refreshPreOrderFlag,
  refreshOrderBeforePayment,
} from '_redux/order/slice';

import { showModal } from '_redux/modals/slice';

import { refreshForm as refreshUpsellInfo } from '_redux/upsell/slice';

const initForm = {
  firstName: '',
  lastName: '',
  cardNumber: null,
  cardMonth: '',
  cardYear: '',
  cvc: '',
  billingAddressCountry: 'US',
  billingAddressState: '',
  billingAddressAddress1: null,
  billingAddressCity: null,
  billingAddressZip: null,
  agreeWithTerms: false,
  campaignId: null,
  restored: false,
};

export const createPayment = createAsyncThunk(
  'payment/createPayment',
  async function (params, { dispatch, getState, rejectWithValue }) {
    const { redirectUrl } = params || {};
    const crmSessionId = restoreLocalValue(STORAGE_KEYS.crmSessionId);
    const { order, payment, products, variants } = getState();

    const {
      data: {
        id: orderId,
        token: { token },
        discountCouponType,
        tokenWithDiscount,
        products: productsFromOrder,
      },
      form: { processingOption },
    } = order;

    const { activeProductCode: primaryProductCode, items: productItems } =
      products;

    const { data: priceItems } = variants.prices;
    const { data: feeItems } = variants.fees;
    const { form: billingInfo } = payment;

    const productNameMap = createProductNameMap(productItems);
    const productCategoryMap = createProductCategoryMap(productItems);

    const priceMapByProcessingCode = createPriceMapByProcessingCode(
      processingOption,
      priceItems
    );

    const feeMapByProcessingCode = createPriceMapByProcessingCode(
      processingOption,
      feeItems
    );

    const productCodesFromOrder = productsFromOrder.map(({ code }) => code);

    GTM.dataLayer({
      dataLayer: {
        event: GA_EVENTS.addPaymentInfo,
        eventProps: createEcommerceEventProps(
          productCodesFromOrder,
          productNameMap,
          productCategoryMap,
          priceMapByProcessingCode,
          feeMapByProcessingCode,
          discountCouponType
        ),
      },
    });

    if (hotjar.initialized()) {
      hotjar.event(HOTJAR_EVENTS.paymentFormSubmitted);
    }

    const cardData = {
      cardNumber: billingInfo.cardData,
      cardMonth: billingInfo.cardMonth,
      cardYear: billingInfo.cardYear,
      cvc: billingInfo.cvc,
    };

    const paymentBody = createPaymentBody(billingInfo, processingOption);

    fillPaymentBodyWithSourceData(paymentBody);

    try {
      const isNumberOfPaymentAttemptsExceeded =
        checkIfNumberOfPaymentAttemptsExceeded();
      const isCardDataNotChanged = await checkIfCardDataNotChanged(cardData);

      if (isCardDataNotChanged) {
        increaseBillingCounter();
      }

      if (isNumberOfPaymentAttemptsExceeded && isCardDataNotChanged) {
        dispatch(showModal(MODALS_KEYS.paymentFailed));
        throw new Error('Transaction Declined');
      }

      if (discountCouponType && tokenWithDiscount) {
        MainApiService.token = tokenWithDiscount;
      }

      const response = await MainApiService.createPayment({
        crmSessionId,
        orderId,
        body: paymentBody,
      });

      MainApiService.token = null;

      const result = processResponse(response);

      const primaryProduct = result?.products?.find(
        ({ code: { code } }) => code === primaryProductCode
      );

      const isDeclined =
        primaryProduct?.paymentStatus === PAYMENT_STATUS.declined;

      if (isDeclined) {
        if (!isCardDataNotChanged) {
          createBillingHash(cardData);
          increaseBillingCounter();
        }

        dispatch(showModal(MODALS_KEYS.paymentFailed));
        dispatch(
          refreshOrderBeforePayment({ ...(order?.data || {}), declined: true })
        );

        if (hotjar.initialized()) {
          hotjar.event(HOTJAR_EVENTS.paymentDeclined);
        }

        throw new Error(
          primaryProduct?.crmErrorMessage || 'Transaction Declined'
        );
      }

      dispatch(
        refreshUpsellInfo({
          [FIELDS_KEYS.organizedState]:
            primaryProduct[FIELDS_KEYS.organizedState],
          [FIELDS_KEYS.processingOption]:
            primaryProduct[FIELDS_KEYS.processingOption],
          token,
        })
      );

      dispatch(resetOrderBeforePayment());
      dispatch(resetBusinessInfoForm());
      dispatch(resetPackage());
      dispatch(resetForm());
      dispatch(refreshPreOrderFlag(false));
      dispatch(storeOrderAfterPayment(result));
      clearSessionValue(STORAGE_KEYS.businessInfo);
      clearSessionValue(STORAGE_KEYS.packageInfo);
      clearSessionValue(STORAGE_KEYS.orderBeforePayment);
      clearSessionValue(STORAGE_KEYS.billingInfo);
      clearSessionValue(STORAGE_KEYS.billingCounter);
      clearSessionValue(STORAGE_KEYS.billingHash);

      GTM.dataLayer(createSaleEventBody(result));

      if (hotjar.initialized()) {
        hotjar.event(HOTJAR_EVENTS.paymentSuccessful);
      }

      if (redirectUrl) {
        Router.push(redirectUrl);
      }

      return result;
    } catch (e) {
      return rejectWithValue(processError(e));
    }
  }
);

const paymentSlice = createSlice({
  name: 'payment',
  initialState: {
    form: {
      ...initForm,
    },
    data: null,
    error: null,
    loading: false,
    restored: false,
  },
  reducers: {
    refreshForm(state, action) {
      const { cardNumber, cardMonth, cardYear, cvc, ...resFields } =
        action.payload;
      const activeProductCode = restoreSessionValue(STORAGE_KEYS.productCode);

      if (activeProductCode) {
        const storedBillingInfo =
          restoreSessionValue(STORAGE_KEYS.billingInfo) || {};

        storeSessionValue(STORAGE_KEYS.billingInfo, {
          ...storedBillingInfo,
          [activeProductCode]: {
            ...(storedBillingInfo[activeProductCode] || {}),
            ...resFields,
          },
        });
      }

      state.form = {
        ...state.form,
        ...action.payload,
        restored: false,
      };
    },
    restoreForm(state) {
      const activeProductCode = restoreSessionValue(STORAGE_KEYS.productCode);

      if (activeProductCode) {
        const storedBillingInfo =
          restoreSessionValue(STORAGE_KEYS.billingInfo) || {};

        state.form = {
          ...state.form,
          ...(storedBillingInfo[activeProductCode] || initForm),
          restored: true,
        };
      }
    },
    resetForm(state) {
      state.form = { ...initForm };
    },
    storeOrderAfterPayment(_, action) {
      const activeProductCode = restoreSessionValue(STORAGE_KEYS.productCode);

      if (activeProductCode) {
        const storedOrderAfterPayment =
          restoreSessionValue(STORAGE_KEYS.orderAfterPayment) || {};

        storeSessionValue(STORAGE_KEYS.orderAfterPayment, {
          ...storedOrderAfterPayment,
          [activeProductCode]: {
            ...(action.payload || {}),
          },
        });
      }
    },
    restoreOrderAfterPayment(state) {
      const activeProductCode = restoreSessionValue(STORAGE_KEYS.productCode);

      if (activeProductCode) {
        const storedOrderAfterPayment =
          restoreSessionValue(STORAGE_KEYS.orderAfterPayment) || {};
        const storedOrderAfterPaymentByActiveCode =
          storedOrderAfterPayment[activeProductCode] || null;
        state.data = storedOrderAfterPaymentByActiveCode;
        state.restored = true;
      }
    },
    resetResult(state) {
      state.data = null;
    },
    resetError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createPayment.pending, (state) => {
        state.error = null;
        state.data = null;
        state.loading = true;
        state.restored = false;
      })
      .addCase(createPayment.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(createPayment.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export const {
  refreshForm,
  restoreForm,
  resetForm,
  storeOrderAfterPayment,
  restoreOrderAfterPayment,
  resetResult,
  resetError,
} = paymentSlice.actions;

export default paymentSlice.reducer;
