import PropTypes from 'prop-types';

const InsuranceSvg = ({ width, height, palette = [] }) => {
  return (
    <svg
      width={`${width || 61}`}
      height={`${height || 60}`}
      viewBox="0 0 61 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath={`url(#clip0_310_17875${width + height})`}>
        <path
          d="M35.8906 13.6934C37.9298 12.9902 39.4062 11.0215 39.4062 8.73645V1.81055C39.4062 0.826289 38.6329 0.0527344 37.6484 0.0527344C36.6639 0.0527344 35.8555 0.896485 35.8906 1.88098C35.9258 2.79504 35.0821 3.46301 34.168 3.46301H16.5547C13.6366 3.46301 11.2812 5.81836 11.2812 8.73645V14.0099L23.5859 21.2755L35.8906 13.6934ZM55.9715 36.4125C54.6002 34.9609 52.287 35.0137 51.0002 36.3764L46.4306 40.9168L45.4246 39.928C44.0491 38.472 41.7255 38.5407 40.4843 39.8627C39.0402 41.2275 39.0673 43.5364 40.4499 44.8426L43.9003 48.2896C44.532 48.9952 45.459 49.4003 46.4374 49.4003C47.4158 49.4003 48.3429 48.9952 48.9093 48.3582L55.9062 41.3596C57.3725 39.9743 57.2922 37.6529 55.9715 36.4125ZM39.4062 43.564V37.0957C39.4062 36.1241 38.62 35.3379 37.6484 35.3379H27.1016C26.13 35.3379 25.3438 36.1241 25.3438 37.0957C25.3438 38.0655 24.5541 38.8535 23.5859 38.8535C22.6177 38.8535 21.8281 38.0655 21.8281 37.0957C21.8281 36.1241 21.0419 35.3379 20.0703 35.3379H9.28906C4.44137 35.3379 0.5 39.2809 0.5 44.127V54.6738C0.5 55.6454 1.28621 56.4316 2.25781 56.4316H42.3931C43.1107 56.4316 43.7595 55.9939 44.0239 55.3279C44.2917 54.6601 44.1269 53.8979 43.6051 53.4018C40.8963 50.8201 39.4062 47.3251 39.4062 43.564Z"
          fill={`url(#paint0_linear_310_17875${width + height})`}
        />
        <path
          d="M58.7422 31.8223C57.7577 31.8223 56.9844 31.0487 56.9844 30.0645C56.9844 29.08 56.2111 28.3066 55.2266 28.3066H41.1641C40.1796 28.3066 39.4062 29.08 39.4062 30.0645C39.4062 31.0487 38.6329 31.8223 37.6484 31.8223C36.6639 31.8223 35.8906 32.5956 35.8906 33.5801V43.5643C35.8906 48.3105 37.754 52.7051 41.1641 55.9395C43.0624 57.7677 45.2421 59.0684 47.6329 59.877C47.8087 59.9121 48.0196 59.9474 48.1953 59.9474C48.371 59.9474 48.582 59.9122 48.7577 59.8418C55.7889 57.4864 60.5 50.9474 60.5 43.5645V33.5801C60.5 32.5957 59.7267 31.8223 58.7422 31.8223ZM54.6991 40.084L47.6679 47.1152C47.3516 47.4669 46.8945 47.6427 46.4375 47.6427C45.9805 47.6427 45.5234 47.4669 45.2071 47.1152L41.6915 43.5996C40.9884 42.9316 40.9884 41.8066 41.6915 41.1387C42.3595 40.4357 43.4843 40.4357 44.1523 41.1387L46.4375 43.3887L52.2384 37.623C52.9064 36.92 54.0311 36.92 54.6992 37.623C55.4023 38.2909 55.4023 39.416 54.6991 40.084ZM35.8906 13.6934C35.3282 13.9043 34.7656 14.0099 34.1328 14.0099H11.2812V23.0333C11.2812 27.8146 14.0585 32.1387 18.3125 34.1426V37.0958C18.3125 40.0137 20.6679 42.3693 23.5859 42.3693C26.504 42.3693 28.8594 40.0137 28.8594 37.0958V34.1426C33.1134 32.1387 35.8906 27.8145 35.8906 23.0333V13.6934Z"
          fill={`url(#paint1_linear_310_17875${width + height})`}
        />
      </g>
      <defs>
        <linearGradient
          id={`paint0_linear_310_17875${width + height}`}
          x1="28.742"
          y1="56.4316"
          x2="28.742"
          y2="0.0527344"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[0]} />
          <stop offset="1" stopColor={palette[1]} />
        </linearGradient>
        <linearGradient
          id={`paint1_linear_310_17875${width + height}`}
          x1="35.8906"
          y1="59.9474"
          x2="35.8906"
          y2="13.6934"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[2]} />
          <stop offset="1" stopColor={palette[3]} />
        </linearGradient>
        <clipPath id={`clip0_310_17875${width + height}`}>
          <rect
            width="60"
            height="60"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

InsuranceSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  palette: PropTypes.arrayOf(PropTypes.string),
};

export default InsuranceSvg;
