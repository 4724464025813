import { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';

import { usePrevious } from '_hooks';
import { ROUTES } from '_constants/routes';
import { getDeviceType } from '_helpers/device';
import { getSalePageURL } from '_helpers/urls';

import {
  refreshDevice,
  refreshSalePageURL,
  getGeolocation,
} from '_redux/general/slice';

import { createClick } from '_redux/location/slice';
import { getProducts } from '_redux/products/slice';

import {
  getStates,
  getProcessingOptions,
  getPrices,
  getUpsellOffers,
} from '_redux/variants/slice';

import {
  refreshLinkedProducts,
  restoreForm as restoreBusinessInfoForm,
  restoreProducts,
  restoreBankingBundle,
  restoreLinkedProducts,
  restorePackageBackup,
} from '_redux/order/slice';

import { restoreForm as restoreBillingInfoForm } from '_redux/payment/slice';

import {
  selectActiveProductCode,
  selectLinkedProductCodesByMainCodes,
} from '_redux/products/selector';

import { selectProductCodesFromPackage } from '_redux/order/selector';
import { selectClickData } from '_redux/location/selector';

import Header from '../Header';
import Footer from '../Footer';
import ZendeskComponent from '../ZendeskComponent';
import { isMobile } from 'react-device-detect';

const StyledPageLayout = styled.div`
  ${({ overflowHidden }) =>
    overflowHidden &&
    css`
      overflow: hidden;
    `}
`;

const PageLayout = ({ children }) => {
  const headerRef = useRef(null);
  const divRef = useRef(null);
  const router = useRouter();
  const dispatch = useDispatch();
  const clickData = useSelector(selectClickData);
  const activeProductCode = useSelector(selectActiveProductCode);
  const prevActiveProductCode = usePrevious(activeProductCode);
  const productCodesFromPackage = useSelector(selectProductCodesFromPackage);
  const linkedProductCodes = useSelector(
    selectLinkedProductCodesByMainCodes(productCodesFromPackage)
  );

  const handleResize = () => {
    if (!isMobile && divRef.current && headerRef.current) {
      divRef.current.style.marginTop =
        headerRef.current.getBoundingClientRect().height + 'px';
    }
  };

  useEffect(() => {
    dispatch(refreshLinkedProducts(linkedProductCodes));
  }, [dispatch, linkedProductCodes]);

  useEffect(() => {
    const isPageReloaded = !prevActiveProductCode && activeProductCode;
    const isActiveProductChanged =
      prevActiveProductCode && prevActiveProductCode !== activeProductCode;

    if (isPageReloaded || isActiveProductChanged) {
      dispatch(restoreBusinessInfoForm());
      dispatch(restoreBillingInfoForm());
      dispatch(restoreProducts());
      dispatch(restoreBankingBundle());
      dispatch(restoreLinkedProducts());
      dispatch(restorePackageBackup());
    }
  }, [dispatch, prevActiveProductCode, activeProductCode, router]);

  useEffect(() => {
    if (router.isReady && !clickData) {
      dispatch(createClick());
    }
  }, [dispatch, router, clickData]);

  useEffect(() => {
    dispatch(refreshDevice(getDeviceType()));
    dispatch(refreshSalePageURL(getSalePageURL()));
    dispatch(getGeolocation());
    dispatch(getProducts());
    dispatch(getStates());
    dispatch(getProcessingOptions());
    dispatch(getPrices());
    dispatch(getUpsellOffers());
  }, [dispatch]);

  useEffect(() => {
    const handleRouteChange = (url) => {
      dispatch(createClick());
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [dispatch, router]);

  useEffect(() => {
    if (divRef.current && headerRef.current) {
      divRef.current.style.marginTop =
        headerRef.current.getBoundingClientRect().height + 'px';
    }

    window.addEventListener('resize', handleResize, false);

    return () => {
      window.removeEventListener('resize', handleResize, false);
    };
  }, []);

  return (
    <StyledPageLayout overflowHidden={!router.pathname.includes(ROUTES.blog)}>
      <Header headerRef={headerRef} />
      <div id="topEmptyHeightDiv" ref={divRef}></div>
      {children}
      <Footer />
      <ZendeskComponent />
    </StyledPageLayout>
  );
};

export default PageLayout;
