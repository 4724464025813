import Zendesk, { ZendeskAPI } from 'react-zendesk';
import { theme } from '_constants/theme';

const { colors } = theme;
function ZendeskComponent() {
  const setting = {
    color: {
      theme: `${colors.orange[500]}`,
    },
    launcher: {
      chatLabel: {
        '*': 'Chat now',
      },
      mobile: {
        labelVisible: true,
      },
    },
    contactOptions: {
      enabled: true,
      contactButton: { '*': 'Get in touch' },
    },
  };
  return (
    <Zendesk
      defer
      zendeskKey={process.env.NEXT_PUBLIC_ZENDESK_KEY}
      {...setting}
      onLoaded={() => {
        ZendeskAPI('webWidget', 'setLocale', 'en');
        ZendeskAPI('webWidget', 'hide');
      }}
    />
  );
}
export default ZendeskComponent;
