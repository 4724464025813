import PropTypes from 'prop-types';

const WorkingTimeSvg = ({ width, height, palette = [] }) => {
  return (
    <svg
      width={`${width || 60}`}
      height={`${height || 60}`}
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M50.5781 35.8008C51.1055 36.0117 51.7383 35.9414 52.1953 35.5898C52.6875 35.2734 52.9688 34.7461 52.9688 34.1484V22.2305L0 22.2656V40.5469C0 43.4648 2.35547 45.8203 5.27344 45.8203H37.1484C38.1328 45.8203 38.9062 45.0469 38.9062 44.0625C38.9062 39.2109 42.8438 35.2734 47.6953 35.2734C48.7148 35.2734 49.6992 35.4492 50.5781 35.8008ZM21.2109 10.6641V7.14844H31.7578V10.6641L33.3984 13.4766L35.2734 10.6641V5.39062C35.2734 4.40625 34.5 3.63281 33.5156 3.63281H19.4531C18.4688 3.63281 17.6953 4.40625 17.6953 5.39062V10.6641L18.75 14.7656L21.2109 10.6641ZM51.2109 40.5469C51.2109 38.5762 49.6659 37.0312 47.6953 37.0312C45.7247 37.0312 44.1797 38.5762 44.1797 40.5469V44.0625C44.1797 46.0331 45.7247 47.5781 47.6953 47.5781H51.2109C53.1816 47.5781 54.7266 46.0331 54.7266 44.0625C54.7266 42.0919 53.1816 40.5469 51.2109 40.5469Z"
        fill={`url(#paint0_linear_50_8588${width + height})`}
      />
      <path
        d="M52.9688 15.9375C52.9688 13.0195 50.6133 10.6641 47.6953 10.6641H5.27344C2.35547 10.6641 0 13.0195 0 15.9375V22.2656C5.77734 25.752 14.7305 27.9586 24.7266 28.1953V30C24.7266 30.9715 25.5129 31.7578 26.4844 31.7578C27.4559 31.7578 28.2422 30.9715 28.2422 30V28.1953C38.2699 27.9551 47.223 25.718 52.9688 22.2305V15.9375ZM47.6953 31.7578C40.9102 31.7578 35.3906 37.2773 35.3906 44.0625C35.3906 50.8477 40.9102 56.3672 47.6953 56.3672C54.4805 56.3672 60 50.8477 60 44.0625C60 37.2773 54.4805 31.7578 47.6953 31.7578ZM51.2109 45.8203H47.6953C46.7108 45.8203 45.9375 45.0468 45.9375 44.0625V40.5469C45.9375 39.5624 46.7108 38.7891 47.6953 38.7891C48.6798 38.7891 49.4531 39.5624 49.4531 40.5469V42.3047H51.2109C52.1954 42.3047 52.9688 43.078 52.9688 44.0625C52.9688 45.0468 52.1954 45.8203 51.2109 45.8203Z"
        fill={`url(#paint1_linear_50_8588${width + height})`}
      />
      <defs>
        <linearGradient
          id={`paint0_linear_50_8588${width + height}`}
          x1="27.3633"
          y1="47.5781"
          x2="27.3633"
          y2="3.63281"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[0]} />
          <stop offset="1" stopColor={palette[1]} />
        </linearGradient>
        <linearGradient
          id={`paint1_linear_50_8588${width + height}`}
          x1="30"
          y1="56.3672"
          x2="30"
          y2="10.6641"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[2]} />
          <stop offset="1" stopColor={palette[3]} />
        </linearGradient>
      </defs>
    </svg>
  );
};

WorkingTimeSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  palette: PropTypes.arrayOf(PropTypes.string),
};

export default WorkingTimeSvg;
