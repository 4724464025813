import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import styled, { css } from 'styled-components';

import { theme } from '_constants/theme';

const StyledContainer = styled.div`
  height: ${theme.spaces.small2X + 1}px;
  width: 100%;

  position: relative;

  border-top: 1px solid ${theme.colors.gray[200]};

  @media ${theme.devices.tablet} {
    height: ${theme.spaces.small2X}px;
  }
`;

// eslint-disable-next-line prettier/prettier, react/display-name
const StyledBar = styled(forwardRef(({ color, value, maxValue, ...props }, ref) => (<div ref={ref} {...props} />)))`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;

  background-color: ${theme.colors.indigo[600]};

  ${({ color }) =>
    color &&
    css`
      background-color: ${color};
    `}

  ${({ value, maxValue }) =>
    css`
      width: ${`${(value * 100) / maxValue}%`};
    `}
`;

const ProgressBar = ({ color, value, maxValue }) => (
  <StyledContainer>
    <StyledBar color={color} value={value} maxValue={maxValue} />
  </StyledContainer>
);

ProgressBar.propTypes = {
  color: PropTypes.string,
  value: PropTypes.number,
  maxValue: PropTypes.number,
};

export default ProgressBar;
