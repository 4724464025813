import { MAX_NUM_OF_PAYMENT_ATTEMPTS } from '_constants/payment';
import { STORAGE_KEYS } from '_constants/storage';
import { STATE_MAP } from '_constants/forms';

import {
  PRODUCT_CODES,
  LINKED_PRODUCT_CODES,
  PRODUCTS_SORT_ORDER_ARRAY,
  SYNTHETIC_PRODUCT_CODES,
} from '_constants/products';

import { PROCESSING_CODES } from '_constants/processing';

import {
  restoreLocalValue,
  restoreSessionValue,
  storeSessionValue,
} from '_helpers/storage';

import * as bcrypt from '_helpers/bcrypt';
import { getFullSetOfProducts } from '_helpers/products';

const PRODUCT_NAME_MAP = {
  [PRODUCT_CODES.incLLC]: {
    [PROCESSING_CODES.diamond]: 'LLC Formation - Premium Package',
    [PROCESSING_CODES.platinum]: 'LLC Formation - Deluxe Package',
    [PROCESSING_CODES.gold]: 'LLC Formation - Starter Package',
  },
};

const PACKAGE_PAYLOADS = {
  [PROCESSING_CODES.diamond]: [
    'Comprehensive name check',
    'Prepared and filed Articles of Organization',
    'All documentation filed with Secretary of State',
    'Fastest Processing Time',
    'EIN/ Tax ID Number',
    'Operating Agreement',
    'Banking Resolution',
    'IRS Form 2553',
    'Email, Chat & Phone Support',
    // 'Fastest Processing Time',
    'Business Licenses & Permits',
    'Certificate of Good Standing',
    '1 Free Entity Amendment',
    'Business Minutes and Resolutions',
    'Dedicated Account Representative',
  ],
  [PROCESSING_CODES.platinum]: [
    'Comprehensive name check',
    'Prepared and filed Articles of Organization',
    'All documentation filed with Secretary of State',
    'Faster Processing Times',
    'EIN/ Tax ID Number',
    'Operating Agreement',
    'Banking Resolution',
    'IRS Form 2553',
    'Email, Chat & Phone Support',
  ],
  [PROCESSING_CODES.gold]: [
    'Comprehensive name check',
    'Prepared and filed Articles of Organization',
    'All documentation filed with Secretary of State',
  ],
};

export const createPaymentBody = (billingInfo, processingOption) => {
  const paymentBody = {
    processingOption,
    firstName: billingInfo.firstName,
    lastName: billingInfo.lastName,
    cardNumber: billingInfo.cardNumber.replace(/\s/g, ''),
    expMonth: billingInfo.cardMonth,
    expYear: billingInfo.cardYear,
    cvv: billingInfo.cvc,
    billingAddress: {
      address1: billingInfo.billingAddressAddress1,
      city: billingInfo.billingAddressCity,
      state: billingInfo.billingAddressState,
      zip: billingInfo.billingAddressZip,
      country: billingInfo.billingAddressCountry,
    },
  };

  if (billingInfo?.campaignId) {
    paymentBody.campaignId = billingInfo?.campaignId;
  }

  return paymentBody;
};

export const fillPaymentBodyWithSourceData = (paymentBody) => {
  if (restoreLocalValue(STORAGE_KEYS.source)) {
    paymentBody.affId = restoreLocalValue(STORAGE_KEYS.source);
  }

  if (restoreLocalValue(STORAGE_KEYS.adAccount)) {
    paymentBody.sourceValue1 = restoreLocalValue(STORAGE_KEYS.adAccount);
  }

  if (restoreLocalValue(STORAGE_KEYS.keyword)) {
    paymentBody.sourceValue2 = restoreLocalValue(STORAGE_KEYS.keyword);
  }
};

export const createOrderSummary = ({
  state,
  productsFromOrder = [],
  productsFromPackage = [],
  bankingBundleProductsFromPackage = [],
  productNameMap = {},
  priceMap = {},
  feeMap = {},
  standardPriceMap = {},
  activeProcessingCode,
  activeProductCode,
}) => {
  const productsSetByProcessingCode = getFullSetOfProducts({
    activeProductCode,
    processingCode: activeProcessingCode,
    noBundles: true,
    state,
  });

  const productsSetWithBankingBundle = [
    ...productsSetByProcessingCode,
    ...bankingBundleProductsFromPackage,
  ].map((code) => {
    if (
      code === PRODUCT_CODES.incRushProcessing &&
      productsFromPackage.includes(PRODUCT_CODES.incSameDayProcessing)
    ) {
      return PRODUCT_CODES.incSameDayProcessing;
    }

    if (
      code === PRODUCT_CODES.incSameDayProcessing &&
      productsFromPackage.includes(PRODUCT_CODES.incRushProcessing)
    ) {
      return PRODUCT_CODES.incRushProcessing;
    }

    return code;
  });

  const productsInSummary = [...productsSetWithBankingBundle];

  if (state !== 'ME') {
    productsInSummary.push(LINKED_PRODUCT_CODES.incStateIncorporationFee);
  }

  const sortedProductsFromOrder = PRODUCTS_SORT_ORDER_ARRAY.reduce(
    (res, code) => {
      if (productsInSummary.includes(code)) {
        res.push(code);
      }
      return res;
    },
    []
  );

  return sortedProductsFromOrder.reduce(
    (res, code) => {
      let active = productsFromPackage.includes(code);
      let edit = true;
      let name = PRODUCT_NAME_MAP[code]
        ? PRODUCT_NAME_MAP[code][activeProcessingCode] || productNameMap[code]
        : productNameMap[code];
      let info = null;
      let status = 'Not Included';
      let price = 0;
      let upgradeTo = null;

      const isMiddleBundle =
        productsInSummary.includes(PRODUCT_CODES.incOperatingAgreement) &&
        productsInSummary.includes(PRODUCT_CODES.incEIN) &&
        !productsInSummary.includes(PRODUCT_CODES.incBankingResolution);

      const isFullBundle =
        productsInSummary.includes(PRODUCT_CODES.incOperatingAgreement) &&
        productsInSummary.includes(PRODUCT_CODES.incEIN) &&
        productsInSummary.includes(PRODUCT_CODES.incBankingResolution);

      if (
        [
          PRODUCT_CODES.incOperatingAgreement,
          PRODUCT_CODES.incEIN,
          PRODUCT_CODES.incBankingResolution,
        ].includes(code) &&
        isFullBundle
      ) {
        // eslint-disable-next-line prettier/prettier, max-len
        if (
          !res.products.find(
            ({ key }) => key === SYNTHETIC_PRODUCT_CODES.incOAAndEINAndBR
          )
        ) {
          active =
            productsFromPackage.includes(PRODUCT_CODES.incOperatingAgreement) &&
            productsFromPackage.includes(PRODUCT_CODES.incEIN) &&
            productsFromPackage.includes(PRODUCT_CODES.incBankingResolution);

          name = 'Operating Agreement, EIN & Banking Resolution';

          price =
            (priceMap[PRODUCT_CODES.incOperatingAgreement] || 0) +
            (priceMap[PRODUCT_CODES.incEIN] || 0) +
            (priceMap[PRODUCT_CODES.incBankingResolution] || 0);

          if (active) {
            status = price === 0 ? 'Included' : null;
            res.total = res.total + price;
          }

          res.products.push({
            key: SYNTHETIC_PRODUCT_CODES.incOAAndEINAndBR,
            active,
            edit,
            name,
            info,
            price,
            status,
            upgradeTo,
          });
        } else {
          return res;
        }
      } else if (
        [PRODUCT_CODES.incOperatingAgreement, PRODUCT_CODES.incEIN].includes(
          code
        ) &&
        isMiddleBundle
      ) {
        // eslint-disable-next-line prettier/prettier, max-len
        if (
          !res.products.find(
            ({ key }) => key === SYNTHETIC_PRODUCT_CODES.incOAAndEIN
          )
        ) {
          active =
            productsFromPackage.includes(PRODUCT_CODES.incOperatingAgreement) &&
            productsFromPackage.includes(PRODUCT_CODES.incEIN);

          name = 'Operating Agreement & EIN / Tax ID Number';

          price =
            (priceMap[PRODUCT_CODES.incOperatingAgreement] || 0) +
            (priceMap[PRODUCT_CODES.incEIN] || 0);

          if (active) {
            status = price === 0 ? 'Included' : null;
            res.total = res.total + price;
          }

          res.products.push({
            key: SYNTHETIC_PRODUCT_CODES.incOAAndEIN,
            active,
            edit,
            name,
            info,
            price,
            status,
            upgradeTo,
          });
        } else {
          return res;
        }
      } else {
        price = priceMap[code] || feeMap[code] || 0;

        if (code === 'INC_StateIncorporationFee') {
          edit = false;
          name = 'State Filing Fee';
          info = `Required and Collected by ${STATE_MAP[state]} for company formations`;
        }

        if (code === PRODUCT_CODES.incLLC) {
          info = PACKAGE_PAYLOADS[activeProcessingCode] || null;

          if (activeProcessingCode === PROCESSING_CODES.gold) {
            upgradeTo = PROCESSING_CODES.platinum;
          }
        }

        if (code === PRODUCT_CODES.incSCorp) {
          name = 'IRS Form 2553';
        }

        if (code === PRODUCT_CODES.incRushProcessing) {
          name = 'Express Processing';

          if (!active) {
            name = 'Standard Processing';
            status = 'Included';
          }
        }

        if (code === PRODUCT_CODES.incSameDayProcessing) {
          name = 'Same Day Processing';

          if (!active) {
            name = 'Express Processing';
            status = 'Included';
          }
        }

        if (active) {
          status = null;
          res.total = res.total + price;
        }

        if (
          active &&
          code === PRODUCT_CODES.incBusinessLicense &&
          price === 0
        ) {
          status = 'Included';
        }

        if (
          active &&
          code === PRODUCT_CODES.incAnnualCompliance &&
          price === 0
        ) {
          status = 'Included';
        }

        if (active && code === PRODUCT_CODES.incSCorp && price === 0) {
          status = 'Included';
        }

        if (
          active &&
          code === PRODUCT_CODES.incCertificateOfGoodStanding &&
          price === 0
        ) {
          status = 'Included';
        }

        if (active && code === PRODUCT_CODES.incRushProcessing && price === 0) {
          status = 'Included';
        }

        if (
          active &&
          code === PRODUCT_CODES.incSameDayProcessing &&
          price === 0
        ) {
          status = 'Included';
        }

        if (
          active &&
          code === PRODUCT_CODES.incRegisteredAgent &&
          price === 0
        ) {
          status = 'Included';
        }

        if (active && code === PRODUCT_CODES.incEIN && price === 0) {
          status = 'Included';
        }

        res.products.push({
          key: code,
          active,
          edit,
          name,
          info,
          price,
          status,
          upgradeTo,
        });
      }

      return res;
    },
    { products: [], total: 0 }
  );
};

export const checkIfNumberOfPaymentAttemptsExceeded = () => {
  const billingCounter = restoreSessionValue(STORAGE_KEYS.billingCounter);
  return billingCounter >= MAX_NUM_OF_PAYMENT_ATTEMPTS;
};

export const checkIfCardDataNotChanged = async (cardData) => {
  const billingHash = restoreSessionValue(STORAGE_KEYS.billingHash);

  if (!billingHash) {
    return false;
  }

  const cardDataString = JSON.stringify(cardData);
  return await bcrypt.compare(cardDataString, billingHash);
};

export const increaseBillingCounter = () => {
  const billingCounter = restoreSessionValue(STORAGE_KEYS.billingCounter) || 0;
  storeSessionValue(STORAGE_KEYS.billingCounter, billingCounter + 1);
};

export const createBillingHash = async (cardData) => {
  const cardDataString = JSON.stringify(cardData);
  const billingHash = await bcrypt.createHash(cardDataString);
  storeSessionValue(STORAGE_KEYS.billingHash, billingHash);
};
