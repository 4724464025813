import { config } from '_config';
import { AxiosClient } from '_services/AxiosClient';

const { ipApiUrl, ipApiKey } = config;

class IPService extends AxiosClient {
  constructor(url) {
    super(url);
    this.getGeolocation = this.getGeolocation.bind(this);
  }

  getGeolocation() {
    const path = `/json`;
    return this.client.get(path, { params: { key: ipApiKey } });
  }
}

const ServiceInstance = new IPService(ipApiUrl);

export { ServiceInstance as IPService };
