import { PRODUCT_CODES } from '_constants/products';
import { PROCESSING_CODES } from '_constants/processing';
import { FIELDS_KEYS } from '_constants/forms';

import {
  LLC_INCORPORATION_FILING_STEPS,
  LLC_INCORPORATION_FILING_STEPS_GOLD,
  LLC_INCORPORATION_FILING_STEPS_PLATINUM,
  LLC_INCORPORATION_FILING_STEPS_DIAMOND,
  LLC_INCORPORATION_FILING_BANNER,
  LLC_INCORPORATION_FILING_BANNER_GOLD,
  LLC_INCORPORATION_FILING_BANNER_PLATINUM,
  LLC_INCORPORATION_FILING_BANNER_DIAMOND,
} from '_constants/filingFlow';

export const getMaxStep = (steps = [], parentIndex) => {
  return steps
    .slice(0, typeof parentIndex === 'undefined' ? undefined : parentIndex + 1)
    .reduce((acc, { children }, idx, arr) => {
      acc = acc + 1 + (children?.length ? children?.length - 1 : 0);

      if (idx === arr.length - 1) {
        acc = acc - 1;
      }

      return acc;
    }, 0);
};

export const getFilingStepsDataSource = ({
  productCode,
  processingCode,
} = {}) => {
  const MAP = {
    [PRODUCT_CODES.incLLC]: {
      [PROCESSING_CODES.gold]: LLC_INCORPORATION_FILING_STEPS_GOLD,
      [PROCESSING_CODES.platinum]: LLC_INCORPORATION_FILING_STEPS_PLATINUM,
      [PROCESSING_CODES.diamond]: LLC_INCORPORATION_FILING_STEPS_DIAMOND,
    },
  };

  const subMap = MAP[productCode] || {};
  const dataSource = subMap[processingCode] || LLC_INCORPORATION_FILING_STEPS;

  return dataSource;
};

export const getBannerDataSource = ({ productCode, processingCode } = {}) => {
  const MAP = {
    [PRODUCT_CODES.incLLC]: {
      [PROCESSING_CODES.gold]: LLC_INCORPORATION_FILING_BANNER_GOLD,
      [PROCESSING_CODES.platinum]: LLC_INCORPORATION_FILING_BANNER_PLATINUM,
      [PROCESSING_CODES.diamond]: LLC_INCORPORATION_FILING_BANNER_DIAMOND,
    },
  };

  const subMap = MAP[productCode] || {};
  const dataSource = subMap[processingCode] || LLC_INCORPORATION_FILING_BANNER;

  return dataSource;
};

export const getBusinessInfoUpgradesFragment = (products = []) => {
  const MAP = {
    [PRODUCT_CODES.incRegisteredAgent]: FIELDS_KEYS.registeredAgent,
    [PRODUCT_CODES.incOperatingAgreement]: FIELDS_KEYS.operatingAgreement,
    [PRODUCT_CODES.incEIN]: FIELDS_KEYS.ein,
    [PRODUCT_CODES.incBankingResolution]: FIELDS_KEYS.bankingResolution,
    [PRODUCT_CODES.incBusinessLicense]: FIELDS_KEYS.license,
    [PRODUCT_CODES.incAnnualCompliance]: FIELDS_KEYS.compliance,
    [PRODUCT_CODES.incSCorp]: FIELDS_KEYS.sCorp,
    [PRODUCT_CODES.incCertificateOfGoodStanding]: FIELDS_KEYS.goodStanding,
    [PRODUCT_CODES.incRushProcessing]: FIELDS_KEYS.rush,
    [PRODUCT_CODES.incSameDayProcessing]: FIELDS_KEYS.rush,
  };

  return Object.keys(MAP).reduce(
    (res, code) => {
      if (products.includes(code)) {
        res = {
          ...res,
          [MAP[code]]:
            code === PRODUCT_CODES.incRushProcessing ||
            code === PRODUCT_CODES.incSameDayProcessing
              ? code
              : true,
        };
      }

      return res;
    },
    {
      [FIELDS_KEYS.registeredAgent]: false,
      [FIELDS_KEYS.operatingAgreement]: false,
      [FIELDS_KEYS.ein]: false,
      [FIELDS_KEYS.bankingResolution]: false,
      [FIELDS_KEYS.license]: false,
      [FIELDS_KEYS.compliance]: false,
      [FIELDS_KEYS.sCorp]: false,
      [FIELDS_KEYS.goodStanding]: false,
      [FIELDS_KEYS.rush]: null,
    }
  );
};
