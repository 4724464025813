import PropTypes from 'prop-types';

const TrashSvg = ({ color, width, height }) => {
  return (
    <svg
      width={`${width || 24}`}
      height={`${height || 25}`}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.07 5.73C19.46 5.57 17.85 5.45 16.23 5.36V5.35L16.01 4.05C15.86 3.13 15.64 1.75 13.3 1.75H10.68C8.34997 1.75 8.12997 3.07 7.96997 4.04L7.75997 5.32C6.82997 5.38 5.89997 5.44 4.96997 5.53L2.92997 5.73C2.50997 5.77 2.20997 6.14 2.24997 6.55C2.28997 6.96 2.64997 7.26 3.06997 7.22L5.10997 7.02C10.35 6.5 15.63 6.7 20.93 7.23C20.96 7.23 20.98 7.23 21.01 7.23C21.39 7.23 21.72 6.94 21.76 6.55C21.79 6.14 21.49 5.77 21.07 5.73Z"
        fill={color}
      />
      <path
        opacity="0.3991"
        d="M19.23 8.64C18.99 8.39 18.66 8.25 18.32 8.25H5.67999C5.33999 8.25 4.99999 8.39 4.76999 8.64C4.53999 8.89 4.40999 9.23 4.42999 9.58L5.04999 19.84C5.15999 21.36 5.29999 23.26 8.78999 23.26H15.21C18.7 23.26 18.84 21.37 18.95 19.84L19.57 9.59C19.59 9.23 19.46 8.89 19.23 8.64Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.58002 17.5C9.58002 17.0858 9.9158 16.75 10.33 16.75H13.66C14.0742 16.75 14.41 17.0858 14.41 17.5C14.41 17.9142 14.0742 18.25 13.66 18.25H10.33C9.9158 18.25 9.58002 17.9142 9.58002 17.5Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.75 13.5C8.75 13.0858 9.08579 12.75 9.5 12.75H14.5C14.9142 12.75 15.25 13.0858 15.25 13.5C15.25 13.9142 14.9142 14.25 14.5 14.25H9.5C9.08579 14.25 8.75 13.9142 8.75 13.5Z"
        fill={color}
      />
    </svg>
  );
};

TrashSvg.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default TrashSvg;
