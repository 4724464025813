import React from 'react';
import styled from 'styled-components';
import { theme } from '_constants/theme';
const { colors, devices } = theme;

const StyledButton = styled.button`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 8px;
  padding: 8px 8px;
  border-radius: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ open }) => (open ? colors.gray[100] : colors.indigo[200])};
  background: ${({ open }) => (open ? colors.gray[100] : colors.indigo[200])};
}

`;
const StyledBurger = styled.div`
  width: 24px;
  height: 24px;
  display: none;
  @media ${devices.tablet} {
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
  }
  div {
    width: 24px;
    height: 0.25rem;
    background-color: ${({ open }) =>
      open ? colors.indigo[600] : colors.indigo[600]};
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;
    &:nth-child(1) {
      transform: ${({ open }) =>
        open ? 'translateX(3px) rotate(45deg)' : 'rotate(0)'};
    }
    &:nth-child(2) {
      opacity: ${({ open }) => (open ? 0 : 1)};
    }
    &:nth-child(3) {
      transform: ${({ open }) =>
        open ? 'translateX(3px) rotate(-45deg)' : 'rotate(0)'};
    }
  }
`;

const Burger = ({ open, handleOpen }) => {
  return (
    <StyledButton open={open}>
      <StyledBurger open={open} onClick={handleOpen}>
        <div />
        <div />
        <div />
      </StyledBurger>
    </StyledButton>
  );
};

export default Burger;
