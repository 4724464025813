import PropTypes from 'prop-types';

const MedalStarSvg = ({ color, width, height }) => {
  return (
    <svg
      width={`${width || 36}`}
      height={`${height || 36}`}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M28.5 13.5C28.5 15.675 27.855 17.67 26.745 19.335C25.125 21.735 22.56 23.43 19.575 23.865C19.065 23.955 18.54 24 18 24C17.46 24 16.935 23.955 16.425 23.865C13.44 23.43 10.875 21.735 9.255 19.335C8.145 17.67 7.5 15.675 7.5 13.5C7.5 7.695 12.195 3 18 3C23.805 3 28.5 7.695 28.5 13.5Z"
        fill={color}
      />
      <path
        d="M31.8746 27.7051L29.3996 28.2901C28.8446 28.4251 28.4096 28.8451 28.2896 29.4001L27.7646 31.6051C27.4796 32.8051 25.9496 33.1651 25.1546 32.2201L17.9996 24.0001L10.8446 32.2351C10.0496 33.1801 8.51963 32.8201 8.23463 31.6201L7.70963 29.4151C7.57463 28.8601 7.13963 28.4251 6.59963 28.3051L4.12463 27.7201C2.98463 27.4501 2.57963 26.0251 3.40463 25.2001L9.25463 19.3501C10.8746 21.7501 13.4396 23.4451 16.4246 23.8801C16.9346 23.9701 17.4596 24.0151 17.9996 24.0151C18.5396 24.0151 19.0646 23.9701 19.5746 23.8801C22.5596 23.4451 25.1246 21.7501 26.7446 19.3501L32.5946 25.2001C33.4196 26.0101 33.0146 27.4351 31.8746 27.7051Z"
        fill={color}
      />
      <path
        d="M18.87 8.97L19.755 10.74C19.875 10.98 20.19 11.22 20.475 11.265L22.08 11.535C23.1 11.7 23.34 12.45 22.605 13.185L21.36 14.43C21.15 14.64 21.03 15.045 21.105 15.345L21.465 16.89C21.75 18.105 21.105 18.585 20.025 17.94L18.525 17.055C18.255 16.89 17.805 16.89 17.535 17.055L16.035 17.94C14.955 18.57 14.31 18.105 14.595 16.89L14.955 15.345C15.015 15.06 14.91 14.64 14.7 14.43L13.455 13.185C12.72 12.45 12.96 11.715 13.98 11.535L15.585 11.265C15.855 11.22 16.17 10.98 16.29 10.74L17.175 8.97C17.61 8.01 18.39 8.01 18.87 8.97Z"
        fill={color}
      />
    </svg>
  );
};

MedalStarSvg.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default MedalStarSvg;
