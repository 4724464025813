import PropTypes from 'prop-types';

const Taxes3Svg = ({ width, height, palette = [] }) => {
  return (
    <svg
      width={`${width || 60}`}
      height={`${height || 60}`}
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath={`url(#clip0_310_17863${width + height})`}>
        <rect width="60" height="60" fill="white" />
        <path
          d="M19.4531 17.8125H40.5469C41.5314 17.8125 42.3047 17.0392 42.3047 16.0547V8.78906C42.3047 7.80457 41.5314 7.03125 40.5469 7.03125H31.7578L30 3.51562L28.2422 7.03125H19.4531C18.4686 7.03125 17.6953 7.80457 17.6953 8.78906V16.0547C17.6953 17.0392 18.4686 17.8125 19.4531 17.8125ZM40.5469 19.5703H19.4531V44.1797L17.6953 47.6953L21.2109 60H38.7891L42.3047 47.6953L40.5469 44.1797V19.5703ZM17.0273 25.2305C16.6055 24.879 16.0431 24.7734 15.5156 24.9142L1.33594 28.4298C0.562383 28.6055 0 29.3087 0 30.1172V35.3906L6.67676 36.7198L0 38.9062V42.4219L5.39062 44.1797L0 45.9375V49.4531L6.67676 51.6396L0 52.9688V58.2422C0 59.2267 0.77332 60 1.75781 60H14.1797C16.1214 60 17.6953 58.4261 17.6953 56.4844V47.6953V26.6016C17.6953 26.0741 17.4492 25.5469 17.0273 25.2305ZM60 30.1172C60 29.3087 59.4379 28.6055 58.6641 28.4298L44.4844 24.9142C43.9569 24.7734 43.3948 24.879 42.9724 25.2305C42.551 25.5469 42.3047 26.0741 42.3047 26.6016V47.6953V56.4844C42.3047 58.4261 43.8789 60 45.8203 60H58.2422C59.2267 60 60 59.2267 60 58.2422V52.9688L56.9561 51.6396L60 49.4531V45.9375L58.2422 44.1797L60 42.4219V38.9062L56.9561 36.7198L60 35.3906V30.1172Z"
          fill={`url(#paint0_linear_310_17863${width + height})`}
        />
        <path
          d="M31.7578 1.75781C31.7578 0.77332 30.9845 0 30 0C29.0155 0 28.2422 0.77332 28.2422 1.75781V7.03125H31.7578V1.75781ZM44.0625 14.2969H15.9375C14.953 14.2969 14.1797 15.0702 14.1797 16.0547V60H21.2109V47.6953C21.2109 46.7108 21.9843 45.9375 22.9688 45.9375H37.0312C38.0157 45.9375 38.7891 46.7108 38.7891 47.6953V60H45.8203V16.0547C45.8203 15.0702 45.047 14.2969 44.0625 14.2969ZM33.5156 21.3281C34.5001 21.3281 35.2734 22.1014 35.2734 23.0859C35.2734 24.0704 34.5001 24.8438 33.5156 24.8438C32.5311 24.8438 31.7578 24.0704 31.7578 23.0859C31.7578 22.1014 32.5311 21.3281 33.5156 21.3281ZM25.4999 21.6095C26.3086 21.082 27.3984 21.293 27.9608 22.1016L34.9921 32.6484C35.5195 33.4571 35.3086 34.547 34.5 35.1094C33.535 35.6453 32.568 35.3579 32.0391 34.6173L25.0078 24.0704C24.4805 23.2617 24.6914 22.1719 25.4999 21.6095ZM28.2422 33.6328C28.2422 34.6173 27.4689 35.3906 26.4844 35.3906C25.4999 35.3906 24.7266 34.6173 24.7266 33.6328C24.7266 32.6483 25.4999 31.875 26.4844 31.875C27.4689 31.875 28.2422 32.6483 28.2422 33.6328ZM37.0312 42.4219H22.9688C21.9843 42.4219 21.2109 41.6486 21.2109 40.6641C21.2109 39.6796 21.9843 38.9062 22.9688 38.9062H37.0312C38.0157 38.9062 38.7891 39.6796 38.7891 40.6641C38.7891 41.6486 38.0157 42.4219 37.0312 42.4219ZM60 38.9062V35.3906H51.0938C50.1093 35.3906 49.3359 36.1639 49.3359 37.1484V51.2109C49.3359 52.1954 50.1093 52.9688 51.0938 52.9688H60V49.4531H52.8516V45.9375H60V42.4219H52.8516V38.9062H60ZM8.90625 35.3906H0V38.9062H7.14844V42.4219H0V45.9375H7.14844V49.4531H0V52.9688H8.90625C9.89074 52.9688 10.6641 52.1954 10.6641 51.2109V37.1484C10.6641 36.1639 9.89074 35.3906 8.90625 35.3906Z"
          fill={`url(#paint1_linear_310_17863${width + height})`}
        />
      </g>
      <defs>
        <linearGradient
          id={`paint0_linear_310_17863${width + height}`}
          x1="30"
          y1="60"
          x2="30"
          y2="3.51562"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[2]} />
          <stop offset="1" stopColor={palette[3]} />
        </linearGradient>
        <linearGradient
          id={`paint1_linear_310_17863${width + height}`}
          x1="30"
          y1="60"
          x2="30"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[0]} />
          <stop offset="1" stopColor={palette[1]} />
        </linearGradient>
        <clipPath id={`clip0_310_17863${width + height}`}>
          <rect width="60" height="60" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

Taxes3Svg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  palette: PropTypes.arrayOf(PropTypes.string),
};

export default Taxes3Svg;
