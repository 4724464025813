import React from 'react';
import styled from 'styled-components';
import Navbar from '../Navbar';
import PhoneItem from '../PhoneItem';

const StyledMobNav = styled.div`
  padding: 16px;
  display: none;
  @media ${({ theme }) => theme.devices.tablet} {
    display: ${({ open }) => (open ? 'block' : 'none')};
  }
`;

const MobNav = ({ open, handleOpen }) => {
  return (
    <StyledMobNav open={open}>
      <Navbar handleOpen={handleOpen} />
      <PhoneItem />
    </StyledMobNav>
  );
};

export default MobNav;
