export const PROCESSING_CODES = {
  rush: 'RUSH',
  standard: 'STANDARD',
  gold: 'GOLD',
  platinum: 'PLATINUM',
  diamond: 'DIAMOND',
  upsell: 'UPSELL',
};

export const PROCESSING_LABELS = {
  starter: 'STARTER',
  deluxe: 'DELUXE',
  premium: 'PREMIUM',
};
