import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Router from 'next/router';

import { MainApiService } from '_services';
import { PAGE_TYPE } from '_constants/location';
import { STORAGE_KEYS } from '_constants/storage';
import { storeLocalValue, clearLocalValue } from '_helpers/storage';
import { processResponse, processError } from '_helpers/api';
import { createClickBody } from '_helpers/location';

export const createClick = createAsyncThunk(
  'location/createClick',
  async function (_, { getState, rejectWithValue }) {
    const { general } = getState();
    const { device } = general;

    try {
      const clickBody = createClickBody(Router, device);
      const response = await MainApiService.createClick({ body: clickBody });
      const result = processResponse(response);

      if (
        result?.crmSessionId &&
        clickBody.pageType !== PAGE_TYPE.thankyouPage
      ) {
        storeLocalValue(STORAGE_KEYS.crmSessionId, result?.crmSessionId);
      }

      if (clickBody?.pageType === PAGE_TYPE.thankyouPage) {
        clearLocalValue(STORAGE_KEYS.crmSessionId);
      }

      return result;
    } catch (e) {
      return rejectWithValue(processError(e));
    }
  }
);

const locationSlice = createSlice({
  name: 'location',
  initialState: {
    click: {
      data: null,
      error: null,
      loading: false,
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createClick.pending, (state) => {
        state.click.error = null;
        state.click.loading = true;
      })
      .addCase(createClick.fulfilled, (state, action) => {
        state.click.data = action.payload;
        state.click.loading = false;
      })
      .addCase(createClick.rejected, (state, action) => {
        state.click.error = action.payload;
        state.click.loading = false;
      });
  },
});

export default locationSlice.reducer;
