import React, { useState, forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';

import { theme } from '_constants/theme';
import { ROUTES } from '_constants/routes';
import { FIELDS_KEYS } from '_constants/forms';
import { PRODUCT_CODES } from '_constants/products';

import { getMaxStep, getFilingStepsDataSource } from '_helpers/filingFlow';
import { selectFormValues as selectFilingValues } from '_redux/order/selector';
import { selectActiveProductCode } from '_redux/products/selector';
import { selectFormValues as selectUpsellValues } from '_redux/upsell/selector';
import { selectAllowedUpsellProductCodes } from '_redux/variants/selector';

import ProgressBar from '_components/atomic/ProgressBar';
import NavMenu from './NavMenu';
import MobNav from './NavMenu/MobNav';

const getProgressValues = ({
  path,
  activeProductCode,
  allowedUpsellProductCodes = [],
  filingValues = {},
  upsellValues = {},
}) => {
  const steps = {
    [PRODUCT_CODES.incLLC]: getFilingStepsDataSource({
      productCode: activeProductCode,
      processingCode:
        filingValues[FIELDS_KEYS.processingCode] ||
        upsellValues[FIELDS_KEYS.processingCode],
    }),
  };

  let maxValue =
    getMaxStep(steps[activeProductCode], 3) +
    allowedUpsellProductCodes.length +
    2;

  let value = filingValues.step + 1;

  if (path === ROUTES.payment) {
    value = getMaxStep(steps[activeProductCode], 3) + 1;
  }

  if (path === ROUTES.upsell) {
    value = getMaxStep(steps[activeProductCode], 3) + 1 + upsellValues.step + 1;
  }

  if (path === ROUTES.thankyou) {
    maxValue = 1;
    value = 1;
  }

  return { value, maxValue };
};

const { colors, spaces } = theme;

// eslint-disable-next-line react/display-name, prettier/prettier
const StyledHeader = styled(forwardRef(({ divider, ...props }, ref) => (<div ref={ref} {...props} />)))`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: ${colors.white};
  position: fixed;
  top: 0;
  z-index: 20;

  ${({ divider }) =>
    divider &&
    css`
      border-bottom: 1px solid ${colors.gray[200]};
    `}
`;

const StyledHeaderContainer = styled.div`
  max-width: ${spaces.large24X * 10 + 80}px;
  width: 100%;
`;

function Header({ headerRef }) {
  const router = useRouter();
  const [open, setOpen] = useState(false);
  const activeProductCode = useSelector(selectActiveProductCode);
  const filingValues = useSelector(selectFilingValues);
  const upsellValues = useSelector(selectUpsellValues);

  const allowedUpsellProductCodes = useSelector(
    selectAllowedUpsellProductCodes([activeProductCode])
  );

  const handleOpen = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const progressBarVisible =
    router.pathname === ROUTES.llcIncorporationFiling ||
    router.pathname === ROUTES.payment ||
    router.pathname === ROUTES.upsell ||
    router.pathname === ROUTES.thankyou;

  const progressBarProps = getProgressValues({
    path: router.pathname,
    activeProductCode,
    allowedUpsellProductCodes,
    filingValues,
    upsellValues,
  });

  return (
    <StyledHeader ref={headerRef} divider={!progressBarVisible}>
      <StyledHeaderContainer>
        <NavMenu open={open} handleOpen={handleOpen} />
        <MobNav open={open} handleOpen={handleOpen} />
      </StyledHeaderContainer>
      {progressBarVisible && <ProgressBar {...progressBarProps} />}
    </StyledHeader>
  );
}

export default Header;
