import React from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import { Col, Row } from 'react-bootstrap';
import { PatternFormat } from 'react-number-format';
import { theme } from '_constants/theme';
import Icon from '_components/atomic/Icon';
import { ICONS_ALIASES } from '_constants/icons';
import { config } from '_config';
import { ROUTES } from '_constants/routes';
import RoundMenu from './RoundMenu';
import { useRouter } from 'next/router';

const { spaces } = theme;

const StyledFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-bottom: 50px;

  .footer-top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    max-width: ${spaces.large24X * 10 + 80}px;
    width: 100%;
    margin-bottom: 50px;

    .footer-top-logo {
      display: flex;
      justify-content: flex-start;
      width: 50%;

      img {
        height: 40px;
        min-width: 164px;
      }
    }

    .footer-top-disclaimer {
      width: 50%;
      .childrenDescription {
        margin-left: 10px;
      }
    }
  }

  .footer-bottom {
    justify-content: space-between;
    align-items: center;
    max-width: ${spaces.large24X * 10 + 80}px;
    width: 100%;

    p {
      font-family: 'Aeonik';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: ${({ theme }) => theme.colors.gray[700]};
    }
    .footer-legal {
      color: ${({ theme }) => theme.colors.gray[500]};
    }
    .footer-bottom-menu {
      margin-bottom: 50px;
      h5 {
        font-family: 'Aeonik';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 130%;
        color: ${({ theme }) => theme.colors.gray[900]};
      }
      a {
        display: inline-block;
        width: 100%;
        font-family: 'Aeonik';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: ${({ theme }) => theme.colors.gray[700]};
        text-decoration: none;
        padding-top: 6px;
        padding-bottom: 6px;
        &:hover {
          text-decoration: underline;
        }
      }
      .phone a {
        font-family: 'Aeonik';
        font-style: normal;
        font-weight: 500;
        font-size: 36px;
        line-height: 150%;
        padding-top: 12px;
        padding-bottom: 10px;
        color: ${({ theme }) => theme.colors.indigo[600]};
      }
      .email a {
        font-family: 'Aeonik';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        padding-top: 12px;
        padding-bottom: 10px;
        color: ${({ theme }) => theme.colors.indigo[600]};
      }
      .text {
        display: block;
      }
      .contacts {
        .footer-bottom-line {
          display: none;
        }
      }
    }
  }
  .footer-bottom-line {
    height: 1px;
    background: ${({ theme }) => theme.colors.gray[200]};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 8%;
    padding-right: 8%;
    margin-bottom: 30px;
    margin-top: 30px;
  }
  .footer-bottom-line-copyright {
    font-family: 'Aeonik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.gray[500]};
  }
  .footer-bottom-line-copyright-right {
    text-align: end;
  }
  .footer-bottom-line-row {
    display: flex;
  }
  @media ${({ theme }) => theme.devices.tablet} {
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 64px;
    .footer-legal {
      text-align: center;
      font-size: 14px !important;
    }
    .footer-top {
      flex-direction: column;
      justify-content: center;
      padding-left: 0;
      padding-right: 0px;
      margin-bottom: 16px;
      .footer-top-logo {
        justify-content: center;
        width: 100%;
        img {
          width: 80%;
          min-height: 30px;
          min-width: 146px;
          display: block;
          margin-left: auto;
          margin-right: auto;
        }
      }

      .footer-top-disclaimer {
        margin-top: 16px;
        width: 100%;
        .suffix {
          display: none;
        }
        .childrenDescription {
          margin-left: 0px;
        }
      }
    }

    .footer-bottom {
      flex-direction: column;
      justify-content: center;

      padding-left: 0;
      padding-right: 0;

      .footer-bottom-menu {
        margin-bottom: 0px;
        h5 {
          font-size: 14px;
        }
        a {
          font-size: 14px;
          padding-left: 0;
          padding-right: 0;
          width: 100%;
        }
        .otherServices {
          display: none;
        }
        .contacts {
          flex: 1 100%;
          text-align: center;
        }
        .contacts {
          .footer-bottom-line {
            display: flex;
          }
        }
        .text {
          display: inline;
        }
      }
      .footer-bottom-line-copyright {
        text-align: center;
      }
      .footer-bottom-line-copyright-right {
        text-align: center;
        margin-top: 24px;
      }
      .footer-bottom-line-row {
        display: block;
      }
      .footer-bottom-line {
        margin-bottom: 16px;
        margin-top: 16px;
      }
      p {
        margin-bottom: 0rem;
      }
    }
  }
`;

function Footer() {
  const router = useRouter();
  const isFiling =
    router.pathname.includes('-filing') ||
    router.pathname.includes(ROUTES.payment);
  return (
    <StyledFooter>
      <div className="footer-top">
        <div className="footer-top-logo">
          <picture>
            <source
              srcSet={`${config.basePath}/images/logo.svg`}
              type="image/svg+xml"
            />
            <img
              src={`${config.basePath}/images/logo.svg`}
              title="Incorpified Logo"
              alt="Incorpified Logo"
            />
          </picture>
        </div>
        <div className="footer-top-disclaimer"></div>
      </div>
      {!isFiling && (
        <div className="footer-bottom">
          <div className="footer-bottom-menu">
            <Row>
              <Col>
                <h5>QUICK LINKS</h5>
                <Link href={ROUTES.about}>About Us</Link>
                <Link href={ROUTES.blogGallery}>Resource Center</Link>
                <Link href={ROUTES.services}>Services</Link>
                {/*<Link href={ROUTES.about}>Resources Center</Link>*/}
                <Link href={ROUTES.contactUs}>Contact Us</Link>
                <Link href={ROUTES.terms}>Terms & Conditions</Link>
                <Link href={ROUTES.privacy}>Privacy Policy</Link>
                {/* <Link href={ROUTES.contactUs}>Cancellation Policy</Link>
                <Link href={ROUTES.contactUs}>Customer Care</Link>
                <Link href={ROUTES.contactUs}>Legal Disclaimer</Link> */}
              </Col>
              <Col>
                <h5>SERVICES</h5>
                <Link href={ROUTES.llcIncorporation}>LLC Incorporation</Link>
                <Link href={ROUTES.einTaxId}>EIN/TAX ID</Link>
                <Link href={ROUTES.registeredAgent}>Registered Agent</Link>
                <Link href={ROUTES.trademarking}>Trademarking</Link>
                <Link href={ROUTES.bookkeeping}>Bookeeping</Link>
                <Link href={ROUTES.virtualAddress}>
                  Virtual Address (coming soon)
                </Link>
                <Link href={ROUTES.payroll}>Payroll</Link>
                <Link href={ROUTES.merchantProcessing}>
                  Merchant Processing
                </Link>
              </Col>
              <Col className="otherServices">
                {/*<h5>RESOURCES CENTER</h5>*/}
                {/*<Link href={ROUTES.contactUs}>Starting a Business</Link>*/}
                {/*<Link href={ROUTES.contactUs}>Manage a Business</Link>*/}
                {/*<Link href={ROUTES.contactUs}>Incorporation Guide per State</Link>*/}
                {/*<Link href={ROUTES.contactUs}>Business Licenses</Link>*/}
                {/*<Link href={ROUTES.contactUs}>Business Tools</Link>*/}
                <h5>COMPANY</h5>
                <Link href={ROUTES.about}>About Us</Link>
                <Link href={ROUTES.services}>Services</Link>
                <Link href={ROUTES.contactUs}>Contact Us</Link>
              </Col>
              <Col className="contacts">
                <div className="footer-bottom-line" />
                <h5>CUSTOMER SUPPORT</h5>
                <div className="phone">
                  <a href={'tel:' + config.phone}>
                    <PatternFormat
                      value={config.phone?.substring(2)}
                      displayType="text"
                      format="(###) ###-####"
                    />
                  </a>
                </div>
                <p>
                  Reach us{' '}
                  <span className="text">Monday - Friday 9am - 6pm</span>
                </p>
                <div className="email">
                  <a href={`mailto:${config.email}`}>{config.email}</a>
                </div>
              </Col>
            </Row>
          </div>
          <Row>
            <Col>
              <div className="footer-bottom-line" />
            </Col>
          </Row>
          <Row className="footer-bottom-line-row">
            <Col className="footer-bottom-line-copyright">
              © {new Date().getFullYear()}. Incorpified.com. All Rights
              Reserved.
            </Col>
            <Col className="footer-bottom-line-copyright-right">
              <Icon
                alias={ICONS_ALIASES.facebook}
                color={theme.colors.gray[500]}
              />
              <span style={{ margin: '15px' }} />
              <Icon
                alias={ICONS_ALIASES.twitter}
                color={theme.colors.gray[500]}
              />
              <span style={{ margin: '15px' }} />
              <Icon
                alias={ICONS_ALIASES.linkedin}
                color={theme.colors.gray[500]}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="footer-bottom-line" />
            </Col>
          </Row>
          <p className="footer-legal">
            We are not a law firm, or a substitute for an attorney or law firm.
            Use of our products and services are governed by our&nbsp;
            <Link href={ROUTES.terms}>Terms & Conditions</Link>&nbsp;and&nbsp;
            <Link href={ROUTES.privacy}>Privacy Policy</Link>.
          </p>
        </div>
      )}
      {!router.pathname.includes('-filing') && <RoundMenu />}
    </StyledFooter>
  );
}

export default Footer;
