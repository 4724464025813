export const ICONS_ALIASES = {
  accuracy: 'accuracy',
  adaptation: 'adaptation',
  arrowLeftSquared: 'arrowLeftSquared',
  arrowRightSquared: 'arrowRightSquared',
  arrowRight: 'arrowRight',
  arrowLeft: 'arrowLeft',
  chevronDown: 'chevronDown',
  chevronUp: 'chevronUp',
  chevronDownRounded: 'chevronDownRounded',
  arrowUp: 'arrowUp',
  bankCheck: 'bankCheck',
  career: 'career',
  cash: 'cash',
  cashFlow: 'cashFlow',
  certificate: 'certificate',
  check: 'check',
  close: 'close',
  clipboard: 'clipboard',
  copyright: 'copyright',
  corporate: 'corporate',
  creditCard: 'creditCard',
  checkFilled: 'checkFilled',
  closeFilled: 'closeFilled',
  checkRounded: 'checkRounded',
  checkRoundedInverted: 'checkRoundedInverted',
  deal: 'deal',
  document: 'document',
  facebook: 'facebook',
  favorite: 'favorite',
  feedback: 'feedback',
  freelance: 'freelance',
  gallery: 'gallery',
  globalNetwork: 'globalNetwork',
  grocery: 'grocery',
  happy: 'happy',
  headphones: 'headphones',
  kindness: 'kindness',
  link: 'link',
  linkedin: 'linkedin',
  lock: 'lock',
  messageText: 'messageText',
  moneyBag: 'moneyBag',
  onlinePayment: 'onlinePayment',
  phone: 'phone',
  piggyBank: 'piggyBank',
  pointer: 'pointer',
  poverty: 'poverty',
  privacy: 'privacy',
  productIncrement: 'productIncrement',
  quality: 'quality',
  question: 'question',
  quick: 'quick',
  reduceCost: 'reduceCost',
  report: 'report',
  rocket: 'rocket',
  salary: 'salary',
  saveEnergy: 'saveEnergy',
  saveTime: 'saveTime',
  security: 'security',
  seoReport: 'seoReport',
  shield: 'shield',
  medalStar: 'medalStar',
  star: 'star',
  step: 'step',
  stepDone: 'stepDone',
  taxes1: 'taxes1',
  taxes2: 'taxes2',
  taxes3: 'taxes3',
  tick: 'tick',
  tickCircle: 'tickCircle',
  trash: 'trash',
  twitter: 'twitter',
  verified: 'verified',
  verify: 'verify',
  vote: 'vote',
  work: 'work',
  shop: 'shop',
  chart: 'chart',
  lockPC: 'lockPC',
  reduceStress: 'reduceStress',
  calendar: 'calendar',
  cancel: 'cancel',
  stopWatch: 'stopWatch',
  development: 'development',
  shieldTick: 'shieldTick',
  shieldWithBand: 'shieldWithBand',
  searchZoomIn: 'searchZoomIn',
  searchNormal: 'searchNormal',
  devices: 'devices',
  radioOn: 'radioOn',
  radioOff: 'radioOff',
  filingCalendar: 'filingCalendar',
  filingWebsite: 'filingWebsite',
  filingUpdateGear: 'filingUpdateGear',
  filingReview: 'filingReview',
  filingCheck: 'filingCheck',
  filingChildCheck: 'filingChildCheck',
  plus: 'plus',
  goldMedal: 'goldMedal',
  platinumMedal: 'platinumMedal',
  diamondMedal: 'diamondMedal',
  save50: 'save50',
  edit: 'edit',
  info: 'info',
  infoFiled: 'infoFiled',
  task: 'task',
  location: 'location',
  profile: 'profile',
  more: 'more',
  taskList: 'taskList',
  scan: 'scan',
  phoneAndClock: 'phoneAndClock',
  workingTime: 'workingTime',
  safeLock: 'safeLock',
  accountHolder: 'accountHolder',
  expenses: 'expenses',
  protection: 'protection',
  guard: 'guard',
  newspaper: 'newspaper',
  stamp: 'stamp',
  award: 'award',
  shiningGem: 'shiningGem',
  fee: 'fee',
  insurance: 'insurance',
  fancySpinner: 'fancySpinner',
  monoCheck: 'monoCheck',
  monoDollar: 'monoDollar',
  monoLike: 'monoLike',
  monoShield: 'monoShield',
  cart: 'cart',
  warning: 'warning',
};

export const ICONS_PALETTE = {
  indigo: {
    [ICONS_ALIASES.accuracy]: ['#3538CD', '#8098F9', '#AEBFE3', '#F0F3FA'],
    [ICONS_ALIASES.adaptation]: ['#3538CD', '#8098F9', '#AEBFE3', '#F0F3FA'],
    [ICONS_ALIASES.clipboard]: ['#3538CD', '#8098F9', '#A4BCFD', 'white'],
    [ICONS_ALIASES.career]: ['#234DA1', '#95ACD9', '#C7D7FE', '#F0F3FA'],
    [ICONS_ALIASES.corporate]: ['#3538CD', '#8098F9', '#A4BCFD', 'white'],
    [ICONS_ALIASES.document]: ['#3538CD', '#8098F9', '#AEBFE3', '#F0F3FA'],
    [ICONS_ALIASES.favorite]: ['#3538CD', '#8098F9', '#A4BCFD', 'white'],
    [ICONS_ALIASES.freelance]: ['#3538CD', '#8098F9', '#A4BCFD', 'white'],
    [ICONS_ALIASES.grocery]: ['#3538CD', '#8098F9', '#A4BCFD', 'white'],
    [ICONS_ALIASES.headphones]: ['#3538CD', '#8098F9', '#C7D7FE', '#F0F3FA'],
    [ICONS_ALIASES.kindness]: ['#3538CD', '#8098F9', '#A4BCFD', 'white'],
    [ICONS_ALIASES.privacy]: ['#3538CD', '#8098F9', '#A4BCFD', '#E4F1FD'],
    [ICONS_ALIASES.quick]: ['#3538CD', '#8098F9', '#AEBFE3', '#F0F3FA'],
    [ICONS_ALIASES.rocket]: [
      '#2D31A6',
      '#8098F9',
      '#AEBFE3',
      '#C7D4EC',
      '#F0F3FA',
    ],
    [ICONS_ALIASES.salary]: ['#3538CD', '#8098F9', '#A4BCFD', 'white'],
    [ICONS_ALIASES.saveEnergy]: ['#3538CD', '#8098F9', '#C7D7FE', '#F0F3FA'],
    [ICONS_ALIASES.security]: ['#3538CD', '#8098F9', '#A4BCFD', '#E4F1FD'],
    [ICONS_ALIASES.shield]: ['#3538CD', '#8098F9', '#AEBFE3', '#F0F3FA'],
    [ICONS_ALIASES.taxes1]: ['#3538CD', '#8098F9', '#A4BCFD', '#E4F1FD'],
    [ICONS_ALIASES.taxes2]: ['#3538CD', '#8098F9', '#A4BCFD', 'white'],
    [ICONS_ALIASES.work]: ['#3538CD', '#8098F9', '#AEBFE3', '#F0F3FA'],
    [ICONS_ALIASES.shop]: ['#3538CD', '#8098F9', '#A4BCFD', '#E4F1FD'],
    [ICONS_ALIASES.lockPC]: ['#3538CD', '#8098F9', '#A4BCFD', '#E4F1FD'],
    [ICONS_ALIASES.reduceStress]: ['#3538CD', '#8098F9', '#A4BCFD', '#E4F1FD'],
    [ICONS_ALIASES.cancel]: ['#3538CD', '#8098F9', '#A4BCFD', '#E4F1FD'],
    [ICONS_ALIASES.development]: ['#3538CD', '#8098F9', '#A4BCFD', '#E4F1FD'],
    [ICONS_ALIASES.filingCalendar]: [
      '#444CE7',
      '#A4BCFD',
      '#A4BCFD',
      '#EEF4FF',
    ],
    [ICONS_ALIASES.filingWebsite]: ['#444CE7', '#A4BCFD', '#A4BCFD', '#EEF4FF'],
    [ICONS_ALIASES.filingUpdateGear]: [
      '#444CE7',
      '#A4BCFD',
      '#A4BCFD',
      '#EEF4FF',
    ],
    [ICONS_ALIASES.filingReview]: ['#444CE7', '#A4BCFD', '#A4BCFD', '#EEF4FF'],
    [ICONS_ALIASES.filingCheck]: ['#444CE7', '#A4BCFD', '#D0D5DD', 'white'],
    [ICONS_ALIASES.platinumMedal]: ['#444CE7', '#A4BCFD', '#A4BCFD', '#EEF4FF'],
    [ICONS_ALIASES.workingTime]: ['#444CE7', '#A4BCFD', '#A4BCFD', '#EEF4FF'],
    [ICONS_ALIASES.accountHolder]: ['#444CE7', '#A4BCFD', '#A4BCFD', '#EEF4FF'],
    [ICONS_ALIASES.newspaper]: ['#444CE7', '#A4BCFD', '#A4BCFD', '#EEF4FF'],
    [ICONS_ALIASES.stamp]: ['#444CE7', '#A4BCFD', '#A4BCFD', '#EEF4FF'],
    [ICONS_ALIASES.taxes3]: ['#444CE7', '#A4BCFD', '#A4BCFD', '#EEF4FF'],
    [ICONS_ALIASES.bankCheck]: ['#444CE7', '#A4BCFD', '#A4BCFD', '#EEF4FF'],
    [ICONS_ALIASES.moneyBag]: ['#444CE7', '#A4BCFD', '#A4BCFD', '#EEF4FF'],
  },
  blue: {
    [ICONS_ALIASES.cashFlow]: ['#175CD3', '#53B1FD', '#84CAFF', 'white'],
    [ICONS_ALIASES.creditCard]: ['#175CD3', '#53B1FD', '#84CAFF', 'white'],
    [ICONS_ALIASES.deal]: ['#175CD3', '#53B1FD', '#84CAFF', 'white'],
    [ICONS_ALIASES.moneyBag]: ['#175CD3', '#53B1FD', '#84CAFF', 'white'],
    [ICONS_ALIASES.onlinePayment]: ['#175CD3', '#53B1FD', '#84CAFF', 'white'],
    [ICONS_ALIASES.poverty]: ['#175CD3', '#53B1FD', '#84CAFF', 'white'],
    [ICONS_ALIASES.productIncrement]: [
      '#175CD3',
      '#53B1FD',
      '#84CAFF',
      'white',
    ],
    [ICONS_ALIASES.shield]: ['#175CD3', '#84CAFF', '#B2DDFF', '#EFF8FF'],
    [ICONS_ALIASES.scan]: ['#1C62D6', '#BEE3FF', '#90D0FF', '#FEFFFF'],
    [ICONS_ALIASES.protection]: ['#1C62D6', '#BEE3FF', '#90D0FF', '#FEFFFF'],
    [ICONS_ALIASES.shiningGem]: ['#1C62D6', '#BEE3FF', '#90D0FF', '#FEFFFF'],
  },
  green: {
    [ICONS_ALIASES.bankCheck]: ['#027A48', '#A6F4C5', '#A6F4C5', '#D1FADF'],
    [ICONS_ALIASES.cash]: ['#027A48', '#A6F4C5', '#A6F4C5', '#D1FADF'],
    [ICONS_ALIASES.copyright]: ['#027A48', '#A6F4C5', '#A6F4C5', '#D1FADF'],
    [ICONS_ALIASES.globalNetwork]: ['#027A48', '#A6F4C5', '#A6F4C5', '#D1FADF'],
    [ICONS_ALIASES.happy]: ['#027A48', '#A6F4C5', '#A6F4C5', '#D1FADF'],
    [ICONS_ALIASES.pointer]: ['#027A48', '#A6F4C5', '#A6F4C5', '#D1FADF'],
    [ICONS_ALIASES.saveTime]: ['#027A48', '#A6F4C5', '#A6F4C5', '#D1FADF'],
    [ICONS_ALIASES.taxes2]: ['#027A48', '#A6F4C5', '#A6F4C5', '#D1FADF'],
    [ICONS_ALIASES.verified]: ['#027A48', '#A6F4C5', '#A6F4C5', '#D1FADF'],
    [ICONS_ALIASES.verify]: ['#027A48', '#A6F4C5', '#D1FADF', 'white'],
    [ICONS_ALIASES.chart]: ['#027A48', '#A6F4C5', '#A6F4C5', '#D1FADF'],
    [ICONS_ALIASES.adaptation]: ['#027A48', '#A6F4C5', '#A6F4C5', '#D1FADF'],
    [ICONS_ALIASES.feedback]: ['#027A48', '#A6F4C5', '#A6F4C5', '#D1FADF'],
    [ICONS_ALIASES.calendar]: ['#027A48', '#A6F4C5', '#A6F4C5', '#D1FADF'],
    [ICONS_ALIASES.accuracy]: ['#027A48', '#A6F4C5', '#A6F4C5', '#D1FADF'],
    [ICONS_ALIASES.cancel]: ['#027A48', '#A6F4C5', '#A6F4C5', '#D1FADF'],
    [ICONS_ALIASES.stopWatch]: ['#027A48', '#A6F4C5', '#A6F4C5', '#D1FADF'],
    [ICONS_ALIASES.reduceStress]: ['#027A48', '#A6F4C5', '#A6F4C5', '#D1FADF'],
    [ICONS_ALIASES.diamondMedal]: ['#039855', '#6CE9A6', '#A6F4C5', '#ECFDF3'],
    [ICONS_ALIASES.save50]: ['#039855', '#6CE9A6'],
    [ICONS_ALIASES.taskList]: ['#039855', '#6CE9A6', '#A6F4C5', '#ECFDF3'],
    [ICONS_ALIASES.expenses]: ['#039855', '#6CE9A6', '#A6F4C5', '#ECFDF3'],
    [ICONS_ALIASES.shield]: ['#039855', '#6CE9A6', '#A6F4C5', '#ECFDF3'],
    [ICONS_ALIASES.stamp]: ['#039855', '#6CE9A6', '#A6F4C5', '#ECFDF3'],
    [ICONS_ALIASES.seoReport]: ['#039855', '#6CE9A6', '#A6F4C5', '#ECFDF3'],
    [ICONS_ALIASES.fee]: ['#039855', '#6CE9A6', '#A6F4C5', '#ECFDF3'],
    [ICONS_ALIASES.certificate]: ['#039855', '#6CE9A6', '#A6F4C5', '#ECFDF3'],
    [ICONS_ALIASES.insurance]: ['#039855', '#6CE9A6', '#A6F4C5', '#ECFDF3'],
  },
  orange: {
    [ICONS_ALIASES.certificate]: ['#FB6514', '#FEB273', '#FEB273', '#FFEAD5'],
    [ICONS_ALIASES.feedback]: ['#FB6514', '#FEB273', '#FEB273', '#FFEAD5'],
    [ICONS_ALIASES.piggyBank]: ['#FB6514', '#FEB273', '#FEB273', '#FFEAD5'],
    [ICONS_ALIASES.question]: ['#FB6514', '#FEB273', '#FDDCAB', 'white'],
    [ICONS_ALIASES.reduceCost]: ['#FB6514', '#FEB273', '#FEB273', '#FFEAD5'],
    [ICONS_ALIASES.report]: ['#FB6514', '#FEB273', '#FEB273', '#FFEAD5'],
    [ICONS_ALIASES.seoReport]: ['#FB6514', '#FEB273', '#FEB273', '#FFEAD5'],
    [ICONS_ALIASES.quality]: ['#FB6514', '#FEB273', '#FEB273', '#FFEAD5'],
    [ICONS_ALIASES.vote]: ['#FB6514', '#FEB273', '#FEB273', '#FFEAD5'],
    [ICONS_ALIASES.phoneAndClock]: ['#FC6919', '#FFB071', '#FFBA81', '#FFE9D4'],
    [ICONS_ALIASES.guard]: ['#FC6919', '#FFB071', '#FFBA81', '#FFE9D4'],
    [ICONS_ALIASES.accuracy]: ['#FC6919', '#FFB071', '#FFBA81', '#FFE9D4'],
    [ICONS_ALIASES.award]: ['#FC6919', '#FFB071', '#FFBA81', '#FFE9D4'],
    [ICONS_ALIASES.adaptation]: ['#FC6919', '#FFB071', '#FFBA81', '#FFE9D4'],
  },
  gray: {
    [ICONS_ALIASES.filingCalendar]: ['#475467', '#EAECF0', '#D0D5DD', 'white'],
    [ICONS_ALIASES.filingWebsite]: ['#475467', '#EAECF0', '#D0D5DD', 'white'],
    [ICONS_ALIASES.filingUpdateGear]: [
      '#475467',
      '#EAECF0',
      '#D0D5DD',
      'white',
    ],
    [ICONS_ALIASES.filingReview]: ['#475467', '#EAECF0', '#D0D5DD', 'white'],
    [ICONS_ALIASES.goldMedal]: ['#475467', '#EAECF0', '#D0D5DD', 'white'],
  },
};
