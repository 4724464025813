import { createSlice } from '@reduxjs/toolkit';
import { MODALS_KEYS } from '_constants/modals';

const modalsSlice = createSlice({
  name: 'modals',
  initialState: {
    visibility: {
      [MODALS_KEYS.paymentFailed]: false,
      [MODALS_KEYS.packageEdit]: false,
      [MODALS_KEYS.bundleEdit]: false,
      [MODALS_KEYS.packageUpgrade]: false,
      [MODALS_KEYS.productRemovalConfirm]: false,
      [MODALS_KEYS.residentAgentRemovalConfirmPrimary]: false,
      [MODALS_KEYS.residentAgentRemovalConfirmSecondary]: false,
    },
  },
  reducers: {
    showModal(state, action) {
      state.visibility = {
        ...state.visibility,
        [action.payload]: true,
      };
    },
    hideModal(state, action) {
      state.visibility = {
        ...state.visibility,
        [action.payload]: false,
      };
    },
  },
});

export const { showModal, hideModal } = modalsSlice.actions;

export default modalsSlice.reducer;
