import PropTypes from 'prop-types';

const QuestionSvg = ({ width, height, palette = [] }) => {
  return (
    <svg
      width={`${width || 70}`}
      height={`${height || 70}`}
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_488_11094)">
        <path
          d="M63.8475 28.7109H41.3439C40.5288 28.7109 39.7898 29.1955 39.4634 29.9426C37.0701 35.44 32.1294 39.4415 26.2475 40.647C25.2942 40.8434 24.6093 41.6824 24.6093 42.6557V55.6443C24.6093 59.0369 27.3691 61.7967 30.7617 61.7967H50.5569L58.1592 69.3989C58.7512 69.9909 59.6385 70.1589 60.3942 69.8435C61.1612 69.5271 61.66 68.778 61.66 67.949V61.7967H63.8475C67.24 61.7967 69.9998 59.0369 69.9998 55.6443V34.8632C69.9998 31.4707 67.24 28.7109 63.8475 28.7109ZM26.6601 24.6093V23.5098C29.1636 22.0618 30.7617 19.3643 30.7617 16.4062C30.7617 11.8821 27.0807 8.20312 22.5586 8.20312C18.0365 8.20312 14.3555 11.8821 14.3555 16.4062C14.3555 18.6673 16.1939 20.5078 18.457 20.5078V24.6093C18.457 26.8704 20.2955 28.7109 22.5586 28.7109C24.8217 28.7109 26.6601 26.8704 26.6601 24.6093ZM26.6601 32.8124C26.6601 30.5514 24.8217 28.7109 22.5586 28.7109C20.2955 28.7109 18.457 30.5514 18.457 32.8124C18.457 35.0735 20.2955 36.9139 22.5586 36.9139C24.8217 36.9139 26.6601 35.0735 26.6601 32.8124Z"
          fill="url(#paint0_linear_488_11094)"
        />
        <path
          d="M22.5585 0C10.1197 0 0 10.1197 0 22.5585C0 27.2448 1.41585 31.7149 4.10155 35.5301V47.1678C4.10155 47.9968 4.60016 48.7459 5.36728 49.0623C6.12306 49.3777 7.01036 49.2097 7.60235 48.6177L13.1558 43.0642C16.0998 44.4281 19.254 45.117 22.5585 45.117C35.0256 45.117 45.117 34.9781 45.117 22.5585C45.117 10.1197 34.9973 0 22.5585 0V0ZM22.5582 34.8631C21.4257 34.8631 20.5075 33.9449 20.5075 32.8124C20.5075 31.6795 21.4257 30.7616 22.5582 30.7616C23.6908 30.7616 24.609 31.6795 24.609 32.8124C24.609 33.9449 23.6908 34.8631 22.5582 34.8631ZM24.6093 22.2081V24.6093C24.6093 25.7428 23.692 26.6601 22.5585 26.6601C21.425 26.6601 20.5077 25.7428 20.5077 24.6093V20.5077C20.5077 19.3742 21.425 18.457 22.5585 18.457C23.69 18.457 24.6093 17.5377 24.6093 16.4062C24.6093 15.2747 23.69 14.3554 22.5585 14.3554C21.427 14.3554 20.5077 15.2747 20.5077 16.4062C20.5077 17.5397 19.5905 18.457 18.457 18.457C17.3234 18.457 16.4062 17.5397 16.4062 16.4062C16.4062 13.0137 19.166 10.2539 22.5585 10.2539C25.951 10.2539 28.7108 13.0137 28.7108 16.4062C28.7108 19.0798 26.9965 21.3609 24.6093 22.2081ZM39.1011 45.2537C37.9686 45.2537 37.0504 46.1719 37.0504 47.3045C37.0504 48.4371 37.9686 49.3553 39.1011 49.3553C40.2337 49.3553 41.1519 48.4371 41.1519 47.3045C41.1519 46.1719 40.2337 45.2537 39.1011 45.2537ZM47.3042 45.2537C46.1717 45.2537 45.2535 46.1719 45.2535 47.3045C45.2535 48.4371 46.1717 49.3553 47.3042 49.3553C48.4368 49.3553 49.355 48.4371 49.355 47.3045C49.355 46.1719 48.4368 45.2537 47.3042 45.2537ZM55.5073 45.2537C54.3747 45.2537 53.4565 46.1719 53.4565 47.3045C53.4565 48.4371 54.3747 49.3553 55.5073 49.3553C56.6399 49.3553 57.5581 48.4371 57.5581 47.3045C57.5581 46.1719 56.6399 45.2537 55.5073 45.2537Z"
          fill="url(#paint1_linear_488_11094)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_488_11094"
          x1="42.1776"
          y1="70"
          x2="42.1776"
          y2="8.20312"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[2]} />
          <stop offset="1" stopColor={palette[3]} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_488_11094"
          x1="28.779"
          y1="49.3553"
          x2="28.779"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[0]} />
          <stop offset="1" stopColor={palette[1]} />
        </linearGradient>
        <clipPath id="clip0_488_11094">
          <rect width="70" height="70" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

QuestionSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  palette: PropTypes.arrayOf(PropTypes.string),
};

export default QuestionSvg;
