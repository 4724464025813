import PropTypes from 'prop-types';

const OnlinePaymentSvg = ({ width, height, palette = [] }) => {
  return (
    <svg
      width={`${width || 70}`}
      height={`${height || 70}`}
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_637_8611)">
        <path
          d="M67.9492 8.33984H59.6094V2.05078H10.3906V8.33984H2.05078C0.917246 8.33984 0 9.25709 0 10.3906V55.6445C0 56.7781 0.917246 57.6953 2.05078 57.6953H31.168L35.0108 61.7969L38.832 57.6953H67.9492C69.0828 57.6953 70 56.7781 70 55.6445V10.3906C70 9.25709 69.0828 8.33984 67.9492 8.33984Z"
          fill="url(#paint0_linear_637_8611)"
        />
        <path
          d="M38.832 57.6953H31.168L25.0905 66.8117C24.6719 67.4406 24.6319 68.2497 24.9884 68.9166C25.3448 69.5835 26.0399 70 26.7969 70H43.2031C43.9601 70 44.6551 69.5834 45.0116 68.9165C45.3682 68.2496 45.328 67.4405 44.9095 66.8116L38.832 57.6953ZM47.3047 41.1523H22.6953C21.5618 41.1523 20.6445 42.0696 20.6445 43.2031C20.6445 44.3367 21.5618 45.2539 22.6953 45.2539H47.3047C48.4382 45.2539 49.3555 44.3367 49.3555 43.2031C49.3555 42.0696 48.4382 41.1523 47.3047 41.1523ZM59.6094 0H10.3906C9.25709 0 8.33984 0.917246 8.33984 2.05078V35C8.33984 36.1335 9.25709 37.0508 10.3906 37.0508H59.6094C60.7429 37.0508 61.6602 36.1335 61.6602 35V2.05078C61.6602 0.917246 60.7429 0 59.6094 0ZM16.543 10.3906C16.543 9.25709 17.4602 8.33984 18.5938 8.33984H30.8984C32.032 8.33984 32.9492 9.25709 32.9492 10.3906V14.4922C32.9492 15.6257 32.032 16.543 30.8984 16.543H18.5938C17.4602 16.543 16.543 15.6257 16.543 14.4922V10.3906ZM24.3355 28.8477H18.5938C17.4602 28.8477 16.543 27.9304 16.543 26.7969C16.543 25.6633 17.4602 24.7461 18.5938 24.7461H24.3355C25.4691 24.7461 26.3863 25.6633 26.3863 26.7969C26.3863 27.9304 25.4691 28.8477 24.3355 28.8477ZM37.8719 28.8477H32.5387C31.4051 28.8477 30.4879 27.9304 30.4879 26.7969C30.4879 25.6633 31.4051 24.7461 32.5387 24.7461H37.8719C39.0055 24.7461 39.9227 25.6633 39.9227 26.7969C39.9227 27.9304 39.0055 28.8477 37.8719 28.8477ZM51.4062 28.8477H46.075C44.9415 28.8477 44.0243 27.9304 44.0243 26.7969C44.0243 25.6633 44.9415 24.7461 46.075 24.7461H51.4062C52.5398 24.7461 53.457 25.6633 53.457 26.7969C53.457 27.9304 52.5398 28.8477 51.4062 28.8477ZM51.4062 12.4414H43.2031C42.0696 12.4414 41.1523 11.5242 41.1523 10.3906C41.1523 9.25709 42.0696 8.33984 43.2031 8.33984H51.4062C52.5398 8.33984 53.457 9.25709 53.457 10.3906C53.457 11.5242 52.5398 12.4414 51.4062 12.4414Z"
          fill="url(#paint1_linear_637_8611)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_637_8611"
          x1="35"
          y1="61.7969"
          x2="35"
          y2="2.05078"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[2]} />
          <stop offset="1" stopColor={palette[3]} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_637_8611"
          x1="35"
          y1="70"
          x2="35"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[0]} />
          <stop offset="1" stopColor={palette[1]} />
        </linearGradient>
        <clipPath id="clip0_637_8611">
          <rect width="70" height="70" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

OnlinePaymentSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  palette: PropTypes.arrayOf(PropTypes.string),
};

export default OnlinePaymentSvg;
