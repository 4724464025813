import PropTypes from 'prop-types';

const SearchZoomInSvg = ({ color, width, height }) => {
  return (
    <svg
      width={`${width || 50}`}
      height={`${height || 50}`}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M23.9577 43.7503C34.8883 43.7503 43.7493 34.8893 43.7493 23.9587C43.7493 13.028 34.8883 4.16699 23.9577 4.16699C13.027 4.16699 4.16602 13.028 4.16602 23.9587C4.16602 34.8893 13.027 43.7503 23.9577 43.7503Z"
        fill={color}
      />
      <path
        d="M44.3743 45.8337C43.9993 45.8337 43.6243 45.6878 43.3535 45.417L39.4785 41.542C38.916 40.9795 38.916 40.0628 39.4785 39.4795C40.041 38.917 40.9577 38.917 41.541 39.4795L45.416 43.3545C45.9785 43.917 45.9785 44.8337 45.416 45.417C45.1243 45.6878 44.7493 45.8337 44.3743 45.8337Z"
        fill={color}
      />
      <path
        d="M29.5827 22.8128H25.9368V19.167C25.9368 18.3128 25.2285 17.6045 24.3743 17.6045C23.5202 17.6045 22.8118 18.3128 22.8118 19.167V22.8128H19.166C18.3118 22.8128 17.6035 23.5212 17.6035 24.3753C17.6035 25.2295 18.3118 25.9378 19.166 25.9378H22.8118V29.5837C22.8118 30.4378 23.5202 31.1462 24.3743 31.1462C25.2285 31.1462 25.9368 30.4378 25.9368 29.5837V25.9378H29.5827C30.4368 25.9378 31.1452 25.2295 31.1452 24.3753C31.1452 23.5212 30.4368 22.8128 29.5827 22.8128Z"
        fill={color}
      />
    </svg>
  );
};

SearchZoomInSvg.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default SearchZoomInSvg;
