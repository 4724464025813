import PropTypes from 'prop-types';

const WarningSvg = ({ color, width, height }) => {
  return (
    <svg
      width={`${width || 16}`}
      height={`${height || 16}`}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.26795 3C7.03775 1.66667 8.96225 1.66667 9.73205 3L13.1962 9C13.966 10.3333 13.0037 12 11.4641 12H4.5359C2.9963 12 2.03405 10.3333 2.80385 9L6.26795 3Z"
        fill={color}
      />
      <path
        d="M8.28723 8.99H7.71723L7.46723 3.86H8.53723L8.28723 8.99ZM7.37723 10.46C7.37723 10.2133 7.43723 10.04 7.55723 9.94C7.67723 9.84 7.82389 9.79 7.99723 9.79C8.16389 9.79 8.30723 9.84 8.42723 9.94C8.55389 10.04 8.61723 10.2133 8.61723 10.46C8.61723 10.7 8.55389 10.8733 8.42723 10.98C8.30723 11.0867 8.16389 11.14 7.99723 11.14C7.82389 11.14 7.67723 11.0867 7.55723 10.98C7.43723 10.8733 7.37723 10.7 7.37723 10.46Z"
        fill="white"
      />
    </svg>
  );
};

WarningSvg.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default WarningSvg;
