import PropTypes from 'prop-types';

const ProductIncrementSvg = ({ width, height, palette = [] }) => {
  return (
    <svg
      width={`${width || 70}`}
      height={`${height || 70}`}
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_504_12928)">
        <path
          d="M67.949 41.2598H61.7968C59.5315 41.2598 57.6953 43.096 57.6953 45.3613V50.6644L57.0944 50.0635C56.2934 49.2625 54.9955 49.2625 54.1945 50.0635L53.5936 50.6644V45.3613C53.5936 43.096 51.7572 41.2598 49.4921 41.2598H43.3398C42.2073 41.2598 41.2891 42.178 41.2891 43.3105V67.9196C41.2891 69.0522 42.2073 69.9704 43.3398 69.9704H67.9489C69.0815 69.9704 69.9997 69.0522 69.9997 67.9196V43.3105C69.9998 42.178 69.0817 41.2598 67.949 41.2598Z"
          fill="url(#paint0_linear_504_12928)"
        />
        <path
          d="M49.4924 41.26V55.6153C49.4924 56.4443 49.993 57.1934 50.7581 57.5097C51.5151 57.8261 52.4043 57.6519 52.9931 57.0651L55.6448 54.4135L58.2964 57.0651C58.893 57.6618 59.7858 57.8234 60.5315 57.5097C61.2965 57.1934 61.7972 56.4443 61.7972 55.6153V41.26H49.4924ZM32.4685 17.5641C31.7075 16.4226 29.8169 16.4226 29.0559 17.5641L20.8529 29.8687C20.4323 30.4976 20.3923 31.3067 20.7528 31.9736C21.1092 32.6405 21.8022 33.0571 22.5592 33.0571H24.61V51.5139C24.61 52.6474 25.5272 53.5646 26.6607 53.5646H34.8638C35.9973 53.5646 36.9145 52.6474 36.9145 51.5139V33.0571H38.9653C39.7223 33.0571 40.4152 32.6405 40.7717 31.9736C41.1323 31.3067 41.0922 30.4976 40.6717 29.8687L32.4685 17.5641ZM57.422 34.8343V16.3774H59.4727C60.2297 16.3774 60.9226 15.9609 61.2792 15.294C61.6397 14.627 61.5996 13.818 61.1791 13.1891L52.9761 0.884512C52.2151 -0.257077 50.3244 -0.257077 49.5635 0.884512L41.3604 13.1891C40.9399 13.818 40.8998 14.627 41.2604 15.294C41.6168 15.9609 42.3098 16.3774 43.0668 16.3774H45.1176V34.8343C45.1176 35.9678 46.0348 36.885 47.1683 36.885H55.3713C56.5047 36.885 57.422 35.9678 57.422 34.8343ZM11.9609 33.9702C11.2 32.8286 9.30929 32.8286 8.54832 33.9702L0.345293 46.2747C-0.0752488 46.9036 -0.115307 47.7127 0.245216 48.3796C0.601638 49.0465 1.29466 49.4631 2.05166 49.4631H4.10242V67.9199C4.10242 69.0535 5.01965 69.9707 6.15317 69.9707H14.3562C15.4897 69.9707 16.407 69.0535 16.407 67.9199V49.4631H18.4577C19.2147 49.4631 19.9076 49.0465 20.2642 48.3796C20.6247 47.7127 20.5846 46.9036 20.1641 46.2747L11.9609 33.9702Z"
          fill="url(#paint1_linear_504_12928)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_504_12928"
          x1="55.6444"
          y1="69.9704"
          x2="55.6444"
          y2="41.2598"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[2]} />
          <stop offset="1" stopColor={palette[3]} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_504_12928"
          x1="30.8986"
          y1="69.9707"
          x2="30.8986"
          y2="0.0283203"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[0]} />
          <stop offset="1" stopColor={palette[1]} />
        </linearGradient>
        <clipPath id="clip0_504_12928">
          <rect width="70" height="70" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

ProductIncrementSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  palette: PropTypes.arrayOf(PropTypes.string),
};

export default ProductIncrementSvg;
