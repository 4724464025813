import PropTypes from 'prop-types';

const RadioOnSvg = ({ color, width, height }) => {
  return (
    <svg
      width={`${width || 26}`}
      height={`${height || 26}`}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="13" cy="13" r="5" fill={color} />
      <rect x="1" y="1" width="24" height="24" rx="12" stroke={color} />
    </svg>
  );
};

RadioOnSvg.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default RadioOnSvg;
