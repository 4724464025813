import PropTypes from 'prop-types';

const InfoSvg = ({ color, width, height }) => {
  return (
    <svg
      width={`${width || 25}`}
      height={`${height || 25}`}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M12.5 22.5C18.0228 22.5 22.5 18.0228 22.5 12.5C22.5 6.97715 18.0228 2.5 12.5 2.5C6.97715 2.5 2.5 6.97715 2.5 12.5C2.5 18.0228 6.97715 22.5 12.5 22.5Z"
        fill={color}
      />
      <path
        d="M12.5 14.25C12.91 14.25 13.25 13.91 13.25 13.5V8.5C13.25 8.09 12.91 7.75 12.5 7.75C12.09 7.75 11.75 8.09 11.75 8.5V13.5C11.75 13.91 12.09 14.25 12.5 14.25Z"
        fill={color}
      />
      <path
        d="M13.42 16.1199C13.37 15.9999 13.3 15.8899 13.21 15.7899C13.11 15.6999 13 15.6299 12.88 15.5799C12.64 15.4799 12.36 15.4799 12.12 15.5799C12 15.6299 11.89 15.6999 11.79 15.7899C11.7 15.8899 11.63 15.9999 11.58 16.1199C11.53 16.2399 11.5 16.3699 11.5 16.4999C11.5 16.6299 11.53 16.7599 11.58 16.8799C11.63 17.0099 11.7 17.1099 11.79 17.2099C11.89 17.2999 12 17.3699 12.12 17.4199C12.24 17.4699 12.37 17.4999 12.5 17.4999C12.63 17.4999 12.76 17.4699 12.88 17.4199C13 17.3699 13.11 17.2999 13.21 17.2099C13.3 17.1099 13.37 17.0099 13.42 16.8799C13.47 16.7599 13.5 16.6299 13.5 16.4999C13.5 16.3699 13.47 16.2399 13.42 16.1199Z"
        fill={color}
      />
    </svg>
  );
};

InfoSvg.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default InfoSvg;
