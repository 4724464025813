import PropTypes from 'prop-types';

const GallerySvg = ({ color, width, height }) => {
  return (
    <svg
      width={`${width || 36}`}
      height={`${height || 36}`}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M33 11.715V20.85L30.555 18.75C29.385 17.745 27.495 17.745 26.325 18.75L20.085 24.105C18.915 25.11 17.025 25.11 15.855 24.105L15.345 23.685C14.28 22.755 12.585 22.665 11.385 23.475L4.005 28.425L3.84 28.545C3.285 27.345 3 25.92 3 24.285V11.715C3 6.255 6.255 3 11.715 3H24.285C29.745 3 33 6.255 33 11.715Z"
        fill={color}
      />
      <path
        d="M13.4997 15.5697C15.4713 15.5697 17.0697 13.9713 17.0697 11.9997C17.0697 10.028 15.4713 8.42969 13.4997 8.42969C11.528 8.42969 9.92969 10.028 9.92969 11.9997C9.92969 13.9713 11.528 15.5697 13.4997 15.5697Z"
        fill={color}
      />
      <path
        d="M32.9998 20.8498V24.2848C32.9998 29.7448 29.7448 32.9998 24.2848 32.9998H11.7148C7.88984 32.9998 5.12984 31.3948 3.83984 28.5448L4.00484 28.4248L11.3848 23.4748C12.5848 22.6648 14.2798 22.7548 15.3448 23.6848L15.8548 24.1048C17.0248 25.1098 18.9148 25.1098 20.0848 24.1048L26.3248 18.7498C27.4948 17.7448 29.3848 17.7448 30.5548 18.7498L32.9998 20.8498Z"
        fill={color}
      />
    </svg>
  );
};

GallerySvg.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default GallerySvg;
