import { configureStore } from '@reduxjs/toolkit';
import { createWrapper } from 'next-redux-wrapper';

import { config } from '_config';

import generalSlice from './general/slice';
import locationSlice from './location/slice';
import modalsSlice from './modals/slice';
import orderSlice from './order/slice';
import paymentSlice from './payment/slice';
import upsellSlice from './upsell/slice';
import productsSlice from './products/slice';
import variantsSlice from './variants/slice';
import contactUsSlice from './contactUs/slice';
import tipsSlice from './tips/slice';

const makeStore = () =>
  configureStore({
    reducer: {
      general: generalSlice,
      location: locationSlice,
      modals: modalsSlice,
      order: orderSlice,
      payment: paymentSlice,
      upsell: upsellSlice,
      products: productsSlice,
      variants: variantsSlice,
      contactUs: contactUsSlice,
      tips: tipsSlice,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }),
    devTools: config.devtools === 'true',
  });

export const wrapper = createWrapper(makeStore);
