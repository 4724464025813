import PropTypes from 'prop-types';

const VoteSvg = ({ width, height, palette = [] }) => {
  return (
    <svg
      width={`${width || 70}`}
      height={`${height || 70}`}
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_624_12010)">
        <path
          d="M20.931 16.543H49.0692C49.9244 16.543 50.6894 16.0122 50.9898 15.2131C51.2902 14.4121 51.0619 13.5108 50.419 12.9481L39.0536 1.53004C36.7023 -0.512677 33.2938 -0.508712 30.9425 1.53414L19.581 12.9481C18.9381 13.5108 18.7098 14.4121 19.0102 15.2131C19.3107 16.0122 20.0758 16.543 20.931 16.543ZM66.5052 26.891L56.8535 18.6057C56.2468 18.077 55.3875 17.9528 54.6585 18.2852C53.9256 18.6177 53.4569 19.3486 53.4569 20.1517V42.0413L36.0173 52.4877C35.4085 52.8603 34.5874 52.8582 33.9626 52.4777L16.543 42.0416V20.1518C16.543 19.3487 16.0743 18.6177 15.3413 18.2853C14.6084 17.953 13.7551 18.0771 13.1463 18.6058L3.48264 26.9011C1.26971 28.8577 0 31.6756 0 34.6316V63.8477C0 67.2402 2.7598 70 6.15234 70H63.8477C67.2402 70 70 67.2402 70 63.8477V34.6316C70 31.6756 68.7303 28.8577 66.5052 26.891ZM22.6953 39.1016C22.6953 41.3626 24.5338 43.2031 26.7969 43.2031H43.2031C45.4662 43.2031 47.3047 41.3626 47.3047 39.1016C47.3047 36.8405 45.4662 35 43.2031 35H32.9492H26.7969C24.5338 35 22.6953 36.8405 22.6953 39.1016ZM44.0523 25.5953C45.6504 23.9972 45.6504 21.3936 44.0523 19.7954C43.2793 19.0203 42.2458 18.5938 41.1523 18.5938C40.0589 18.5938 39.0254 19.0203 38.2524 19.7954L32.9492 25.0986L31.7476 23.897C30.1479 22.2931 27.5458 22.2947 25.9477 23.897C24.3415 25.5074 24.3477 28.0926 25.9477 29.6968L30.0493 33.7984C30.8223 34.5735 31.8557 35 32.9492 35C34.0427 35 35.0762 34.5735 35.8492 33.7984L44.0523 25.5953Z"
          fill="url(#paint0_linear_624_12010)"
        />
        <path
          d="M51.4062 12.4414H18.5938C15.2011 12.4414 12.4414 15.2012 12.4414 18.5938V43.2031C12.4414 43.924 12.82 44.591 13.4388 44.9615L31.8358 55.9844C32.7991 56.5752 33.9046 56.8697 35.006 56.8697C36.1015 56.8697 37.195 56.5772 38.1443 55.9945L56.5612 44.9616C57.18 44.5911 57.5586 43.9242 57.5586 43.2033V18.5938C57.5586 15.2012 54.7989 12.4414 51.4062 12.4414ZM27.3978 25.347C28.1988 24.5459 29.4967 24.5459 30.2977 25.347L32.9492 27.9985L39.7024 21.2453C40.5035 20.4442 41.8013 20.4442 42.6024 21.2453C43.4034 22.0463 43.4034 23.3442 42.6024 24.1452L34.3993 32.3483C33.9987 32.7489 33.4739 32.9492 32.9494 32.9492C32.4248 32.9492 31.8999 32.7489 31.4995 32.3483L27.3979 28.2468C26.5966 27.4457 26.5966 26.148 27.3978 25.347ZM43.2031 41.1523H26.7969C25.6633 41.1523 24.7461 40.2351 24.7461 39.1016C24.7461 37.968 25.6633 37.0508 26.7969 37.0508H43.2031C44.3367 37.0508 45.2539 37.968 45.2539 39.1016C45.2539 40.2351 44.3367 41.1523 43.2031 41.1523Z"
          fill="url(#paint1_linear_624_12010)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_624_12010"
          x1="35"
          y1="70"
          x2="35"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[0]} />
          <stop offset="1" stopColor={palette[1]} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_624_12010"
          x1="35"
          y1="56.8697"
          x2="35"
          y2="12.4414"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[2]} />
          <stop offset="1" stopColor={palette[3]} />
        </linearGradient>
        <clipPath id="clip0_624_12010">
          <rect width="70" height="70" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

VoteSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  palette: PropTypes.arrayOf(PropTypes.string),
};

export default VoteSvg;
