import PropTypes from 'prop-types';

const CloseFilledSvg = ({ color, width, height }) => {
  return (
    <svg
      width={`${width || 37}`}
      height={`${height || 36}`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2Z"
        fill={color}
      />
      <path
        d="M13.0594 12.0004L15.3594 9.70035C15.6494 9.41035 15.6494 8.93035 15.3594 8.64035C15.0694 8.35035 14.5894 8.35035 14.2994 8.64035L11.9994 10.9404L9.69937 8.64035C9.40937 8.35035 8.92937 8.35035 8.63938 8.64035C8.34938 8.93035 8.34938 9.41035 8.63938 9.70035L10.9394 12.0004L8.63938 14.3004C8.34938 14.5904 8.34938 15.0704 8.63938 15.3604C8.78938 15.5104 8.97937 15.5804 9.16937 15.5804C9.35937 15.5804 9.54937 15.5104 9.69937 15.3604L11.9994 13.0604L14.2994 15.3604C14.4494 15.5104 14.6394 15.5804 14.8294 15.5804C15.0194 15.5804 15.2094 15.5104 15.3594 15.3604C15.6494 15.0704 15.6494 14.5904 15.3594 14.3004L13.0594 12.0004Z"
        fill={color}
      />
    </svg>
  );
};

CloseFilledSvg.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default CloseFilledSvg;
