import PropTypes from 'prop-types';

const LinkedinSvg = ({ color, width, height }) => {
  return (
    <svg
      width={`${width || 24}`}
      height={`${height || 24}`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.88448 5.76897C4.47754 5.76897 5.76897 4.47754 5.76897 2.88448C5.76897 1.29143 4.47754 0 2.88448 0C1.29143 0 0 1.29143 0 2.88448C0 4.47754 1.29143 5.76897 2.88448 5.76897Z"
        fill={color}
      />
      <path
        d="M8.49263 7.95507V23.9582H13.4614V16.0443C13.4614 13.9561 13.8542 11.9338 16.4434 11.9338C18.997 11.9338 19.0286 14.3212 19.0286 16.1761V23.9595H24V15.1834C24 10.8725 23.0719 7.55957 18.0333 7.55957C15.6142 7.55957 13.9927 8.88712 13.3295 10.1435H13.2623V7.95507H8.49263V7.95507ZM0.395508 7.95507H5.37217V23.9582H0.395508V7.95507Z"
        fill={color}
      />
    </svg>
  );
};

LinkedinSvg.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default LinkedinSvg;
