import React from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { config } from '_config';
import { theme } from '_constants/theme';
import { ICONS_ALIASES } from '_constants/icons';
import { ROUTES } from '_constants/routes';

import Navbar from './Navbar';
import Burger from './Burger';
import PhoneItem from './PhoneItem';
import Button from '_components/atomic/Button';
import Icon from '_components/atomic/Icon';

const { colors } = theme;

const StyledNavMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;

  .nav-logo {
    img {
      height: 36px;
      margin-right: 18px;
    }
  }

  .nav-menu {
    display: flex;
    flex: auto;
    justify-content: space-between;
    align-items: center;

    a {
      display: block;

      padding-left: 12px;

      font-family: 'Aeonik';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: ${colors.gray[500]};

      text-decoration: none;
      white-space: nowrap;

      &:hover {
        text-decoration: none;
      }
    }

    a.active {
      color: ${colors.indigo[600]};
    }
  }

  .mobile-nav-menu {
    display: none;

    a {
      text-decoration: none;
      white-space: nowrap;

      &:hover {
        text-decoration: none;
      }
    }
  }

  @media ${({ theme }) => theme.devices.laptopS} {
    padding-right: 4%;
    padding-left: 4%;
  }

  @media ${({ theme }) => theme.devices.tablet} {
    padding-right: 16px;
    padding-left: 16px;
    padding-top: 10px;
    padding-bottom: 10px;

    .nav-menu {
      display: none;
    }

    .nav-logo {
      img {
        height: 25px;
        margin-right: 0px;
      }
    }

    .mobile-nav-menu {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-grow: 1;
    }
  }
`;

function NavMenu({ open, handleOpen }) {
  const router = useRouter();
  const isFiling =
    router.pathname.includes('-filing') ||
    router.pathname.includes(ROUTES.payment);

  return (
    <StyledNavMenu>
      <div className="nav-logo">
        <Link href="/">
          <picture>
            <source
              srcSet={`${config.basePath}/images/logo.svg`}
              type="image/svg+xml"
            />
            <img
              src={`${config.basePath}/images/logo.svg`}
              title="Incorpified Logo"
              alt="Incorpified Logo"
            />
          </picture>
        </Link>
      </div>

      <div className="nav-menu">
        {isFiling ? <div /> : <Navbar />}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <PhoneItem />
          <Link href={config.membershipUrl}>
            <Button
              prefix={
                <Icon
                  alias={ICONS_ALIASES.lock}
                  width={20}
                  height={20}
                  color={theme.colors.white}
                />
              }
              color={colors.indigo[600]}
            >
              Client Portal
            </Button>
          </Link>
        </div>
      </div>
      <div className="mobile-nav-menu">
        <Link href={config.membershipUrl}>
          <Button
            prefix={
              <Icon
                alias={ICONS_ALIASES.lock}
                width={20}
                height={20}
                color={theme.colors.white}
              />
            }
            color={colors.indigo[600]}
          >
            Client Portal
          </Button>
        </Link>
        {!isFiling && <Burger open={open} handleOpen={handleOpen} />}
      </div>
    </StyledNavMenu>
  );
}

export default NavMenu;
