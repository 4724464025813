import PropTypes from 'prop-types';

const CheckSvg = ({ color, width, height }) => {
  return (
    <svg
      width={`${width || 18}`}
      height={`${height || 19}`}
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M15.9925 6.35613V6.3575V12.6425C15.9925 13.9081 15.6172 14.8582 14.9839 15.4914C14.3507 16.1247 13.4006 16.5 12.135 16.5H5.8575C4.59195 16.5 3.64196 16.1247 3.00877 15.4907C2.37552 14.8567 2 13.9047 2 12.635V6.3575C2 5.09194 2.3753 4.1418 3.00855 3.50855C3.6418 2.8753 4.59194 2.5 5.8575 2.5H12.1425C13.4082 2.5 14.3581 2.87535 14.9903 3.50833C15.6224 4.14121 15.996 5.09088 15.9925 6.35613Z"
        fill={color}
        stroke={color}
      />
      <path
        d="M7.93508 12.185C7.78508 12.185 7.64258 12.125 7.53758 12.02L5.41508 9.8975C5.19758 9.68 5.19758 9.32 5.41508 9.1025C5.63258 8.885 5.99258 8.885 6.21008 9.1025L7.93508 10.8275L11.7901 6.9725C12.0076 6.755 12.3676 6.755 12.5851 6.9725C12.8026 7.19 12.8026 7.55 12.5851 7.7675L8.33258 12.02C8.22758 12.125 8.08508 12.185 7.93508 12.185Z"
        fill={color}
      />
    </svg>
  );
};

CheckSvg.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default CheckSvg;
