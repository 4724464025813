import PropTypes from 'prop-types';

const ArrowLeftSvg = ({ color, width, height }) => {
  return (
    <svg
      width={`${width || 24}`}
      height={`${height || 24}`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        fill={color}
      />
      <path
        d="M15.5004 11.2499H10.3104L12.0304 9.52994C12.3204 9.23994 12.3204 8.75994 12.0304 8.46994C11.7404 8.17994 11.2604 8.17994 10.9704 8.46994L7.97043 11.4699C7.68043 11.7599 7.68043 12.2399 7.97043 12.5299L10.9704 15.5299C11.1204 15.6799 11.3104 15.7499 11.5004 15.7499C11.6904 15.7499 11.8804 15.6799 12.0304 15.5299C12.3204 15.2399 12.3204 14.7599 12.0304 14.4699L10.3104 12.7499H15.5004C15.9104 12.7499 16.2504 12.4099 16.2504 11.9999C16.2504 11.5899 15.9104 11.2499 15.5004 11.2499Z"
        fill={color}
      />
    </svg>
  );
};

ArrowLeftSvg.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default ArrowLeftSvg;
