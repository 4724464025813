import PropTypes from 'prop-types';

const SaveTimeSvg = ({ width, height, palette = [] }) => {
  return (
    <svg
      width={`${width || 70}`}
      height={`${height || 70}`}
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_624_7405)">
        <path
          d="M55.3711 35.1367C53.072 35.1367 51.2695 36.9392 51.2695 39.2383C51.2695 41.5373 53.072 43.3398 55.3711 43.3398C57.6702 43.3398 59.4727 41.5373 59.4727 39.2383C59.4727 36.9392 57.6702 35.1367 55.3711 35.1367ZM34.8633 26.9336C25.815 26.9336 18.457 34.2936 18.457 43.3398C18.457 52.3861 25.815 59.7461 34.8633 59.7461C43.9116 59.7461 51.2695 52.3861 51.2695 43.3398C51.2695 34.2936 43.9116 26.9336 34.8633 26.9336ZM7.61838 36.5967C5.47941 35.5834 4.10156 33.4004 4.10156 31.0352C4.10156 29.9016 3.18432 28.9844 2.05078 28.9844C0.917246 28.9844 0 29.9016 0 31.0352C0 34.9744 2.29906 38.6135 5.85594 40.3017C6.88598 40.7877 8.1051 40.3484 8.59168 39.3284C9.07635 38.305 8.63967 37.0814 7.61838 36.5967ZM34.8633 16.4062C39.3854 16.4062 43.0664 12.7273 43.0664 8.20312C43.0664 3.67896 39.3854 0 34.8633 0C30.3412 0 26.6602 3.67896 26.6602 8.20312C26.6602 12.7273 30.3412 16.4062 34.8633 16.4062Z"
          fill="url(#paint0_linear_624_7405)"
        />
        <path
          d="M67.9492 37.1875H64.7635C64.1074 34.8496 62.9999 32.5938 61.5234 30.502V18.7305C61.5234 17.5819 60.6212 16.6797 59.4727 16.6797C55.3711 16.6797 51.4335 18.4844 48.7266 21.5605C46.8397 21.0273 44.9533 20.7812 43.0664 20.7812H26.6602C14.2323 20.7812 4.10156 30.912 4.10156 43.3398C4.10156 51.8301 8.90025 59.5819 16.4062 63.3965V67.9492C16.4062 69.0975 17.3085 70 18.457 70H26.6602C27.8087 70 28.7109 69.0975 28.7109 67.9492V65.8984H41.0156V67.9492C41.0156 69.0975 41.9178 70 43.0664 70H51.2695C52.4181 70 53.3203 69.0975 53.3203 67.9492V63.3965C57.5451 61.2225 60.9902 57.8182 63.123 53.5938H67.9492C69.0978 53.5938 70 52.6913 70 51.543V39.2383C70 38.0897 69.0978 37.1875 67.9492 37.1875ZM34.8633 57.6953C26.9473 57.6953 20.5078 51.2559 20.5078 43.3398C20.5078 35.4238 26.9473 28.9844 34.8633 28.9844C42.7793 28.9844 49.2188 35.4238 49.2188 43.3398C49.2188 51.2559 42.7793 57.6953 34.8633 57.6953ZM55.3711 41.2891C54.2225 41.2891 53.3203 40.3866 53.3203 39.2383C53.3203 38.0897 54.2225 37.1875 55.3711 37.1875C56.5197 37.1875 57.4219 38.0897 57.4219 39.2383C57.4219 40.3866 56.5197 41.2891 55.3711 41.2891ZM38.9648 41.2891H36.9141V39.2383C36.9141 38.1047 35.9968 37.1875 34.8633 37.1875C33.7297 37.1875 32.8125 38.1047 32.8125 39.2383V43.3398C32.8125 44.4734 33.7297 45.3906 34.8633 45.3906H38.9648C40.0984 45.3906 41.0156 44.4734 41.0156 43.3398C41.0156 42.2063 40.0984 41.2891 38.9648 41.2891Z"
          fill="url(#paint1_linear_624_7405)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_624_7405"
          x1="29.7363"
          y1="59.7462"
          x2="29.7363"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[1]} />
          <stop offset="1" stopColor={palette[3]} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_624_7405"
          x1="37.0508"
          y1="70"
          x2="37.0508"
          y2="16.6797"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[0]} />
          <stop offset="1" stopColor={palette[2]} />
        </linearGradient>
        <clipPath id="clip0_624_7405">
          <rect width="70" height="70" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

SaveTimeSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  palette: PropTypes.arrayOf(PropTypes.string),
};

export default SaveTimeSvg;
