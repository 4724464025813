import PropTypes from 'prop-types';

const ShiningGemSvg = ({ width, height, palette = [] }) => {
  return (
    <svg
      width={`${width || 70}`}
      height={`${height || 70}`}
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath={`url(#clip0_268_16556${width + height})`}>
        <path
          d="M12.7376 19.906L16.5267 18.3359C17.5721 17.9033 18.0688 16.7017 17.6362 15.6562C17.1997 14.6128 16.0099 14.1142 14.9565 14.5467L11.1674 16.1168C10.122 16.5494 9.6253 17.751 10.0579 18.7965C10.4973 19.852 11.7007 20.3353 12.7376 19.906ZM55.043 5.77882L58.8322 4.20875C59.8775 3.77617 60.3742 2.57455 59.9416 1.52906C59.5051 0.487671 58.3154 -0.0150439 57.2619 0.419585L53.4728 1.98966C52.4274 2.42224 51.9307 3.62386 52.3633 4.66935C52.8026 5.72482 54.006 6.20812 55.043 5.77882ZM53.4728 18.3359L57.2619 19.906C58.3016 20.3364 59.5033 19.8495 59.9416 18.7965C60.3742 17.7511 59.8775 16.5494 58.8322 16.1168L55.043 14.5467C54.0095 14.1222 52.8 14.6068 52.3633 15.6562C51.9307 16.7017 52.4274 17.9033 53.4728 18.3359ZM11.1675 4.20875L14.9567 5.77882C15.9963 6.20921 17.198 5.72236 17.6363 4.66935C18.0689 3.624 17.5722 2.42224 16.5269 1.98966L12.7376 0.419585C11.7001 -0.00902829 10.4946 0.479604 10.0579 1.52906C9.6253 2.57455 10.122 3.77617 11.1675 4.20875ZM61.3154 42.2023L53.1123 29.8976C52.7318 29.3268 52.0908 28.9844 51.406 28.9844H18.5935C17.9085 28.9844 17.2677 29.3268 16.8873 29.8976L8.68413 42.2023C8.14341 43.0154 8.24759 44.0989 8.94048 44.7899L33.5499 69.3993C33.9504 69.7998 34.4752 70.0001 34.9998 70.0001C35.5243 70.0001 36.0492 69.7998 36.4497 69.3993L61.059 44.7899C61.7521 44.0989 61.8562 43.0154 61.3154 42.2023Z"
          fill={`url(#paint0_linear_268_16556${width + height})`}
        />
        <path
          d="M24.8501 19.0149C25.1305 19.852 25.9156 20.4168 26.7967 20.4168H43.203C44.0841 20.4168 44.8693 19.852 45.1496 19.0149L49.2511 6.71021C49.5114 5.92312 49.2752 5.05591 48.6463 4.51123C48.0215 3.96845 47.1362 3.85839 46.3873 4.22685L40.0187 7.41117L36.8343 1.04253C36.1373 -0.347349 33.8623 -0.347349 33.1654 1.04253L29.981 7.41117L23.6124 4.22685C22.8674 3.85839 21.9782 3.96845 21.3534 4.51123C20.7245 5.05591 20.4882 5.92312 20.7486 6.71021L24.8501 19.0149ZM48.0095 41.2891H44.4706L42.9867 38.3211C42.4779 37.3077 41.2523 36.9032 40.2349 37.4038C39.2216 37.9105 38.8131 39.1422 39.3177 40.1556L39.8845 41.2891H30.1153L30.6821 40.1556C31.1868 39.1422 30.7782 37.9105 29.7649 37.4038C28.7515 36.9071 27.5219 37.3077 27.0131 38.3211L25.5291 41.2891H21.9902C20.8567 41.2891 19.9395 42.2064 19.9395 43.3399C19.9395 44.4734 20.8567 45.3907 21.9902 45.3907H32.9491V55.6446C32.9491 56.7781 33.8663 57.6954 34.9998 57.6954C36.1334 57.6954 37.0506 56.7781 37.0506 55.6446V45.3907H48.0095C49.143 45.3907 50.0602 44.4734 50.0602 43.3399C50.0602 42.2064 49.143 41.2891 48.0095 41.2891Z"
          fill={`url(#paint1_linear_268_16556${width + height})`}
        />
      </g>
      <defs>
        <linearGradient
          id={`paint0_linear_268_16556${width + height}`}
          x1="34.9998"
          y1="70.0001"
          x2="34.9998"
          y2="0.263062"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[0]} />
          <stop offset="1" stopColor={palette[1]} />
        </linearGradient>
        <linearGradient
          id={`paint1_linear_268_16556${width + height}`}
          x1="34.9998"
          y1="57.6954"
          x2="34.9998"
          y2="0.00012207"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[2]} />
          <stop offset="1" stopColor={palette[3]} />
        </linearGradient>
        <clipPath id={`clip0_268_16556${width + height}`}>
          <rect width="70" height="70" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

ShiningGemSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  palette: PropTypes.arrayOf(PropTypes.string),
};

export default ShiningGemSvg;
