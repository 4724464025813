import PropTypes from 'prop-types';

const ArrowLeftSquaredSvg = ({ color, width, height }) => {
  return (
    <svg
      width={`${width || 24}`}
      height={`${height || 24}`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M7.81 22L16.19 22C19.83 22 22 19.83 22 16.19L22 7.82C22 4.17 19.83 2 16.19 2L7.82 2C4.18 2 2.01 4.17 2.01 7.81L2.01 16.19C2 19.83 4.17 22 7.81 22Z"
        fill={color}
      />
      <path
        d="M5.47 12.5296L9.76 16.8196C10.05 17.1096 10.53 17.1096 10.82 16.8196C11.11 16.5296 11.11 16.0496 10.82 15.7596L7.81 12.7496L18 12.7496C18.41 12.7496 18.75 12.4096 18.75 11.9996C18.75 11.5896 18.41 11.2496 18 11.2496L7.81 11.2496L10.82 8.23961C11.11 7.94961 11.11 7.46961 10.82 7.17961C10.67 7.02961 10.48 6.95961 10.29 6.95961C10.1 6.95961 9.91 7.02961 9.76 7.17961L5.47 11.4696C5.33 11.6096 5.25 11.7996 5.25 11.9996C5.25 12.1996 5.33 12.3896 5.47 12.5296Z"
        fill={color}
      />
    </svg>
  );
};

ArrowLeftSquaredSvg.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default ArrowLeftSquaredSvg;
