import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { MainApiService } from '_services';
import { processError } from '_helpers/api';

export const sendMessage = createAsyncThunk(
  'contactUs/sendMessage',
  async function (body, { rejectWithValue }) {
    try {
      await MainApiService.sendMessage({ body });
    } catch (e) {
      return rejectWithValue(processError(e));
    }
  }
);

const contactUsSlice = createSlice({
  name: 'contactUs',
  initialState: {
    result: false,
    error: null,
    loading: false,
  },
  reducers: {
    resetResult(state) {
      state.result = false;
    },
    resetError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendMessage.pending, (state) => {
        state.result = false;
        state.error = null;
        state.loading = true;
      })
      .addCase(sendMessage.fulfilled, (state) => {
        state.loading = false;
        state.result = true;
      })
      .addCase(sendMessage.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export const { resetResult, resetError } = contactUsSlice.actions;

export default contactUsSlice.reducer;
