import PropTypes from 'prop-types';

const CashSvg = ({ width, height, mixBlendMode, palette = [] }) => {
  return (
    <svg
      width={`${width || 70}`}
      height={`${height || 70}`}
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_637_8607)">
        <path
          d="M13.8086 22.1484V13.6719L27.4805 0H37.0508L50.7227 13.6719V22.1484H13.8086ZM70 41.9727V50.1758C70 52.4411 68.1637 54.2773 65.8984 54.2773H54.9609C50.4305 54.2773 46.7578 50.6047 46.7578 46.0742C46.7578 41.5438 50.4305 37.8711 54.9609 37.8711H65.8984C68.1637 37.8711 70 39.7074 70 41.9727Z"
          fill="url(#paint0_linear_637_8607)"
        />
        <path
          d="M37.0508 0H48.6719C49.8045 0 50.7227 0.918203 50.7227 2.05078V13.6719C43.1718 13.6719 37.0508 7.55084 37.0508 0ZM27.4805 0H15.8594C14.7268 0 13.8086 0.918203 13.8086 2.05078V13.6719C21.3594 13.6719 27.4805 7.55084 27.4805 0ZM54.2773 44.0234C53.1448 44.0234 52.2266 44.9416 52.2266 46.0742C52.2266 47.2068 53.1448 48.125 54.2773 48.125H58.3789C59.5115 48.125 60.4297 47.2068 60.4297 46.0742C60.4297 44.9416 59.5115 44.0234 58.3789 44.0234H54.2773ZM64.5312 29.668C64.5312 25.5217 61.158 22.1484 57.0117 22.1484H7.51953C3.37326 22.1484 0 25.5217 0 29.668V62.4805C0 66.6267 3.37326 70 7.51953 70H57.0117C61.158 70 64.5312 66.6267 64.5312 62.4805V54.2773H54.9609C50.4305 54.2773 46.7578 50.6047 46.7578 46.0742C46.7578 41.5438 50.4305 37.8711 54.9609 37.8711H64.5312V29.668Z"
          fill="url(#paint1_linear_637_8607)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_637_8607"
          x1="41.9043"
          y1="54.2774"
          x2="41.9043"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[2]} />
          <stop offset="1" stopColor={palette[3]} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_637_8607"
          x1="32.2656"
          y1="70"
          x2="32.2656"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[0]} />
          <stop offset="1" stopColor={palette[1]} />
        </linearGradient>
        <clipPath id="clip0_637_8607">
          <rect width="70" height="70" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

CashSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  mixBlendMode: PropTypes.string,
  palette: PropTypes.arrayOf(PropTypes.string),
};

export default CashSvg;
