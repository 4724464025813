import PropTypes from 'prop-types';

const DealSvg = ({ width, height, palette = [] }) => {
  return (
    <svg
      width={`${width || 70}`}
      height={`${height || 70}`}
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M51.3182 52.4268H51.2772L49.3496 42.1729L40.7365 41.8857L34.9531 36.0615C32.2872 33.3955 28.6779 31.9189 24.9043 31.9189H10.3848L8.33398 42.1729L10.3848 52.4268H16.4961L31.713 67.6434C33.3126 69.2432 35.8965 69.2432 37.4961 67.6434C39.0957 66.0439 39.0957 63.4189 37.4961 61.8192L40.4083 64.7314C42.0081 66.3312 44.5921 66.3312 46.1914 64.7314C47.791 63.1318 47.791 60.5477 46.1914 58.9481C47.791 60.5477 50.4162 60.5477 52.0158 58.9481C53.6151 57.3486 53.6151 54.7236 52.0158 53.1239L51.3182 52.4268ZM59.6094 31.9189L57.5586 42.1729L59.6094 52.4268H70V31.9189H59.6094Z"
        fill="url(#paint0_linear_504_6911)"
      />
      <path
        d="M45.0078 31.9189C41.2344 31.9189 37.625 33.3955 34.959 36.0615L26.0586 44.9619C24.459 46.5615 24.459 49.1864 26.0586 50.7861C27.6583 52.3857 30.2423 52.3857 31.8417 50.7861L40.7422 41.8857L51.2829 52.4268H59.6094V31.9189H45.0078ZM0 52.4268H10.3906V31.9189H0V52.4268ZM28.8477 15.5127C28.8477 15.1943 28.7736 14.8798 28.6314 14.5954L26.0138 9.36035H32.9492V21.665H30.8984C29.7649 21.665 28.8477 22.5823 28.8477 23.7158C28.8477 24.8494 29.7649 25.7666 30.8984 25.7666H39.1016C40.2351 25.7666 41.1523 24.8494 41.1523 23.7158C41.1523 22.5823 40.2351 21.665 39.1016 21.665H37.0508V9.36035H43.9862L41.3686 14.5954C41.2264 14.8798 41.1523 15.1943 41.1523 15.5127C41.1523 18.9052 43.9121 21.665 47.3047 21.665C50.6972 21.665 53.457 18.9052 53.457 15.5127C53.457 15.1943 53.3829 14.8798 53.2407 14.5954L49.1392 6.39232C49.1214 6.35705 49.0808 6.34625 49.0611 6.31275C48.7084 5.69396 48.0688 5.25879 47.3047 5.25879H37.0508V3.20801C37.0508 2.07447 36.1335 1.15723 35 1.15723C33.8665 1.15723 32.9492 2.07447 32.9492 3.20801V5.25879H22.6953C21.9313 5.25879 21.2916 5.69396 20.9389 6.31275C20.9191 6.34625 20.8786 6.35705 20.8608 6.39232L16.7593 14.5954C16.6171 14.8798 16.543 15.1943 16.543 15.5127C16.543 18.9052 19.3028 21.665 22.6953 21.665C26.0879 21.665 28.8477 18.9052 28.8477 15.5127ZM47.3047 11.8958L49.3154 15.9172C49.1271 16.8545 48.2981 17.5635 47.3047 17.5635C46.3113 17.5635 45.4822 16.8545 45.294 15.9172L47.3047 11.8958ZM22.6953 17.5635C21.7019 17.5635 20.8729 16.8545 20.6846 15.9172L22.6953 11.8958L24.706 15.9172C24.5178 16.8545 23.6887 17.5635 22.6953 17.5635Z"
        fill="url(#paint1_linear_504_6911)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_504_6911"
          x1="39.167"
          y1="68.8432"
          x2="39.167"
          y2="31.9189"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[0]} />
          <stop offset="1" stopColor={palette[1]} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_504_6911"
          x1="29.8047"
          y1="52.4268"
          x2="29.8047"
          y2="1.15723"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[2]} />
          <stop offset="1" stopColor={palette[3]} />
        </linearGradient>
      </defs>
    </svg>
  );
};

DealSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  palette: PropTypes.arrayOf(PropTypes.string),
};

export default DealSvg;
