import Icon from '_components/atomic/Icon';
import { ICONS_ALIASES } from '_constants/icons';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { theme } from '_constants/theme';
import { ZendeskAPI } from 'react-zendesk';

const { colors, devices } = theme;

const StyledRoundMenu = styled.div`
  z-index: 20;
  position: fixed;
  bottom: 30px;
  right: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media ${devices.tablet} {
    gap: 8px;
    bottom: 16px;
    right: 16px;
  }
`;

const RoundWrapper = styled.div`
  height: 80px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;

  background: ${({ color }) => color};
  border-radius: 100px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);

  cursor: pointer;

  @media ${devices.tablet} {
    height: 48px;
    width: 48px;

    svg {
      width: 24px;
    }
  }
`;

const RoundMenu = () => {
  const [showTopBtn, setShowTopBtn] = useState(false);

  const handleChat = () => {
    ZendeskAPI('webWidget', 'setLocale', 'en');
    ZendeskAPI('webWidget', 'show');
    ZendeskAPI('webWidget', 'open');

    ZendeskAPI('webWidget:on', 'close', function () {
      ZendeskAPI('webWidget', 'hide');
    });
  };

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleScroll = () => {
    if (window.scrollY > 400) {
      setShowTopBtn(true);
    } else {
      setShowTopBtn(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <StyledRoundMenu>
      <RoundWrapper onClick={handleChat} color={colors.indigo[600]}>
        <Icon alias={ICONS_ALIASES.messageText} color={colors.white} />
      </RoundWrapper>
      {showTopBtn && (
        <RoundWrapper onClick={goToTop} color={colors.white}>
          <Icon alias={ICONS_ALIASES.arrowUp} color={colors.indigo[600]} />
        </RoundWrapper>
      )}
    </StyledRoundMenu>
  );
};

export default RoundMenu;
