import PropTypes from 'prop-types';

import { ICONS_ALIASES } from '_constants/icons';

import ArrowLeftSquaredSvg from './assets/ArrowLeftSquaredSvg';
import ArrowRightSquaredSvg from './assets/ArrowRightSquaredSvg';
import ChevronDownSvg from './assets/ChevronDownSvg';
import ChevronUpSvg from './assets/ChevronUpSvg';
import ChevronDownRoundedSvg from './assets/ChevronDownRoundedSvg';
import CheckFilledSvg from './assets/CheckFilledSvg';
import CloseFilledSvg from './assets/CloseFilledSvg';
import PhoneSvg from './assets/PhoneSvg';
import LockSvg from './assets/LockSvg';
import FacebookSvg from './assets/FacebookSvg';
import LinkedinSvg from './assets/LinkedinSvg';
import TwitterSvg from './assets/TwitterSvg';
import ArrowUpSvg from './assets/ArrowUpSvg';
import MessageTextSvg from './assets/MessageTextSvg';
import StepSvg from './assets/StepSvg';
import StepDoneSvg from './assets/StepDoneSvg';
import ArrowLeftSvg from './assets/ArrowLeftSvg';
import ArrowRightSvg from './assets/ArrowRightSvg';
import QuestionSvg from './assets/QuestionSvg';
import ShieldSvg from './assets/ShieldSvg';
import VerifySvg from './assets/VerifySvg';
import RocketSvg from './assets/RocketSvg';
import SaveEnergySvg from './assets/SaveEnergySvg';
import QuickSvg from './assets/QuickSvg';
import HeadphonesSvg from './assets/HeadphonesSvg';
import PiggyBankSvg from './assets/PiggyBankSvg';
import Taxes2Svg from './assets/Taxes2Svg';
import PovertySvg from './assets/PovertySvg';
import CareerSvg from './assets/CareerSvg';
import ClipboardSvg from './assets/ClipboardSvg';
import SalarySvg from './assets/SalarySvg';
import GrocerySvg from './assets/GrocerySvg';
import FavoriteSvg from './assets/FavoriteSvg';
import FreelanceSvg from './assets/FreelanceSvg';
import CorporateSvg from './assets/CorporateSvg';
import KindnessSvg from './assets/KindnessSvg';
import DocumentSvg from './assets/DocumentSvg';
import PointerSvg from './assets/PointerSvg';
import DealSvg from './assets/DealSvg';
import ReportSvg from './assets/ReportSvg';
import CertificateSvg from './assets/CertificateSvg';
import BankCheckSvg from './assets/BankCheckSvg';
import Taxes1Svg from './assets/Taxes1Svg';
import PrivacySvg from './assets/PrivacySvg';
import CopyrightSvg from './assets/CopyrightSvg';
import ProductIncrementSvg from './assets/ProductIncrementSvg';
import QualitySvg from './assets/QualitySvg';
import AccuracySvg from './assets/AccuracySvg';
import SaveTimeSvg from './assets/SaveTimeSvg';
import FeedbackSvg from './assets/FeedbackSvg';
import WorkSvg from './assets/WorkSvg';
import VerifiedSvg from './assets/VerifiedSvg';
import VoteSvg from './assets/VoteSvg';
import MoneyBagSvg from './assets/MoneyBagSvg';
import CashSvg from './assets/CashSvg';
import OnlinePaymentSvg from './assets/OnlinePaymentSvg';
import SeoReportSvg from './assets/SeoReportSvg';
import AdaptationSvg from './assets/AdaptationSvg';
import SecuritySvg from './assets/SecuritySvg';
import HappySvg from './assets/HappySvg';
import CreditCardSvg from './assets/CreditCardSvg';
import ReduceCostSvg from './assets/ReduceCostSvg';
import GlobalNetworkSvg from './assets/GlobalNetworkSvg';
import CashFlowSvg from './assets/CashFlowSvg';
import GallerySvg from './assets/GallerySvg';
import StarSvg from './assets/StarSvg';
import MedalStarSvg from './assets/MedalStarSvg';
import ShopSvg from './assets/ShopSvg';
import ChartSvg from './assets/ChartSvg';
import LockPCSvg from './assets/LockPCSvg';
import ReduceStressSvg from './assets/ReduceStressSvg';
import CalendarSvg from './assets/CalendarSvg';
import CancelSvg from './assets/CancelSvg';
import StopWatchSvg from './assets/StopWatchSvg';
import DevelopmentSvg from './assets/DevelopmentSvg';
import ShieldTickSvg from './assets/ShieldTickSvg';
import ShieldWithBandSvg from './assets/ShieldWithBandSvg';
import SearchZoomInSvg from './assets/SearchZoomInSvg';
import SearchNormalSvg from './assets/SearchNormalSvg';
import DevicesSvg from './assets/DevicesSvg';
import CheckSvg from './assets/CheckSvg';
import CloseSvg from './assets/CloseSvg';
import TickSvg from './assets/TickSvg';
import LinkSvg from './assets/LinkSvg';
import RadioOnSvg from './assets/RadioOnSvg';
import RadioOffSvg from './assets/RadioOffSvg';
import FilingCalendarSvg from './assets/FilingCalendarSvg';
import FilingWebsiteSvg from './assets/FilingWebsiteSvg';
import FilingUpdateGearSvg from './assets/FilingUpdateGearSvg';
import FilingReviewSvg from './assets/FilingReviewSvg';
import FilingCheckSvg from './assets/FilingCheckSvg';
import FilingChildCheckSvg from './assets/FilingChildCheckSvg';
import PlusSvg from './assets/PlusSvg';
import CheckRoundedSvg from './assets/CheckRoundedSvg';
import CheckRoundedInvertedSvg from './assets/CheckRoundedInvertedSvg';
import GoldMedalSvg from './assets/GoldMedalSvg';
import PlatinumMedalSvg from './assets/PlatinumMedalSvg';
import DiamondMedalSvg from './assets/DiamondMedalSvg';
import Save50Svg from './assets/Save50Svg';
import EditSvg from './assets/EditSvg';
import InfoSvg from './assets/InfoSvg';
import TrashSvg from './assets/TrashSvg';
import TaskListSvg from './assets/TaskListSvg';
import ScanSvg from './assets/ScanSvg';
import PhoneAndClockSvg from './assets/PhoneAndClockSvg';
import WorkingTimeSvg from './assets/WorkingTimeSvg';
import TaskSvg from './assets/TaskSvg';
import LocationSvg from './assets/LocationSvg';
import ProfileSvg from './assets/ProfileSvg';
import MoreSvg from './assets/MoreSvg';
import SafeLockSvg from './assets/SafeLockSvg';
import AccountHolderSvg from './assets/AccountHolderSvg';
import ExpensesSvg from './assets/ExpensesSvg';
import ProtectionSvg from './assets/ProtectionSvg';
import GuardSvg from './assets/GuardSvg';
import NewspaperSvg from './assets/NewspaperSvg';
import StampSvg from './assets/StampSvg';
import AwardSvg from './assets/AwardSvg';
import ShiningGemSvg from './assets/ShiningGemSvg';
import FeeSvg from './assets/FeeSvg';
import InsuranceSvg from './assets/InsuranceSvg';
import Taxes3Svg from './assets/Taxes3Svg';
import TickCircleSvg from './assets/TickCircleSvg';
import InfoFiledSvg from './assets/InfoFiledSvg';
import FancySpinnerSvg from './assets/FancySpinnerSvg';
import MonoCheckSvg from './assets/MonoCheckSvg';
import MonoDollarSvg from './assets/MonoDollarSvg';
import MonoLikeSvg from './assets/MonoLikeSvg';
import MonoShieldSvg from './assets/MonoShieldSvg';
import CartSvg from './assets/CartSvg';
import WarningSvg from './assets/WarningSvg';

const icons = {
  [ICONS_ALIASES.accuracy]: AccuracySvg,
  [ICONS_ALIASES.adaptation]: AdaptationSvg,
  [ICONS_ALIASES.arrowLeftSquared]: ArrowLeftSquaredSvg,
  [ICONS_ALIASES.arrowRightSquared]: ArrowRightSquaredSvg,
  [ICONS_ALIASES.chevronDown]: ChevronDownSvg,
  [ICONS_ALIASES.chevronUp]: ChevronUpSvg,
  [ICONS_ALIASES.chevronDownRounded]: ChevronDownRoundedSvg,
  [ICONS_ALIASES.checkFilled]: CheckFilledSvg,
  [ICONS_ALIASES.closeFilled]: CloseFilledSvg,
  [ICONS_ALIASES.arrowLeft]: ArrowLeftSvg,
  [ICONS_ALIASES.arrowRight]: ArrowRightSvg,
  [ICONS_ALIASES.arrowUp]: ArrowUpSvg,
  [ICONS_ALIASES.bankCheck]: BankCheckSvg,
  [ICONS_ALIASES.cash]: CashSvg,
  [ICONS_ALIASES.cashFlow]: CashFlowSvg,
  [ICONS_ALIASES.certificate]: CertificateSvg,
  [ICONS_ALIASES.copyright]: CopyrightSvg,
  [ICONS_ALIASES.creditCard]: CreditCardSvg,
  [ICONS_ALIASES.deal]: DealSvg,
  [ICONS_ALIASES.document]: DocumentSvg,
  [ICONS_ALIASES.facebook]: FacebookSvg,
  [ICONS_ALIASES.feedback]: FeedbackSvg,
  [ICONS_ALIASES.globalNetwork]: GlobalNetworkSvg,
  [ICONS_ALIASES.happy]: HappySvg,
  [ICONS_ALIASES.linkedin]: LinkedinSvg,
  [ICONS_ALIASES.lock]: LockSvg,
  [ICONS_ALIASES.messageText]: MessageTextSvg,
  [ICONS_ALIASES.moneyBag]: MoneyBagSvg,
  [ICONS_ALIASES.onlinePayment]: OnlinePaymentSvg,
  [ICONS_ALIASES.phone]: PhoneSvg,
  [ICONS_ALIASES.piggyBank]: PiggyBankSvg,
  [ICONS_ALIASES.pointer]: PointerSvg,
  [ICONS_ALIASES.poverty]: PovertySvg,
  [ICONS_ALIASES.privacy]: PrivacySvg,
  [ICONS_ALIASES.productIncrement]: ProductIncrementSvg,
  [ICONS_ALIASES.question]: QuestionSvg,
  [ICONS_ALIASES.reduceCost]: ReduceCostSvg,
  [ICONS_ALIASES.report]: ReportSvg,
  [ICONS_ALIASES.rocket]: RocketSvg,
  [ICONS_ALIASES.security]: SecuritySvg,
  [ICONS_ALIASES.seoReport]: SeoReportSvg,
  [ICONS_ALIASES.taxes1]: Taxes1Svg,
  [ICONS_ALIASES.taxes2]: Taxes2Svg,
  [ICONS_ALIASES.twitter]: TwitterSvg,
  [ICONS_ALIASES.saveEnergy]: SaveEnergySvg,
  [ICONS_ALIASES.saveTime]: SaveTimeSvg,
  [ICONS_ALIASES.shield]: ShieldSvg,
  [ICONS_ALIASES.step]: StepSvg,
  [ICONS_ALIASES.stepDone]: StepDoneSvg,
  [ICONS_ALIASES.quality]: QualitySvg,
  [ICONS_ALIASES.quick]: QuickSvg,
  [ICONS_ALIASES.headphones]: HeadphonesSvg,
  [ICONS_ALIASES.career]: CareerSvg,
  [ICONS_ALIASES.clipboard]: ClipboardSvg,
  [ICONS_ALIASES.salary]: SalarySvg,
  [ICONS_ALIASES.grocery]: GrocerySvg,
  [ICONS_ALIASES.gallery]: GallerySvg,
  [ICONS_ALIASES.favorite]: FavoriteSvg,
  [ICONS_ALIASES.freelance]: FreelanceSvg,
  [ICONS_ALIASES.corporate]: CorporateSvg,
  [ICONS_ALIASES.kindness]: KindnessSvg,
  [ICONS_ALIASES.verified]: VerifiedSvg,
  [ICONS_ALIASES.verify]: VerifySvg,
  [ICONS_ALIASES.vote]: VoteSvg,
  [ICONS_ALIASES.work]: WorkSvg,
  [ICONS_ALIASES.star]: StarSvg,
  [ICONS_ALIASES.medalStar]: MedalStarSvg,
  [ICONS_ALIASES.shop]: ShopSvg,
  [ICONS_ALIASES.chart]: ChartSvg,
  [ICONS_ALIASES.lockPC]: LockPCSvg,
  [ICONS_ALIASES.reduceStress]: ReduceStressSvg,
  [ICONS_ALIASES.calendar]: CalendarSvg,
  [ICONS_ALIASES.cancel]: CancelSvg,
  [ICONS_ALIASES.stopWatch]: StopWatchSvg,
  [ICONS_ALIASES.development]: DevelopmentSvg,
  [ICONS_ALIASES.shieldTick]: ShieldTickSvg,
  [ICONS_ALIASES.shieldWithBand]: ShieldWithBandSvg,
  [ICONS_ALIASES.searchZoomIn]: SearchZoomInSvg,
  [ICONS_ALIASES.searchNormal]: SearchNormalSvg,
  [ICONS_ALIASES.devices]: DevicesSvg,
  [ICONS_ALIASES.check]: CheckSvg,
  [ICONS_ALIASES.close]: CloseSvg,
  [ICONS_ALIASES.tick]: TickSvg,
  [ICONS_ALIASES.tickCircle]: TickCircleSvg,
  [ICONS_ALIASES.link]: LinkSvg,
  [ICONS_ALIASES.radioOn]: RadioOnSvg,
  [ICONS_ALIASES.radioOff]: RadioOffSvg,
  [ICONS_ALIASES.filingCalendar]: FilingCalendarSvg,
  [ICONS_ALIASES.filingWebsite]: FilingWebsiteSvg,
  [ICONS_ALIASES.filingUpdateGear]: FilingUpdateGearSvg,
  [ICONS_ALIASES.filingReview]: FilingReviewSvg,
  [ICONS_ALIASES.filingCheck]: FilingCheckSvg,
  [ICONS_ALIASES.filingChildCheck]: FilingChildCheckSvg,
  [ICONS_ALIASES.plus]: PlusSvg,
  [ICONS_ALIASES.checkRounded]: CheckRoundedSvg,
  [ICONS_ALIASES.checkRoundedInverted]: CheckRoundedInvertedSvg,
  [ICONS_ALIASES.goldMedal]: GoldMedalSvg,
  [ICONS_ALIASES.platinumMedal]: PlatinumMedalSvg,
  [ICONS_ALIASES.diamondMedal]: DiamondMedalSvg,
  [ICONS_ALIASES.save50]: Save50Svg,
  [ICONS_ALIASES.edit]: EditSvg,
  [ICONS_ALIASES.info]: InfoSvg,
  [ICONS_ALIASES.trash]: TrashSvg,
  [ICONS_ALIASES.taskList]: TaskListSvg,
  [ICONS_ALIASES.scan]: ScanSvg,
  [ICONS_ALIASES.phoneAndClock]: PhoneAndClockSvg,
  [ICONS_ALIASES.workingTime]: WorkingTimeSvg,
  [ICONS_ALIASES.task]: TaskSvg,
  [ICONS_ALIASES.location]: LocationSvg,
  [ICONS_ALIASES.profile]: ProfileSvg,
  [ICONS_ALIASES.more]: MoreSvg,
  [ICONS_ALIASES.safeLock]: SafeLockSvg,
  [ICONS_ALIASES.accountHolder]: AccountHolderSvg,
  [ICONS_ALIASES.expenses]: ExpensesSvg,
  [ICONS_ALIASES.protection]: ProtectionSvg,
  [ICONS_ALIASES.guard]: GuardSvg,
  [ICONS_ALIASES.newspaper]: NewspaperSvg,
  [ICONS_ALIASES.stamp]: StampSvg,
  [ICONS_ALIASES.award]: AwardSvg,
  [ICONS_ALIASES.shiningGem]: ShiningGemSvg,
  [ICONS_ALIASES.fee]: FeeSvg,
  [ICONS_ALIASES.insurance]: InsuranceSvg,
  [ICONS_ALIASES.taxes3]: Taxes3Svg,
  [ICONS_ALIASES.infoFiled]: InfoFiledSvg,
  [ICONS_ALIASES.fancySpinner]: FancySpinnerSvg,
  [ICONS_ALIASES.monoCheck]: MonoCheckSvg,
  [ICONS_ALIASES.monoDollar]: MonoDollarSvg,
  [ICONS_ALIASES.monoLike]: MonoLikeSvg,
  [ICONS_ALIASES.monoShield]: MonoShieldSvg,
  [ICONS_ALIASES.cart]: CartSvg,
  [ICONS_ALIASES.warning]: WarningSvg,
};

const Icon = ({
  uniqueKey = '',
  alias,
  color,
  palette,
  mixBlendMode,
  width,
  height,
}) => {
  const Icon = icons[alias];

  if (!Icon) {
    return null;
  }

  return (
    <Icon
      uniqueKey={uniqueKey}
      color={color}
      palette={palette}
      mixBlendMode={mixBlendMode}
      width={width}
      height={height}
    />
  );
};

Icon.propTypes = {
  uniqueKey: PropTypes.string,
  alias: PropTypes.oneOf([
    ICONS_ALIASES.accuracy,
    ICONS_ALIASES.adaptation,
    ICONS_ALIASES.arrowLeftSquared,
    ICONS_ALIASES.arrowRightSquared,
    ICONS_ALIASES.chevronDown,
    ICONS_ALIASES.chevronUp,
    ICONS_ALIASES.chevronDownRounded,
    ICONS_ALIASES.checkFilled,
    ICONS_ALIASES.closeFilled,
    ICONS_ALIASES.arrowLeft,
    ICONS_ALIASES.arrowRight,
    ICONS_ALIASES.arrowUp,
    ICONS_ALIASES.bankCheck,
    ICONS_ALIASES.career,
    ICONS_ALIASES.cash,
    ICONS_ALIASES.cashFlow,
    ICONS_ALIASES.certificate,
    ICONS_ALIASES.clipboard,
    ICONS_ALIASES.copyright,
    ICONS_ALIASES.corporate,
    ICONS_ALIASES.creditCard,
    ICONS_ALIASES.deal,
    ICONS_ALIASES.document,
    ICONS_ALIASES.facebook,
    ICONS_ALIASES.favorite,
    ICONS_ALIASES.feedback,
    ICONS_ALIASES.freelance,
    ICONS_ALIASES.gallery,
    ICONS_ALIASES.globalNetwork,
    ICONS_ALIASES.grocery,
    ICONS_ALIASES.happy,
    ICONS_ALIASES.headphones,
    ICONS_ALIASES.kindness,
    ICONS_ALIASES.linkedin,
    ICONS_ALIASES.lock,
    ICONS_ALIASES.messageText,
    ICONS_ALIASES.moneyBag,
    ICONS_ALIASES.onlinePayment,
    ICONS_ALIASES.phone,
    ICONS_ALIASES.piggyBank,
    ICONS_ALIASES.pointer,
    ICONS_ALIASES.poverty,
    ICONS_ALIASES.privacy,
    ICONS_ALIASES.productIncrement,
    ICONS_ALIASES.quality,
    ICONS_ALIASES.question,
    ICONS_ALIASES.quick,
    ICONS_ALIASES.reduceCost,
    ICONS_ALIASES.report,
    ICONS_ALIASES.rocket,
    ICONS_ALIASES.salary,
    ICONS_ALIASES.saveEnergy,
    ICONS_ALIASES.saveTime,
    ICONS_ALIASES.security,
    ICONS_ALIASES.seoReport,
    ICONS_ALIASES.shield,
    ICONS_ALIASES.step,
    ICONS_ALIASES.stepDone,
    ICONS_ALIASES.taxes1,
    ICONS_ALIASES.taxes2,
    ICONS_ALIASES.twitter,
    ICONS_ALIASES.verified,
    ICONS_ALIASES.verify,
    ICONS_ALIASES.vote,
    ICONS_ALIASES.work,
    ICONS_ALIASES.star,
    ICONS_ALIASES.medalStar,
    ICONS_ALIASES.shop,
    ICONS_ALIASES.chart,
    ICONS_ALIASES.lockPC,
    ICONS_ALIASES.reduceStress,
    ICONS_ALIASES.calendar,
    ICONS_ALIASES.cancel,
    ICONS_ALIASES.stopWatch,
    ICONS_ALIASES.development,
    ICONS_ALIASES.shieldTick,
    ICONS_ALIASES.shieldWithBand,
    ICONS_ALIASES.searchZoomIn,
    ICONS_ALIASES.searchNormal,
    ICONS_ALIASES.devices,
    ICONS_ALIASES.check,
    ICONS_ALIASES.close,
    ICONS_ALIASES.tick,
    ICONS_ALIASES.tickCircle,
    ICONS_ALIASES.link,
    ICONS_ALIASES.radioOn,
    ICONS_ALIASES.radioOff,
    ICONS_ALIASES.filingCalendar,
    ICONS_ALIASES.filingWebsite,
    ICONS_ALIASES.filingUpdateGear,
    ICONS_ALIASES.filingReview,
    ICONS_ALIASES.filingCheck,
    ICONS_ALIASES.filingChildCheck,
    ICONS_ALIASES.plus,
    ICONS_ALIASES.checkRounded,
    ICONS_ALIASES.checkRoundedInverted,
    ICONS_ALIASES.goldMedal,
    ICONS_ALIASES.platinumMedal,
    ICONS_ALIASES.diamondMedal,
    ICONS_ALIASES.save50,
    ICONS_ALIASES.edit,
    ICONS_ALIASES.info,
    ICONS_ALIASES.trash,
    ICONS_ALIASES.taskList,
    ICONS_ALIASES.scan,
    ICONS_ALIASES.phoneAndClock,
    ICONS_ALIASES.workingTime,
    ICONS_ALIASES.task,
    ICONS_ALIASES.location,
    ICONS_ALIASES.profile,
    ICONS_ALIASES.more,
    ICONS_ALIASES.safeLock,
    ICONS_ALIASES.accountHolder,
    ICONS_ALIASES.expenses,
    ICONS_ALIASES.protection,
    ICONS_ALIASES.guard,
    ICONS_ALIASES.newspaper,
    ICONS_ALIASES.stamp,
    ICONS_ALIASES.award,
    ICONS_ALIASES.shiningGem,
    ICONS_ALIASES.fee,
    ICONS_ALIASES.insurance,
    ICONS_ALIASES.taxes3,
    ICONS_ALIASES.infoFiled,
    ICONS_ALIASES.fancySpinner,
    ICONS_ALIASES.monoCheck,
    ICONS_ALIASES.monoDollar,
    ICONS_ALIASES.monoLike,
    ICONS_ALIASES.monoShield,
    ICONS_ALIASES.cart,
    ICONS_ALIASES.warning,
  ]),
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  palette: PropTypes.arrayOf(PropTypes.string),
  mixBlendMode: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default Icon;
