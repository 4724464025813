import PropTypes from 'prop-types';

const VerifySvg = ({ width, height, palette = [] }) => {
  return (
    <svg
      width={`${width || 70}`}
      height={`${height || 70}`}
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_488_11070)">
        <path
          d="M51.4062 35C50.3215 35 49.2656 35.1184 48.2392 35.3201L49.1989 33.0033C49.5835 32.078 49.239 31.0086 48.3898 30.4778L44.8949 28.2964C45.0987 27.1381 45.2539 25.9617 45.2539 24.746C45.2539 23.5962 45.0972 22.487 44.9144 21.3882L48.3898 19.2193C49.239 18.6886 49.5834 17.6212 49.1989 16.6939L47.3885 12.3239C47.0039 11.3986 45.9946 10.878 45.0332 11.1103L40.7642 12.0983C39.7764 10.8501 38.6568 9.59337 37.4097 8.60407L38.3797 4.40995C38.604 3.43268 38.0913 2.43532 37.166 2.05278L32.8003 0.158408C31.871 -0.228096 30.8096 0.112333 30.2769 0.965458L28.1553 4.44618C27.0405 4.25806 25.9134 4.10151 24.7461 4.10151C23.5776 4.10151 22.4492 4.25778 21.3335 4.44645L19.2154 0.967509C18.6827 0.114384 17.6173 -0.224132 16.692 0.158408L12.326 1.96679C11.4007 2.34933 10.888 3.43255 11.1124 4.40995L12.082 8.60462C10.8351 9.59364 9.57878 10.8505 8.59099 12.0983L4.32222 11.1103C3.35685 10.878 2.35156 11.3986 1.96697 12.3239L0.156536 16.6939C-0.228054 17.6211 0.116478 18.6886 0.965638 19.2193L4.44117 21.3875C4.2581 22.4865 4.10156 23.596 4.10156 24.746C4.10156 25.962 4.25646 27.1383 4.46058 28.2966L0.965638 30.4778C0.116478 31.0086 -0.227917 32.0759 0.156536 33.0033L1.96697 37.3733C2.36427 38.3273 3.38788 38.8083 4.32222 38.5869L8.73345 37.566C9.69226 38.7507 10.9037 39.8189 12.0991 40.7648L11.1125 45.0357C10.8881 46.011 11.4008 47.0084 12.3261 47.3909L16.692 49.1994C17.6162 49.582 18.6834 49.243 19.2154 48.3924L21.3887 44.9147C22.4874 45.0972 23.5963 45.2539 24.7461 45.2539C25.8968 45.2539 27.0069 45.0972 28.1066 44.9141L30.2769 48.39C30.8094 49.2445 31.8776 49.5811 32.8003 49.1992L35.3292 48.1517C35.1165 49.2045 35 50.2917 35 51.4062C35 60.4525 42.3579 67.9492 51.4062 67.9492C60.4546 67.9492 67.9492 60.4525 67.9492 51.4062C67.9492 42.3599 60.4546 35 51.4062 35Z"
          fill="url(#paint0_linear_488_11070)"
        />
        <path
          d="M51.4062 32.9492C41.2285 32.9492 32.9492 41.2285 32.9492 51.4062C32.9492 61.584 41.2285 70 51.4062 70C61.584 70 70 61.584 70 51.4062C70 41.2285 61.5841 32.9492 51.4062 32.9492ZM59.0086 48.7547L50.8055 56.9579C50.4049 57.3584 49.8802 57.5587 49.3556 57.5587C48.831 57.5587 48.3062 57.3584 47.9057 56.9579L43.8041 52.8563C43.0031 52.0553 43.0031 50.7574 43.8041 49.9563C44.6052 49.1553 45.903 49.1553 46.7041 49.9563L49.3555 52.6079L56.1087 45.8547C56.9097 45.0536 58.2076 45.0536 59.0086 45.8547C59.8097 46.6557 59.8097 47.9536 59.0086 48.7547ZM24.7461 14.4922C19.0904 14.4922 14.4922 19.0924 14.4922 24.7461C14.4922 30.3998 19.0904 35 24.7461 35C30.4017 35 35 30.3998 35 24.7461C35 19.0924 30.4019 14.4922 24.7461 14.4922Z"
          fill="url(#paint1_linear_488_11070)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_488_11070"
          x1="33.9746"
          y1="67.9492"
          x2="33.9746"
          y2="-4.86011e-05"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[0]} />
          <stop offset="1" stopColor={palette[1]} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_488_11070"
          x1="42.2461"
          y1="70"
          x2="42.2461"
          y2="14.4922"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[2]} />
          <stop offset="0.9973" stopColor={palette[3]} />
        </linearGradient>
        <clipPath id="clip0_488_11070">
          <rect width="70" height="70" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

VerifySvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  palette: PropTypes.arrayOf(PropTypes.string),
};

export default VerifySvg;
