import PropTypes from 'prop-types';

const CareerSvg = ({ width, height, mixBlendMode, palette = [] }) => {
  return (
    <svg
      width={`${width || 51}`}
      height={`${height || 50}`}
      viewBox="0 0 51 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        style={{ mixBlendMode: mixBlendMode || 'normal' }}
        clipPath={`url(#clip0_665_6849${width + height})`}
      >
        <path
          d="M19.9735 8.78894C20.7831 8.78894 21.4383 8.13378 21.4383 7.32412V2.92965H30.2272V7.32412C30.2272 8.13378 30.8824 8.78894 31.692 8.78894C32.5017 8.78894 33.1569 8.13378 33.1569 7.32412V1.46482C33.1569 0.655167 32.5017 0 31.692 0H19.9735C19.1638 0 18.5086 0.655167 18.5086 1.46482V7.32412C18.5086 8.13378 19.1638 8.78894 19.9735 8.78894ZM28.1588 37.6005C26.734 38.2442 24.9316 38.2442 23.5068 37.6005C22.5334 37.1628 21.4383 37.875 21.4383 38.9351V48.5345C21.4383 49.1267 21.7944 49.6617 22.3424 49.8877C22.8917 50.1151 23.5182 49.9907 23.9388 49.5701L25.8328 47.6761L27.7269 49.5701C28.1497 49.993 28.7835 50.113 29.3233 49.8877C29.8712 49.6617 30.2274 49.1267 30.2274 48.5345V38.9351C30.2272 37.8677 29.1178 37.1604 28.1588 37.6005V37.6005ZM25.8328 8.78894L6.79004 13.1834V30.7613C6.79004 33.1928 8.75271 35.1558 11.1845 35.1558H18.6552C19.5668 35.1558 20.2754 34.2882 20.0907 33.4272C20.032 33.0463 19.9735 32.6363 19.9735 32.2261C19.9735 29.0034 22.6102 26.3668 25.8328 26.3668C29.0553 26.3668 31.692 29.0034 31.692 32.2261C31.692 32.6363 31.6334 33.0463 31.5748 33.4272C31.3903 34.2872 32.0978 35.1558 33.0103 35.1558H40.481C42.9128 35.1558 44.8755 33.1928 44.8755 30.7613V13.1834L25.8328 8.78894ZM25.8328 35.1558C27.4492 35.1558 28.7624 33.8411 28.7624 32.2261C28.7624 30.6111 27.4492 29.2965 25.8328 29.2965C24.2163 29.2965 22.9031 30.6111 22.9031 32.2261C22.9031 33.8411 24.2164 35.1558 25.8328 35.1558Z"
          fill={`url(#paint0_linear_665_6849${width + height})`}
        />
        <path
          d="M40.481 5.85938H11.1845C8.75271 5.85938 6.79004 7.82224 6.79004 10.2538V13.1835C6.79004 17.2264 10.0712 20.5076 14.1142 20.5076H24.3679V16.1131C24.3679 15.2927 25.0124 14.6483 25.8328 14.6483C26.6531 14.6483 27.2976 15.2927 27.2976 16.1131V20.5076H37.5513C41.5943 20.5076 44.8755 17.2264 44.8755 13.1835V10.2538C44.8755 7.82224 42.9129 5.85938 40.481 5.85938ZM25.8328 23.4373C20.9862 23.4373 17.0438 27.3797 17.0438 32.2262C17.0438 36.9967 20.925 41.0151 25.8328 41.0151C30.7374 41.0151 34.6217 36.9881 34.6217 32.2262C34.6217 27.3797 30.6793 23.4373 25.8328 23.4373ZM25.8328 33.691C25.0238 33.691 24.3679 33.0352 24.3679 32.2262C24.3679 31.417 25.0238 30.7614 25.8328 30.7614C26.6417 30.7614 27.2976 31.417 27.2976 32.2262C27.2976 33.0352 26.6417 33.691 25.8328 33.691Z"
          fill={`url(#paint1_linear_665_6849${width + height})`}
        />
      </g>
      <defs>
        <linearGradient
          id={`paint0_linear_665_6849${width + height}`}
          x1="25.8327"
          y1="49.9999"
          x2="25.8327"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[0]} />
          <stop offset="1" stopColor={palette[1]} />
        </linearGradient>
        <linearGradient
          id={`paint1_linear_665_6849${width + height}`}
          x1="25.8327"
          y1="41.0151"
          x2="25.8327"
          y2="5.85938"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[2]} />
          <stop offset="1" stopColor={palette[3]} />
        </linearGradient>
        <clipPath id={`clip0_665_6849${width + height}`}>
          <rect
            width="50"
            height="50"
            fill="white"
            transform="translate(0.833008)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

CareerSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  mixBlendMode: PropTypes.string,
  palette: PropTypes.arrayOf(PropTypes.string),
};

export default CareerSvg;
