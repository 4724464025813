import PropTypes from 'prop-types';

const ShieldTickSvg = ({ color, width, height }) => {
  return (
    <svg
      width={`${width || 50}`}
      height={`${height || 50}`}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M22.834 4.29139L11.3757 8.58305C9.18815 9.41639 7.39648 11.9997 7.39648 14.3539V31.2289C7.39648 32.9164 8.50065 35.1456 9.85482 36.1456L21.3132 44.7081C23.334 46.2289 26.6465 46.2289 28.6673 44.7081L40.1257 36.1456C41.4798 35.1247 42.584 32.9164 42.584 31.2289V14.3539C42.584 12.0206 40.7923 9.41639 38.6048 8.60389L27.1465 4.31222C25.9798 3.85389 24.0215 3.85389 22.834 4.29139Z"
        fill={color}
      />
      <path
        d="M22.2083 29.6455C21.8125 29.6455 21.4167 29.4997 21.1042 29.1872L17.75 25.833C17.1458 25.2288 17.1458 24.2288 17.75 23.6247C18.3542 23.0205 19.3542 23.0205 19.9583 23.6247L22.2083 25.8747L30.0625 18.0205C30.6667 17.4163 31.6667 17.4163 32.2708 18.0205C32.875 18.6247 32.875 19.6247 32.2708 20.2288L23.3125 29.1872C23 29.4997 22.6042 29.6455 22.2083 29.6455Z"
        fill={color}
      />
    </svg>
  );
};

ShieldTickSvg.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default ShieldTickSvg;
