import PropTypes from 'prop-types';

const MonoLikeSvg = ({ color, width, height }) => {
  return (
    <svg
      width={`${width || 51}`}
      height={`${height || 51}`}
      viewBox="0 0 51 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.9949 21.2984V42.1318M16.9949 21.2984L8.66154 21.2984V42.1317L16.9949 42.1318M16.9949 21.2984L27.819 8.67026C28.8461 7.47199 30.462 6.95686 31.9931 7.33963L32.0922 7.36441C34.8876 8.06324 36.1468 11.3205 34.5485 13.7179L29.4949 21.2984H38.9957C41.6251 21.2984 43.5971 23.7039 43.0814 26.2822L40.5814 38.7822C40.1919 40.7298 38.4819 42.1317 36.4957 42.1317L16.9949 42.1318"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

MonoLikeSvg.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default MonoLikeSvg;
