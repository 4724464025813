import PropTypes from 'prop-types';

const FilingChildCheckSvg = ({ color, width, height }) => {
  return (
    <svg
      width={`${width || 16}`}
      height={`${height || 16}`}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="15"
        height="15"
        rx="3.5"
        fill={color}
        stroke={color}
      />
      <path
        d="M6.86343 10.6667C6.70335 10.6667 6.55128 10.6072 6.43922 10.503L4.17409 8.3972C3.94197 8.18141 3.94197 7.82425 4.17409 7.60846C4.4062 7.39267 4.7904 7.39267 5.02251 7.60846L6.86343 9.31989L10.9775 5.49522C11.2096 5.27943 11.5938 5.27943 11.8259 5.49522C12.058 5.711 12.058 6.06817 11.8259 6.28396L7.28764 10.503C7.17559 10.6072 7.02351 10.6667 6.86343 10.6667Z"
        fill="white"
      />
    </svg>
  );
};

FilingChildCheckSvg.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default FilingChildCheckSvg;
