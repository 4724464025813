import PropTypes from 'prop-types';

const PhoneAndClockSvg = ({ width, height, palette = [] }) => {
  return (
    <svg
      width={`${width || 60}`}
      height={`${height || 60}`}
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M51.2109 47.5781H40.4297C39.4452 47.5781 38.6719 48.3514 38.6719 49.3359C38.6719 50.3202 39.4452 51.0938 40.4297 51.0938H51.2109C54.1291 51.0938 56.4844 53.4492 56.4844 56.3672C56.4844 57.3514 57.2577 58.125 58.2422 58.125C59.2267 58.125 60 57.3514 60 56.3672C60 51.5156 56.0625 47.5781 51.2109 47.5781ZM49.2188 15.9375C49.2188 8.16785 42.9257 1.875 35.1562 1.875C27.3868 1.875 21.0938 8.16785 21.0938 15.9375C21.0938 23.7069 27.3868 30 35.1562 30C42.9257 30 49.2188 23.7069 49.2188 15.9375Z"
        fill={`url(#paint0_linear_50_8576${width + height})`}
      />
      <path
        d="M40.3593 37.0312C36.5973 37.0312 32.9766 36.2225 29.6012 34.7108C28.9335 34.3945 28.1249 34.535 27.6327 35.0625L25.91 36.7852C21.0936 34.078 17.0159 30.0352 14.3087 25.1836L16.0314 23.496C16.5584 22.9687 16.6991 22.16 16.3825 21.492C14.871 18.1172 14.0625 14.496 14.0625 10.7343C14.0625 9.71473 13.2892 8.90625 12.3047 8.90625H1.75781C0.808477 8.90625 0 9.67957 0 10.6289C0 32.953 18.1403 51.0938 40.4297 51.0938C41.4005 51.0938 42.1875 50.3067 42.1875 49.3359V38.7891C42.1875 37.8046 41.379 37.0312 40.3593 37.0312ZM35.1562 17.6953H40.4297C41.4142 17.6953 42.1875 16.9218 42.1875 15.9375C42.1875 14.953 41.4142 14.1797 40.4297 14.1797H36.9141V10.6641C36.9141 9.67957 36.1407 8.90625 35.1562 8.90625C34.1718 8.90625 33.3984 9.67957 33.3984 10.6641V15.9375C33.3984 16.9218 34.1718 17.6953 35.1562 17.6953Z"
        fill={`url(#paint1_linear_50_8576${width + height})`}
      />
      <defs>
        <linearGradient
          id={`paint0_linear_50_8576${width + height}`}
          x1="40.5469"
          y1="58.125"
          x2="40.5469"
          y2="1.875"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[2]} />
          <stop offset="1" stopColor={palette[3]} />
        </linearGradient>
        <linearGradient
          id={`paint1_linear_50_8576${width + height}`}
          x1="21.0938"
          y1="51.0938"
          x2="21.0938"
          y2="8.90625"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[0]} />
          <stop offset="1" stopColor={palette[1]} />
        </linearGradient>
      </defs>
    </svg>
  );
};

PhoneAndClockSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  palette: PropTypes.arrayOf(PropTypes.string),
};

export default PhoneAndClockSvg;
