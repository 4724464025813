import PropTypes from 'prop-types';

const FeeSvg = ({ width, height, palette = [] }) => {
  return (
    <svg
      width={`${width || 61}`}
      height={`${height || 60}`}
      viewBox="0 0 61 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath={`url(#clip0_310_17871${width + height})`}>
        <rect width="60" height="60" transform="translate(0.5)" fill="white" />
        <path
          d="M58.7414 60.0001H2.25779C1.2862 60.0001 0.5 59.2139 0.5 58.2423V24.6099C0.5 24.0812 0.736833 23.58 1.14886 23.2469L11.8128 14.6692C12.338 14.24 13.0658 14.1576 13.677 14.4443C14.2881 14.7361 14.6795 15.3541 14.6795 16.0321V32.35L30.4996 45.197L46.3197 32.35V16.0321C46.3197 15.3541 46.7111 14.7361 47.3222 14.4443C47.9264 14.1559 48.6577 14.2366 49.1864 14.6692L59.8503 23.2469C60.2623 23.58 60.4992 24.0812 60.4992 24.6099V58.2423C60.4992 59.2139 59.713 60.0001 58.7414 60.0001ZM39.1477 10.5476H21.8514C21.1064 10.5476 20.4438 10.079 20.1966 9.37856C19.9459 8.6765 20.1657 7.89546 20.7425 7.42507L29.3907 0.39392C30.0361 -0.131307 30.9631 -0.131307 31.6085 0.39392L40.2567 7.42507C40.8335 7.89546 41.0532 8.6765 40.8026 9.37856C40.5553 10.079 39.8927 10.5476 39.1477 10.5476ZM34.0152 36.9144V35.948C36.1574 34.7069 37.5307 32.4032 37.5307 29.8833C37.5307 27.4681 36.3085 25.3344 34.4512 24.0709C34.798 23.9507 35.1241 23.7773 35.4262 23.5508C36.9746 22.3869 37.287 20.1777 36.1197 18.6293C35.5601 17.8809 34.8426 17.2578 34.0152 16.7892V15.821C34.0152 13.883 32.4394 12.3054 30.4996 12.3054C28.5598 12.3054 26.984 13.883 26.984 15.821V16.7874C24.8417 18.0285 23.4684 20.3322 23.4684 22.8521C23.4684 25.2657 24.6907 27.401 26.548 28.6645C26.2012 28.7847 25.8717 28.9581 25.573 29.1846C24.0246 30.3485 23.7122 32.5577 24.8795 34.1061C25.439 34.8545 26.1566 35.4776 26.984 35.9462V36.9144C26.984 38.8525 28.5598 40.43 30.4996 40.43C32.4394 40.43 34.0152 38.8525 34.0152 36.9144Z"
          fill={`url(#paint0_linear_310_17871${width + height})`}
        />
        <path
          d="M52.9598 29.1984C52.3453 28.4431 51.2364 28.3298 50.4879 28.9392L49.8357 29.4677V8.78977C49.8357 7.81818 49.0495 7.03198 48.0779 7.03198H12.9221C11.9505 7.03198 11.1643 7.81818 11.1643 8.78977V29.4676L10.5121 28.9391C9.7602 28.3313 8.65127 28.443 8.04015 29.1983C7.42902 29.9535 7.54574 31.0608 8.30112 31.6718L29.3912 48.8257C29.7139 49.0884 30.1087 49.2188 30.5001 49.2188C30.8915 49.2188 31.2863 49.0884 31.609 48.8257L52.6991 31.6718C53.4543 31.0608 53.5709 29.9537 52.9598 29.1984ZM32.2578 34.8328V36.9144C32.2578 37.886 31.4716 38.6722 30.5 38.6722C29.5284 38.6722 28.7422 37.886 28.7422 36.9144V34.8225C27.7815 34.4824 26.9199 33.8968 26.284 33.0503C25.7004 32.2744 25.8549 31.1724 26.6308 30.5887C27.3997 30.0085 28.5053 30.1578 29.0924 30.9354C30.0159 32.1628 32.2578 31.4126 32.2578 29.8832C32.2578 28.9134 31.4682 28.1254 30.5 28.1254C27.5921 28.1254 25.2266 25.7599 25.2266 22.8521C25.2266 20.5634 26.7006 18.6306 28.7422 17.9025V15.8209C28.7422 14.8493 29.5284 14.0631 30.5 14.0631C31.4716 14.0631 32.2578 14.8493 32.2578 15.8209V17.9128C33.2185 18.2529 34.0801 18.8385 34.716 19.685C35.2996 20.4609 35.1451 21.5629 34.3692 22.1466C33.6003 22.7268 32.4947 22.5758 31.9076 21.7999C31.5712 21.3519 31.0562 21.0943 30.5 21.0943C29.5318 21.0943 28.7422 21.8822 28.7422 22.8521C28.7422 23.8219 29.5318 24.6099 30.5 24.6099C33.4079 24.6099 35.7734 26.9754 35.7734 29.8832C35.7734 32.1719 34.2994 34.1047 32.2578 34.8328ZM51.5935 52.8517H44.5623C43.5907 52.8517 42.8045 52.0655 42.8045 51.0939C42.8045 50.1223 43.5907 49.3361 44.5623 49.3361H51.5935C52.565 49.3361 53.3512 50.1223 53.3512 51.0939C53.3512 52.0655 52.565 52.8517 51.5935 52.8517Z"
          fill={`url(#paint1_linear_310_17871${width + height})`}
        />
      </g>
      <defs>
        <linearGradient
          id={`paint0_linear_310_17871${width + height}`}
          x1="30.4996"
          y1="60.0001"
          x2="30.4996"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[0]} />
          <stop offset="1" stopColor={palette[1]} />
        </linearGradient>
        <linearGradient
          id={`paint1_linear_310_17871${width + height}`}
          x1="30.5"
          y1="52.8517"
          x2="30.5"
          y2="7.03198"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[2]} />
          <stop offset="1" stopColor={palette[3]} />
        </linearGradient>
        <clipPath id={`clip0_310_17871${width + height}`}>
          <rect
            width="60"
            height="60"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

FeeSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  palette: PropTypes.arrayOf(PropTypes.string),
};

export default FeeSvg;
