import PropTypes from 'prop-types';

const QualitySvg = ({ width, height, palette = [] }) => {
  return (
    <svg
      width={`${width || 70}`}
      height={`${height || 70}`}
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_504_12936)">
        <path
          d="M12.7376 19.9056L16.5267 18.3355C17.5721 17.9029 18.0688 16.7013 17.6362 15.6558C17.1997 14.6124 16.0099 14.1138 14.9565 14.5464L11.1674 16.1164C10.122 16.549 9.6253 17.7506 10.0579 18.7961C10.4973 19.8516 11.7007 20.3349 12.7376 19.9056ZM55.043 5.77846L58.8322 4.20838C59.8775 3.7758 60.3742 2.57418 59.9416 1.52869C59.5051 0.487305 58.3154 -0.0154101 57.2619 0.419219L53.4728 1.9893C52.4274 2.42188 51.9307 3.6235 52.3633 4.66898C52.8026 5.72445 54.006 6.20775 55.043 5.77846ZM53.4728 18.3355L57.2619 19.9056C58.3016 20.336 59.5033 19.8491 59.9416 18.7961C60.3742 17.7508 59.8775 16.549 58.8322 16.1164L55.043 14.5464C54.0095 14.1219 52.8 14.6064 52.3633 15.6558C51.9307 16.7013 52.4274 17.9029 53.4728 18.3355ZM11.1675 4.20838L14.9567 5.77846C15.9963 6.20885 17.198 5.72199 17.6363 4.66898C18.0689 3.62363 17.5722 2.42188 16.5269 1.9893L12.7376 0.419219C11.7001 -0.0093945 10.4946 0.479238 10.0579 1.52869C9.6253 2.57418 10.122 3.7758 11.1675 4.20838ZM61.3154 42.202L53.1123 29.8973C52.7318 29.3265 52.0908 28.984 51.406 28.984H18.5935C17.9085 28.984 17.2677 29.3265 16.8873 29.8973L8.68413 42.202C8.14341 43.015 8.24759 44.0985 8.94048 44.7895L33.5499 69.3989C33.9504 69.7995 34.4752 69.9998 34.9998 69.9998C35.5243 69.9998 36.0492 69.7995 36.4497 69.3989L61.059 44.7895C61.7521 44.0985 61.8562 43.015 61.3154 42.202Z"
          fill="url(#paint0_linear_504_12936)"
        />
        <path
          d="M24.8501 19.0148C25.1305 19.8519 25.9156 20.4167 26.7967 20.4167H43.203C44.0841 20.4167 44.8693 19.8519 45.1496 19.0148L49.2511 6.71009C49.5114 5.923 49.2752 5.05579 48.6463 4.5111C48.0215 3.96833 47.1362 3.85827 46.3873 4.22673L40.0187 7.41105L36.8343 1.04241C36.1373 -0.347471 33.8623 -0.347471 33.1654 1.04241L29.981 7.41105L23.6124 4.22673C22.8674 3.85827 21.9782 3.96833 21.3534 4.5111C20.7245 5.05579 20.4882 5.923 20.7486 6.71009L24.8501 19.0148ZM48.0095 41.289H44.4706L42.9867 38.321C42.4779 37.3076 41.2523 36.9031 40.2349 37.4037C39.2216 37.9104 38.8131 39.1421 39.3177 40.1555L39.8845 41.289H30.1153L30.6821 40.1555C31.1868 39.1421 30.7782 37.9104 29.7649 37.4037C28.7515 36.907 27.5219 37.3076 27.0131 38.321L25.5291 41.289H21.9902C20.8567 41.289 19.9395 42.2062 19.9395 43.3398C19.9395 44.4733 20.8567 45.3906 21.9902 45.3906H32.9491V55.6445C32.9491 56.778 33.8663 57.6952 34.9998 57.6952C36.1334 57.6952 37.0506 56.778 37.0506 55.6445V45.3906H48.0095C49.143 45.3906 50.0602 44.4733 50.0602 43.3398C50.0602 42.2062 49.143 41.289 48.0095 41.289Z"
          fill="url(#paint1_linear_504_12936)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_504_12936"
          x1="34.9998"
          y1="69.9998"
          x2="34.9998"
          y2="0.262695"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[0]} />
          <stop offset="1" stopColor={palette[1]} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_504_12936"
          x1="34.9998"
          y1="57.6953"
          x2="34.9998"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[2]} />
          <stop offset="1" stopColor={palette[3]} />
        </linearGradient>
        <clipPath id="clip0_504_12936">
          <rect width="70" height="70" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

QualitySvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  palette: PropTypes.arrayOf(PropTypes.string),
};

export default QualitySvg;
