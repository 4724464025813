import PropTypes from 'prop-types';

const CertificateSvg = ({ width, height, palette = [] }) => {
  return (
    <svg
      width={`${width || 70}`}
      height={`${height || 70}`}
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath={`url(#clip0_504_8870${width + height})`}>
        <path
          d="M39.1016 43.0664C39.1016 47.5885 42.7826 51.2695 47.3047 51.2695C51.8268 51.2695 55.5078 47.5885 55.5078 43.0664C55.5078 38.5443 51.8268 34.8633 47.3047 34.8633C42.7826 34.8633 39.1016 38.5443 39.1016 43.0664ZM56.341 50.3403C55.6 50.0119 54.7348 50.148 54.1339 50.6927C50.3848 54.0813 44.2246 54.0813 40.4755 50.6927C40.33 50.5616 40.1695 50.4547 39.9998 50.3714C39.9167 50.2012 39.8098 50.0406 39.6782 49.8956C37.984 48.0211 37.0508 45.5979 37.0508 43.0664C37.0508 37.4108 41.649 32.8125 47.3047 32.8125C48.4382 32.8125 49.3555 31.8953 49.3555 30.7617V2.05078C49.3555 0.917246 48.4382 0 47.3047 0H10.3906C9.25709 0 8.33984 0.917246 8.33984 2.05078V51.2695C8.33984 52.4031 9.25709 53.3203 10.3906 53.3203H37.0508V67.9492C37.0508 68.6582 37.4192 69.3191 38.0241 69.6955C38.6209 70.06 39.382 70.1001 40.0188 69.7837L47.3047 66.1388L54.5906 69.7837C54.8789 69.9279 55.1954 70 55.5078 70C56.6545 70 57.5586 69.0665 57.5586 67.9492V52.2148C57.5586 51.4057 57.082 50.6687 56.341 50.3403Z"
          fill={`url(#paint0_linear_504_8870${width + height})`}
        />
        <path
          d="M47.3047 28.7109C39.3887 28.7109 32.9492 35.1504 32.9492 43.0664C32.9492 50.9824 39.3887 57.4219 47.3047 57.4219C55.2207 57.4219 61.6602 50.9824 61.6602 43.0664C61.6602 35.1504 55.2207 28.7109 47.3047 28.7109ZM47.3047 49.2188C43.9003 49.2188 41.1523 46.4706 41.1523 43.0664C41.1523 39.6621 43.9003 36.9141 47.3047 36.9141C50.7091 36.9141 53.457 39.6621 53.457 43.0664C53.457 46.4706 50.7091 49.2188 47.3047 49.2188ZM18.5938 16.4062H39.1016C40.2351 16.4062 41.1523 15.489 41.1523 14.3555C41.1523 13.2219 40.2351 12.3047 39.1016 12.3047H18.5938C17.4602 12.3047 16.543 13.2219 16.543 14.3555C16.543 15.489 17.4602 16.4062 18.5938 16.4062ZM18.5938 24.6094H39.1016C40.2351 24.6094 41.1523 23.6921 41.1523 22.5586C41.1523 21.4251 40.2351 20.5078 39.1016 20.5078H18.5938C17.4602 20.5078 16.543 21.4251 16.543 22.5586C16.543 23.6921 17.4602 24.6094 18.5938 24.6094ZM26.7969 28.7109H18.5938C17.4602 28.7109 16.543 29.6282 16.543 30.7617C16.543 31.8953 17.4602 32.8125 18.5938 32.8125H26.7969C27.9304 32.8125 28.8477 31.8953 28.8477 30.7617C28.8477 29.6282 27.9304 28.7109 26.7969 28.7109Z"
          fill={`url(#paint1_linear_504_8870${width + height})`}
        />
      </g>
      <defs>
        <linearGradient
          id={`paint0_linear_504_8870${width + height}`}
          x1="32.9492"
          y1="70"
          x2="32.9492"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[0]} />
          <stop offset="1" stopColor={palette[1]} />
        </linearGradient>
        <linearGradient
          id={`paint1_linear_504_8870${width + height}`}
          x1="39.1016"
          y1="57.4219"
          x2="39.1016"
          y2="12.3047"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[2]} />
          <stop offset="1" stopColor={palette[3]} />
        </linearGradient>
        <clipPath id={`clip0_504_8870${width + height}`}>
          <rect width="70" height="70" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

CertificateSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  palette: PropTypes.arrayOf(PropTypes.string),
};

export default CertificateSvg;
