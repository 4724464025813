import PropTypes from 'prop-types';

const ClipboardSvg = ({ width, height, mixBlendMode, palette = [] }) => {
  return (
    <svg
      width={`${width || 51}`}
      height={`${height || 50}`}
      viewBox="0 0 51 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g style={{ mixBlendMode: mixBlendMode || 'normal' }}>
        <path
          d="M38.6836 36.8164C38.6836 36.9365 38.6982 37.0557 38.7275 37.1719L41.6572 48.8906C41.8203 49.543 42.4062 50 43.0781 50C43.75 50 44.3359 49.543 44.499 48.8906L47.4287 37.1719C47.458 37.0557 47.4727 36.9365 47.4727 36.8164L42.8828 32.8125L38.6836 36.8164ZM46.0078 5.85938H40.1484C39.3398 5.85938 38.6836 6.51562 38.6836 7.32422V13.1836L42.6875 16.1133L47.4727 13.1836V7.32422C47.4727 6.51562 46.8164 5.85938 46.0078 5.85938ZM34.2891 5.85938H25.5C24.691 5.85938 24.0352 6.51523 24.0352 7.32422V8.78906H15.2461V7.32422C15.2461 6.51523 14.5902 5.85938 13.7812 5.85938H4.99219C4.1832 5.85938 3.52734 6.51523 3.52734 7.32422V48.5352C3.52734 49.3441 4.1832 50 4.99219 50H34.2891C35.098 50 35.7539 49.3441 35.7539 48.5352V7.32422C35.7539 6.51523 35.098 5.85938 34.2891 5.85938Z"
          fill={`url(#paint0_linear_665_6859${width + height})`}
        />
        <path
          d="M13.7812 11.7188H25.5C26.309 11.7188 26.9648 11.0629 26.9648 10.2539V4.39453C26.9648 3.58555 26.309 2.92969 25.5 2.92969H23.7842C23.1796 1.22451 21.5504 0 19.6406 0C17.7309 0 16.1017 1.22451 15.4971 2.92969H13.7812C12.9723 2.92969 12.3164 3.58555 12.3164 4.39453V10.2539C12.3164 11.0629 12.9723 11.7188 13.7812 11.7188ZM28.4297 17.7734H10.8516C10.0426 17.7734 9.38672 18.4293 9.38672 19.2383C9.38672 20.0473 10.0426 20.7031 10.8516 20.7031H28.4297C29.2387 20.7031 29.8945 20.0473 29.8945 19.2383C29.8945 18.4293 29.2387 17.7734 28.4297 17.7734ZM28.4297 26.5625H10.8516C10.0426 26.5625 9.38672 27.2184 9.38672 28.0273C9.38672 28.8363 10.0426 29.4922 10.8516 29.4922H28.4297C29.2387 29.4922 29.8945 28.8363 29.8945 28.0273C29.8945 27.2184 29.2387 26.5625 28.4297 26.5625ZM28.4297 35.3516H10.8516C10.0426 35.3516 9.38672 36.0074 9.38672 36.8164C9.38672 37.6254 10.0426 38.2812 10.8516 38.2812H28.4297C29.2387 38.2812 29.8945 37.6254 29.8945 36.8164C29.8945 36.0074 29.2387 35.3516 28.4297 35.3516ZM38.6836 13.1836V36.8164H47.4727V13.1836H38.6836Z"
          fill={`url(#paint1_linear_665_6859${width + height})`}
        />
      </g>
      <defs>
        <linearGradient
          id={`paint0_linear_665_6859${width + height}`}
          x1="25.5"
          y1="50"
          x2="25.5"
          y2="5.85938"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[0]} />
          <stop offset="1" stopColor={palette[1]} />
        </linearGradient>
        <linearGradient
          id={`paint1_linear_665_6859${width + height}`}
          x1="28.4297"
          y1="38.2812"
          x2="28.4297"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[2]} />
          <stop offset="1" stopColor={palette[3]} />
        </linearGradient>
      </defs>
    </svg>
  );
};

ClipboardSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  mixBlendMode: PropTypes.string,
  palette: PropTypes.arrayOf(PropTypes.string),
};

export default ClipboardSvg;
