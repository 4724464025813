export const CARD_TYPE = {
  mastercard: 'mastercard',
  visa: 'visa',
  amex: 'amex',
  discover: 'discover',
};

export const PAYMENT_STATUS = {
  declined: 'Declined',
  success: 'Success',
};

export const MAX_NUM_OF_PAYMENT_ATTEMPTS = 3;
