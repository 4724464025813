import { config } from '_config';
import { AxiosClient } from '_services/AxiosClient';

const { apiUrl2 } = config;

class TipsService extends AxiosClient {
  constructor(url) {
    super(url);
    this.subscribe = this.subscribe.bind(this);
  }

  subscribe({ subscriptionType = 'INC_MONTHLY_TIPS', body }) {
    const path = `/${subscriptionType}/subscribe`;
    return this.client.post(path, body);
  }
}

const ServiceInstance = new TipsService(apiUrl2);

export { ServiceInstance as TipsService };
