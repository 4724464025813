import PropTypes from 'prop-types';

const CheckFilledSvg = ({ color, width, height }) => {
  return (
    <svg
      width={`${width || 37}`}
      height={`${height || 36}`}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M16.19 2.5H7.81C4.17 2.5 2 4.67 2 8.31V16.68C2 20.33 4.17 22.5 7.81 22.5H16.18C19.82 22.5 21.99 20.33 21.99 16.69V8.31C22 4.67 19.83 2.5 16.19 2.5Z"
        fill={color}
      />
      <path
        d="M10.5795 16.0796C10.3795 16.0796 10.1895 15.9996 10.0495 15.8596L7.21945 13.0296C6.92945 12.7396 6.92945 12.2596 7.21945 11.9696C7.50945 11.6796 7.98945 11.6796 8.27945 11.9696L10.5795 14.2696L15.7195 9.12961C16.0095 8.83961 16.4895 8.83961 16.7795 9.12961C17.0695 9.41961 17.0695 9.89961 16.7795 10.1896L11.1095 15.8596C10.9695 15.9996 10.7795 16.0796 10.5795 16.0796Z"
        fill={color}
      />
    </svg>
  );
};

CheckFilledSvg.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default CheckFilledSvg;
