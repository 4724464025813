import { createSelector } from '@reduxjs/toolkit';
import { PRODUCT_CODES } from '_constants/products';

export const selectActiveProductCode = (state) =>
  state.products.activeProductCode;
export const selectActiveProductCodeRestored = (state) =>
  state.products.restored;
export const selectProducts = (state) => state.products.items;
export const selectProductsError = (state) => state.products.error;
export const selectProductsLoading = (state) => state.products.loading;

export const selectProductNameMap = createSelector(
  [selectProducts],
  (products) => {
    return products.reduce((res, { code, customerName }) => {
      res[code] = customerName;

      if (code === PRODUCT_CODES.incEIN) {
        res[code] = 'Employer Identification Number';
      }

      return res;
    }, {});
  }
);

export const selectProductCategoryMap = createSelector(
  [selectProducts],
  (products) => {
    return products.reduce((res, { category, code }) => {
      res[code] = category;
      return res;
    }, {});
  }
);

export const selectMainProducts = createSelector([selectProducts], (products) =>
  products?.filter(({ main }) => main)
);

export const selectMainProductCodes = createSelector(
  [selectMainProducts],
  (mainProducts) => mainProducts.map(({ code }) => code)
);

export const selectEntityOptions = createSelector(
  [selectMainProducts],
  (mainProducts) =>
    mainProducts.map(({ code, customerName }) => ({
      title: customerName,
      value: code,
    }))
);

export const selectActiveProduct = createSelector(
  [selectActiveProductCode, selectProducts],
  (activeProductCode, products) =>
    products?.find(({ code }) => code === activeProductCode)
);

export const selectRegisteredAgentActive = createSelector(
  [selectProducts],
  (products) =>
    products?.some(({ code }) => code === PRODUCT_CODES.registeredAgent)
);

export const selectRushProcessingActive = createSelector(
  [selectProducts],
  (products) =>
    products?.some(({ code }) => code === PRODUCT_CODES.rushProcessing)
);

export const selectLinkedProductCodesByMainCodes = (productCodes = []) =>
  createSelector([selectProducts], (products) =>
    productCodes.reduce((linkedProducts, code) => {
      products
        ?.find((item) => item.code === code)
        ?.linkedOneOf?.forEach(({ code }) => {
          linkedProducts.push(code);
        });

      return linkedProducts;
    }, [])
  );
