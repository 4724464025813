import PropTypes from 'prop-types';

const GrocerySvg = ({ width, height, mixBlendMode, palette = [] }) => {
  return (
    <svg
      width={`${width || 50}`}
      height={`${height || 50}`}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g style={{ mixBlendMode: mixBlendMode || 'normal' }}>
        <path
          d="M49.9707 14.4238C49.9414 14.3065 49.8827 14.1895 49.8534 14.1015L47.0703 8.53516L25 5.60547L2.92969 8.53516L0.146582 14.1015C0.117285 14.1895 0.0585937 14.3065 0.0292969 14.4238L25 17.3535L49.9707 14.4238ZM46.4037 21.9544C45.9831 21.6854 45.4596 21.6411 45.0103 21.8457C43.104 22.6901 40.6244 21.2132 40.6244 18.8474C40.6244 18.0377 39.9692 17.3825 39.1596 17.3825C38.3499 17.3825 37.6947 18.0377 37.6947 18.8474C37.6947 20.624 36.1522 22.0703 34.3756 22.0703C32.5989 22.0703 31.1512 20.624 31.1512 18.8474C31.1512 18.0377 30.496 17.3825 29.6863 17.3825C28.8767 17.3825 28.2215 18.0377 28.2215 18.8474C28.2215 20.624 26.7767 22.0703 25 22.0703C23.2233 22.0703 21.7785 20.624 21.7785 18.8474C21.7785 18.0377 21.1233 17.3825 20.3137 17.3825C19.504 17.3825 18.8488 18.0377 18.8488 18.8474C18.8488 20.624 17.4012 22.0703 15.6244 22.0703C13.8477 22.0703 12.3053 20.624 12.3053 18.8474C12.3053 18.0377 11.6501 17.3825 10.8404 17.3825C10.0308 17.3825 9.37558 18.0377 9.37558 18.8474C9.37558 21.2143 6.89658 22.6904 4.98965 21.8457C4.54336 21.6411 4.01406 21.6854 3.59629 21.9544C3.18145 22.2248 2.92969 22.6868 2.92969 23.1832V46.9727C2.92969 47.7816 3.58555 48.4375 4.39453 48.4375H8.78906C10.4071 48.4375 11.7187 47.1258 11.7187 45.5078V30.8594H20.6055V45.5078C20.6055 47.1258 21.9171 48.4375 23.5352 48.4375H45.6055C46.4145 48.4375 47.0703 47.7816 47.0703 46.9727V23.1832C47.0703 22.6868 46.8186 22.2248 46.4037 21.9544Z"
          fill={`url(#paint0_linear_665_6890${width + height})`}
        />
        <path
          d="M47.0703 3.02734C47.0703 2.61719 46.8943 2.26563 46.6308 2.00186C46.3672 1.73828 46.0156 1.5625 45.6055 1.5625H4.39453C3.98438 1.5625 3.63281 1.73828 3.36924 2.00186C3.10566 2.26563 2.92969 2.61719 2.92969 3.02734V8.53516H47.0703V3.02734ZM49.9707 14.4238H0.0292969C0 14.5117 0 14.6288 0 14.7461V18.8476C0 22.1927 2.72656 25 6.15225 25C8.03486 25 9.74961 24.152 10.8396 22.832C11.9425 24.1665 13.6471 25 15.6251 25C17.5085 25 19.2224 24.1513 20.3125 22.832C21.4062 24.1553 23.1215 25 25 25C26.8796 25 28.5943 24.1548 29.6874 22.832C30.7785 24.1523 32.4936 25 34.3748 25C36.353 25 38.0575 24.1664 39.1603 22.832C40.2508 24.1524 41.9661 25 43.8477 25C47.2135 25 50 22.2354 50 18.8476V14.7461C50 14.6288 50 14.5117 49.9707 14.4238ZM22.0703 27.9297H10.2539C9.44492 27.9297 8.78906 28.5855 8.78906 29.3945V48.4375H23.5352V29.3945C23.5352 28.5855 22.8793 27.9297 22.0703 27.9297ZM39.7461 27.9297H27.9297C27.12 27.9297 26.4648 28.5849 26.4648 29.3945V41.1133C26.4648 41.923 27.12 42.5781 27.9297 42.5781H39.7461C40.5558 42.5781 41.2109 41.923 41.2109 41.1133V29.3945C41.2109 28.5849 40.5558 27.9297 39.7461 27.9297Z"
          fill={`url(#paint1_linear_665_6890${width + height})`}
        />
      </g>
      <defs>
        <linearGradient
          id={`paint0_linear_665_6890${width + height}`}
          x1="25"
          y1="48.4375"
          x2="25"
          y2="5.60547"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[0]} />
          <stop offset="1" stopColor={palette[1]} />
        </linearGradient>
        <linearGradient
          id={`paint1_linear_665_6890${width + height}`}
          x1="25"
          y1="48.4375"
          x2="25"
          y2="1.5625"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[2]} />
          <stop offset="1" stopColor={palette[3]} />
        </linearGradient>
      </defs>
    </svg>
  );
};

GrocerySvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  mixBlendMode: PropTypes.string,
  palette: PropTypes.arrayOf(PropTypes.string),
};

export default GrocerySvg;
