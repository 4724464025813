import { useEffect } from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { DefaultSeo } from 'next-seo';
import GTM from 'react-gtm-module';
import { hotjar } from 'react-hotjar';

import 'styles/index.css';

import { config } from '_config';
import { theme } from '_constants/theme';
import { wrapper } from '_redux/store';

import PageLayout from '_components/composed/layouts/PageLayout';

function App({ Component, ...restProps }) {
  const { store, props } = wrapper.useWrappedStore(restProps);

  useEffect(() => {
    hotjar.initialize({ id: config.hjId, sv: config.hjSv });
  }, []);

  useEffect(() => {
    import('bootstrap/dist/js/bootstrap');
    GTM.initialize({ gtmId: config.gtmId });
  }, []);

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <PageLayout>
          <DefaultSeo
            titleTemplate="%s | Incorpified"
            defaultTitle="Incorpified - Streamline your business incorporation process. Get started with LLC formation, trademark registration, and more with our fast and affordable services."
            openGraph={{
              type: 'website',
              url: 'https://incorpified.com/',
              title:
                'Incorpified - Official Site | Register Your LLC Online Today',
              description:
                'Incorpified - Streamline your business incorporation process. Get started with LLC formation, trademark registration, and more with our fast and affordable services.',
              images: [
                {
                  url: `${config.basePath}/favicon-32x32.png`,
                  alt: 'Incorpified Logo',
                },
              ],
            }}
          />
          <Component {...props.pageProps} />
        </PageLayout>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
