import PropTypes from 'prop-types';

const ReportSvg = ({ width, height, palette = [] }) => {
  return (
    <svg
      width={`${width || 70}`}
      height={`${height || 70}`}
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M59.6094 0H14.4922C13.3596 0 12.4414 0.91793 12.4414 2.05078V65.8984H49.3555C49.3555 68.1543 55.3027 70 57.5586 70C59.8145 70 61.6602 68.1543 61.6602 65.8984V2.05078C61.6602 0.91793 60.742 0 59.6094 0Z"
        fill="url(#paint0_linear_504_6919)"
      />
      <path
        d="M22.6953 45.2539H51.4062C52.5398 45.2539 53.457 44.3367 53.457 43.2031C53.457 42.0696 52.5398 41.1523 51.4062 41.1523H22.6953C21.5618 41.1523 20.6445 42.0696 20.6445 43.2031C20.6445 44.3367 21.5618 45.2539 22.6953 45.2539ZM22.6953 37.0508H51.4062C52.5398 37.0508 53.457 36.1335 53.457 35C53.457 33.8665 52.5398 32.9492 51.4062 32.9492H27.646L31.7476 28.8477H39.1016C39.6335 28.8477 40.1692 28.6303 40.5541 28.2443L49.4979 19.3005C49.7874 20.0818 50.5236 20.6445 51.4062 20.6445C52.5398 20.6445 53.457 19.7273 53.457 18.5938V14.4922C53.457 13.3614 52.5287 12.4414 51.4062 12.4414H47.3047C46.1712 12.4414 45.2539 13.3587 45.2539 14.4922C45.2539 15.3748 45.8166 16.1111 46.598 16.4005L38.2524 24.7461H30.8984C30.3665 24.7461 29.8308 24.9635 29.4459 25.3494L24.7461 30.0493V10.3906C24.7461 9.25709 23.8288 8.33984 22.6953 8.33984C21.5618 8.33984 20.6445 9.25709 20.6445 10.3906V35C20.6445 36.1219 21.5642 37.0508 22.6953 37.0508ZM22.6953 53.457H51.4062C52.5398 53.457 53.457 52.5398 53.457 51.4062C53.457 50.2727 52.5398 49.3555 51.4062 49.3555H22.6953C21.5618 49.3555 20.6445 50.2727 20.6445 51.4062C20.6445 52.5398 21.5618 53.457 22.6953 53.457ZM53.457 65.8984V63.8477C53.457 62.6991 52.5548 61.7969 51.4062 61.7969H12.4414C10.1855 61.7969 8.33984 63.6426 8.33984 65.8984C8.33984 68.1543 10.1855 70 12.4414 70H57.5586C55.3027 70 53.457 68.1543 53.457 65.8984Z"
        fill="url(#paint1_linear_504_6919)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_504_6919"
          x1="37.0508"
          y1="70"
          x2="37.0508"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[0]} />
          <stop offset="1" stopColor={palette[1]} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_504_6919"
          x1="32.9492"
          y1="70.0001"
          x2="32.9492"
          y2="8.33984"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[2]} />
          <stop offset="1" stopColor={palette[3]} />
        </linearGradient>
      </defs>
    </svg>
  );
};

ReportSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  palette: PropTypes.arrayOf(PropTypes.string),
};

export default ReportSvg;
