export const ROUTES = {
  about: '/about',
  bookkeeping: '/bookkeeping',
  contactUs: '/contact-us',
  einTaxId: '/ein-tax-id',
  home: '/',
  llcIncorporation: '/llc-incorporation',
  llcIncorporationFiling: '/llc-incorporation-filing',
  merchantProcessing: '/merchant-processing',
  payment: '/payment',
  payroll: '/payroll',
  registeredAgent: '/registered-agent',
  services: '/services',
  thankyou: '/thankyou',
  trademarking: '/trademarking',
  virtualAddress: '/virtual-address',
  upsell: '/upsell',
  privacy: '/privacy',
  terms: '/terms',
  blog: '/blog',
  blogGallery: '/blog/gallery',
};

export const BLOG_SUB_ROUTES = {
  tenBestReasonsToIncorporate: '/10-best-reasons-to-incorporate',
  howToFormAnLLCStepByStepGuide: '/how-to-form-an-llc-step-by-step-guide',
  understandingTheDifferentTypesOfBusinessEntities:
    '/understanding-the-different-types-of-business-entities',
  commonMistakesToAvoidWhenFormingAnLLC:
    '/common-mistakes-to-avoid-when-forming-an-llc',
  choosingTheRightNameForYourBusiness:
    '/choosing-the-right-name-for-your-business',
  definingYourBrandIdentityComprehensiveGuide:
    '/defining-your-brand-identity-comprehensive-guide',
  checkingDomainAndTrademarkAvailability:
    '/checking-domain-and-trademark-availability',
  howToWriteABusinessPlanStepByStepGuide:
    '/how-to-write-a-business-plan-step-by-step-guide',
  registeringYourTrademarkGuide: '/registering-your-trademark-guide',
  howToChooseBestStateToIncorporateBusiness:
    '/how-to-choose-best-state-to-incorporate-business',
  benefitsOfFormingAnLlcForSmallBusinesses:
    '/benefits-of-forming-an-llc-for-small-businesses',
  understandingDifferencesBetweenLLCAndSCorp:
    '/understanding-differences-between-llc-and-s-corp',
  importanceOfOperatingAgreementsForLLCs:
    '/importance-of-operating-agreements-for-llcs',
  essentialTaxTipsForNewBusinessOwners:
    '/essential-tax-tips-for-new-business-owners',
  roleOfRegisteredAgentsInBusinessFormation:
    '/role-of-registered-agents-in-business-formation',
  topReasonsToUseProfessionalRegisteredAgentService:
    '/top-reasons-to-use-professional-registered-agent-service',
};
