import PropTypes from 'prop-types';

const Taxes1Svg = ({ width, height, palette = [] }) => {
  return (
    <svg
      width={`${width || 70}`}
      height={`${height || 70}`}
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath={`url(#clip0_504_8854${width + height})`}>
        <path
          d="M51.4062 10.2539L47.3047 0H10.3906C9.24205 0 8.33984 0.902207 8.33984 2.05078V67.9492C8.33984 69.0975 9.24205 70 10.3906 70H59.6094C60.7579 70 61.6602 69.0975 61.6602 67.9492V14.3555L51.4062 10.2539Z"
          fill={`url(#paint0_linear_504_8854${width + height})`}
        />
        <path
          d="M37.3953 27.7977C38.03 28.7463 39.303 28.9905 40.2392 28.3664C41.1805 27.7375 41.4369 26.4659 40.8079 25.5225L32.6048 13.2178C31.972 12.2726 30.6942 12.0243 29.7609 12.6491C28.8196 13.278 28.5633 14.5496 29.1922 15.493L37.3953 27.7977ZM28.8477 26.6602C28.8477 27.7927 29.7659 28.7109 30.8984 28.7109C32.031 28.7109 32.9492 27.7927 32.9492 26.6602C32.9492 25.5276 32.031 24.6094 30.8984 24.6094C29.7659 24.6094 28.8477 25.5276 28.8477 26.6602ZM39.1016 16.4062C40.2341 16.4062 41.1523 15.488 41.1523 14.3555C41.1523 13.2229 40.2341 12.3047 39.1016 12.3047C37.969 12.3047 37.0508 13.2229 37.0508 14.3555C37.0508 15.488 37.969 16.4062 39.1016 16.4062ZM51.4062 32.8125H18.5938C17.4602 32.8125 16.543 33.7297 16.543 34.8633V59.4727C16.543 60.6062 17.4602 61.5234 18.5938 61.5234H51.4062C52.5398 61.5234 53.457 60.6062 53.457 59.4727V34.8633C53.457 33.7297 52.5398 32.8125 51.4062 32.8125ZM28.8477 57.4219H20.6445V53.3203H28.8477V57.4219ZM28.8477 49.2188H20.6445V45.1172H28.8477V49.2188ZM28.8477 41.0156H20.6445V36.9141H28.8477V41.0156ZM49.3555 57.4219H32.9492V53.3203H49.3555V57.4219ZM49.3555 49.2188H32.9492V45.1172H49.3555V49.2188ZM49.3555 41.0156H32.9492V36.9141H49.3555V41.0156ZM49.3555 14.3555H61.6602C61.6602 13.8223 61.4549 13.2891 61.0448 12.9198L48.7401 0.615098C48.3711 0.205078 47.8379 0 47.3047 0V12.3047C47.3047 13.4373 48.2229 14.3555 49.3555 14.3555Z"
          fill={`url(#paint1_linear_504_8854${width + height})`}
        />
      </g>
      <defs>
        <linearGradient
          id={`paint0_linear_504_8854${width + height}`}
          x1="35"
          y1="70"
          x2="35"
          y2="0.000100136"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[2]} />
          <stop offset="1" stopColor={palette[3]} />
        </linearGradient>
        <linearGradient
          id={`paint1_linear_504_8854${width + height}`}
          x1="16.9453"
          y1="60.9683"
          x2="67.3651"
          y2="23.9937"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[0]} />
          <stop offset="1" stopColor={palette[1]} />
        </linearGradient>
        <clipPath id={`clip0_504_8854${width + height}`}>
          <rect width="70" height="70" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

Taxes1Svg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  palette: PropTypes.arrayOf(PropTypes.string),
};

export default Taxes1Svg;
