import { PROCESSING_CODES, PROCESSING_LABELS } from '_constants/processing';

export const getProcessingLabel = (processingCode) => {
  const MAP = {
    [PROCESSING_CODES.gold]: PROCESSING_LABELS.starter,
    [PROCESSING_CODES.platinum]: PROCESSING_LABELS.deluxe,
    [PROCESSING_CODES.diamond]: PROCESSING_LABELS.premium,
  };

  return MAP[processingCode] || null;
};
