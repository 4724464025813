import PropTypes from 'prop-types';

const LockPCSvg = ({ width, height, palette = [] }) => {
  return (
    <svg
      width={`${width || 80}`}
      height={`${height || 80}`}
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath={`url(#clip0_504_6935${width + height})`}>
        <path
          d="M58.75 7.03125H21.25V53.9062H58.75V7.03125ZM58.75 75.3125H51.7188V58.5938C51.7188 57.2983 50.6705 56.25 49.375 56.25H30.625C29.3295 56.25 28.2812 57.2983 28.2812 58.5938V75.3125H21.25C19.9545 75.3125 18.9062 76.3608 18.9062 77.6562C18.9062 78.9517 19.9545 80 21.25 80H58.75C60.0455 80 61.0938 78.9517 61.0938 77.6562C61.0938 76.3608 60.0455 75.3125 58.75 75.3125Z"
          fill={`url(#paint0_linear_504_6935${width + height})`}
        />
        <path
          d="M39.8592 14.0625C37.3281 14.0625 35.3125 16.125 35.3125 18.6092V23.4375H44.6875V18.8905C44.6875 16.2186 42.5311 14.0625 39.8592 14.0625ZM68.2812 0H7.03125C3.14062 0 0 3.14062 0 7.03125V53.9062C0 57.7967 3.14062 60.9375 7.03125 60.9375H72.9688C76.8594 60.9375 80 57.7967 80 53.9062V11.7188C80 5.25 74.75 0 68.2812 0ZM54.0625 37.5C54.0625 45.2342 47.7345 51.5625 40 51.5625C32.2655 51.5625 25.9375 45.2342 25.9375 37.5V25.7812C25.9375 24.4686 26.9686 23.4375 28.2812 23.4375H30.625V18.6092C30.625 13.5 34.75 9.375 39.8592 9.375C45.1095 9.375 49.375 13.6405 49.375 18.8905V23.4375H51.7188C53.0314 23.4375 54.0625 24.4686 54.0625 25.7812V37.5ZM40 32.8125C38.7045 32.8125 37.6562 33.8608 37.6562 35.1562V39.8438C37.6562 41.1392 38.7045 42.1875 40 42.1875C41.2955 42.1875 42.3438 41.1392 42.3438 39.8438V35.1562C42.3438 33.8608 41.2955 32.8125 40 32.8125Z"
          fill={`url(#paint1_linear_504_6935${width + height})`}
        />
      </g>
      <defs>
        <linearGradient
          id={`paint0_linear_504_6935${width + height}`}
          x1="40"
          y1="80"
          x2="40"
          y2="7.03135"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[2]} />
          <stop offset="1" stopColor={palette[3]} />
        </linearGradient>
        <linearGradient
          id={`paint1_linear_504_6935${width + height}`}
          x1="0.713334"
          y1="60.3877"
          x2="51.1929"
          y2="-5.88304"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[0]} />
          <stop offset="1" stopColor={palette[1]} />
        </linearGradient>
        <clipPath id={`clip0_504_6935${width + height}`}>
          <rect width="80" height="80" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

LockPCSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  palette: PropTypes.arrayOf(PropTypes.string),
};

export default LockPCSvg;
