import { useEffect, useState } from 'react';
import { getBlogSubRoute } from '_helpers/blog';

export const useBlogSubRoute = (path) => {
  const [route, setRoute] = useState();

  useEffect(() => {
    if (path) {
      setRoute(getBlogSubRoute(path));
    }
  }, [path]);

  return route;
};
