import PropTypes from 'prop-types';

const PrivacySvg = ({ width, height, palette = [] }) => {
  return (
    <svg
      width={`${width || 70}`}
      height={`${height || 70}`}
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_504_12914)">
        <path
          d="M35.0004 70C34.7801 70 34.5638 69.9639 34.3515 69.8939C18.7942 64.7088 8.33984 50.2049 8.33984 33.8043V10.2531C8.33984 9.36984 8.90464 8.58671 9.74178 8.30834L34.3515 0.105104C34.776 -0.0350347 35.2247 -0.0350347 35.6492 0.105104L60.2589 8.30834C61.0961 8.58671 61.6609 9.36984 61.6609 10.2531V33.8044C61.6609 50.2049 51.2065 64.7088 35.6492 69.8939C35.437 69.9639 35.2208 70 35.0004 70Z"
          fill="url(#paint0_linear_504_12914)"
        />
        <path
          d="M43.2035 45.39H26.797C25.6635 45.39 24.7462 46.3073 24.7462 47.4409C24.7462 48.5744 25.6635 49.4917 26.797 49.4917H43.2035C44.337 49.4917 45.2543 48.5744 45.2543 47.4409C45.2543 46.3073 44.337 45.39 43.2035 45.39ZM53.2412 30.1171L49.1396 21.9139C49.1218 21.8786 49.0812 21.8678 49.0616 21.8343C48.7088 21.2155 48.0692 20.7803 47.3051 20.7803H37.0511V18.7295C37.0511 17.596 36.1338 16.6787 35.0003 16.6787C33.8667 16.6787 32.9494 17.596 32.9494 18.7295V20.7803H22.6954C21.9314 20.7803 21.2917 21.2154 20.9389 21.8343C20.9191 21.8678 20.8787 21.8786 20.8609 21.9139L16.7593 30.1171C16.6171 30.4015 16.543 30.716 16.543 31.0344C16.543 34.427 19.3028 37.1868 22.6954 37.1868C26.088 37.1868 28.8478 34.427 28.8478 31.0344C28.8478 30.716 28.7737 30.4015 28.6315 30.1171L26.0139 24.8819H32.9494V37.1868H30.8986C29.7651 37.1868 28.8478 38.1041 28.8478 39.2376C28.8478 40.3712 29.7651 41.2884 30.8986 41.2884H39.1019C40.2354 41.2884 41.1527 40.3712 41.1527 39.2376C41.1527 38.1041 40.2354 37.1868 39.1019 37.1868H37.0511V24.8819H43.9866L41.369 30.1171C41.2268 30.4015 41.1527 30.716 41.1527 31.0344C41.1527 34.427 43.9125 37.1868 47.3051 37.1868C50.6977 37.1868 53.4575 34.427 53.4575 31.0344C53.4575 30.716 53.3834 30.4015 53.2412 30.1171ZM22.6954 33.0852C21.702 33.0852 20.8729 32.3762 20.6846 31.4389L22.6954 27.4174L24.7061 31.4389C24.5179 32.3762 23.6888 33.0852 22.6954 33.0852ZM47.3051 33.0852C46.3117 33.0852 45.4826 32.3762 45.2944 31.4389L47.3051 27.4174L49.3159 31.4389C49.1276 32.3762 48.2985 33.0852 47.3051 33.0852Z"
          fill="url(#paint1_linear_504_12914)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_504_12914"
          x1="8.81529"
          y1="69.3684"
          x2="66.8023"
          y2="25.1979"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[0]} />
          <stop offset="1" stopColor={palette[1]} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_504_12914"
          x1="35.0003"
          y1="49.4916"
          x2="35.0003"
          y2="16.6788"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[2]} />
          <stop offset="1" stopColor={palette[3]} />
        </linearGradient>
        <clipPath id="clip0_504_12914">
          <rect width="70" height="70" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

PrivacySvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  palette: PropTypes.arrayOf(PropTypes.string),
};

export default PrivacySvg;
