import bcrypt from 'bcryptjs';
import { BCRYPT_SALT_ROUNDS } from '_constants/bcrypt';

export const createHash = async (plainText) => {
  try {
    const hash = await bcrypt.hash(plainText, BCRYPT_SALT_ROUNDS);
    return hash;
  } catch (e) {
    return null;
  }
};

export const compare = async (plainText, hash) => {
  return await bcrypt.compare(plainText, hash);
};
