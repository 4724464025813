import PropTypes from 'prop-types';

const GuardSvg = ({ width, height, palette = [] }) => {
  return (
    <svg
      width={`${width || 60}`}
      height={`${height || 60}`}
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M51.2109 28.2422H44.1797C45.1513 28.2422 45.9375 27.456 45.9375 26.4844V8.90625C45.9375 5.99836 43.572 3.63281 40.6641 3.63281H5.27344C2.36555 3.63281 0 5.99836 0 8.90625V30C0 32.9079 2.36555 35.2734 5.27344 35.2734H33.6328C34.6044 35.2734 35.3906 34.4872 35.3906 33.5156V31.4575C35.5672 31.3949 35.7391 31.3225 35.9066 31.2421C35.5761 31.9309 35.3906 32.702 35.3906 33.5156V38.2964C35.3906 43.8737 38.4634 49.0166 43.4072 51.7168C43.843 51.8605 44.7277 52.3246 45.9375 52.3246C47.0597 52.3246 47.904 51.9626 48.4678 51.7168C53.4116 49.0166 56.4844 43.8737 56.4844 38.2964V33.5156C56.4844 30.6077 54.1188 28.2422 51.2109 28.2422ZM38.389 28.7588C38.4693 28.5912 38.5417 28.419 38.6041 28.2422H40.6641C39.8498 28.2422 39.0782 28.4278 38.389 28.7588Z"
        fill={`url(#paint0_linear_237_16252${width + height})`}
      />
      <path
        d="M58.2422 28.2422C57.2577 28.2422 56.4844 27.4686 56.4844 26.4844C56.4844 25.4999 55.7111 24.7266 54.7266 24.7266H37.1484C36.1639 24.7266 35.3906 25.4999 35.3906 26.4844C35.3906 27.4686 34.6173 28.2422 33.6328 28.2422C32.6483 28.2422 31.875 29.0155 31.875 30V38.2969C31.875 46.6289 37.465 54.0116 45.4453 56.2969C45.6211 56.332 45.7618 56.3673 45.9375 56.3673C46.1132 56.3673 46.254 56.3321 46.4297 56.2969C54.4193 54.0141 60 46.6427 60 38.2969V30C60 29.0155 59.2267 28.2422 58.2422 28.2422ZM52.9688 38.2969C52.9688 42.621 50.5781 46.5585 46.7812 48.6328C46.4999 48.7382 46.2189 48.8086 45.9375 48.8086C45.6561 48.8086 45.3751 48.7382 45.0938 48.6328C41.2968 46.5585 38.9062 42.6211 38.9062 38.2969V33.5156C38.9062 32.5311 39.6796 31.7578 40.6641 31.7578H51.2109C52.1954 31.7578 52.9688 32.5311 52.9688 33.5156V38.2969ZM8.78906 14.1797H37.1484C38.12 14.1797 38.9062 13.3935 38.9062 12.4219C38.9062 11.4503 38.12 10.6641 37.1484 10.6641H8.78906C7.81746 10.6641 7.03125 11.4503 7.03125 12.4219C7.03125 13.3935 7.81746 14.1797 8.78906 14.1797ZM12.3047 24.7266H8.78906C7.81746 24.7266 7.03125 25.5128 7.03125 26.4844C7.03125 27.456 7.81746 28.2422 8.78906 28.2422H12.3047C13.2763 28.2422 14.0625 27.456 14.0625 26.4844C14.0625 25.5128 13.2763 24.7266 12.3047 24.7266Z"
        fill={`url(#paint1_linear_237_16252${width + height})`}
      />
      <defs>
        <linearGradient
          id={`paint0_linear_237_16252${width + height}`}
          x1="28.2422"
          y1="52.3246"
          x2="28.2422"
          y2="3.63281"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[0]} />
          <stop offset="1" stopColor={palette[1]} />
        </linearGradient>
        <linearGradient
          id={`paint1_linear_237_16252${width + height}`}
          x1="33.5156"
          y1="56.3673"
          x2="33.5156"
          y2="10.6641"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[2]} />
          <stop offset="1" stopColor={palette[3]} />
        </linearGradient>
      </defs>
    </svg>
  );
};

GuardSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  palette: PropTypes.arrayOf(PropTypes.string),
};

export default GuardSvg;
