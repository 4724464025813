import PropTypes from 'prop-types';

const ReduceStressSvg = ({ width, height, palette = [] }) => {
  return (
    <svg
      width={`${width || 80}`}
      height={`${height || 80}`}
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath={`url(#clip0_504_6943${width + height})`}>
        <path
          d="M74.9311 43.5435L62.9771 17.7134C57.3513 5.52444 44.5534 -1.50746 31.2399 0.273953C17.9732 2.00849 7.28453 12.4158 5.22215 25.5889C3.48699 36.5117 7.61299 47.1533 16.1442 54.0914C17.9263 55.4979 18.9106 57.5137 18.9106 59.6231V77.6562C18.9106 78.969 19.9418 80.0002 21.2546 80.0002H44.6942C46.007 80.0002 47.0382 78.969 47.0382 77.6562V65.6239H54.0701C59.7423 65.6239 64.4771 61.5922 65.5553 56.248L63.4459 53.904L65.7899 51.5601V46.8721H72.8218C73.6184 46.8721 74.3691 46.4501 74.7904 45.7941C75.2126 45.1374 75.3066 44.2467 74.9311 43.5435Z"
          fill={`url(#paint0_linear_504_6943${width + height})`}
        />
        <path
          d="M48.1824 19.5827C47.9993 19.6123 44.4558 20.2305 40.8713 21.748C39.4063 18.1405 37.337 15.1968 37.2317 15.0457C36.3527 13.8051 34.2835 13.8051 33.4045 15.0457C33.2992 15.1968 31.2299 18.1405 29.7649 21.748C26.1804 20.2304 22.6369 19.6123 22.4538 19.5827C21.7259 19.4476 20.9476 19.6925 20.4074 20.2305C19.8717 20.7639 19.6245 21.5261 19.7481 22.2723C21.8173 34.855 29.3574 37.4966 35.3181 37.4966C41.2788 37.4966 48.8187 34.8551 50.8881 22.2723C51.0117 21.5261 50.7645 20.7639 50.2289 20.2305C49.6887 19.6925 48.8965 19.4453 48.1824 19.5827ZM58.7578 51.5603C57.445 51.5603 56.4138 50.5291 56.4138 49.2163C56.4138 47.9035 55.3826 46.8723 54.0698 46.8723C52.7571 46.8723 51.7259 47.9035 51.7259 49.2163C51.7259 53.1074 54.8665 56.2482 58.7578 56.2482H65.5551C65.6959 55.4983 65.7897 54.701 65.7897 53.9042V51.5603H58.7578Z"
          fill={`url(#paint1_linear_504_6943${width + height})`}
        />
      </g>
      <defs>
        <linearGradient
          id={`paint0_linear_504_6943${width + height}`}
          x1="5.45918"
          y1="79.2783"
          x2="73.6339"
          y2="19.3407"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[0]} />
          <stop offset="1" stopColor={palette[1]} />
        </linearGradient>
        <linearGradient
          id={`paint1_linear_504_6943${width + height}`}
          x1="42.7532"
          y1="56.2482"
          x2="42.7532"
          y2="14.1153"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[2]} />
          <stop offset="1" stopColor={palette[3]} />
        </linearGradient>
        <clipPath id={`clip0_504_6943${width + height}`}>
          <rect width="80" height="80" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

ReduceStressSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  palette: PropTypes.arrayOf(PropTypes.string),
};

export default ReduceStressSvg;
