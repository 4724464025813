import {
  GTM_EVENTS,
  GA_ECOMMERCE_FIELDS,
  GTM_ENHANCED_CONVERSION_FIELDS,
} from '_constants/analytics';
import { LINKED_PRODUCT_CODES } from '_constants/products';

export const createLeadEventBody = (data) => {
  const mailingAddress = data.products.find((product) => {
    return product.hasOwnProperty('mailingAddress');
  }).mailingAddress;

  const enhancedConversions = {
    [GTM_ENHANCED_CONVERSION_FIELDS.customerPhone]: data.customer.phone,
    [GTM_ENHANCED_CONVERSION_FIELDS.customerEmail]: data.customer.email,
    [GTM_ENHANCED_CONVERSION_FIELDS.customerFirstName]: data.customer.firstName,
    [GTM_ENHANCED_CONVERSION_FIELDS.customerLastName]: data.customer.lastName,
    [GTM_ENHANCED_CONVERSION_FIELDS.customerCity]: mailingAddress.city,
    [GTM_ENHANCED_CONVERSION_FIELDS.customerCountry]: mailingAddress.country,
    [GTM_ENHANCED_CONVERSION_FIELDS.customerStreet]: mailingAddress.address1,
    [GTM_ENHANCED_CONVERSION_FIELDS.customerState]: mailingAddress.state,
    [GTM_ENHANCED_CONVERSION_FIELDS.customerZip]: mailingAddress.zip,
  };
  return {
    dataLayer: {
      event: GTM_EVENTS.lead,
      eventProps: { enhancedConversions },
    },
  };
};

export const createSaleEventBody = (data) => {
  const items = data.products
    ?.filter(
      (product) =>
        product.code.code !== LINKED_PRODUCT_CODES.incStateIncorporationFee
    )
    ?.map((product) => ({
      [GA_ECOMMERCE_FIELDS.id]: product.code.code,
      [GA_ECOMMERCE_FIELDS.name]: product.code.customerName,
      [GA_ECOMMERCE_FIELDS.price]: product.price || 0,
      [GA_ECOMMERCE_FIELDS.category]: product.code.category,
      [GA_ECOMMERCE_FIELDS.quantity]: 1,
      [GA_ECOMMERCE_FIELDS.currency]: 'USD',
    }));

  const ecommerce = {
    [GA_ECOMMERCE_FIELDS.cardType]: data.order.paymentDetails.cardType,
    [GA_ECOMMERCE_FIELDS.transactionId]: data.order.crmOrderId,
    [GA_ECOMMERCE_FIELDS.value]: items.reduce(
      (acc, item) => (acc += item[GA_ECOMMERCE_FIELDS.price]),
      0
    ),
    [GA_ECOMMERCE_FIELDS.currency]: 'USD',
  };

  if (items?.length) {
    ecommerce.items = items;
  }

  const enhancedConversions = {
    [GTM_ENHANCED_CONVERSION_FIELDS.customerPhone]: data.customer.phone,
    [GTM_ENHANCED_CONVERSION_FIELDS.customerEmail]: data.customer.email,
    [GTM_ENHANCED_CONVERSION_FIELDS.customerFirstName]: data.customer.firstName,
    [GTM_ENHANCED_CONVERSION_FIELDS.customerLastName]: data.customer.lastName,
    [GTM_ENHANCED_CONVERSION_FIELDS.customerCity]:
      data.customer.billingAddress.city,
    [GTM_ENHANCED_CONVERSION_FIELDS.customerCountry]:
      data.customer.billingAddress.country,
    [GTM_ENHANCED_CONVERSION_FIELDS.customerStreet]:
      data.customer.billingAddress.address1,
    [GTM_ENHANCED_CONVERSION_FIELDS.customerState]:
      data.customer.billingAddress.state,
    [GTM_ENHANCED_CONVERSION_FIELDS.customerZip]:
      data.customer.billingAddress.zip,
  };

  return {
    dataLayer: {
      event: GTM_EVENTS.sale,
      eventProps: { ecommerce, enhancedConversions },
    },
  };
};

export const createEcommerceEventProps = (
  productCodes = [],
  productNameMap = {},
  productCategoryMap = {},
  priceMap = {},
  feeMap = {},
  discountCouponType
) => {
  const items = productCodes
    ?.filter((code) => code !== LINKED_PRODUCT_CODES.incStateIncorporationFee)
    ?.map((code) => ({
      [GA_ECOMMERCE_FIELDS.id]: code,
      [GA_ECOMMERCE_FIELDS.name]: productNameMap[code],
      [GA_ECOMMERCE_FIELDS.price]: priceMap[code] || feeMap[code] || 0,
      [GA_ECOMMERCE_FIELDS.category]: productCategoryMap[code],
      [GA_ECOMMERCE_FIELDS.quantity]: 1,
      [GA_ECOMMERCE_FIELDS.currency]: 'USD',
    }));

  const ecommerce = {
    [GA_ECOMMERCE_FIELDS.value]: items.reduce(
      (acc, item) => (acc += item[GA_ECOMMERCE_FIELDS.price]),
      0
    ),
    [GA_ECOMMERCE_FIELDS.currency]: 'USD',
  };

  if (items?.length) {
    ecommerce.items = items;
  }

  if (discountCouponType) {
    ecommerce[GA_ECOMMERCE_FIELDS.coupon] = discountCouponType;
  }

  return { ecommerce };
};
