import { config } from '_config';
import { ROUTES, BLOG_SUB_ROUTES } from '_constants/routes';

export const getBlogSubRoute = (route) => {
  return route?.replace(ROUTES.blog, '');
};

export const getBlogSeoTitle = (route) => {
  const MAP = {
    [BLOG_SUB_ROUTES.tenBestReasonsToIncorporate]:
      'Top 10 Best Reasons to Incorporate Your Business Today | Incorpified™ ',
    [BLOG_SUB_ROUTES.howToFormAnLLCStepByStepGuide]:
      'How to Form an LLC: A Step-by-Step Guide | Incorpified™',
    [BLOG_SUB_ROUTES.understandingTheDifferentTypesOfBusinessEntities]:
      'Understanding the Different Types of Business Entities | Incorpified™',
    [BLOG_SUB_ROUTES.commonMistakesToAvoidWhenFormingAnLLC]:
      'Common Mistakes to Avoid When Forming an LLC | Incorpified™',
    [BLOG_SUB_ROUTES.choosingTheRightNameForYourBusiness]:
      'Choosing the Right Name for Your Business | Incorpified™',
    [BLOG_SUB_ROUTES.definingYourBrandIdentityComprehensiveGuide]:
      'Defining Your Brand Identity | Incorpified™',
    [BLOG_SUB_ROUTES.checkingDomainAndTrademarkAvailability]:
      'Checking Domain and Trademark Availability | Incorpified™',
    [BLOG_SUB_ROUTES.howToWriteABusinessPlanStepByStepGuide]:
      'How to Write a Business Plan: A Step-by-Step Guide | Incorpified™',
    [BLOG_SUB_ROUTES.registeringYourTrademarkGuide]:
      'Registering Your Trademark | Incorpified™',
    [BLOG_SUB_ROUTES.howToChooseBestStateToIncorporateBusiness]:
      'How to Choose the Best State to Incorporate Your Business | Incorpified™',
    [BLOG_SUB_ROUTES.benefitsOfFormingAnLlcForSmallBusinesses]:
      'The Benefits of Forming an LLC for Small Businesses| Incorpified™',
    [BLOG_SUB_ROUTES.understandingDifferencesBetweenLLCAndSCorp]:
      'Understanding the Differences Between LLC and S-Corp| Incorpified™',
    [BLOG_SUB_ROUTES.importanceOfOperatingAgreementsForLLCs]:
      'The Importance of Operating Agreements for LLCs| Incorpified™',
    [BLOG_SUB_ROUTES.essentialTaxTipsForNewBusinessOwners]:
      'Essential Tax Tips for New Business Owners| Incorpified™',
    [BLOG_SUB_ROUTES.roleOfRegisteredAgentsInBusinessFormation]:
      'The Role of Registered Agents in Business Formation| Incorpified™',
    [BLOG_SUB_ROUTES.topReasonsToUseProfessionalRegisteredAgentService]:
      'Top Reasons to Use a Professional Registered Agent Service| Incorpified™',
  };

  return MAP[route];
};

export const getBlogSeoDescription = (route) => {
  const MAP = {
    [BLOG_SUB_ROUTES.tenBestReasonsToIncorporate]:
      'Discover the top 10 reasons why incorporating your business can benefit you today. Learn about legal protections, tax advantages, and more',
    [BLOG_SUB_ROUTES.howToFormAnLLCStepByStepGuide]:
      'Learn how to form an LLC with our comprehensive step-by-step guide. Understand the process, requirements, and benefits of establishing your own Limited Liability Company.',
    [BLOG_SUB_ROUTES.understandingTheDifferentTypesOfBusinessEntities]:
      'Explore the various types of business entities, including LLCs, corporations, partnerships, and sole proprietorships. Learn the benefits and drawbacks of each to determine the best structure for your business.',
    [BLOG_SUB_ROUTES.commonMistakesToAvoidWhenFormingAnLLC]:
      'Learn about the common mistakes to avoid when forming an LLC. Ensure a smooth setup process by understanding the pitfalls and how to prevent them.',
    [BLOG_SUB_ROUTES.choosingTheRightNameForYourBusiness]:
      'Discover how to choose the perfect name for your business. Learn key factors, avoid common mistakes, and get tips for a unique and memorable name.',
    [BLOG_SUB_ROUTES.definingYourBrandIdentityComprehensiveGuide]:
      'Discover how to define your brand identity with our comprehensive guide. Learn the key elements, strategies, and tips to create a strong, memorable brand.',
    [BLOG_SUB_ROUTES.checkingDomainAndTrademarkAvailability]:
      'Ensure your business name is unique by checking domain and trademark availability. Learn the steps to verify and secure your brand identity effectively.',
    [BLOG_SUB_ROUTES.howToWriteABusinessPlanStepByStepGuide]:
      'Learn how to write a comprehensive business plan with our step-by-step guide. Discover essential components, tips, and strategies for a successful business plan.',
    [BLOG_SUB_ROUTES.registeringYourTrademarkGuide]:
      'Learn how to register your trademark with our comprehensive guide. Protect your brand with step-by-step instructions, from conducting a trademark search to maintaining your registration.',
    [BLOG_SUB_ROUTES.howToChooseBestStateToIncorporateBusiness]:
      'Discover how to choose the best state to incorporate your business. Learn about the key factors, benefits, and considerations to make an informed decision.',
    [BLOG_SUB_ROUTES.benefitsOfFormingAnLlcForSmallBusinesses]:
      'Discover the key benefits of forming an LLC for small businesses. Learn how an LLC can provide legal protection, tax advantages, and operational flexibility.',
    [BLOG_SUB_ROUTES.understandingDifferencesBetweenLLCAndSCorp]:
      'Learn the key differences between an LLC and an S-Corp. Discover which business structure is best for your needs, focusing on legal protection, tax advantages, and management flexibility.',
    [BLOG_SUB_ROUTES.importanceOfOperatingAgreementsForLLCs]:
      "Learn why an operating agreement is crucial for your LLC. Understand the key components, benefits, and how it protects your business and members' interests.",
    [BLOG_SUB_ROUTES.essentialTaxTipsForNewBusinessOwners]:
      "Discover essential tax tips for new business owners. Learn how to navigate tax obligations, maximize deductions, and avoid common pitfalls to ensure your business's financial success.",
    [BLOG_SUB_ROUTES.roleOfRegisteredAgentsInBusinessFormation]:
      'Discover the crucial role of registered agents in business formation. Learn about their responsibilities, benefits, and why having a registered agent is essential for your business.',
    [BLOG_SUB_ROUTES.topReasonsToUseProfessionalRegisteredAgentService]:
      'Learn the top reasons to use a professional registered agent service for your business. Discover how it ensures compliance, protects privacy, and offers peace of mind.',
  };

  return MAP[route];
};

export const getBlogImageAlt = (route) => {
  const MAP = {
    [BLOG_SUB_ROUTES.tenBestReasonsToIncorporate]:
      'Smiling business owner holding an "Open" sign, highlighting the benefits of incorporating your business, such as improved credibility and legal protections.',
    [BLOG_SUB_ROUTES.howToFormAnLLCStepByStepGuide]:
      'Two professionals discussing LLC formation with charts and a lightbulb idea icon, promoting a guide on how to start an LLC.',
    [BLOG_SUB_ROUTES.understandingTheDifferentTypesOfBusinessEntities]:
      'Illustration of various business entities: Non-profit, Sole Proprietor, C-Corp, S-Corp, Partnership, and Trust, showcasing different business structures.',
    [BLOG_SUB_ROUTES.commonMistakesToAvoidWhenFormingAnLLC]:
      'Concerned woman holding up her hands, symbolizing common mistakes to avoid when forming an LLC. Learn about these pitfalls to ensure a smooth business setup.',
    [BLOG_SUB_ROUTES.choosingTheRightNameForYourBusiness]:
      'Wooden blocks spelling out "BUSINESS" on a rustic wooden background, representing the importance of choosing the right name for your business.',
    [BLOG_SUB_ROUTES.definingYourBrandIdentityComprehensiveGuide]:
      'Diagram showing key elements of branding: identity, logo, design, strategy, and marketing, illustrating the components of defining a brand identity.',
    [BLOG_SUB_ROUTES.checkingDomainAndTrademarkAvailability]:
      'Hand holding a block with the "Registered Trademark" symbol, representing the importance of checking domain and trademark availability for business branding.',
    [BLOG_SUB_ROUTES.howToWriteABusinessPlanStepByStepGuide]:
      'Illustration of a business plan with key elements like goals, finance, competitor analysis, strategy, marketing, operations, timeline, and team, highlighting essential components of writing a business plan.',
    [BLOG_SUB_ROUTES.registeringYourTrademarkGuide]:
      'Business professional pointing towards the camera with a text overlay "Trademark Registration", emphasizing the importance of securing your brand through trademark registration.',
    [BLOG_SUB_ROUTES.howToChooseBestStateToIncorporateBusiness]:
      'Photo of the united states resembling businesses in every state.',
    [BLOG_SUB_ROUTES.benefitsOfFormingAnLlcForSmallBusinesses]:
      'LLC highlighted between tax words',
    [BLOG_SUB_ROUTES.understandingDifferencesBetweenLLCAndSCorp]:
      'Person thinking between an LLC or S Corporation',
    [BLOG_SUB_ROUTES.importanceOfOperatingAgreementsForLLCs]:
      'Image of LLC Operating Agreement on desk with a pen',
    [BLOG_SUB_ROUTES.essentialTaxTipsForNewBusinessOwners]:
      'W4 and Tax papers sitting on a table',
    [BLOG_SUB_ROUTES.roleOfRegisteredAgentsInBusinessFormation]:
      'Office with location marker over top',
    [BLOG_SUB_ROUTES.topReasonsToUseProfessionalRegisteredAgentService]:
      'Map of united states with certain states highlighted',
  };

  return MAP[route];
};

export const getBlogArticle = (route) => {
  const MAP = {
    [BLOG_SUB_ROUTES.tenBestReasonsToIncorporate]: {
      title: 'Top 10 Best Reasons to Incorporate Your Business Today',
      description: `Incorporating your business can be a game-changer, offering numerous benefits that can help your business thrive. From protecting your personal assets to enhancing your credibility and unlocking tax advantages, discover why incorporation might be the best decision for your business today. Read on to explore the top 10 reasons to incorporate your business and set yourself up for long-term success.`,
      imgSrc: `${config.basePath}/images${route}.png`,
      tags: ['Starting Your Business'],
      timestamp: 'May 15th, 2024',
      timeToReadMin: 4,
    },
    [BLOG_SUB_ROUTES.howToFormAnLLCStepByStepGuide]: {
      title: 'How to Form an LLC: A Step-by-Step Guide',
      description: `Forming an LLC can seem daunting, but with the right guidance, it’s a straightforward process. This step-by-step guide will walk you through each stage, from choosing a name to filing the necessary paperwork, ensuring you can confidently establish your LLC. Dive in to simplify your business formation journey today!`,
      imgSrc: `${config.basePath}/images${route}.png`,
      tags: ['Starting Your Business', 'LLC'],
      timestamp: 'May 15th, 2024',
      timeToReadMin: 4,
    },
    [BLOG_SUB_ROUTES.understandingTheDifferentTypesOfBusinessEntities]: {
      title: 'Understanding the Different Types of Business Entities',
      description: `Choosing the right business entity is crucial for your company's success and growth. This comprehensive guide breaks down the various types of business entities, highlighting their benefits and drawbacks to help you make an informed decision. Explore the differences and find the best fit for your business needs.`,
      imgSrc: `${config.basePath}/images${route}.png`,
      tags: ['Business Entities', 'LLC'],
      timestamp: 'May 15th, 2024',
      timeToReadMin: 4,
    },
    [BLOG_SUB_ROUTES.commonMistakesToAvoidWhenFormingAnLLC]: {
      title: 'Common Mistakes to Avoid When Forming an LLC',
      description: `Forming an LLC is a significant step, but it's easy to make errors that can cost you time and money. This article highlights common mistakes to avoid during the LLC formation process, ensuring a smooth and successful setup. Learn from others' missteps and set your business up for success from the start.`,
      imgSrc: `${config.basePath}/images${route}.png`,
      tags: ['Starting Your Business', 'LLC'],
      timestamp: 'May 15th, 2024',
      timeToReadMin: 4,
    },
    [BLOG_SUB_ROUTES.choosingTheRightNameForYourBusiness]: {
      title: 'Choosing the Right Name for Your Business',
      description: `Choosing the right name for your business is a critical step in building your brand and establishing a strong market presence. Discover expert tips and strategies to create a memorable, impactful name that resonates with your target audience and sets you apart from the competition.`,
      imgSrc: `${config.basePath}/images${route}.png`,
      tags: ['Starting Your Business'],
      timestamp: 'May 15th, 2024',
      timeToReadMin: 4,
    },
    [BLOG_SUB_ROUTES.definingYourBrandIdentityComprehensiveGuide]: {
      title: 'Defining Your Brand Identity',
      description: `Defining your brand identity is essential for creating a cohesive and recognizable image in the marketplace. This comprehensive guide will help you craft a compelling brand identity that reflects your values, resonates with your audience, and drives business success.`,
      imgSrc: `${config.basePath}/images${route}.png`,
      tags: ['Starting Your Business', 'Branding'],
      timestamp: 'May 15th, 2024',
      timeToReadMin: 4,
    },
    [BLOG_SUB_ROUTES.checkingDomainAndTrademarkAvailability]: {
      title: 'Checking Domain and Trademark Availability',
      description: `Defining your brand identity is essential for creating a cohesive and recognizable image in the marketplace. This comprehensive guide will help you craft a compelling brand identity that reflects your values, resonates with your audience, and drives business success.`,
      imgSrc: `${config.basePath}/images${route}.png`,
      tags: ['Starting Your Business', 'Branding'],
      timestamp: 'May 15th, 2024',
      timeToReadMin: 4,
    },
    [BLOG_SUB_ROUTES.howToWriteABusinessPlanStepByStepGuide]: {
      title: 'How to Write a Business Plan: A Step-by-Step Guide',
      description: `Learn how to create a comprehensive business plan with our step-by-step guide. From market analysis to financial projections, we'll help you outline your strategy for success. Start building a solid foundation for your business today!`,
      imgSrc: `${config.basePath}/images${route}.png`,
      tags: ['Starting Your Business', 'Manage Your Business', 'Branding'],
      timestamp: 'May 15th, 2024',
      timeToReadMin: 4,
    },
    [BLOG_SUB_ROUTES.registeringYourTrademarkGuide]: {
      title: 'Registering Your Trademark',
      description: `Registering your trademark is essential for protecting your brand and ensuring its distinctiveness in the marketplace. This guide provides a step-by-step approach to the trademark registration process, helping you secure your brand's identity and legal rights. Learn how to conduct a trademark search, prepare your application, and maintain your trademark effectively.`,
      imgSrc: `${config.basePath}/images${route}.png`,
      tags: ['Trademark', 'Manage Your Business'],
      timestamp: 'May 15th, 2024',
      timeToReadMin: 4,
    },
    [BLOG_SUB_ROUTES.howToChooseBestStateToIncorporateBusiness]: {
      title: 'How to Choose the Best State to Incorporate Your Business',
      description: `Choosing the best state to incorporate your business can significantly impact your legal protections, tax obligations, and overall costs. This comprehensive guide will help you navigate the key factors to consider, including business location, tax environment, and legal benefits, to make an informed decision.`,
      imgSrc: `${config.basePath}/images${route}.png`,
      tags: ['Choose the Best State to Incorporate Your Business'],
      timestamp: 'June 15th, 2024',
      timeToReadMin: 5,
    },
    [BLOG_SUB_ROUTES.benefitsOfFormingAnLlcForSmallBusinesses]: {
      title: 'The Benefits of Forming an LLC for Small Businesses',
      description: `Forming a Limited Liability Company (LLC) offers significant benefits for small business owners, including legal protection, tax advantages, and operational flexibility. This guide explores why an LLC might be the best choice for your business.`,
      imgSrc: `${config.basePath}/images${route}.png`,
      tags: ['Benefits of Forming an LLC for Small Businesses'],
      timestamp: 'June 19th, 2024',
      timeToReadMin: 6,
    },
    [BLOG_SUB_ROUTES.understandingDifferencesBetweenLLCAndSCorp]: {
      title: 'Understanding the Differences Between LLC and S-Corp',
      description: `Deciding between an LLC and an S-Corp can impact your business's legal protection, tax benefits, and management flexibility. This guide breaks down the key differences to help you choose the right structure.`,
      imgSrc: `${config.basePath}/images${route}.png`,
      tags: ['Differences Between LLC and S-Corp'],
      timestamp: 'June 15th, 2024',
      timeToReadMin: 3,
    },
    [BLOG_SUB_ROUTES.importanceOfOperatingAgreementsForLLCs]: {
      title: 'The Importance of Operating Agreements for LLCs',
      description: `An operating agreement is essential for LLCs, providing legal protection, operational flexibility, and a clear framework for decision-making. Learn about its key components and benefits.`,
      imgSrc: `${config.basePath}/images${route}.png`,
      tags: ['Importance of Operating Agreements LLCs'],
      timestamp: 'June 17th, 2024',
      timeToReadMin: 5,
    },
    [BLOG_SUB_ROUTES.essentialTaxTipsForNewBusinessOwners]: {
      title: 'Essential Tax Tips for New Business Owners',
      description: `Navigating business taxes can be complex, but with the right strategies and guidance, you can minimize your tax liability and ensure compliance. Learn essential tax tips to support your new business's financial success.`,
      imgSrc: `${config.basePath}/images${route}.png`,
      tags: ['Essential Tax Tips for New Business Owners'],
      timestamp: 'June 20th, 2024',
      timeToReadMin: 6,
    },
    [BLOG_SUB_ROUTES.roleOfRegisteredAgentsInBusinessFormation]: {
      title: 'The Role of Registered Agents in Business Formation',
      description: `An operating agreement is essential for LLCs, providing legal protection, operational flexibility, and a clear framework for decision-making. Learn about its key components and benefits.`,
      imgSrc: `${config.basePath}/images${route}.png`,
      tags: ['The Role of Registered Agents in Business Formation'],
      timestamp: 'June 20th, 2024',
      timeToReadMin: 5,
    },
    [BLOG_SUB_ROUTES.topReasonsToUseProfessionalRegisteredAgentService]: {
      title: 'Top Reasons to Use a Professional Registered Agent Service',
      description: `A professional registered agent service ensures your business stays compliant, protects your privacy, and provides reliable support. Discover the top reasons to choose a trusted service like Incorpified to manage your legal and administrative needs effectively.`,
      imgSrc: `${config.basePath}/images${route}.png`,
      tags: ['Reasons to Use a Registered Agent Service'],
      timestamp: 'June 22nd, 2024',
      timeToReadMin: 5,
    },
  };

  return MAP[route];
};

export const getBlogTags = (search) => {
  return Array.from(
    Object.values(BLOG_SUB_ROUTES).reduce((res, route) => {
      const {
        title,
        description,
        tags: articleTags,
      } = getBlogArticle(route) || {};

      if (search) {
        const fragment = `${title} ${description} ${articleTags.join(' ')}`;

        if (fragment.toLowerCase().search(search.toLowerCase()) !== -1) {
          articleTags.forEach((tag) => {
            res.add(tag);
          });
        }
      } else {
        articleTags.forEach((tag) => {
          res.add(tag);
        });
      }

      return res;
    }, new Set())
  );
};

export const getBlogSubRoutes = ({ tags = [] }) => {
  return Object.values(BLOG_SUB_ROUTES).reduce((res, route) => {
    const articleTags = getBlogArticle(route)?.tags;

    if (articleTags.some((tag) => tags.includes(tag))) {
      res.push(route);
    }

    return res;
  }, []);
};

export const getPaginatedBlogSubRoutes = ({
  currentPageIndex = 0,
  postsPerPage = 9,
  tags = [],
}) => {
  const blogSubRoutes = getBlogSubRoutes({ tags });
  const pageCount = Math.ceil(blogSubRoutes.length / postsPerPage);

  return {
    subRoutes: blogSubRoutes.slice(
      currentPageIndex * postsPerPage,
      currentPageIndex * postsPerPage + postsPerPage
    ),
    pageCount,
  };
};

export const getRandomBlogSubRoutes = ({ max = 3, exclude = [] } = {}) => {
  const allBlogSubRoutes = Object.values(BLOG_SUB_ROUTES).filter(
    (route) => !exclude.includes(route)
  );

  if (!allBlogSubRoutes.length) {
    return [];
  }

  if (allBlogSubRoutes.length <= max) {
    return allBlogSubRoutes;
  }

  return Array.from({ length: max }).reduce((res, _) => {
    const sanitizedBlogRoutes = allBlogSubRoutes.filter(
      (route) => !res.includes(route)
    );

    const randomRouteIndex = Math.round(
      (sanitizedBlogRoutes.length - 1) * Math.random()
    );

    res.push(sanitizedBlogRoutes[randomRouteIndex]);
    return res;
  }, []);
};
