import { useEffect, useState } from 'react';
import { getPaginatedBlogSubRoutes } from '_helpers/blog';

export const usePaginatedBlogSubRoutes = ({
  currentPageIndex = 0,
  postsPerPage = 9,
  tags = [],
}) => {
  const [paginatedBlogSubRoutes, setPaginatedBlogRoutes] = useState({
    subRoutes: [],
    pageCount: 1,
  });

  useEffect(() => {
    setPaginatedBlogRoutes(
      getPaginatedBlogSubRoutes({ currentPageIndex, postsPerPage, tags })
    );
  }, [currentPageIndex, postsPerPage, tags]);

  return paginatedBlogSubRoutes;
};
