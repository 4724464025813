import PropTypes from 'prop-types';

const ChevronUpSvg = ({ color, width, height }) => {
  return (
    <svg
      width={`${width || 16}`}
      height={`${height || 16}`}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.72003 10.0333L7.0667 5.68666C7.58003 5.17332 8.42003 5.17332 8.93336 5.68666L13.28 10.0333"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

ChevronUpSvg.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default ChevronUpSvg;
