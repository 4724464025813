import PropTypes from 'prop-types';

const AccountHolderSvg = ({ width, height, palette = [] }) => {
  return (
    <svg
      width={`${width || 60}`}
      height={`${height || 60}`}
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath={`url(#clip0_237_16232${width + height})`}>
        <path
          d="M58.2422 19.9219H1.75781C0.787031 19.9219 0 19.1348 0 18.1641V12.3047C0 11.5711 0.455625 10.9146 1.14281 10.658L29.385 0.111094C29.7816 -0.0370312 30.2183 -0.0370312 30.6149 0.111094L58.8571 10.658C59.5444 10.9146 60 11.5711 60 12.3047V18.1641C60 19.1348 59.213 19.9219 58.2422 19.9219ZM58.2422 60H1.75781C0.787031 60 0 59.213 0 58.2422V53.5547C0 52.5839 0.787031 51.7969 1.75781 51.7969H58.2422C59.213 51.7969 60 52.5839 60 53.5547V58.2422C60 59.213 59.213 60 58.2422 60ZM30 36.4453C26.7691 36.4453 24.1406 33.8168 24.1406 30.5859C24.1406 27.3551 26.7691 24.7266 30 24.7266C33.2309 24.7266 35.8594 27.3551 35.8594 30.5859C35.8594 33.8168 33.2309 36.4453 30 36.4453Z"
          fill={`url(#paint0_linear_237_16232${width + height})`}
        />
        <path
          d="M48.1641 19.9219H56.3672V51.7969H48.1641V19.9219ZM3.63281 51.7969H11.8359V19.9219H3.63281V51.7969ZM34.1016 11.1328C34.1016 10.162 33.3145 9.375 32.3438 9.375H27.6562C26.6855 9.375 25.8984 10.162 25.8984 11.1328C25.8984 12.1036 26.6855 12.8906 27.6562 12.8906H32.3438C33.3145 12.8906 34.1016 12.1036 34.1016 11.1328ZM34.0125 34.85C32.9631 35.838 31.5516 36.4453 30 36.4453C28.4484 36.4453 27.0369 35.838 25.9875 34.85C21.8258 36.4636 18.8672 40.5097 18.8672 45.2344C18.8672 46.2052 19.6542 46.9922 20.625 46.9922H39.375C40.3458 46.9922 41.1328 46.2052 41.1328 45.2344C41.1328 40.5097 38.1742 36.4636 34.0125 34.85Z"
          fill={`url(#paint1_linear_237_16232${width + height})`}
        />
      </g>
      <defs>
        <linearGradient
          id={`paint0_linear_237_16232${width + height}`}
          x1="30"
          y1="60"
          x2="30"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[2]} />
          <stop offset="1" stopColor={palette[3]} />
        </linearGradient>
        <linearGradient
          id={`paint1_linear_237_16232${width + height}`}
          x1="30"
          y1="51.7969"
          x2="30"
          y2="9.375"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[0]} />
          <stop offset="1" stopColor={palette[1]} />
        </linearGradient>
        <clipPath id={`clip0_237_16232${width + height}`}>
          <rect width="60" height="60" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

AccountHolderSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  palette: PropTypes.arrayOf(PropTypes.string),
};

export default AccountHolderSvg;
