import PropTypes from 'prop-types';

const RocketSvg = ({ width, height, palette = [] }) => {
  return (
    <svg
      width={`${width || 70}`}
      height={`${height || 70}`}
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_488_11058)">
        <path
          d="M40.1949 29.8056H25.6937L18.4431 42.8566L16.9931 44.3067C14.5859 46.7139 14.5859 50.6001 16.9931 53.0074C19.4001 55.4145 23.2865 55.4146 25.6938 53.0074L27.1439 51.5574L40.1949 44.3068V29.8056ZM15.9265 39.0716C16.5949 38.9441 17.1542 38.4953 17.4247 37.8706C18.9428 34.3643 22.3174 29.194 26.454 24.0407C26.8732 23.5166 27.0106 22.8214 26.8193 22.1771C26.4279 20.8508 25.1539 20.7488 24.892 20.6095C24.4884 20.4749 14.8799 17.4175 5.39179 26.9056C-1.77665 34.0741 2.31452 38.3296 2.49157 38.5066C3.29316 39.3082 4.59034 39.3082 5.39179 38.5066C7.79079 36.1078 11.6936 36.1078 14.0926 38.5066C14.5726 38.9865 15.2595 39.1976 15.9265 39.0716ZM47.8235 43.1812C47.1792 42.99 46.4838 43.1273 45.9599 43.5465C40.6268 47.8261 35.5938 51.1143 32.147 52.5672C31.5182 52.8334 31.0622 53.3942 30.9304 54.064C30.8015 54.7338 31.0112 55.4249 31.4941 55.9078C33.893 58.3066 33.893 62.2096 31.4941 64.6086C30.6926 65.4101 30.6926 66.7073 31.4941 67.5088C31.6712 67.6857 35.9267 71.777 43.0951 64.6086C52.5833 55.1204 49.5258 45.512 49.3913 45.1112C49.2583 44.8615 49.1468 43.5697 47.8235 43.1812ZM69.7773 1.96335C69.6324 1.06428 68.9363 0.36825 68.037 0.223054C67.5152 0.164949 59.7714 -0.850051 50.0846 1.99206L56.081 13.7888L68.0082 19.9156C70.8504 10.229 69.8353 2.4852 69.7773 1.96335ZM43.0951 15.3044C39.9048 18.4947 39.9048 23.715 43.0951 26.9054C46.3143 30.1245 51.5058 30.0957 54.6961 26.9054C57.9153 23.6862 57.9153 18.5235 54.6961 15.3044C51.5058 12.114 46.3144 12.0852 43.0951 15.3044Z"
          fill="url(#paint0_linear_488_11058)"
        />
        <path
          d="M50.0845 1.99219C43.7329 3.81957 36.5694 7.32873 30.0438 13.8543C27.8976 16.0005 25.5483 18.6397 23.2571 21.4527C18.9067 26.9052 15.3105 32.4158 13.6864 36.2441C13.3383 36.9982 13.4834 37.8973 14.0925 38.5063L18.4428 42.8567L28.5937 32.7058C31.0009 30.2986 34.8871 30.2986 37.2944 32.7058C39.7305 35.1418 39.7305 38.9704 37.2944 41.4066L27.1436 51.5574L31.494 55.9078C32.074 56.4877 32.973 56.6908 33.727 56.3428C37.5263 54.7477 42.921 51.2673 48.5474 46.743C51.3603 44.4518 53.9998 42.1026 56.1459 39.9565C62.6715 33.4309 66.1807 26.2674 68.0078 19.9157L50.0845 1.99219ZM54.6959 26.9054C51.5055 30.0957 46.3142 30.1245 43.0949 26.9054C39.9045 23.715 39.9045 18.4947 43.0949 15.3044C46.3141 12.0852 51.5055 12.114 54.6959 15.3044C57.9151 18.5235 57.9151 23.6862 54.6959 26.9054ZM14.1193 55.9094C13.3177 55.108 12.0206 55.108 11.219 55.9094L0.601187 66.4987C-0.200395 67.3003 -0.200395 68.5975 0.601187 69.3989C1.40277 70.2003 2.69996 70.2005 3.5014 69.3989L14.1193 58.8096C14.9208 58.008 14.9208 56.7109 14.1193 55.9094ZM17.0195 61.7098L14.1193 64.6101C13.3177 65.4116 13.3177 66.7088 14.1193 67.5103C14.9208 68.3119 16.218 68.3119 17.0195 67.5103L19.9197 64.6101C20.7213 63.8086 20.7213 62.5113 19.9197 61.7098C19.1181 60.9084 17.8211 60.9084 17.0195 61.7098ZM5.41847 55.9094L8.31869 53.0092C9.12027 52.2076 9.12027 50.9104 8.31869 50.109C7.51711 49.3074 6.21992 49.3074 5.41847 50.109L2.51826 53.0092C1.71667 53.8106 1.71667 55.108 2.51826 55.9094C3.31984 56.7109 4.61703 56.7109 5.41847 55.9094Z"
          fill="url(#paint1_linear_488_11058)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_488_11058"
          x1="16.9955"
          y1="52.9976"
          x2="69.1848"
          y2="0.808251"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[2]} />
          <stop offset="0.5028" stopColor={palette[3]} />
          <stop offset="1" stopColor={palette[4]} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_488_11058"
          x1="0.606403"
          y1="69.3864"
          x2="59.0426"
          y2="10.9502"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[0]} />
          <stop offset="1" stopColor={palette[1]} />
        </linearGradient>
        <clipPath id="clip0_488_11058">
          <rect width="70" height="70" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

RocketSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  palette: PropTypes.arrayOf(PropTypes.string),
};

export default RocketSvg;
