import PropTypes from 'prop-types';

const ChartSvg = ({ width, height, palette = [] }) => {
  return (
    <svg
      width={`${width || 80}`}
      height={`${height || 80}`}
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath={`url(#clip0_488_2946${width + height})`}>
        <path
          d="M40.0002 32.9689H44.6878C45.9832 32.9689 47.0315 31.9207 47.0315 30.6252C47.0315 29.3297 45.9832 28.2814 44.6878 28.2814H42.344V25.9377C42.344 24.6422 41.2957 23.5939 40.0002 23.5939C38.7048 23.5939 37.6565 24.6422 37.6565 25.9377V28.7131C34.9343 29.6838 32.9689 32.2611 32.9689 35.3127V40.0002C32.9689 41.2957 34.0172 42.344 35.3127 42.344H42.344V44.6878C42.344 45.9809 41.2912 47.0315 40.0002 47.0315H35.3127C34.0172 47.0315 32.9689 48.0798 32.9689 49.3753C32.9689 50.6708 34.0172 51.7191 35.3127 51.7191H37.6565V54.0628C37.6565 55.3583 38.7048 56.4066 40.0002 56.4066C41.2957 56.4066 42.344 55.3583 42.344 54.0628V51.2873C45.0662 50.3167 47.0315 47.7393 47.0315 44.6878V40.0002C47.0315 38.7048 45.9832 37.6565 44.6878 37.6565H37.6565V35.3127C37.6565 34.0194 38.7093 32.9689 40.0002 32.9689ZM65.3376 10.2271C65.2963 9.55646 64.9668 8.93615 64.4358 8.52192C57.3953 3.04924 48.945 0 40.0002 0C18.032 0 0 18.0298 0 40.0002C0 60.0596 15.1435 77.5033 35.0243 79.9822C35.7285 80.0661 36.3836 79.8391 36.8644 79.4145C37.368 78.9683 37.6563 78.3297 37.6563 77.6567V62.9663C37.6563 61.8516 36.8735 60.8927 35.7794 60.6707C26.0017 58.6771 18.9064 49.984 18.9064 40.0002C18.9064 28.3684 28.3684 18.9064 40.0002 18.9064C44.1521 18.9064 48.1714 20.1171 51.623 22.4059C52.5477 23.0262 53.7836 22.9026 54.5755 22.1106L64.6555 12.0305C65.1316 11.5546 65.3788 10.8977 65.3376 10.2271Z"
          fill={`url(#paint0_linear_488_2946${width + height})`}
        />
        <path
          d="M77.6565 42.3437H62.9661C61.8537 42.3437 60.8925 43.1287 60.6681 44.2206C58.9788 52.5222 52.5199 58.9789 44.2205 60.6704C43.1266 60.8924 42.3438 61.8513 42.3438 62.966V77.6564C42.3438 78.3294 42.6322 78.968 43.1356 79.4142C43.6166 79.8389 44.2724 80.0656 44.9758 79.9819C63.0714 77.7273 77.725 63.0691 79.9818 44.9782C80.0641 44.31 79.8582 43.6393 79.4141 43.1357C78.9701 42.6321 78.3294 42.3437 77.6565 42.3437ZM60.6728 35.7793C60.8971 36.871 61.8584 37.6562 62.9707 37.6562H77.6565C78.3294 37.6562 78.9702 37.3677 79.4143 36.8643C79.8583 36.3585 80.0643 35.6879 79.9819 35.0218C79.0985 27.9402 75.8614 21.2133 71.4714 15.5645C71.0594 15.0357 70.4369 14.7062 69.7686 14.6649C69.1094 14.6398 68.4411 14.8709 67.965 15.347L57.8987 25.4178C57.1113 26.2052 56.9877 27.4411 57.6012 28.3681C59.0981 30.6318 60.1326 33.1243 60.6728 35.7793Z"
          fill={`url(#paint1_linear_488_2946${width + height})`}
        />
      </g>
      <defs>
        <linearGradient
          id={`paint0_linear_488_2946${width + height}`}
          x1="32.671"
          y1="79.9995"
          x2="32.671"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[0]} />
          <stop offset="1" stopColor={palette[1]} />
        </linearGradient>
        <linearGradient
          id={`paint1_linear_488_2946${width + height}`}
          x1="61.1717"
          y1="79.9992"
          x2="61.1717"
          y2="14.6631"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[2]} />
          <stop offset="1" stopColor={palette[3]} />
        </linearGradient>
        <clipPath id={`clip0_488_2946${width + height}`}>
          <rect width="80" height="80" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

ChartSvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  palette: PropTypes.arrayOf(PropTypes.string),
};

export default ChartSvg;
