import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { IPService } from '_services';
import { processResponse, processError } from '_helpers/api';

export const getGeolocation = createAsyncThunk(
  'general/getGeolocation',
  async function (_, { rejectWithValue }) {
    try {
      const response = await IPService.getGeolocation();
      return processResponse(response);
    } catch (e) {
      return rejectWithValue(processError(e));
    }
  }
);

const generalSlice = createSlice({
  name: 'general',
  initialState: {
    device: null,
    salePageURL: null,
    geolocation: {
      data: null,
      error: null,
      loading: false,
    },
  },
  reducers: {
    refreshDevice(state, action) {
      state.device = action.payload;
    },
    refreshSalePageURL(state, action) {
      state.salePageURL = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGeolocation.pending, (state) => {
        state.geolocation.error = null;
        state.geolocation.loading = true;
      })
      .addCase(getGeolocation.fulfilled, (state, action) => {
        state.geolocation.data = action.payload;
        state.geolocation.loading = false;
      })
      .addCase(getGeolocation.rejected, (state, action) => {
        state.geolocation.error = action.payload;
        state.geolocation.loading = false;
      });
  },
});

export const { refreshDevice, refreshSalePageURL } = generalSlice.actions;

export default generalSlice.reducer;
