import { createSelector } from '@reduxjs/toolkit';
import { PRODUCT_CODES, UPSELL_PRODUCT_CODES_ARRAY } from '_constants/products';
import { getForbiddenStatesKeys } from '_helpers/upsellFlow';

export const selectActivities = (state) => state.variants.activities.data;
export const selectActivitiesError = (state) => state.variants.activities.error;
export const selectActivitiesLoading = (state) =>
  state.variants.activities.loading;

export const selectStates = (state) => state.variants.states.data;
export const selectStatesError = (state) => state.variants.states.error;
export const selectStatesLoading = (state) => state.variants.states.loading;

export const selectStateOptions = createSelector([selectStates], (states) =>
  states.map(({ code, name }) => ({ title: name, value: code }))
);

export const selectProcessingOptions = (state) =>
  state.variants.processingOptions.data;
export const selectProcessingOptionsError = (state) =>
  state.variants.processingOptions.error;
export const selectProcessingOptionsLoading = (state) =>
  state.variants.processingOptions.loading;

export const selectProcessingCodes = createSelector(
  [selectProcessingOptions],
  (processingOptions) => processingOptions.map(({ code }) => code)
);

export const selectPrices = (state) => state.variants.prices.data;
export const selectPricesError = (state) => state.variants.prices.error;
export const selectPricesLoading = (state) => state.variants.prices.loading;

export const selectPriceMapByProductCode = (productCode) =>
  createSelector([selectPrices], (prices) => {
    return prices
      .filter(({ code: { code } }) => code === productCode)
      .reduce((res, { option, price }) => {
        res[option] = price;
        return res;
      }, {});
  });

export const selectPriceMapByProcessingCode = (processingCode) =>
  createSelector([selectPrices], (prices) => {
    return prices
      .filter(({ option }) => option === processingCode)
      .reduce((res, { code: { code }, price }) => {
        res[code] = price;
        return res;
      }, {});
  });

export const selectRushProcessingPriceByProcessingCode = (processingCode) =>
  createSelector([selectPrices], (prices) => {
    return (
      prices.find(
        ({ option, code: { code } }) =>
          option === processingCode && code === PRODUCT_CODES.rushProcessing
      )?.price || null
    );
  });

export const selectRegisteredAgentPriceByProcessingCode = (processingCode) =>
  createSelector([selectPrices], (prices) => {
    return (
      prices.find(
        ({ option, code: { code } }) =>
          option === processingCode && code === PRODUCT_CODES.registeredAgent
      )?.price || null
    );
  });

export const selectFees = (state) => state.variants.fees.data;
export const selectFeesError = (state) => state.variants.fees.error;
export const selectFeesLoading = (state) => state.variants.fees.loading;

export const selectFeeMapByProductCode = (productCode) =>
  createSelector([selectFees], (fees) => {
    return fees
      .filter(({ code: { code } }) => code === productCode)
      .reduce((res, { option, price }) => {
        res[option] = price;
        return res;
      }, {});
  });

export const selectFeeMapByProcessingCode = (processingCode) =>
  createSelector([selectFees], (fees) => {
    return fees
      .filter(({ option }) => option === processingCode)
      .reduce((res, { code: { code }, price }) => {
        res[code] = price;
        return res;
      }, {});
  });

export const selectUpsellOffers = (state) => state.variants.upsellOffers.data;
export const selectUpsellOffersError = (state) =>
  state.variants.upsellOffers.error;
export const selectUpsellOffersLoading = (state) =>
  state.variants.upsellOffers.loading;

export const selectAvailableUpsellProductCodes = (productCodesFromOrder) =>
  createSelector([selectUpsellOffers], (upsellOffers) => {
    return upsellOffers
      .filter(
        ({ finishedProduct, upsellOffer, disabled }) =>
          productCodesFromOrder?.includes(finishedProduct?.code) &&
          !productCodesFromOrder?.includes(upsellOffer?.code) &&
          !disabled
      )
      .map(({ upsellOffer }) => upsellOffer?.code)
      .reduce((res, code) => {
        res.push(code);
        return res;
      }, []);
  });

export const selectAllowedUpsellProductCodes = (
  productCodesFromOrder,
  organizedState
) =>
  createSelector(
    [selectAvailableUpsellProductCodes(productCodesFromOrder)],
    (availableUpsellProductCodes) => {
      return UPSELL_PRODUCT_CODES_ARRAY.reduce((res, code) => {
        const forbiddenStatesKeys = getForbiddenStatesKeys(code);

        if (
          availableUpsellProductCodes.includes(code) &&
          !productCodesFromOrder.includes(code) &&
          !forbiddenStatesKeys.includes(organizedState)
        ) {
          if (
            code === PRODUCT_CODES.incSalesTaxPermit &&
            !productCodesFromOrder.includes(PRODUCT_CODES.incEIN)
          ) {
            return res;
          }

          if (
            code === PRODUCT_CODES.incSCorp &&
            !productCodesFromOrder.includes(PRODUCT_CODES.incEIN)
          ) {
            return res;
          }

          res.push(code);
        }

        return res;
      }, []);
    }
  );

export const selectFileTypes = (state) => state.variants.fileTypes.data;
export const selectFileTypesError = (state) => state.variants.fileTypes.error;
export const selectFileTypesLoading = (state) =>
  state.variants.fileTypes.loading;
