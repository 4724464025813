import PropTypes from 'prop-types';

const HappySvg = ({ width, height, palette = [] }) => {
  return (
    <svg
      width={`${width || 70}`}
      height={`${height || 70}`}
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_713_11331)">
        <path
          d="M35 70C47.4588 70 57.5586 59.9002 57.5586 47.4414C57.5586 34.9826 47.4588 24.8828 35 24.8828C22.5412 24.8828 12.4414 34.9826 12.4414 47.4414C12.4414 59.9002 22.5412 70 35 70Z"
          fill="url(#paint0_linear_713_11331)"
        />
        <path
          d="M39.1155 19.4693L35 17.3056L30.8845 19.4691C29.3801 20.26 27.6216 18.9825 27.909 17.3072L28.695 12.7245L25.3655 9.47922C24.1483 8.29278 24.82 6.22559 26.502 5.98127L31.1033 5.31272L33.161 1.14321C33.9133 -0.381069 36.0868 -0.381069 36.839 1.14321L38.8968 5.31272L43.4981 5.98127C45.1801 6.22573 45.8518 8.29278 44.6346 9.47922L41.3051 12.7247L42.0911 17.3073C42.3785 18.9827 40.62 20.2602 39.1155 19.4693ZM14.3716 19.4693L10.2561 17.3057L6.14063 19.4693C4.63618 20.2602 2.8777 18.9827 3.16508 17.3073L3.95108 12.7247L0.621566 9.47922C-0.595641 8.29278 0.0760577 6.22559 1.75811 5.98127L6.35938 5.31272L8.41713 1.14321C9.16936 -0.381069 11.3429 -0.381069 12.0951 1.14321L14.1529 5.31272L18.7542 5.98127C20.4362 6.22573 21.1079 8.29278 19.8907 9.47922L16.5612 12.7247L17.3472 17.3073C17.6346 18.9827 15.8762 20.2602 14.3716 19.4693ZM63.8595 19.4693L59.7439 17.3057L55.6284 19.4693C54.124 20.2602 52.3655 18.9827 52.6529 17.3073L53.4389 12.7247L50.1094 9.47922C48.8922 8.29278 49.5639 6.22559 51.2459 5.98127L55.8472 5.31272L57.9049 1.14321C58.6572 -0.381069 60.8307 -0.381069 61.583 1.14321L63.6407 5.31272L68.242 5.98127C69.924 6.22573 70.5957 8.29278 69.3785 9.47922L66.049 12.7247L66.835 17.3073C67.1222 18.9827 65.3639 20.2602 63.8595 19.4693ZM35 63.164C28.5922 63.164 23.3789 57.9508 23.3789 51.5429C23.3789 50.4104 24.2972 49.4922 25.4297 49.4922H44.5704C45.7029 49.4922 46.6211 50.4104 46.6211 51.5429C46.6211 57.9508 41.4079 63.164 35 63.164ZM26.7969 37.1875C25.6643 37.1875 24.7461 38.1057 24.7461 39.2382C24.7461 40.3708 25.6643 41.289 26.7969 41.289C27.9295 41.289 28.8477 40.3708 28.8477 39.2382C28.8477 38.1057 27.9295 37.1875 26.7969 37.1875ZM43.2032 37.1875C42.0706 37.1875 41.1524 38.1057 41.1524 39.2382C41.1524 40.3708 42.0706 41.289 43.2032 41.289C44.3357 41.289 45.2539 40.3708 45.2539 39.2382C45.2539 38.1057 44.3357 37.1875 43.2032 37.1875Z"
          fill="url(#paint1_linear_713_11331)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_713_11331"
          x1="35"
          y1="70.0001"
          x2="35"
          y2="24.8828"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[2]} />
          <stop offset="1" stopColor={palette[3]} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_713_11331"
          x1="35"
          y1="63.164"
          x2="35"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette[0]} />
          <stop offset="1" stopColor={palette[1]} />
        </linearGradient>
        <clipPath id="clip0_713_11331">
          <rect width="70" height="70" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

HappySvg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  palette: PropTypes.arrayOf(PropTypes.string),
};

export default HappySvg;
