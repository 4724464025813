import PropTypes from 'prop-types';

const StepDoneSvg = ({ color, width, height }) => {
  return (
    <svg
      width={`${width || 30}`}
      height={`${height || 30}`}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="15" cy="15" r="7" transform="rotate(90 15 15)" fill={color} />
      <rect
        x="29.5"
        y="0.5"
        width="29"
        height="29"
        rx="14.5"
        transform="rotate(90 29.5 0.5)"
        stroke={color}
      />
    </svg>
  );
};

StepDoneSvg.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default StepDoneSvg;
