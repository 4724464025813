import PropTypes from 'prop-types';

const TaskSvg = ({ color, width, height }) => {
  return (
    <svg
      width={`${width || 30}`}
      height={`${height || 30}`}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M21.285 0H8.715C3.255 0 0 3.255 0 8.715V21.285C0 26.745 3.255 30 8.715 30H21.285C26.745 30 30 26.745 30 21.285V8.715C30 3.255 26.745 0 21.285 0Z"
        fill={color}
      />
      <path
        d="M24.4648 10.3051C24.4648 10.9201 23.9698 11.4301 23.3398 11.4301H15.4648C14.8498 11.4301 14.3398 10.9201 14.3398 10.3051C14.3398 9.69005 14.8498 9.18005 15.4648 9.18005H23.3398C23.9698 9.18005 24.4648 9.69005 24.4648 10.3051Z"
        fill={color}
      />
      <path
        d="M11.9548 8.84997L8.57984 12.225C8.35484 12.45 8.06984 12.555 7.78484 12.555C7.49984 12.555 7.19984 12.45 6.98984 12.225L5.86484 11.1C5.41484 10.665 5.41484 9.94497 5.86484 9.50997C6.29984 9.07497 7.00484 9.07497 7.45484 9.50997L7.78484 9.83997L10.3648 7.25997C10.7998 6.82497 11.5048 6.82497 11.9548 7.25997C12.3898 7.69497 12.3898 8.41497 11.9548 8.84997Z"
        fill={color}
      />
      <path
        d="M24.4648 20.8051C24.4648 21.4201 23.9698 21.9301 23.3398 21.9301H15.4648C14.8498 21.9301 14.3398 21.4201 14.3398 20.8051C14.3398 20.1901 14.8498 19.6801 15.4648 19.6801H23.3398C23.9698 19.6801 24.4648 20.1901 24.4648 20.8051Z"
        fill={color}
      />
      <path
        d="M11.9548 19.35L8.57984 22.725C8.35484 22.95 8.06984 23.055 7.78484 23.055C7.49984 23.055 7.19984 22.95 6.98984 22.725L5.86484 21.6C5.41484 21.165 5.41484 20.445 5.86484 20.01C6.29984 19.575 7.00484 19.575 7.45484 20.01L7.78484 20.34L10.3648 17.76C10.7998 17.325 11.5048 17.325 11.9548 17.76C12.3898 18.195 12.3898 18.915 11.9548 19.35Z"
        fill={color}
      />
    </svg>
  );
};

TaskSvg.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default TaskSvg;
